import {create, useStore} from 'zustand';

export interface ConnectWalletModalStore {
  isOpened: boolean;
}

export const connectWalletModalStore = create<ConnectWalletModalStore>(() => ({
  isOpened: false,
}));

export const useConnectWalletModalStore = () =>
  useStore(connectWalletModalStore);
