import {useStepsStore} from '../Login/store/useStepsStore';

export const StepsOrdersView = () => {
  const {stepsArray, activeStep} = useStepsStore();

  const activeStepIndex = stepsArray.findIndex(item => item.id === activeStep);

  return (
    <span className="text-[#E5FD55] font-mono text-[18px] font-normal uppercase leading-[20px]">
      <div>step {activeStepIndex + 1}</div>
    </span>
  );
};
