import {useShallow} from 'zustand/react/shallow';
import {woodleStore} from '../../../../store';
import classNames from 'classnames';
import './checkKeyStyles.css';
import {LongArrow} from '../../../../../../assets/svgComponents/LongArrow';
import {woodleService} from '../../../../service';

export const CheckKey = () => {
  const isWordEntered = woodleStore(
    useShallow(
      ({currentWoodle, word}) => word.length === currentWoodle?.wordLength
    )
  );

  return (
    <div
      className={classNames(
        'relative border cursor-pointer rounded-md md:rounded-lg border-l-300 flex items-center justify-center h-9 col-span-3 uppercase font-bold text-lg leading-6',
        isWordEntered && 'chackKeyGradientBG border-none'
      )}
      onClick={woodleService.handleSubmit}
    >
      Check
      <div
        className={classNames(
          'absolute -right-[132px] flex-col max-w-[116px] uppercase hidden cursor-auto',
          isWordEntered && '-top-[10%] md:flex'
        )}
      >
        {
          <>
            <div className="w-full font-bold text-end font-mono text-l-800 text-xs">
              Press CHECK
            </div>
            <div>
              <LongArrow className="rotate-180" />
            </div>
          </>
        }
      </div>
    </div>
  );
};
