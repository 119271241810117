import blockedIcon from '../assets/levelBlockedIcon.svg';
import checkIcon from '../assets/levelCheckIcon.svg';
import {UserData} from '../modules/AccountProgress/store/useUserStore';

export const getLevelStateIcon = (userData: UserData, levelValue: number) => {
  if (userData.levelData.progress !== undefined) {
    if ((userData?.levelData?.currentLevel?.value || 0) >= levelValue) {
      return checkIcon;
    } else if (
      (userData?.levelData?.currentLevel?.value || 0) + 1 ===
      levelValue
    ) {
      return '';
    } else {
      return blockedIcon;
    }
  }
  return blockedIcon;
};
