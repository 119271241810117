import {useQuizStore} from '../store/useQuizStore';

import {Cross} from '../../../assets/svgComponents/Cross';
import {QuestionState} from '../types';

export interface IQuizHeaderProps {
  handleClose: () => void;
}

export function QuizHeader({handleClose}: IQuizHeaderProps) {
  const {
    currentQuestionNumber,
    questionsAmount,
    currentQuestionState,
    previousAnswers,
  } = useQuizStore();

  return (
    <>
      <div className="flex rounded-t-20 justify-between items-center p-6 text-l-800 font-bold leading-6 font-mono">
        <div className="text-xs uppercase">
          Question {currentQuestionNumber + 1}
          <span className="text-l-500"> of {questionsAmount}</span>
        </div>
        <h3 className="text-white text-xl leading-6 font-sans order-first md:-order-none">
          Quiz
        </h3>
        <button
          onClick={handleClose}
          className="flex cursor-pointer h-6 items-center"
        >
          <Cross className="" />
        </button>
      </div>
      <div className="bg-[#232526] w-full max-w-[638px] h-[2px]">
        <div
          className="bg-green-200 h-[2px]"
          style={{
            width: `${
              ((previousAnswers.length -
                (currentQuestionState === QuestionState.ERROR ? 1 : 0)) /
                questionsAmount) *
              100
            }%`,
          }}
        ></div>
      </div>
    </>
  );
}
