import {signInWithPopup} from 'firebase/auth';
import {NavigateFunction} from 'react-router-dom';
import {auth, provider} from '../configs/firebase.config';
import {signInStore} from '../modules/Login/store/useSignInStore';
import {signUpStore} from '../modules/Login/store/useSignUpStore';
import {handleSignInError} from '../utilities/axiosErrors';
import {$api} from './interceptor';
import {Auth} from '../types/common';

const SignIn = () => {
  const {setState, getState} = signInStore;
  const {getState: getSignUpState, setState: setSignUpState} = signUpStore;

  const sendOtpRequest = async (
    navigate: NavigateFunction,
    setLoading?: (isLoading: boolean) => void
  ) => {
    const {emailSignIn} = getState();
    try {
      if (setLoading) {
        setLoading(false);
      } else {
        setState({isLoading: true});
      }
      const requestData = {
        email: emailSignIn,
      };
      const response = await $api.post('/otp/request', requestData);
      if (response.status === 200) {
        setState({
          messageFromRequestSignIn: '',
        });
        setSignUpState({isActiveButton: false});
        navigate('/code' + window.location.search);
      }
    } catch (error) {
      handleSignInError(error, setState);
    } finally {
      setState({isLoading: false});
    }
  };

  const sendOtpLoginRequest = async (navigate: NavigateFunction) => {
    const {emailSignIn} = getState();
    const {code: signUpCode} = getSignUpState();
    try {
      setState({isLoading: true});
      const requestData = {
        email: emailSignIn,
        otpCode: signUpCode,
      };
      const response = await $api.post<Auth>('/auth/otp', requestData);

      const accessToken: string = response?.data?.tokens?.access;
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
      }

      if (response.status === 201) {
        if (response.data.user.isNew) {
          setSignUpState({isNew: true});
          navigate('/registration' + window.location.search);
        } else {
          setSignUpState({isNew: false});
          navigate('/dashboard');
          setState({
            messageFromRequestSignIn: '',
          });
        }
      }
    } catch (error) {
      handleSignInError(error, setState);
    } finally {
      setState({isLoading: false});
    }
  };

  const googleLogin = async () => {
    const {setState} = signInStore;
    try {
      setState({isLoadingAuth: true});
      const data = await signInWithPopup(auth, provider);
      const googleToken = await data.user.getIdToken();
      const requestData = {
        googleToken: googleToken,
      };
      const response = await $api.post<Auth>('/auth/google', requestData);
      const accessToken: string = response?.data?.tokens?.access;
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
      }
      if (!response?.data.user.isNew) {
        setState({
          messageFromRequestSignIn: '',
        });
      }

      return response.data;
    } catch (error) {
      handleSignInError(error, setState);
      return undefined;
    } finally {
      setState({isLoadingAuth: false});
    }
  };

  const logout = async () => {
    return await $api.post('/auth/logout');
  };

  return {
    sendOtpRequest,
    sendOtpLoginRequest,
    googleLogin,
    logout,
  };
};

export const signInService = SignIn();
