import {FC, PropsWithChildren, useEffect, useState} from 'react';
import {Footer, FooterProps} from '../components/Footer';
import {Header, HeaderProps} from '../components/Header';
import {Sidebar, SidebarProps} from '../components/Sidebar';
import {useDashboardStore} from '../modules/Dashboard';
import {projectService} from '../services/projectApi';
import {userService} from '../services/userApi';
import {SidebarClasses} from './constants';
import {signUpService} from '../services/SignUp';
import {useSignUpStore} from '../modules/Login/store/useSignUpStore';
import {useUserStore} from '../modules/AccountProgress/store/useUserStore';

interface ILayoutDisclaimerViewProps {
  header?: boolean;
  sidebar?: boolean;
  footer?: boolean;
  headerProps?: Partial<HeaderProps>;
  footerProps?: Partial<FooterProps>;
  sidebarProps?: Partial<SidebarProps>;
}

export const LayoutDashboardView: FC<
  PropsWithChildren<ILayoutDisclaimerViewProps>
> = ({
  children,
  header = true,
  sidebar = true,
  footer = true,
  headerProps = {},
  footerProps = {},
  sidebarProps = {},
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const {actionPoints} = useSignUpStore();
  const [isOpened, setOpened] = useState(false);
  const {projects} = useDashboardStore();
  const {user} = useUserStore();

  useEffect(() => {
    if (!projects.length) {
      projectService.getProjects().catch(err => console.error(err));
    }
    if (!actionPoints) {
      void signUpService.getActionPoints();
    }
    if (!user) {
      void userService.getUserData();
    }
  }, [projects]);

  const sidebarWidth = collapsed
    ? SidebarClasses.defaultSize
    : SidebarClasses.smallerSize;

  useEffect(() => {
    document.body.classList.add('bg-dark');
  }, []);

  return (
    <>
      <div className="bg-[#040506] text-white min-h-screen flex flex-col">
        <main className="flex-1 flex">
          <div className="flex-1 flex">
            {sidebar && (
              <Sidebar
                translated={isOpened}
                open={collapsed}
                toggle={() => setCollapsed(p => !p)}
                {...sidebarProps}
              />
            )}
            <div
              className="sidebar-breakpoint:!w-full transition-all pt-[57px] md:pt-0 flex flex-col"
              style={{
                marginLeft: 'auto',
                width: `calc(100% - ${sidebar ? sidebarWidth : 0}px)`,
              }}
            >
              {header && (
                <Header
                  isOpened={isOpened}
                  handleTranslateSidebar={() => setOpened(s => !s)}
                  {...headerProps}
                />
              )}
              <div className="flex-1">{children}</div>
              {footer && <Footer {...footerProps} />}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
