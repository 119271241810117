import {$api} from '../../../services/interceptor';
import {IProjectFCO} from '../../../types/project';
import {allocationsStore} from '../store/useAllocationsStore';

const allocationsApi = () => {
  const {setState} = allocationsStore;
  const getMyAllocations = async () => {
    try {
      setState({isLoading: true});
      const result = await $api.get<Array<IProjectFCO>>(
        '/allocations/projects-allocations/my'
      );

      setState({allocations: result.data});
    } catch (error) {
      console.log(error);
    } finally {
      setState({isLoading: false});
    }
  };

  return {
    getMyAllocations,
  };
};

export const allocationsService = allocationsApi();
