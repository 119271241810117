import {Bets} from '../constants';

export const getBetValue = (
  bet: Bets,
  min: number,
  max: number,
  balance: number
) => {
  const minMaxDifference = max - min;

  switch (bet) {
    case Bets['MAX']:
      if (balance < max) {
        return balance;
      }
      return max;
    case Bets['25%']:
      return minMaxDifference * 0.25 + min;
    case Bets['50%']:
      return minMaxDifference * 0.5 + min;
    case Bets['75%']:
      return minMaxDifference * 0.75 + min;
    case Bets['MIN']:
    default:
      return min;
  }
};
