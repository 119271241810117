export const CircleCross = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M11.5 8.00053C11.5003 7.88133 11.5004 7.68602 11.5004 7.49961L11.9994 7.49997C12.1187 7.50025 12.3139 7.50028 12.5001 7.50022L12.5 7.99987L12.5 8.00115C12.5003 8.1191 12.5004 8.31358 12.5004 8.49997L12 8.49997L11.9988 8.49998C11.8809 8.50025 11.6865 8.5003 11.5001 8.50026L11.5 8.00053ZM11.5 16.5V11.5H12.5V16.5H11.5Z"
        stroke="black"
      />
      <circle cx="12" cy="12" r="10" fill="#FB4949" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1385 7.07965L12 11.2181L7.86151 7.07965L7.10905 7.83211L10.8714 11.5944L10.8714 11.5944L12.3763 13.0993L12.3763 13.0993L16.1385 16.8615L16.8909 16.1091L12.7525 11.9706L16.8909 7.83211L16.1385 7.07965ZM11.2476 13.4754L10.4952 12.723L7.10905 16.1091L7.86151 16.8615L11.2476 13.4754Z"
        fill="black"
      />
    </svg>
  );
};
