import {useWeb3Modal} from '@web3modal/react';
import {useEffect, useState} from 'react';
import {useAccount, useConnect, useNetwork, useSwitchNetwork} from 'wagmi';
import Metamask from '../../assets/metamask_icon.png';
import verifySuccess from '../../assets/verify_success.png';
import {BackButton} from '../../assets/svgComponents/BackButton';
import {NextArrow} from '../../assets/svgComponents/NextArrow';
import WalletConnect from '../../assets/walletconnect.png';
import {Button} from '../../components/Button';
import {SkipButton} from '../../components/SkipButton';
import {
  socialsColGradientClasses,
  stepsContentHeadingFlexboxClasses,
  stepsMainHeadingClasses,
} from '../../constants/stepsLayoutVariables';
import {useSignUpStore} from '../Login/store/useSignUpStore';
import {useUserStore} from '../AccountProgress/store/useUserStore';
import {
  handleBackStep,
  handleNextStep,
  handleSkipStep,
} from '../Login/helpers/handleSteps';
import {isMetamaskExtensionInstalled} from '../../utilities/metamask';
import {handleExternalLinkOpen} from '../../utilities/handleExternalLinkOpen';
import {bindWalletModalService} from '../BindWalletModal/service';

enum Providers {
  metamask,
  walletConnect,
}

export const ConnectWalletView = () => {
  const {connect, connectors} = useConnect({
    onMutate: () => {
      bindWalletModalService.setIsConsciousChange(true);
    },
    onSettled: () => {
      bindWalletModalService.setIsConsciousChange(false);
    },
  });
  const [connectedProvider, setConnectedProvider] = useState<Providers>();
  const {address} = useAccount();
  const {user} = useUserStore();
  const {
    isActiveButton,
    setIsActiveButton,
    setMessageFromRequestSignUp,
    loading,
  } = useSignUpStore();
  const colImageClasses =
    'top-4 left-1/2 -translate-x-1/2 max-w-[70px] lg:max-w-fit lg:w-[66px] lg:h-[66px] lg:left-1/2 lg:top-[45px] transition-all';
  const {messageFromRequestSignUp} = useSignUpStore();
  const {chains, switchNetwork} = useSwitchNetwork();
  const {chain} = useNetwork();
  const {open} = useWeb3Modal();

  const handleBackStepClick = () => {
    handleBackStep();
  };

  const handleConnectMetamask = async () => {
    if (!isMetamaskExtensionInstalled()) {
      handleExternalLinkOpen('https://metamask.app.link/dapp/');
      return;
    }

    await connect({connector: connectors[1]});
    setMessageFromRequestSignUp('');
    setConnectedProvider(Providers.metamask);
  };

  const handleConnectWallet = async () => {
    await open();
    setMessageFromRequestSignUp('');
    setConnectedProvider(Providers.walletConnect);
  };

  const handleWalletsSkip = () => {
    handleSkipStep();
  };

  useEffect(() => {
    setIsActiveButton(
      (connectedProvider !== undefined &&
        (connectedProvider === Providers.metamask ||
          connectedProvider === Providers.walletConnect)) ||
        !!user?.connectedWallets?.at(0)
    );
  }, [connectedProvider, user]);

  useEffect(() => {
    if (chain) {
      const isOurChain = chains.find(item => item.id === chain.id);
      !isOurChain && switchNetwork && switchNetwork(chains[0].id);
    }
  }, [chain]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className={stepsContentHeadingFlexboxClasses}>
        <span className={stepsMainHeadingClasses}>
          {!user?.connectedWallets[0] || messageFromRequestSignUp
            ? 'Connect Wallet'
            : 'Wallet Connected'}
        </span>
      </div>
      <div className="w-full lg:text-center lg:pt-[20px]">
        <span className="text-white text-opacity-60 font-mono font-normal text-xs lg:text-[14px] leading-[21px]">
          {!user?.connectedWallets[0] || messageFromRequestSignUp
            ? 'You can use 1 wallet at the same time'
            : ''}
        </span>
      </div>
      {messageFromRequestSignUp && (
        <div className="flex">
          <span className="text-red-500 pt-[20px] font-mono">
            {messageFromRequestSignUp}
          </span>
        </div>
      )}
      {!user?.connectedWallets[0] || messageFromRequestSignUp ? (
        <div className="grid grid-cols-2 pt-3 lg:pt-[35px] gap-3 lg:gap-5 max-w-full w-full lg:max-w-[620px]">
          <div
            className="group flex bg-[#111214] relative h-[200px] rounded-[10px] cursor-pointer overflow-hidden"
            onClick={handleConnectMetamask}
          >
            <div className="flex w-full absolute h-full rounded-[10px] bg-opacity-60">
              <span className={socialsColGradientClasses}></span>
              <div
                className={`flex absolute ${colImageClasses} ${
                  connectedProvider === Providers.metamask
                    ? '!left-4 !top-4 !translate-x-0 !max-w-[42px] lg:!left-1/2 lg:!top-11 lg:!-translate-x-1/2 lg:!max-w-full'
                    : ''
                }`}
              >
                <img src={Metamask as string} alt="" />
              </div>
              {connectedProvider === Providers.walletConnect &&
              address &&
              !messageFromRequestSignUp ? (
                <div className="flex flex-col lg:flex-row self-end px-4 lg:px-6 py-4 w-full justify-between">
                  <div className="flex items-center">
                    <span className="text-white text-xs lg:text-[14px] font-bold uppercase leading-[24px] font-mono">
                      connect metamask instead
                    </span>
                  </div>
                  <div className=" relative left-[0] flex items-center pt-[2.5px]">
                    <NextArrow fill="white" />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row self-end px-4 py-5 lg:px-6 lg:py-5 w-full justify-between gap-3 lg:gap-5 lg:items-center flex-wrap">
                  <div className="flex items-center">
                    <span className="text-white text-base lg:text-[20px] font-bold leading-[20px] font-sans">
                      Metamask
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span
                      className={`text-[14px] h-[16px] ${
                        connectedProvider !== Providers.metamask ||
                        !address ||
                        messageFromRequestSignUp
                          ? 'text-white'
                          : 'text-[#76ED00]'
                      }  font-mono uppercase font-bold leading-[24px]tracking-[-0.28px]`}
                    >
                      {connectedProvider !== Providers.metamask ||
                      !address ||
                      messageFromRequestSignUp
                        ? 'Connect'
                        : 'Connected'}
                    </span>
                    <div className="relative left-[0px] flex items-center  pt-[4.5px]">
                      {(connectedProvider !== Providers.metamask ||
                        !address ||
                        messageFromRequestSignUp) && <NextArrow fill="white" />}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="group flex bg-[#111214] relative h-[200px] rounded-[10px] cursor-pointer overflow-hidden"
            onClick={handleConnectWallet}
          >
            <span className={socialsColGradientClasses}></span>
            <div
              className={`flex absolute ${colImageClasses} ${
                connectedProvider === Providers.walletConnect
                  ? '!left-4 !top-2 !translate-x-0 !max-w-[42px] lg:!left-1/2 lg:!top-11 lg:!-translate-x-1/2 lg:!max-w-full'
                  : ''
              }`}
            >
              <img src={WalletConnect as string} alt="" />
            </div>
            {connectedProvider === Providers.metamask &&
            address &&
            !messageFromRequestSignUp ? (
              <div className="flex self-end px-4 lg:px-6 py-4 flex-wrap">
                <div className="flex items-center">
                  <span className="text-white text-[12px] uppercase font-bold lg:text-center leading-[24px] font-mono tracking-[-0.24px]">
                    Connect walletconnect instead
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="relative left-[0px] flex items-center  pt-[1.5px]">
                    <NextArrow fill="white" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex self-end py-5 px-6 w-full flex-col flex-wrap lg:flex-row gap-3 lg:gap-5 justify-between">
                <div className="flex items-center">
                  <span
                    className={`text-white ${
                      connectedProvider !== Providers.walletConnect
                        ? 'text-[16px]'
                        : 'text-base lg:text-[16px]'
                    } font-bold text-center leading-[20px] font-sans`}
                  >
                    WalletConnect
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <span
                    className={`text-[14px] h-[16px] ${
                      connectedProvider !== Providers.walletConnect ||
                      !address ||
                      messageFromRequestSignUp
                        ? 'text-white'
                        : 'text-[#76ED00]'
                    }  font-mono uppercase font-bold leading-[24px]tracking-[-0.28px]`}
                  >
                    {connectedProvider !== Providers.walletConnect ||
                    !address ||
                    messageFromRequestSignUp
                      ? 'Connect'
                      : 'Connected'}
                  </span>
                  {(connectedProvider !== Providers.walletConnect ||
                    !address ||
                    messageFromRequestSignUp) && <NextArrow fill="white" />}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center pt-[30px] lg:pt-[45px]">
          {' '}
          <img src={verifySuccess as string} alt="" />
          <span className="text-white text-opacity-60 font-mono pt-[75px] font-normal text-xs lg:text-[14px] leading-[21px]">
            {user?.connectedWallets[0].address}
          </span>
        </div>
      )}
      <div className="flex w-full relative justify-center items-center text-center pt-[53px] lg:pt-[48px]">
        <div className="pr-[10px] cursor-pointer" onClick={handleBackStepClick}>
          <BackButton />
        </div>
        <Button
          loading={loading}
          className="flex-1 lg:flex-initial w-[356px]"
          text="Next step"
          onClick={handleNextStep}
          disabled={!isActiveButton || !address || loading}
          withArrow
        />
      </div>
      <SkipButton onClick={handleWalletsSkip} text="skip step" />
    </div>
  );
};
