import {shortenAddress} from '../../../../utilities/idex';

export const getMinimalSumErrorMessage = (min: number) => ({
  title: 'Minimal sum',
  description: `Minimal sum is ${min}`,
});

export const getIncorrectWalletErrorMessage = (correctWallet: string) => ({
  title: 'Incorrect wallet',
  description: `You are not eligible to participate in the current round with that wallet. You need to connect that one - ${shortenAddress(
    correctWallet
  )}`,
});
