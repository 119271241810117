import {create, useStore} from 'zustand';
import {Project} from '../../../types/project';

interface ICampaignsStore {
  campaigns: Project[];
  isLoading: boolean;
}

export const campaignsStore = create<ICampaignsStore>(() => ({
  campaigns: [],
  isLoading: false,
}));

export const useCampaignsStore = () => useStore(campaignsStore);
