import React from 'react';

export const TimeSquare = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <g>
        <title>Layer 1</title>
        <g stroke="null" id="svg_3">
          <path
            stroke="null"
            id="svg_1"
            fill="white"
            d="m14.33985,0.0002l-8.66989,0c-3.38998,0 -5.66996,2.37998 -5.66996,5.91996l0,8.16994c0,3.52997 2.27998,5.9099 5.66996,5.9099l8.66989,0c3.39001,0 5.66003,-2.37993 5.66003,-5.9099l0,-8.16994c0,-3.53998 -2.27003,-5.91996 -5.66003,-5.91996z"
            opacity="0.4"
          />
          <path
            stroke="null"
            id="svg_2"
            fill="white"
            d="m13.90948,14.17262c-0.14338,0 -0.28772,-0.03712 -0.42015,-0.11477l-4.29456,-2.56189c-0.24719,-0.14885 -0.39933,-0.41686 -0.39933,-0.70568l0,-5.52081c0,-0.45287 0.36757,-0.82042 0.82045,-0.82042c0.45289,0 0.82045,0.36754 0.82045,0.82042l0,5.0549l3.89425,2.32236c0.38826,0.23295 0.5163,0.73611 0.28444,1.12558c-0.15433,0.25705 -0.42672,0.40031 -0.70556,0.40031z"
          />
        </g>
      </g>
    </svg>
  );
};
