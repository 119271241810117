export enum QuizStatus {
  DONE = 'DONE',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum QuestionState {
  'IN_PROGRESS',
  'DONE',
  'ERROR',
}
export enum QuizState {
  'NOT_INITIATED',
  'IN_PROGRESS',
  'FINISHED',
}
export enum AnswerType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}
export enum QuizAnswerState {
  'SELECTED',
  'NOT_SELECTED',
  'CORRECT',
  'INCORRECT',
  'NOT_SELECTED_CORRECT',
}

export interface Question {
  id: string;
  text: string;
  answerType: AnswerType;
  answers: Array<Answer>;
  correctAnswers: Array<Answer>;
}

export interface INextQuestionType {
  answerType: AnswerType;
  answers: Array<{id: string; text: string}>;
  id: string;
  text: string;
  description?: string;
}

export interface IQuestionResponse {
  nextQuestion?: INextQuestionType;
  correctAnswers: Array<{id: string; text: string}>;
  quizStatus: QuizStatus;
  userPointsReward?: number;
  userTicketReward?: number;
  questionDescription?: string;
}

export interface Answer {
  id: string;
  text: string;
}

export interface UserAnswer {
  questionId: string;
  answerIds: Array<string>;
}

export interface ValidatedAnswer extends UserAnswer {
  correctAnswersIds: Array<string>;
  answerType: AnswerType;
  correct: boolean;
  description?: string;
}

export interface QuizStoreState {
  currentQuestionNumber: number;
  previousAnswers: Array<ValidatedAnswer>;
  lastAnswer: UserAnswer | null;
  questions: Array<Question | INextQuestionType>;
  questionsAmount: number;
  currentQuestionState: QuestionState;
  isSubmitPending: boolean;
  isQuizLoading: boolean;
  isQuizFinished: boolean;
  userPointsReward: number;
  userTicketReward: number;
  correctAnswersAmount?: number;
  customReward?: string;
}

export interface QuizStore extends QuizStoreState {
  setQuestions: (questions: Array<Question>) => void;
  addQuestion: (question: Question) => void;
  initilaizeAnswer: (questionId: string) => void;
  addAnswerId: (answerId: string) => void;
  replaceAnswerIds: (answerId: string) => void;
  removeAnswerId: (answerId: string) => void;
  getAnswerState: (params: {
    questionId: string | null;
    answerId: string;
  }) => QuizAnswerState;
  submitAnswer: (validatedLastAnswer: ValidatedAnswer) => void;
  incrementQuestionNumber: () => void;
  decrementQuestionNumber: () => void;
  resetStore: () => void;
}

export interface ISelectedTaskInfo {
  taskID?: string;
  quizId?: string;
  quizQuestionAmount?: number;
  taskReward?: number;
}

export interface IAnswerResult {
  text: string;
  Icon: JSX.Element;
  title: string;
}
