import {useShallow} from 'zustand/react/shallow';
import {Activity} from './components/Activity';
import {ActivityEmpty} from './components/ActivityEmpty';
import {ActivityGroup} from './components/ActivityGroup';
import {IActivity, activitiesStore} from './store';
import {TimeGroups, timeGroupsTitles} from '../constants';
import {calculateTimeGroup} from '../utils/calculateActivityGroup';
import {activitiesService} from './service';

export const Activities = () => {
  const groupedActivities = activitiesStore(
    useShallow(
      ({activities}) =>
        Object.entries(
          activities
            .sort(
              ({createdAt: a}, {createdAt: b}) =>
                new Date(b).getTime() - new Date(a).getTime()
            )
            .reduce(
              (activityGroupes, activity) => {
                const group = calculateTimeGroup(activity.createdAt);
                const currentGroupActivities = activityGroupes[group] || [];

                activityGroupes[group] = [...currentGroupActivities, activity];
                return activityGroupes;
              },
              {} as Record<TimeGroups, Array<IActivity>>
            )
        ) as unknown as [TimeGroups, IActivity[]][]
    )
  );

  const isActivitiesUnread = activitiesStore(
    useShallow(({unreadCount}) => !!unreadCount)
  );

  return (
    <>
      <div className="flex items-center justify-between mb-3">
        <div className="font-semibold text-sm leading-6">Activities</div>
        {isActivitiesUnread && (
          <div
            className="font-mono font-bold text-green-200 text-xs cursor-pointer hover:underline"
            onClick={activitiesService.markAllActivitiesAsRead}
          >
            Mark as read
          </div>
        )}
      </div>
      {groupedActivities.length ? (
        groupedActivities.map(([group, activities]) => (
          <ActivityGroup title={timeGroupsTitles[group]}>
            {activities.map(activity => (
              <Activity activity={activity} />
            ))}
          </ActivityGroup>
        ))
      ) : (
        <ActivityEmpty />
      )}
    </>
  );
};
