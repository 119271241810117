import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {AccountProgressView} from '../AccountProgress/AccountProgressView';
import {TasksView} from '../AccountTask/TasksView';
import {tasksService} from '../AccountTask/service/TasksService';
import {useTasksStore} from '../AccountTask/store/useTasksStore';
import {ReferalCodeWidget} from './components/ReferalCodeWidget';
import {TooltipInfoIcon} from '../../components/Tooltip/TooltipInfoIcon';
import {DailyReward} from '../../components/DailyReward';

export const LevelPanelView = () => {
  const {availableTasks} = useTasksStore();
  const amountOfVisibleTasks = 3;

  useEffect(() => {
    void tasksService.getAvailableTasks();
  }, []);

  return (
    <div className="grid-layout__panel bg-[#0B0D0E] border rounded-20 border-l-100 w-full relative">
      <div className="mb-5 border-b-[1px] border-l-100 border-solid p-6">
        <AccountProgressView isViewMoreButtonDisplayed={false} isRedirectable />
      </div>
      <div className="mb-5 mx-5">
        <ReferalCodeWidget />
      </div>
      <div className="rounded-xl p-4 relative font-sans overflow-hidden">
        <DailyReward />
      </div>
      <div>
        <div className="px-5 xl:px-6 pt-8">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-2">
              <span className="text-xl font-bold">Raiser Tasks</span>
              <span className="flex bg-[#262729] items-center justify-center h-5 px-2 rounded-[1.75rem] text-xs text-l-600 font-bold leading-3">
                {availableTasks.length}
              </span>
              <TooltipInfoIcon
                content="You can complete tasks and get points that will increase your level."
                hidden={false}
                contentClassName={'w-[240px] text-sm !py-[12px] !px-[12px]'}
              />
            </div>
          </div>
          <TasksView
            showToggleTextLength
            numberOfVisibleTasks={amountOfVisibleTasks}
            isDescriptionToggleShown={false}
            taskRowDirection={false}
            tasks={availableTasks}
            refetchTasks={tasksService.getAvailableTasks}
          />
        </div>
        <div className="py-6 px-5 flex justify-center items-center border-t-[1px] border-solid border-t-[#1D1E1E]">
          <div className="relative group w-full">
            <Link
              to={'/account'}
              className="bg-l-100 text-white hover:bg-green-200 w-full !h-12 transition-all hover:text-black uppercase flex items-center gap-[0.625rem] rounded-[2.625rem] justify-center text-center text-sm font-bold font-mono"
            >
              View more tasks
              <span className="flex min-h-[1.25rem] justify-center text-center px-2 bg-l-100 rounded-[28px] text-l-600 font-bold text-xs items-center group-hover:bg-black group-hover:text-white font-sans">
                {availableTasks.length}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
