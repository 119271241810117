import {InfoIcon} from '../../../../assets/svgComponents/InfoIcon';
import {Tooltip} from '../../../../components/Tooltip';
import {bets} from '../constants';
import {getBetValue} from '../helpers/getBetValue';
import {roundNumber} from '../helpers/roundNumber';
import {useProjectInvestStore} from '../store/useProjectInvestStore';

export const AllocationInput = ({
  min,
  max,
  tokenPrice,
  balance,
}: {
  min: number;
  max: number;
  tokenPrice: number;
  balance: number;
}) => {
  const {investedProject, investedSum, setInvestedSum} =
    useProjectInvestStore();

  if (!investedProject) {
    return;
  }

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const inputValue = Number(e.currentTarget.value);
    if (inputValue > max) {
      setInvestedSum(roundNumber(max));
      return;
    }

    if (inputValue < 0) {
      setInvestedSum(null);
    }
    setInvestedSum(roundNumber(inputValue) || null);
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="w-full border border-l-300 rounded-2xl font-mono">
        <div className="pl-5 pr-4 pt-4 flex mb-[22px] items-center justify-between uppercase">
          <div className=" text-xs leading-5 text-white">
            purchase amount ({investedProject.allocationToken.symbol})
          </div>

          <div className="flex items-center text-sm leading-6 opacity-40">
            {min} {investedProject.allocationToken.symbol} — {max}{' '}
            {investedProject.allocationToken.symbol}
            <Tooltip
              content="The minimum and maximum amount available to the investor for this round."
              contentClassName={'w-[240px] text-sm !py-[12px] !px-[12px]'}
              triggerClassName={'text-black ml-1'}
            >
              <InfoIcon
                iIconProps={{stroke: '#ffffff'}}
                circleProps={{fill: 'gray', fillOpacity: '0.4'}}
              />
            </Tooltip>
          </div>
        </div>
        <div className="pl-6 pr-5 mb-3 flex">
          <input
            className="w-full bg-transparent text-xl leading-6 placeholder:text-l-300 focus:outline-none"
            value={investedSum ?? undefined}
            placeholder="0.000"
            min={min}
            max={max}
            maxLength={max.toString().length}
            type="number"
            onChange={handleChange}
          />
          <div className="flex gap-2">
            <img
              src={investedProject.allocationToken.iconUrl}
              width={24}
              height={24}
              className="rounded-20"
            />
            {investedProject.allocationToken.symbol}
          </div>
        </div>
        <div className="w-full h-12 py-2 pr-4 pl-5 flex gap-2 font-mono text-base text-center bg-[#56575933]">
          {bets.map((bet, i) => (
            <div
              key={i}
              className="py-1 px-[10px] bg-[#565759CC] rounded-[44px] w-full text-center cursor-pointer"
              onClick={() => {
                setInvestedSum(
                  roundNumber(getBetValue(bet, min, max, balance))
                );
              }}
            >
              {bet}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full border border-l-300 rounded-2xl font-mono bg-[#56575933]">
        <div className="pl-5 pr-4 pt-4 flex mb-[22px] items-center justify-between uppercase">
          <div className=" text-xs leading-5 text-white">
            purchase amount ({investedProject.token.symbol})
          </div>
          <div className="text-sm leading-6 opacity-40">
            token price: {tokenPrice} {investedProject.allocationToken.symbol}
          </div>
        </div>
        <div className="pl-6 pr-5 mb-3 flex">
          <div
            className={`w-full text-xl leading-6 ${
              investedSum ? '' : 'text-l-300'
            }`}
          >
            {investedSum ? roundNumber(investedSum / tokenPrice) : '0.000'}
          </div>
          <div className="flex gap-2">
            <img
              src={investedProject.token.iconUrl}
              width={24}
              height={24}
              className="rounded-20"
            />
            {investedProject.token.symbol}
          </div>
        </div>
      </div>
    </div>
  );
};
