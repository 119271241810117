import {create, useStore} from 'zustand';
import {IToken} from '../../../../../types/project';

export interface ISelectedAllocation {
  userWallet: string;
  token: IToken;
  allocationToken: IToken;
  tokenPrice: number;

  tokensAmount: number;
  claimableTokensAmount: number;
  claimedTokensAmount: number;
  vestedTokensAmount: number;

  claim: () => Promise<void>;
}

export interface IClaimModalStore {
  claimError: string | null;
  selectedAllocation: ISelectedAllocation | null;
}

export const claimModalStore = create<IClaimModalStore>(() => ({
  claimError: null,
  selectedAllocation: null,
}));

export const useClaimModalStore = () => useStore(claimModalStore);
