import {useShallow} from 'zustand/react/shallow';
import {CurrentWoodle, WoodleTaskStatus, woodleStore} from '../../store';
import {WoodleInfo} from './components/WoodleInfo';
import {WoodleSuccess} from './components/WoodleSuccess';
import {WoodleMobileTip} from './components/WoodleMobileTip';

export const WoodleTop = ({currentWoodle}: {currentWoodle: CurrentWoodle}) => {
  const isWoodleSuccessfull = woodleStore(
    useShallow(
      ({currentWoodle}) => currentWoodle?.status === WoodleTaskStatus.DONE
    )
  );

  return (
    <div className="flex flex-col justify-center items-center mb-6 w-full">
      <div className="font-bold text-5xl mb-5 leading-normal font-sans text-center">
        Word of the day
      </div>

      <div className="mb-5 w-full flex justify-center">
        {isWoodleSuccessfull ? (
          <WoodleSuccess />
        ) : (
          <WoodleInfo currentWoodle={currentWoodle} />
        )}
      </div>

      <div className="md:hidden">
        <WoodleMobileTip />
      </div>
    </div>
  );
};
