export const MobileRadioButtonIcon = ({
  outlineColor = '#61B60C',
  ...props
}: React.SVGProps<SVGSVGElement> & {
  outlineColor: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      {...props}
    >
      <rect
        x="2.5"
        y="5.5"
        width="15"
        height="15"
        rx="7.5"
        stroke={outlineColor}
        strokeWidth="5"
      />
    </svg>
  );
};
