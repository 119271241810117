import {formatUnits} from 'viem';
import {isWalletMatching} from '../../../../utilities/isWalletMatching';
import {insufficientBalanceMessage, walletRequiredMessage} from '../constants';

import {
  getIncorrectWalletErrorMessage,
  getMinimalSumErrorMessage,
} from '../helpers/getErrorMessage';
import {getSignature} from '../hooks/useProjectInvest';

import {projectInvestStore} from '../store/useProjectInvestStore';

const investApi = () => {
  const {getState, setState} = projectInvestStore;

  const validateAllocation = (
    min: number,
    balance: number,
    userAddress: string,
    bindedWallet: string
  ) => {
    const {investedSum, setErrorMessage} = getState();

    if (!userAddress) {
      setErrorMessage(walletRequiredMessage);
      return;
    }

    if (!isWalletMatching(userAddress)) {
      setErrorMessage(getIncorrectWalletErrorMessage(bindedWallet));
      return;
    }

    if (investedSum && investedSum < min) {
      setErrorMessage(getMinimalSumErrorMessage(min));
      return;
    }

    if (investedSum && investedSum > balance) {
      setErrorMessage(insufficientBalanceMessage);
      return;
    }

    return true;
  };

  const getMaxAllocation = async (
    signatureUrl: string,
    bindedUserWallet: string,
    defaultMaxAllocation: number,
    decimals: number
  ) => {
    try {
      const allocation = await getSignature(signatureUrl, bindedUserWallet);

      setState({
        maxAllocation: allocation?.maxAllocation
          ? Number(formatUnits(BigInt(allocation.maxAllocation), decimals))
          : defaultMaxAllocation,
      });
    } catch (error) {
      setState({maxAllocation: defaultMaxAllocation});
    }
  };

  return {validateAllocation, getMaxAllocation};
};

export const investService = investApi();
