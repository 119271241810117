import globeIcon from '../../assets/globeIcon.svg';
import fbIcon from '../../assets/socials/fbIcon.svg';
import tgIcon from '../../assets/socials/tgIcon.svg';
import twitterIcon from '../../assets/socials/twitterIcon.svg';
import discordIcon from '../../assets/socials/discordIcon.svg';
import {ProjectDetailsView} from './ProjectDetailsView';

export const SOCIAL_ICONS: Record<string, JSX.Element> = {
  website: (
    <img src={globeIcon} className="w-4 h-4 md:w-6 md:h-6" alt="Website" />
  ),
  facebook: (
    <img src={fbIcon} className="w-4 h-4 md:w-6 md:h-6" alt="Facebook icon" />
  ),
  twitter: (
    <img
      src={twitterIcon}
      className="w-4 h-4 md:w-6 md:h-6"
      alt="Twitter icon"
    />
  ),
  telegram: (
    <img src={tgIcon} className="w-4 h-4 md:w-6 md:h-6" alt="Telegram icon" />
  ),
  discord: (
    <img
      src={discordIcon}
      className="w-4 h-4 md:w-6 md:h-6"
      alt="Discord icon"
    />
  ),
};

export {ProjectDetailsView};
