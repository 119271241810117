import React from 'react';

export const ActiveCheckTick = ({
  fillColor = '#E5FD55',
  ...props
}: React.SVGProps<SVGSVGElement> & {fillColor?: string}) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18" cy="18" r="14" fill={fillColor} />
      <path d="M11 18.5L16 23.5L26 13.5" stroke="#070809" strokeWidth="2" />
      <path d="M18.0195 19L13.0195 24" stroke={fillColor} strokeWidth="1.5" />
    </svg>
  );
};
