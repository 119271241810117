import {ethers} from 'ethers';
import {useEVMSigner} from '../../../../hooks/useEthersProvider';
import {investProjectInvestABI} from '../constants';
import {investService} from '../service/investService';
import {projectInvestStore} from '../store/useProjectInvestStore';
import {
  Erc20Contract,
  getContract,
} from '../../../../utilities/contractUtilities';
import axios, {AxiosResponse} from 'axios';

interface DepositFunctionArgs {
  amount: number;
  balance: number;
  signatureUrl: string;
  min: number;
  bindedWallet: string;
}

interface Allocation {
  projectId: string;
  roundId: string;
  beneficiary: string;
  depositToken: string;
  minAllocation: string;
  maxAllocation: string;
  deadline: string;
  hardCap: string;
  digest: string;
  signature: string;
}

const createInvestContract = async (
  contractAdress: string,
  signer: ethers.providers.JsonRpcSigner | undefined
) => {
  if (!signer) {
    return;
  }

  const contract = await getContract(
    contractAdress,
    investProjectInvestABI,
    signer
  );

  return contract;
};

export const getSignature = async (
  signatureUrl: string,
  userAddress: string
) => {
  try {
    const allocation: AxiosResponse<Allocation> = await axios.get(
      `${signatureUrl}/${userAddress}.json`
    );

    return allocation.data;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const useProjectInvest: (
  userAddress: string,
  contractAdress: string,
  chainId: number,
  chainName: string,
  allocationTokenAdress: string,
  decimals: number
) => {deposit: (depositArgs: DepositFunctionArgs) => void} = (
  userAddress,
  contractAdress,
  chainId,
  chainName,
  allocationTokenAdress,
  decimals
) => {
  const signer = useEVMSigner({chainId, chainName});

  const deposit = async ({
    amount,
    signatureUrl,
    min,
    balance,
    bindedWallet,
  }: DepositFunctionArgs) => {
    projectInvestStore.setState({isInvestPending: true});
    try {
      const isAllocationValid = investService.validateAllocation(
        min,
        balance,
        userAddress,
        bindedWallet
      );

      if (isAllocationValid) {
        if (!signer) {
          throw {
            title: 'Something went wrong.',
            description: 'Please try again',
            retryEvent: () => projectInvestStore.setState({errorMessage: null}),
          };
        }

        const allocation = await getSignature(signatureUrl, userAddress);

        if (!allocation) {
          throw {
            title: 'Something went wrong.',
            description:
              "Sorry, your current wallet can't participate in this round. Please try using one of the previously connected wallets if you have changed them.",
            retryEvent: () => projectInvestStore.setState({errorMessage: null}),
          };
        }

        const depositTokenContract = new Erc20Contract(
          allocationTokenAdress,
          signer
        );

        const contract = await createInvestContract(contractAdress, signer);

        const {parsedAllowace} = await depositTokenContract.allowance(
          userAddress,
          contractAdress
        );

        if (Number(parsedAllowace) < amount) {
          await depositTokenContract?.approve(
            contractAdress,
            ethers.utils.parseUnits(amount.toString(), decimals)
          );
        }

        const tx = (await contract?.deposit(
          [
            allocation.projectId,
            allocation.beneficiary,
            allocation.depositToken,
            allocation.roundId,
            BigInt(allocation.minAllocation),
            BigInt(allocation.maxAllocation),
            BigInt(allocation.deadline),
            BigInt(allocation.hardCap),
          ],
          ethers.utils.parseUnits(amount.toString(), decimals),
          allocation.signature
        )) as {wait: () => Promise<unknown>};

        await tx.wait();

        projectInvestStore.setState({isSubmitted: true});
      }
    } catch (error) {
      console.log(error);

      const typedError = error as {
        title?: string;
        description: string;
        retryEvent?: () => void;
      };
      const errorTitle = typedError.title;
      const errorDescription = typedError.description;
      const errorRetryEvent = typedError.retryEvent;

      if (errorTitle && errorRetryEvent) {
        projectInvestStore.setState({
          errorMessage: {
            title: errorTitle,
            description: errorDescription,
            retryEvent: errorRetryEvent,
          },
        });
      }
    } finally {
      projectInvestStore.setState({isInvestPending: false});
    }
  };

  return {deposit};
};
