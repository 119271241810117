import dropdownArrow from '../../../assets/dropdownArrow.svg';

export const DescriptionToggleButton = ({
  isTaskCollapsed,
  toggleTaskCollapsed,
}: {
  isTaskCollapsed: boolean;
  toggleTaskCollapsed: () => void;
}) => {
  return (
    <button
      className="text-xs font-bold uppercase tracking-[-0.015rem] flex items-center gap-1.5 font-mono"
      onClick={toggleTaskCollapsed}
    >
      Read {isTaskCollapsed ? 'More' : 'Less'}
      <img
        className={`${
          isTaskCollapsed ? 'rotate-0' : 'rotate-180'
        } transition-all`}
        src={dropdownArrow}
        alt="dropdownArrow"
      />
    </button>
  );
};
