import React from 'react';

export const Backspace = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_4682_8796)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.67456 4.57449C5.02592 4.13526 5.47159 3.78069 5.97856 3.53705C6.48554 3.2934 7.04082 3.16693 7.6033 3.16699H16.4258C17.4204 3.16699 18.3742 3.56208 19.0775 4.26534C19.7807 4.9686 20.1758 5.92243 20.1758 6.91699V14.417C20.1758 15.4116 19.7807 16.3654 19.0775 17.0686C18.3742 17.7719 17.4204 18.167 16.4258 18.167H7.6033C7.04082 18.1671 6.48554 18.0406 5.97856 17.7969C5.47159 17.5533 5.02592 17.1987 4.67456 16.7595L1.58206 12.8932C1.07632 12.2614 0.800781 11.4763 0.800781 10.667C0.800781 9.85771 1.07632 9.07254 1.58206 8.44074L4.67456 4.57449ZM9.90081 7.50449C9.72309 7.33889 9.48803 7.24874 9.24515 7.25302C9.00228 7.25731 8.77054 7.3557 8.59878 7.52746C8.42701 7.69923 8.32862 7.93096 8.32434 8.17384C8.32005 8.41672 8.4102 8.65177 8.5758 8.82949L10.4133 10.667L8.5758 12.5045C8.4837 12.5903 8.40982 12.6938 8.35858 12.8088C8.30734 12.9238 8.27979 13.048 8.27757 13.1738C8.27534 13.2997 8.2985 13.4248 8.34565 13.5415C8.3928 13.6582 8.46298 13.7643 8.55201 13.8533C8.64103 13.9423 8.74707 14.0125 8.86381 14.0596C8.98054 14.1068 9.10558 14.13 9.23146 14.1277C9.35734 14.1255 9.48148 14.098 9.59648 14.0467C9.71148 13.9955 9.81498 13.9216 9.90081 13.8295L11.7383 11.992L13.5758 13.8295C13.6616 13.9216 13.7651 13.9955 13.8801 14.0467C13.9951 14.098 14.1193 14.1255 14.2452 14.1277C14.371 14.13 14.4961 14.1068 14.6128 14.0596C14.7295 14.0125 14.8356 13.9423 14.9246 13.8533C15.0136 13.7643 15.0838 13.6582 15.131 13.5415C15.1781 13.4248 15.2013 13.2997 15.199 13.1738C15.1968 13.048 15.1693 12.9238 15.118 12.8088C15.0668 12.6938 14.9929 12.5903 14.9008 12.5045L13.0633 10.667L14.9008 8.82949C15.0664 8.65177 15.1566 8.41672 15.1523 8.17384C15.148 7.93096 15.0496 7.69923 14.8778 7.52746C14.7061 7.3557 14.4743 7.25731 14.2315 7.25302C13.9886 7.24874 13.7535 7.33889 13.5758 7.50449L11.7383 9.34199L9.90081 7.50449Z"
          fill="white"
          fill-opacity="0.4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.90073 7.50454C9.72301 7.33894 9.48795 7.24879 9.24508 7.25308C9.0022 7.25736 8.77047 7.35575 8.5987 7.52752C8.42693 7.69928 8.32855 7.93101 8.32426 8.17389C8.31997 8.41677 8.41013 8.65183 8.57573 8.82954L10.4132 10.667L8.57573 12.5045C8.48362 12.5904 8.40974 12.6939 8.3585 12.8089C8.30726 12.9239 8.27971 13.048 8.27749 13.1739C8.27527 13.2998 8.29842 13.4248 8.34558 13.5415C8.39273 13.6583 8.46291 13.7643 8.55193 13.8533C8.64095 13.9424 8.747 14.0125 8.86373 14.0597C8.98047 14.1068 9.1055 14.13 9.23138 14.1278C9.35726 14.1256 9.4814 14.098 9.5964 14.0468C9.7114 13.9955 9.8149 13.9217 9.90073 13.8295L11.7382 11.992L13.5757 13.8295C13.6616 13.9217 13.7651 13.9955 13.8801 14.0468C13.9951 14.098 14.1192 14.1256 14.2451 14.1278C14.371 14.13 14.496 14.1068 14.6127 14.0597C14.7295 14.0125 14.8355 13.9424 14.9245 13.8533C15.0135 13.7643 15.0837 13.6583 15.1309 13.5415C15.178 13.4248 15.2012 13.2998 15.199 13.1739C15.1967 13.048 15.1692 12.9239 15.118 12.8089C15.0667 12.6939 14.9928 12.5904 14.9007 12.5045L13.0632 10.667L14.9007 8.82954C15.0663 8.65183 15.1565 8.41677 15.1522 8.17389C15.1479 7.93101 15.0495 7.69928 14.8778 7.52752C14.706 7.35575 14.4743 7.25736 14.2314 7.25308C13.9885 7.24879 13.7534 7.33894 13.5757 7.50454L11.7382 9.34204L9.90073 7.50454Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4682_8796">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.800781 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
