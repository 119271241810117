import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {signInStore} from '../store/useSignInStore';
import {signInService} from '../../../services/SignIn';

let intervalId: NodeJS.Timeout | null = null;

export const ResendCodeTimer: React.FC = () => {
  const [timer, setTimer] = useState<number>(60);
  const [isResendAllowed, setResendAllowed] = useState<boolean>(false);
  const navigate = useNavigate();

  const startTimer = () => {
    setTimer(60);
    setResendAllowed(false);
    intervalId = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          setResendAllowed(true);
          clearInterval(intervalId!);
          return 0;
        }
      });
    }, 1000);
  };

  const clearTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  };

  useEffect(() => {
    if (!isResendAllowed) {
      startTimer();
    }

    return clearTimer;
  }, [isResendAllowed]);

  const handleResendClick = async () => {
    clearTimer();

    setTimer(60);
    startTimer();
    setResendAllowed(false);
    await signInService.sendOtpRequest(navigate, isLoading => {
      if (isLoading !== undefined) {
        signInStore.setState({isLoading});
      }
    });
    signInStore.setState({
      messageFromRequestSignIn: '',
    });
  };

  return (
    <>
      <div className="pt-[20px] lg:self-baseline w-full md:w-fit">
        {!isResendAllowed && (
          <span className="text-white text-opacity-60 text-[14px] leading-[21px] font-mono">
            Resend code in 00:{timer < 10 ? `0${timer}` : timer}
          </span>
        )}
        {isResendAllowed && (
          <div>
            <span className="text-white text-opacity-60 text-[14px] leading-[21px] font-mono">
              Didn’t get the code?
            </span>
            <span
              className="text-white text-[14px] pl-3 leading-[21px] underline cursor-pointer font-mono hover:text-[#c3b6b6]"
              onClick={handleResendClick}
            >
              Click to resend
            </span>
          </div>
        )}
      </div>
    </>
  );
};
