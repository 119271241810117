import {create, useStore} from 'zustand';
import {Project} from '../../../types/project';

type initial = {
  projects: Project[];
  selectedProject: Project | null;
  featuredCampaign: Project | null;
  isLoading: boolean;
  error: boolean;
  invitedCount: number;
  referralBonus: number;
};
export type DashboardStore = initial & {
  setProjects: (projects: Project[]) => void;
  setProject: (project: Project) => void;
  setProjectsLoading: (isLoading: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setError: (error: boolean) => void;
  setinvitedCount: (invitedCount: number) => void;
};

const initialState: initial = {
  projects: [],
  selectedProject: null,
  featuredCampaign: null,
  isLoading: false,
  error: false,
  invitedCount: 0,
  referralBonus: 0.5,
};

export const dashboardStore = create<DashboardStore>(set => ({
  ...initialState,
  setProjects: projects => set({projects}),
  setProject: project => set({selectedProject: project}),
  setProjectsLoading: isLoading => set({isLoading}),
  setIsLoading: isLoading => set({isLoading}),
  setError: error => set({error}),
  setinvitedCount: invitedCount => set({invitedCount}),
}));

export const useDashboardStore = () => useStore(dashboardStore);
