import {TelegramAuthData} from '@telegram-auth/react'; // this lib could be removed i guess
import {useEffect, useState} from 'react';
import Telegram from '../../assets/Telegram.png';
import Discord from '../../assets/Discord.png';
import Twitter from '../../assets/Twitter.png';
import {BackButton} from '../../assets/svgComponents/BackButton';
import {Button} from '../../components/Button';
import {SkipButton} from '../../components/SkipButton';
import {TWITTER_LOGIN_LINK} from '../../configs/twitter.config';

import {
  stepsContentHeadingFlexboxClasses,
  stepsMainHeadingClasses,
} from '../../constants/stepsLayoutVariables';
import {TELEGRAM_BOT_ID} from '../../constants/telegramBotInfo';
import {signUpService} from '../../services/SignUp';
import {
  handleBackStep,
  handleNextStep,
  handleSkipStep,
} from '../Login/helpers/handleSteps';
import {useSignUpStore} from '../Login/store/useSignUpStore';
import {SocialName, useUserStore} from '../AccountProgress/store/useUserStore';
import {Modal} from '../../components/Modal';
import {DisconnectSocialModal} from './DisconnectSocialModal';
import {SocialCard} from './components/SocialCard';
import {getDiscordLoginLink} from '../../configs/discord.config';
import {getConnectedSocial} from '../../utilities/getConnectedSocial';

export const ConnectSocialsView = () => {
  const [socialForDisconnect, setSocialForDisconnect] =
    useState<SocialName | null>(null);
  const {user} = useUserStore();
  const {
    loading,
    actionPoints,
    isActiveButton,

    messageFromRequestSignUp,
    messageFromRequestTelegram,
    setIsActiveButton,
    setMessageFromRequestTelegram,
  } = useSignUpStore();

  const isTelegramConnected = user?.connectedSocials.some(
    social => social?.social?.name === SocialName.Telegram
  );
  const isTwitterConnected = user?.connectedSocials.some(
    social => social?.social?.name === SocialName.Twitter
  );
  const isDiscordConnected = user?.connectedSocials.some(
    social => social?.social?.name === SocialName.Discord
  );
  const handleBackStepClick = () => {
    handleBackStep();
  };

  const handleTelegramLogin = async (data: TelegramAuthData) => {
    setMessageFromRequestTelegram('');
    if (data) {
      const resp = await signUpService.sendTelegramData(data);
      if (resp) {
        setMessageFromRequestTelegram('');
      }
    }
  };

  const handleConnectTwitter = () => {
    window.open(TWITTER_LOGIN_LINK, '_self');
  };

  const handleTwitterDisconnect = () => {
    setSocialForDisconnect(SocialName.Twitter);
  };

  const handleTelegramConnect = () => {
    if (window.Telegram?.Login?.auth && !isTelegramConnected) {
      window.Telegram.Login.auth(
        {bot_id: TELEGRAM_BOT_ID, request_access: false},
        handleTelegramLogin
      );
    }
  };
  const handleTelegramDisconnect = () => {
    setSocialForDisconnect(SocialName.Telegram);
  };
  const handleDiscordConnect = () => {
    window.open(getDiscordLoginLink(), '_self');
  };
  const handleDiscordDisconnect = () => {
    setSocialForDisconnect(SocialName.Discord);
  };

  useEffect(() => {
    if (!isTelegramConnected) {
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-widget.js';
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, [isTelegramConnected]);

  const handleNextStepClick = () => {
    if (isTelegramConnected && isTwitterConnected && isDiscordConnected) {
      handleNextStep();
    } else {
      handleSkipStep();
    }
  };

  const handleSocialsSkip = () => {
    handleSkipStep();
  };

  useEffect(() => {
    setIsActiveButton(!!user?.connectedSocials?.length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.connectedSocials]);

  const handleDisconnectModalClose = () => {
    setSocialForDisconnect(null);
  };

  const twitterReward = actionPoints?.CONNECT_TWITTER || 250;
  const telegramReward = actionPoints?.CONNECT_TELEGRAM || 250;
  const discordReward = actionPoints?.CONNECT_DISCORD || 250;

  const telegramUsername =
    getConnectedSocial(user, SocialName.Telegram)?.socialData?.username || '';
  const twitterUsername =
    getConnectedSocial(user, SocialName.Twitter)?.socialData?.username || '';
  const discordUsername =
    getConnectedSocial(user, SocialName.Discord)?.socialData?.username || '';

  const socialsRewardSum = twitterReward + telegramReward + discordReward;

  const errorMessage = messageFromRequestSignUp || messageFromRequestTelegram;

  return (
    <>
      <div className="flex flex-col items-center w-full">
        <div className={stepsContentHeadingFlexboxClasses}>
          <span className={stepsMainHeadingClasses}>Connect socials</span>
        </div>
        <div className="lg:pt-[20px] lg:text-center w-full">
          <span className="text-white text-xs text-opacity-60 font-mono font-normal lg:text-[14px] leading-[21px]">
            Connect socials to gain {socialsRewardSum} points
          </span>
        </div>
        {!!errorMessage && (
          <div className="flex">
            <span className="text-red-500 pt-[20px] font-mono">
              {errorMessage}
            </span>
          </div>
        )}
        <div className="flex flex-col sm:grid grid-cols-3 pt-3 lg:pt-[35px] z-[4] gap-3 w-full max-w-full lg:max-w-[38.75rem]">
          <SocialCard
            name="Telegram"
            imgSrc={Telegram as string}
            isConnected={!!isTelegramConnected}
            username={telegramUsername}
            handleConnect={handleTelegramConnect}
            handleDisconnect={handleTelegramDisconnect}
          />
          <SocialCard
            name="X (Twitter)"
            imgSrc={Twitter as string}
            isConnected={!!isTwitterConnected}
            username={twitterUsername}
            handleConnect={handleConnectTwitter}
            handleDisconnect={handleTwitterDisconnect}
          />
          <SocialCard
            name="Discord"
            imgSrc={Discord as string}
            isConnected={!!isDiscordConnected}
            username={discordUsername}
            handleConnect={handleDiscordConnect}
            handleDisconnect={handleDiscordDisconnect}
          />
        </div>
        <div className="flex relative w-full lg:w-fit justify-center items-center text-center pt-[43px] sm:pt-[25px] lg:pt-[48px]">
          <div
            className="pr-[10px] cursor-pointer"
            onClick={handleBackStepClick}
          >
            <BackButton />
          </div>
          <Button
            loading={loading}
            className="flex-1 h-12 lg:w-[356px]"
            text="Next step"
            onClick={handleNextStepClick}
            disabled={!isActiveButton || loading}
            withArrow
          />
        </div>
        <SkipButton text="skip step" onClick={handleSocialsSkip} />
      </div>
      {socialForDisconnect && (
        <Modal
          handleClose={handleDisconnectModalClose}
          childrenWrapperStyles="h-screen items-center"
        >
          <DisconnectSocialModal
            socialName={socialForDisconnect}
            handleClose={handleDisconnectModalClose}
          />
        </Modal>
      )}
    </>
  );
};
