import {TelegramAuthData} from '@telegram-auth/react';
import axios, {AxiosError} from 'axios';
import {signUpStore} from '../modules/Login/store/useSignUpStore';
import {AuthAccess} from '../types/common';
import {EventPoints} from '../types/types';
import {handleSignUpError} from '../utilities/axiosErrors';
import {$api, API_URL} from './interceptor';
import {userService} from './userApi';
import {SocialName} from '../modules/AccountProgress/store/useUserStore';
import {getSocialId} from '../utilities/getSocialId';

interface SocialsType {
  id: string;
  socialId: string;
  social: {
    id: string;
    name: string;
    iconUrl: string;
  };
  socialData: unknown;
  createdAt: string;
}

interface KYCType {
  id: string;
  status:
    | 'APPROVED'
    | 'SUBMISSION_REQUIRED'
    | 'RESUBMISSION_REQUIRED'
    | 'REJECTED'
    | 'PENDING_VERIFICATION';
  sessionId: string | null;
  userId: string;
}

const SignUp = () => {
  const {setState, getState} = signUpStore;

  const sendReferralCodeRequest = async () => {
    const {refCode} = getState();
    try {
      setState({loading: true});
      if (refCode) {
        const referralCode = {
          referralCode: refCode,
        };

        const response = await $api.put(
          `/referral/set-referer/${referralCode.referralCode}`,
          referralCode
        );
        await userService.getUserData();
        if (response.status === 200) {
          setState({
            messageFromRequestSignUp: '',
          });
        }
        return response;
      }
    } catch (error) {
      handleSignUpError(error, setState);
    } finally {
      setState({loading: false});
    }
    return null;
  };

  const sendWalletRequest = async (
    address: string | undefined,
    data: string,
    errorCallback?: null | ((message: string) => void)
  ) => {
    try {
      setState({loading: true});
      if (address && data) {
        const requestData = {
          address: address,
          signature: data,
        };
        const response = await $api.post('/wallets/connect', requestData);
        await userService.getUserData();
        if (response.status === 201) {
          setState({
            messageFromRequestSignUp: '',
          });
        }
        return response;
      }
    } catch (error) {
      handleSignUpError(error, setState);
      const typedError = error as AxiosError<{message: string}>;
      errorCallback && errorCallback(typedError?.response?.data?.message || '');
      return null;
    } finally {
      setState({loading: false});
    }
    return null;
  };

  const sendRefreshRequest = async () => {
    try {
      const storageAccessToken = localStorage.getItem('accessToken');
      const resp = await axios.post<AuthAccess>(
        `${API_URL}/auth/refresh`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `${storageAccessToken!}`,
          },
        }
      );
      const accessToken: string = resp.data.access;
      localStorage.setItem('accessToken', accessToken);
      return true;
    } catch (error) {
      localStorage.removeItem('accessToken');
      window.location.replace('/#/');
      return false;
    }
  };

  const sendNameSurnameRequest = async () => {
    const {firstName, lastName} = getState();

    if (
      firstName &&
      lastName &&
      firstName.trim() !== '' &&
      lastName.trim() !== ''
    ) {
      try {
        setState({loading: true});
        const requestData = {
          updateProfileOptions: {
            name: firstName,
            surname: lastName,
          },
        };

        const response = await $api.patch('/users/@me', requestData);
        await userService.getUserData();
        if (response.status === 200) {
          setState({
            messageFromRequestSignUp: '',
          });
        }
        return response;
      } catch (error) {
        handleSignUpError(error, setState);
      } finally {
        setState({loading: false});
      }
    }

    return null;
  };

  const sendTelegramData = async (requestData: TelegramAuthData) => {
    try {
      const {data} = await $api.post<SocialsType>(
        '/user-socials/connect/telegram',
        requestData
      );
      await userService.getUserData();
      return data;
    } catch (error) {
      return handleSignUpError(error, setState);
    }
  };

  const sendTwitterData = async (requestData: string) => {
    try {
      const {data} = await $api.post<SocialsType>(
        `/user-socials/connect/twitter?code=${requestData}`
      );
      await userService.getUserData();
      // clear query
      window.history.pushState({}, document.title, window.location.pathname);
      return data;
    } catch (error) {
      return handleSignUpError(error, setState);
    }
  };

  const sendDiscordData = async (
    requestData: string,
    fromTasks = false,
    successCallback?: () => void
  ) => {
    try {
      const {data} = await $api.post<SocialsType>(
        '/user-socials/connect/discord',
        {
          code: requestData,
          redirectUrl: fromTasks
            ? window.location.origin + '/account'
            : window.location.origin + '/registration',
        }
      );
      await userService.getUserData();
      window.history.pushState({}, document.title, window.location.pathname);
      successCallback && successCallback();
      return data;
    } catch (error) {
      return handleSignUpError(error, setState);
    }
  };

  const disconnectSocial = async (socialName: SocialName) => {
    const socialId = getSocialId(socialName);

    if (!socialId) {
      return;
    }

    try {
      await $api.delete(`/user-socials/disconnect/${socialId}`);
      await userService.getUserData();
    } catch (error) {
      return handleSignUpError(error, setState);
    }
  };

  const getKYC = async () => {
    try {
      const {data} = await $api.get<KYCType>('/kyc');
      return data;
    } catch (error) {
      return null;
    }
  };

  const sendKYC = async () => {
    try {
      setState({loading: true});
      const {data} = await $api.post<KYCType>('/kyc');
      return data;
    } catch (error) {
      return handleSignUpError(error, setState);
    } finally {
      setState({loading: false});
    }
  };

  const getActionPoints = async () => {
    try {
      const response = await $api.get<EventPoints>('/actions-level-points');
      if (response.status === 200) {
        setState({
          actionPoints: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getActionPoints,
    sendTwitterData,
    sendDiscordData,
    sendRefreshRequest,
    sendReferralCodeRequest,
    sendNameSurnameRequest,
    sendWalletRequest,
    sendTelegramData,
    getKYC,
    sendKYC,
    disconnectSocial,
  };
};

export const signUpService = SignUp();
