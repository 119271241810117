import {TicketIcon} from '../../../assets/svgComponents/TicketIcon';
import {Tooltip} from '../../../components/Tooltip';
import {IMyTask, TaskType, MyTaskStatus} from '../../../types/tasks';
import {QuizResultStatus} from './QuizResultStatus';
import {RetryButton} from './RetryButton';
import {WodlResultStatus} from './WodlResultStatus';

export const TaskStatus = ({
  quiz,
  userQuizResult,
  rewardPointsAmount,
  status,
  type,
  twitterTask,
  userTaskId,
  rewardTicketsAmount,
  userWodlResult,
}: Pick<
  IMyTask,
  | 'quiz'
  | 'userQuizResult'
  | 'rewardPointsAmount'
  | 'type'
  | 'twitterTask'
  | 'rewardTicketsAmount'
  | 'wodl'
  | 'userWodlResult'
> &
  Partial<Pick<IMyTask, 'status' | 'userTaskId'>>) => {
  const isRetryButtonShown =
    ((type === TaskType.twitter && twitterTask) ||
      type === TaskType.telegram ||
      type === TaskType.nft ||
      type === TaskType.gitcoin ||
      type === TaskType.discord) &&
    userTaskId &&
    status === MyTaskStatus.IN_PROGRESS;

  return status === MyTaskStatus.DONE ? (
    <div className="text-green-400 xl:text-right text-xs font-bold uppercase font-mono">
      Completed {!!rewardPointsAmount && `+${rewardPointsAmount}pts`}
    </div>
  ) : (
    <div className="flex items-center flex-wrap gap-3 md:justify-end text-right">
      {status === MyTaskStatus.IN_PROGRESS && userQuizResult && (
        <QuizResultStatus quiz={quiz} userQuizResult={userQuizResult} />
      )}
      {status === MyTaskStatus.IN_PROGRESS && userWodlResult && (
        <WodlResultStatus userWodlResult={userWodlResult} />
      )}

      <div className="flex gap-2 top-1 right-0 absolute xl:relative xl:top-0">
        {isRetryButtonShown && (
          <div>
            <RetryButton
              userTaskId={userTaskId}
              status={status}
              taskType={type}
            />
          </div>
        )}
        <div className="flex md:flex-col-reverse gap-2">
          {!!rewardTicketsAmount && (
            <Tooltip
              content={
                <div className="max-w-[216px] p-3 bg-black rounded-lg text-l-800 text-xs leading-5 font-sans">
                  These lottery tickets will be used to enter an additional
                  round of FCO.
                </div>
              }
              contentClassName="bg-transparent"
              arrowFill="black"
            >
              <span className="bg-green-200 rounded-[1.75rem] text-black text-xs uppercase font-bold h-7 flex gap-1 items-center justify-center py-[6px] pr-2 pl-[10px] font-mono leading-3">
                +{rewardTicketsAmount}
                <TicketIcon
                  width={16}
                  height={16}
                  ticketTopFill="black"
                  ticketBottomFill="black"
                />
              </span>
            </Tooltip>
          )}
          {!!rewardPointsAmount && (
            <div className="min-h-[28px] flex items-center justify-center text-center px-[10px] rounded-[1.75rem] bg-green-200 font-mono">
              <span className="text-black text-xs uppercase font-bold">
                +{rewardPointsAmount}PTS
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
