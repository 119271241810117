import {LetterState} from '../constants';
import {KnownWord} from '../store';

export const formatSubmittedAnswers = (
  submitedAnswer: Array<Record<string, LetterState>>
): KnownWord => {
  return submitedAnswer.map(answer => {
    const [key, value] = Object.entries(answer)[0];
    return {letter: key, status: value as unknown as LetterState};
  }) as KnownWord;
};
