import logoutIcon from '../assets/logoutIcon.svg';
import userIcon from '../assets/userIcon.svg';
import {signInService} from '../services/SignIn';
import {useUserStore} from '../modules/AccountProgress/store/useUserStore';
import {useState} from 'react';
import {Tooltip} from './Tooltip';
import {useNavigate} from 'react-router-dom';
import {useStepsStore} from '../modules/Login/store/useStepsStore';
import {useSignUpStore} from '../modules/Login/store/useSignUpStore';

export const LogoutButton = () => {
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const {user} = useUserStore();
  const {setIsNew} = useSignUpStore();
  const navigate = useNavigate();
  const handleHiddenDropdown = () => {
    setDropdownVisible(visible => !visible);
  };
  const {stepsArray, setSteps, setActiveStep} = useStepsStore();

  const logout = () => {
    void signInService.logout().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    });
  };

  const isTooltipShown =
    !!user &&
    (user?.kyc?.status !== 'APPROVED' || user.profileSetupProgress !== 100);

  return (
    <>
      <button
        className="backdrop-blur-md 2xl:min-h-[3rem] flex justify-center w-[2.75rem] h-[2.75rem] rounded-full items-center gap-3 2xl:pl-4 2xl:pr-6 relative bg-l-100 2xl:rounded-[100px] transition-all 2xl:w-fit 2xl:h-fit hover:bg-l-50 active:brightness-75"
        onClick={handleHiddenDropdown}
      >
        {isTooltipShown && (
          <Tooltip
            align="end"
            side="left"
            content={
              user?.kyc?.status !== 'APPROVED'
                ? 'You have to finish KYC step'
                : user.profileSetupProgress !== 100
                ? 'You have to finish all steps'
                : ''
            }
            contentClassName="z-[200]"
            triggerClassName="absolute m-1 w-3 2xl:w-6 h-3 2xl:h-6 -top-1 -right-[0px] 2xl:-top-[6px] 2xl:-right-1.5"
          >
            <span
              className={`bg-[#040506] w-[inherit] h-[inherit] rounded-full after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:w-3 after:h-3 2xl:after:w-[14px] 2xl:after:h-[14px]
             ${
               user?.kyc?.status !== 'APPROVED'
                 ? 'after:bg-[#FB4949]'
                 : user.profileSetupProgress !== 100
                 ? 'after:bg-green-200'
                 : 'after:hidden'
             }`}
            ></span>
          </Tooltip>
        )}
        <img className="w-6 h-6" src={userIcon} alt="userIcon" />
        <span className="text-sm uppercase font-semibold hidden 2xl:block">
          Account
        </span>
        <div
          className={`absolute cursor-default !w-[266px] right-0 top-[calc(100%+1rem)] z-10 bg-[#1e1e1f] rounded-20 transition-all border-solid border-l-100 border p-5 overflow-hidden ${
            !isDropdownVisible ? 'scale-95 opacity-0 pointer-events-none' : ''
          }`}
        >
          <ul className="[&>li]:cursor-pointer text-start [&>li]:h-10">
            <li className=" text-sm font-mono mb-3">
              <div className="font-bold text-l-800 mb-[2px] truncate">
                {user?.profile?.name} {user?.profile?.surname}
              </div>
              <div className="text-l-600 truncate">{user?.email}</div>
            </li>
            <li
              className="mb-3 flex gap-3 items-center"
              onClick={() => {
                setActiveStep(1);
                setSteps(stepsArray.filter(item => item.id !== 0) ?? []);
                setIsNew(false);
                navigate('/registration');
              }}
            >
              <img className="w-6 h-6" src={userIcon} alt="userIcon" />
              <span className="text-sm text-white uppercase font-semibold 2xl:block ">
                Account
              </span>
            </li>
            <li
              onClick={logout}
              className="flex justify-start items-center gap-3 transition-all min-w-[9rem]"
            >
              <img className="w-6 h-6" src={logoutIcon} alt="logoutIcon" />
              <span className="text-sm font-bold uppercase">Log out</span>
            </li>
          </ul>
        </div>
      </button>
    </>
  );
};
