import {FinishedWoodleFooterWrapper} from './FinishedWoodleFooterWrapper';
import {WoodleClaimButton} from './WoodleClaimButton';

export const WoodleSuccessed = ({
  pointReward,
  ticketReward,
}: {
  pointReward?: number | null;
  ticketReward?: number | null;
}) => {
  return (
    <div>
      <FinishedWoodleFooterWrapper
        title={'You guessed the word of the day'}
        details={'Now you can claim your reward, you earned it ;)'}
      >
        <WoodleClaimButton
          pointReward={pointReward}
          ticketReward={ticketReward}
        />
      </FinishedWoodleFooterWrapper>
    </div>
  );
};
