import {create, useStore} from 'zustand';
import {INetwork, IToken} from '../../../../types/project';

export interface IInvestedProject {
  id: string;
  name: string;
  description: string;
  token: IToken;
  allocationToken: IToken;
  network: INetwork;
  minAllocation: number;
  maxAllocation: number;
  tokenPrice: number;
  investContractAddress: string;
  signatureUrl: string;
  decimals: number;
}

interface ProjectDisclaimerStore {
  investedProject: IInvestedProject | null;
  maxAllocation: number;
  clear: () => void;
}

const initialState: Pick<ProjectDisclaimerStore, 'investedProject'> = {
  investedProject: null,
};

export const projectDisclaimerStore = create<ProjectDisclaimerStore>(set => ({
  investedProject: null,
  maxAllocation: 0,
  setInvestedProject: (investedProject: IInvestedProject) =>
    set({investedProject}),

  clear: () => set({...initialState}),
}));

export const useProjectDisclaimerStore = () => useStore(projectDisclaimerStore);
