import {bindWalletModalStore} from './store';

const connectWalletModalApi = () => {
  const {setState} = bindWalletModalStore;
  const handleModalOpen = ({
    signMessageErrorCallback,
  }: {
    signMessageErrorCallback?: null | ((errorText: string) => void);
  } = {}) => {
    setState({
      isOpened: true,
      signMessageErrorCallback: signMessageErrorCallback || null,
    });
  };
  const handleModalClose = () => {
    setState({isOpened: false});
  };

  const setIsConsciousChange = (isConsciousChange: boolean) => {
    setState({isConsciousChange});
  };

  const setSignMessageErrorCallback = (
    signMessageErrorCallback: null | ((errorText: string) => void)
  ) => {
    setState({signMessageErrorCallback});
  };

  return {
    handleModalOpen,
    handleModalClose,
    setIsConsciousChange,
    setSignMessageErrorCallback,
  };
};

export const bindWalletModalService = connectWalletModalApi();
