import axios, {AxiosResponse} from 'axios';

const minute = 60_000;

type IPrice = {usd: number; usd_24h_change: number};

type Cache = {
  value: Record<string, IPrice>;
  timestamp: number;
};

class PriceService {
  private _loading = false;
  private _cache: Cache = JSON.parse(
    localStorage.getItem('price_cache') ||
      JSON.stringify({
        value: {},
        timestamp: 0,
      })
  ) as Cache;

  private _url = 'https://api.coingecko.com/api/v3';
  private _supported_tokens = {
    usdt: 'tether',
    usdc: 'usd-coin',
  };
  private _tockens = Object.values(this._supported_tokens).join(',');
  private _timeout = minute * 1;

  async fetchPrice() {
    if (this._loading) {
      return {value: 0, timestamp: 0};
    }
    if (this._cache.timestamp + this._timeout > Date.now()) {
      return this._cache;
    }
    this._loading = true;

    try {
      const response: AxiosResponse<Record<string, IPrice>> = await axios.get(
        `${this._url}/simple/price?ids=${this._tockens}&vs_currencies=usd&include_24hr_change=true`
      );
      const fetchedPrice: Record<string, IPrice> = {};

      for (const [key, value] of Object.entries(this._supported_tokens)) {
        fetchedPrice[key] = response.data[value] as IPrice;
      }
      this._loading = false;

      this._cache = {value: fetchedPrice, timestamp: Date.now()};
      localStorage.setItem(
        'price_cache',
        JSON.stringify({value: fetchedPrice, timestamp: Date.now()})
      );
    } catch (error) {
      console.warn(error);
    }

    return this._cache;
  }
  get(symbol: string) {
    symbol = symbol.toLowerCase();
    const isTokenExist = Object.keys(this._supported_tokens).includes(symbol);

    if (!isTokenExist) {
      return {usd: 0, usd_24h_change: 0};
    }
    void this.fetchPrice();

    const tokenPrice = this._cache.value[symbol];

    return tokenPrice || {usd: 0, usd_24h_change: 0};
  }
}
export const Price = new PriceService();
