export const InfoIcon = ({
  iIconProps,
  circleProps,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  iIconProps?: React.SVGProps<SVGPathElement>;
  circleProps?: React.SVGProps<SVGPathElement>;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99967 14.6668C7.07745 14.6668 6.21079 14.4917 5.39967 14.1415C4.58856 13.7913 3.88301 13.3164 3.28301 12.7168C2.68301 12.1168 2.20812 11.4113 1.85834 10.6002C1.50856 9.78905 1.33345 8.92238 1.33301 8.00016C1.33301 7.07794 1.50812 6.21127 1.85834 5.40016C2.20856 4.58905 2.68345 3.8835 3.28301 3.2835C3.88301 2.6835 4.58856 2.20861 5.39967 1.85883C6.21079 1.50905 7.07745 1.33394 7.99967 1.3335C8.9219 1.3335 9.78856 1.50861 10.5997 1.85883C11.4108 2.20905 12.1163 2.68394 12.7163 3.2835C13.3163 3.8835 13.7915 4.58905 14.1417 5.40016C14.4919 6.21127 14.6668 7.07794 14.6663 8.00016C14.6663 8.92238 14.4912 9.78905 14.141 10.6002C13.7908 11.4113 13.3159 12.1168 12.7163 12.7168C12.1163 13.3168 11.4108 13.7919 10.5997 14.1422C9.78856 14.4924 8.9219 14.6673 7.99967 14.6668Z"
        fill="white"
        fillOpacity="0.1"
        {...circleProps}
      />
      <path
        d="M7.66638 5.66684C7.79064 5.66687 7.92028 5.66684 7.99889 5.66665L7.99967 5.66665L8.33325 5.66665C8.33325 5.54239 8.33319 5.41273 8.33301 5.3341L8.33301 5.33325L8.33307 5.00015L7.66638 5.66684ZM7.66638 5.66684L7.66634 5.33369C7.66653 5.25422 7.66658 5.124 7.66658 4.99972L7.99889 4.99998C7.99895 4.99998 7.99902 4.99998 7.99908 4.99998L7.66638 5.66684ZM7.66634 11V7.66665H8.33301V11H7.66634Z"
        stroke="#818283"
        strokeWidth="0.666667"
        {...iIconProps}
      />
    </svg>
  );
};
