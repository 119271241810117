import {useShallow} from 'zustand/react/shallow';
import {woodleStore} from '../../../store';
import {ordinals} from '../../../constants';

export const WoodleMobileTip = () => {
  const word = woodleStore(useShallow(({word}) => word));
  const attempts = woodleStore(
    useShallow(({currentWoodle}) => currentWoodle?.attempts)
  );
  const submittedWordsLength = woodleStore(
    useShallow(({submittedWords}) => submittedWords.length)
  );

  return (
    <div className="text-l-800 text-xs leading-6 font-mono">
      {word.length ? (
        <div className="font-bold font-mono text-l-800 break-keep">
          <span className="text-l-300">Attempt {submittedWordsLength + 1}</span>{' '}
          of {''}
          {attempts}
        </div>
      ) : (
        <>
          <div className="font-mono text-l-800">
            Enter {ordinals[submittedWordsLength]} word
          </div>
        </>
      )}
    </div>
  );
};
