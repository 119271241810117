import React, {ReactNode, useEffect} from 'react';
import logoIcon from '../assets/logoIcon.svg';
import Line from '../assets/Line.png';
import LineTop from '../assets/LineTop.png';
import Circle from '../assets/Circle_white.png';
import {useStepsStore} from '../modules/Login/store/useStepsStore';

type LayoutLoginViewProps = {
  children: ReactNode;
};

export const LayoutLoginView: React.FC<LayoutLoginViewProps> = ({children}) => {
  const {reset} = useStepsStore();

  useEffect(() => {
    document.body.classList.add('bg-[#070809]');
    reset();

    return () => document.body.classList.remove('bg-[#070809]');
  }, []);

  return (
    <div className="flex relative min-h-screen bg-[#070809] after:h-full after:w-[47%] after:top-0 after:left-0 after:bg-[url('./assets/background_registration.png')] after:hidden md:after:block after:bg-cover after:bg-center after:bg-no-repeat after:absolute after:pointer-events-none">
      <div className="relative w-full max-w-[calc(1266px+2rem)] px-4 mx-auto z-[1]">
        <div className="absolute left-4 hidden md:block items-center top-8">
          <img className="" src={logoIcon} alt="raiser" />
        </div>
        <div className="flex h-full md:pt-[100px] xl:pt-[152px] items-start">
          <div className="hidden md:flex bg-center w-full max-w-[650px]">
            <div className="flex flex-col">
              <div className="pt-[60px] md:pt-0 max-w-[522px] justify-center">
                <p className="text-[#525355] text-[28px] lg:text-[48px] leading-[120%] tracking-[-0.96px] font-bold font-sans">
                  Be Part of The{' '}
                  <span>
                    <br></br>Next
                  </span>{' '}
                  Wave of{' '}
                  <span className="text-[#E5FD55] font-bold font-sans">
                    Web3
                  </span>{' '}
                  <span className="text-white font-bold font-sans">
                    <br></br>
                    Projects with Raiser.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center w-full lg:max-w-[602px] relative mx-auto">
            <div className="hidden md:flex flex-col absolute h-full -top-[28px] right-[-3px]">
              <img className="h-full" src={Line} alt="line" />
            </div>
            <div className="hidden md:flex flex-col absolute h-[100px] -top-[3.5rem] right-[-9px]">
              <img src={Circle} alt="" />
            </div>
            {children}
            <div className="hidden md:flex flex-col absolute h-full -top-[28px] left-0">
              <img className="h-full" src={Line} alt="line" />
            </div>
            <div className="hidden md:flex flex-col absolute h-[100px] -top-[3.5rem] left-[-8px]">
              <img src={Circle} alt="" />
            </div>
            <div className="hidden md:block w-[calc(100%-44px)] lg:flex flex-row absolute h-[1px] -top-[50px] left-1/2 -translate-x-1/2 ">
              <img className="w-full" src={LineTop} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
