import {useEffect, useState} from 'react';
import Snow from 'react-canvas-confetti/dist/presets/snow';
import {TooltipInfoIcon} from '../Tooltip/TooltipInfoIcon';
import {dailyRewardsService} from './service';
import {TimerButton} from './TimerButton';
import {useDailyRewardStore} from './store';

const getTimeLeft = (targetDateString: string) => {
  return new Date(targetDateString).getTime() - new Date().getTime();
};

export const DailyReward = ({sm = false}: {sm?: boolean}) => {
  const [isAnimationPlaying, setAnimationPlaying] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const {dailyReward, nextClaim} = useDailyRewardStore();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (timeLeft) {
      intervalId = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1000);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [timeLeft]);

  useEffect(() => {
    void dailyRewardsService.getDailyReward();
  }, []);

  useEffect(() => {
    if (nextClaim) {
      setTimeLeft(getTimeLeft(nextClaim));
    }
  }, [nextClaim]);

  const handleClick = () => {
    setAnimationPlaying(true);
    void dailyRewardsService.claimDailyReward();

    setTimeout(() => {
      setAnimationPlaying(false);
    }, 4000);
  };

  return (
    <div className="">
      <div className={`flex gap-2 items-center ${sm ? 'mb-4' : 'mb-6'}`}>
        <div className={`${sm ? 'text-base' : 'text-xl'} leading-6 font-bold`}>
          Daily reward
        </div>
        <TooltipInfoIcon
          hidden={false}
          content="You can earn extra PTS on a daily basis"
        />
      </div>

      {timeLeft > 0 && !isAnimationPlaying ? (
        <button className="relative cursor-pointer w-full flex justify-center items-center gap-[10px] rounded-[42px] h-[40px] bg-[#FFFFFF26] text-white font-mono text-xs">
          <TimerButton timeLeft={timeLeft} />
        </button>
      ) : (
        <button
          disabled={isAnimationPlaying}
          className="relative py-2 cursor-pointer w-full flex justify-center items-center gap-[10px] rounded-[42px] h-[40px] bg-green-200 text-black hover:bg-white hover:text-black uppercase font-mono  font-bold"
          onClick={handleClick}
        >
          {isAnimationPlaying ? (
            <>
              <Snow
                autorun={{speed: 10, duration: 1500, delay: 0}}
                globalOptions={{resize: true, useWorker: true}}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                decorateOptions={(...props) => {
                  return {
                    ...props,
                    startVelocity: 360,
                    decay: 0,
                    particleCount: 30,
                    spread: 90,
                    colors: ['#000000E5', '#00000073', '#00000059', '#000000'],
                  };
                }}
              />
              <div className="flex gap-2 items-center">
                <div>Claimed</div>
                <div className="py-2 px-[10px] bg-black text-green-200 rounded-[28px] text-xs">
                  +{dailyReward}PTS
                </div>
              </div>
            </>
          ) : (
            <div className="flex gap-2 items-center justify-center">
              <div>Claim reward</div>
              <div className="py-2 px-[10px] bg-black text-green-200 rounded-[28px] text-xs">
                +{dailyReward}PTS
              </div>
            </div>
          )}
        </button>
      )}
    </div>
  );
};
