import React from 'react';

export const TicketIcon = ({
  ticketBottomFill = 'white',
  ticketTopFill = 'white',
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ticketTopFill?: string;
  ticketBottomFill?: string;
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        d="M8.74111 7.09233L7.78894 6.14017C7.60202 6.32709 7.29644 6.33152 7.11486 6.14995L5.98112 5.01621C5.83011 4.86519 5.58788 4.86915 5.43287 5.02416L2.30081 8.15622C1.31606 9.14097 1.29268 10.7224 2.24971 11.6794L3.3963 12.826C3.48531 12.915 3.60593 12.9627 3.73176 12.9608C3.85714 12.9586 3.97919 12.9074 4.07037 12.8162C4.41686 12.4697 4.92043 12.4444 5.23487 12.7589C5.56303 13.087 5.55534 13.6173 5.22196 13.9587C5.03504 14.1456 5.0039 14.4336 5.18991 14.6196L6.32409 15.7538C7.28068 16.7104 8.85809 16.683 9.83828 15.7028L12.9749 12.5662C13.1299 12.4112 13.133 12.1681 12.9829 12.0179L12.0258 11.0609C11.8403 10.8753 11.8487 10.5737 12.0356 10.3868L11.1769 9.5281C10.99 9.71502 10.6839 9.71901 10.5028 9.53788L8.73133 7.76641C8.54975 7.58484 8.55419 7.27925 8.74111 7.09233Z"
        fill={ticketTopFill}
      />
      <path
        d="M12.7576 5.23708C13.0853 5.5648 13.6201 5.55255 13.9573 5.22417C14.1443 5.03725 14.4323 5.00611 14.6138 5.18769L15.752 6.32586C16.709 7.28289 16.6862 8.85574 15.7015 9.84049L14.2152 11.3267C14.0648 11.4772 13.8171 11.4848 13.6665 11.3342L12.7099 10.3777C12.5239 10.1917 12.2274 10.196 12.0359 10.3874L11.1772 9.52871C11.3686 9.33723 11.3681 9.03577 11.1869 8.85464L9.41546 7.08317C9.23344 6.90115 8.93286 6.90146 8.74138 7.09294L7.78877 6.14034C7.98025 5.94886 7.98013 5.64783 7.79855 5.46626L6.66525 4.33296C6.51424 4.18194 6.52232 3.93472 6.67276 3.78427L8.15901 2.29802C9.14376 1.31327 10.7166 1.29046 11.6732 2.24705L12.7892 3.36307C12.8778 3.45165 12.9259 3.57271 12.9237 3.69809C12.9218 3.82392 12.8706 3.94597 12.7795 4.03715C12.4421 4.37452 12.4347 4.91423 12.7576 5.23708Z"
        fill={ticketBottomFill}
      />
    </svg>
  );
};
