import {Link, useNavigate} from 'react-router-dom';

import {ArrowDown} from '../../assets/svgComponents/ArrowDown';
import {Timer} from '../../components/Timer';
import {Project, ProjectSchedule, RoundStatus} from '../../types/project';
import {calcRoundStatus} from '../../utilities/project';
import {navigateToDisclaimer} from '../ProjectInvest/helpers/navigateToDisclaimer';
import './ProjectDetailsStyles.css';
import {ProjectSocials} from './Socials';
import {StatusLabel} from './components/StatusLabel';
import {getIsHardcodedProject} from '../../utilities/getIsHardcodedProject';

interface ProjectHeaderProps {
  project: Project;
  currentRound: ProjectSchedule;
}

export const ProjectHeader = ({project, currentRound}: ProjectHeaderProps) => {
  const navigate = useNavigate();
  const status = calcRoundStatus(currentRound);

  const isInvestAvailable = !!(
    currentRound?.minAllocation &&
    currentRound?.maxAllocation &&
    currentRound?.tokenPrice
  );

  const isHardcodedProject = getIsHardcodedProject(project);

  return (
    <div className="flex flex-col justify-end items-start h-[560px] gap-5 xl:flex-row xl:items-end md:h-[490px]">
      <div className="header-bgImage-wrapper">
        <img
          className="header-bgImage w-full h-full md:object-cover top-0 scale-x-[1.4] md:scale-x-100"
          src={project.mainImageUrl}
        />
      </div>
      <div className="absolute top-[1.125rem] z-[1] flex md:hidden">
        <Link
          to="/dashboard"
          className="flex items-center gap-2 font-sans py-1.5 text-xs text-white text-opacity-50 uppercase"
        >
          <ArrowDown className="opacity-50 rotate-90" width={12} height={12} />
          Dashboard
        </Link>
      </div>
      <div className="relative z-10 w-full lg:w-auto flex flex-col gap-5 lg:flex-row lg:items-end">
        <div className="flex justify-between items-end">
          <div className="w-[96px] h-[96px] lg:w-[120px] lg:h-[120px]">
            <img
              src={project.imageUrl}
              className="w-[96px] h-[96px] lg:w-[120px] lg:h-[120px] rounded-full"
            />
          </div>
          <div className="block lg:hidden">
            <ProjectSocials project={project} />
          </div>
        </div>
        <div className="flex flex-col gap-[19px]">
          <div className="flex items-end gap-[18px]">
            <h1 className="font-sans text-4xl leading-none font-semibold">
              {project.name}
            </h1>
            <StatusLabel roundStatus={status} />
            <div className="hidden lg:block h-[36px] border-l border-[#1D1E1F]" />
            <div className="hidden lg:block">
              <ProjectSocials project={project} />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-3 text-xs font-bold font-mono">
              <span>
                <span className="font-normal inline-block mr-1.5 text-white opacity-40">
                  TYPE
                </span>
                {project?.type}
              </span>
              <span className="w-[3px] h-[3px] bg-white opacity-40 rounded-full" />
              <span className="flex justify-center items-center">
                {isHardcodedProject ? (
                  <>
                    <span className="font-normal inline-block mr-1.5 text-white opacity-40">
                      AMOUNT
                    </span>
                    2000
                  </>
                ) : (
                  <>
                    <span className="font-normal inline-block mr-1.5 text-white opacity-40">
                      CHAIN
                    </span>
                    <div className="inline-flex ml-1.5 mr-2 justify-center items-center w-[20px] h-[20px]">
                      <img
                        src={project.network.iconUrl}
                        className="h-4 rounded-full"
                        alt=""
                      />
                    </div>
                    {project.network.name}
                  </>
                )}
              </span>
            </div>
            <div className="flex gap-1.5 text-xs font-bold font-mono uppercase hidden">
              <span className="font-normal inline-block mr-1.5 text-white opacity-40">
                Current round
              </span>
              <span>{status === 'UPCOMING' && 'upcoming public sale'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full md:w-auto z-10 ml-auto">
        {project.isProjectInvestButtonShown && (
          <Timer
            type="startsIn"
            className="max-w-full ml-auto md:min-w-[283px] md:max-w-[233px]"
            targetDate={
              currentRound.start ? new Date(currentRound.start) : null
            }
            endDate={currentRound.end ? new Date(currentRound.end) : null}
            soldOut={currentRound.status === RoundStatus.SOLDOUT}
            signatureUrl={currentRound.signatureUrl}
            onClick={
              isInvestAvailable
                ? () => {
                    navigateToDisclaimer(navigate, {
                      id: project.id,
                      name: project.name,
                      description: project.details?.disclaimer?.content,
                      token: project.token,
                      allocationToken: project.allocationToken,
                      network: project.network,
                      minAllocation: currentRound.minAllocation!,
                      maxAllocation: currentRound.maxAllocation!,
                      tokenPrice: currentRound.tokenPrice!,
                      signatureUrl: currentRound.signatureUrl,
                      investContractAddress: currentRound.investContractAddress,
                      decimals: project.allocationToken.decimals,
                    });
                  }
                : null
            }
          />
        )}
      </div>
    </div>
  );
};
