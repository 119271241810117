import {useEffect} from 'react';
import {Button} from '../../components/Button';
import {ForwardButton} from '../../components/ForwardButton';
import {levelsService} from '../../services/levelsApi';
import {userService} from '../../services/userApi';
import {useUserStore} from './store/useUserStore';
import Skeleton from 'react-loading-skeleton';
import {SkeletonColorClasses} from '../../layouts/constants';
import {ProgressArc} from '../../components/ProgressArc';
import {calculateProgress} from '../../utilities/calculateProgress';
import {useLevelsStore} from '../AccountLevel/store/useLevelsStore';
import {TooltipInfoIcon} from '../../components/Tooltip/TooltipInfoIcon';
import {TicketIcon} from '../../assets/svgComponents/TicketIcon';
import {Tooltip} from '../../components/Tooltip';
import {roundNumber} from '../../utilities/roundNumber';

interface AccountProgressProps {
  handleLevelsDisplay?: () => void;
  isRedirectable?: boolean;
  isViewMoreButtonDisplayed: boolean;
}

export const AccountProgressView = ({
  handleLevelsDisplay,
  isViewMoreButtonDisplayed,
  isRedirectable,
}: AccountProgressProps) => {
  const {user} = useUserStore();
  const {levels} = useLevelsStore();

  useEffect(() => {
    if (!levels?.length) {
      void levelsService.getLevelsData();
    }
    if (!user) {
      void userService.getUserData();
    }
  }, []);

  return (
    <>
      {!user ? (
        <>
          <Skeleton
            style={{marginBottom: SkeletonColorClasses.mb}}
            height={22}
          />
          <Skeleton
            style={{marginBottom: SkeletonColorClasses.mb}}
            height={192}
          />
          <Skeleton
            style={{marginBottom: SkeletonColorClasses.mb}}
            height={20}
          />
          <Skeleton
            style={{marginBottom: SkeletonColorClasses.mb}}
            height={20}
          />
        </>
      ) : (
        <div>
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-2">
              <span className="text-xl font-bold">Account Level</span>
              <TooltipInfoIcon
                hidden={false}
                content="The higher your level, the higher your chances of getting allocation and participating in the sale."
                contentClassName={
                  'w-[240px] text-sm !py-[12px] !px-[12px] z-50'
                }
              />
            </div>
            <div className="flex gap-3 items-center">
              <Tooltip
                content={
                  <div className="max-w-[216px] p-1 bg-[#1D1E1E] rounded-lg text-l-800 text-xs leading-5 font-sans">
                    These lottery tickets will be used to enter an additional
                    round of FCO.
                  </div>
                }
                arrowFill="#1D1E1E"
              >
                <div className="bg-[#232526] h-7 text-white flex gap-1 items-center p-2 pl-[10px] rounded-[28px] font-mono text-xs leading-3 font-bold">
                  <div>{user.totalTicketsAmount}</div>
                  <TicketIcon width={16} height={16} />
                </div>
              </Tooltip>
              {isRedirectable && <ForwardButton to="/account" />}
            </div>
          </div>
          <div className="relative">
            <div className="w-[160px] top-3 pointer-events-none flex items-start h-[160px] rounded-full absolute left-1/2 -translate-x-1/2">
              <ProgressArc
                progress={
                  calculateProgress(
                    user?.levelData.progress,
                    user?.levelData?.nextLevelPoints?.pointsToReach,
                    user?.levelData?.currentLevel?.pointsToReach
                  ) / 100
                }
              />
            </div>
            <div className="w-[10rem] m-auto flex justify-center pt-12 items-center mb-6">
              <div className="text-center flex flex-col leading-[100%] font-mono text-[4.375rem]">
                {user?.levelData?.currentLevel?.value || 0}
                <span className="block text-l-600 text-xl uppercase">lvl</span>
              </div>
            </div>
          </div>
          {user?.levelData.nextLevelPoints ? (
            <div className="text-center flex flex-col justify-center items-center gap-6">
              <div className="text-green-200 text-base font-mono">
                {roundNumber(user?.levelData.progress)}&nbsp;PTS&nbsp;
                <span className="text-l-600 text-xs">
                  /&nbsp;
                  {user?.levelData.nextLevelPoints.pointsToReach}&nbsp;PTS
                </span>
              </div>
              <div className="text-l-600 text-xs font-mono">
                {roundNumber(
                  user.levelData.nextLevelPoints.pointsToReach -
                    user.levelData.progress
                )}
                &nbsp;PTS&nbsp;until level up
              </div>
            </div>
          ) : (
            <div className="text-green-200 text-sm font-mono text-center">
              You have reached a max level!
            </div>
          )}
          <div className="flex justify-center w-full">
            {isViewMoreButtonDisplayed && (
              <Button
                onClick={handleLevelsDisplay}
                className="mt-[30px] max-w-[300px] w-full text-sm block bg-l-100 text-white xl:hidden"
                text="VIEW LEVELS PROGRESSION ..."
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
