import {create, useStore} from 'zustand';
import {QuizState} from '../types';

interface QuizModalStore {
  quizModalState: QuizState;
  setQuizModalState: (quizState: QuizState) => void;
  resetStore: () => void;
}

const initialState: Pick<QuizModalStore, 'quizModalState'> = {
  quizModalState: QuizState.NOT_INITIATED,
};

export const quizModalStore = create<QuizModalStore>(set => ({
  quizModalState: QuizState.NOT_INITIATED,
  setQuizModalState: quizModalState => set({quizModalState}),
  resetStore: () => set({...initialState}),
}));

export const useQuizModalStore = () => useStore(quizModalStore);
