export function Header() {
  return (
    <div className="p-6">
      <div className="mb-[14px] flex gap-2 items-center font-bold">
        <div className="text-white text-xl leading-6">Leaderboard</div>
        <div className="text-l-600  px-2 py-1 bg-[#262729] rounded-[28px] text-xs leading-3">
          TOP-100
          <span className="hidden md:inline"> Participants</span>
        </div>
      </div>
    </div>
  );
}
