export const isQuizOnCooldown = (retryAfter?: string) => {
  if (!retryAfter) {
    return false;
  }

  try {
    if (new Date() < new Date(retryAfter)) {
      return true;
    }
  } catch {
    return true;
  }

  return false;
};
