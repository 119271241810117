import {NavigateFunction} from 'react-router-dom';
import {
  IInvestedProject,
  projectDisclaimerStore,
} from '../Disclaimer/store/useProjectDisclaimerStore';

export const navigateToDisclaimer: (
  navigate: NavigateFunction,
  investedProject: IInvestedProject
) => void = (navigate, investedProject) => {
  projectDisclaimerStore.setState({
    investedProject,
  });

  navigate('/disclaimer');
};
