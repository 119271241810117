import {MouseEvent, useEffect, useRef} from 'react';
import {ArrowDown} from '../../../assets/svgComponents/ArrowDown';
import classNames from 'classnames';
import {useWindowResize} from '../../../hooks/useWindowResize';

interface ArticleSideBarItemProps {
  label: string;
  href: string;
  isActive?: boolean;
  isAdaptive?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

export function ProjectSideBarItem({
  label,
  href,
  isActive,
  isAdaptive = false,
  className,
  onClick,
}: ArticleSideBarItemProps) {
  const targetRef = useRef<HTMLElement | null>(null);
  const {isAdaptive: isLg} = useWindowResize(992);
  const {isAdaptive: isMd} = useWindowResize(768);

  useEffect(() => {
    if (href.startsWith('#')) {
      const target = document.querySelector(href) as HTMLElement;
      targetRef.current = target;
    }
  }, [href]);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    onClick && onClick(e);
    if (href.startsWith('#')) {
      if (isAdaptive || !targetRef.current) {
        return;
      }

      if (isLg) {
        let shift = 0;

        if (isMd) shift = 80;

        window.scrollTo({
          top: targetRef.current.offsetTop - shift,
          behavior: 'smooth',
        });
      } else {
        targetRef.current.scrollIntoView({behavior: 'smooth'});
      }
    }
  };

  return (
    <a
      href={href}
      className={classNames(
        !className &&
          'flex grow items-center gap-3.5 font-sans font-normal text-sm ',
        isActive ? 'text-white' : 'text-white opacity-[0.33]',
        isAdaptive && !isActive && 'hidden',
        className
      )}
      onClick={handleClick}
    >
      <span className="max-w-[calc(100vw-84px)] lg:max-w-[160px] truncate">
        {label}
      </span>
      <span className={classNames('inline lg:hidden', !isAdaptive && 'hidden')}>
        <ArrowDown width={16} height={16} />
      </span>
    </a>
  );
}
