import {create, useStore} from 'zustand';
import {ISelectedTaskInfo} from '../../../types/tasks';

export const tasksModalStore = create<{
  selectedTaskInfo: ISelectedTaskInfo | null;
}>(() => ({
  selectedTaskInfo: null,
}));

export const useTasksModalStore = () => useStore(tasksModalStore);
