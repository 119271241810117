import {QuizHeader} from './QuizHeader';
import {QuizFooter} from './QuizFooter';
import {QuizAnswerResult} from './QuizAnswerResult';
import {QuizQuestions} from './QuizQuestions';

export interface IQuizProps {
  handleClose: () => void;
  quizID: string;
}

export function Quiz({quizID, handleClose}: IQuizProps) {
  return (
    <div className="bg-[#0B0D0E] rounded-[20px] border border-l-100 w-[640px] max-h-[700px]">
      <QuizHeader handleClose={handleClose} />
      <div className=" flex flex-col justify-between h-[562px] overflow-auto invisible-scrollbar p-6">
        <QuizQuestions />
        <QuizAnswerResult />
      </div>
      <QuizFooter quizID={quizID} />
    </div>
  );
}
