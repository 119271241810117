import React from 'react';
import {useUserStore} from '../modules/AccountProgress/store/useUserStore';
import {roundNumber} from '../utilities/roundNumber';

export const ProgressBlockAdaptive: React.FC = () => {
  const {user} = useUserStore();
  const filledWidth = () => {
    if (!user) return 0;

    if (user.levelData.nextLevelPoints) {
      return (
        (user.levelData?.progress /
          user.levelData?.nextLevelPoints?.pointsToReach) *
        100
      );
    } else {
      return 100;
    }
  };
  return (
    <div className="flex flex-col w-full gap-y-[1.125rem]">
      <div className="text-xl font-bold tracking-[-0.025rem] text-white flex items-center gap-2">
        Set Up Your
        <span className="text-green-200">Raiser Account</span>
      </div>
      <div className="flex lg:hidden flex-col bg-[#E5FD55] rounded-[10px] lg:w-[376px] p-4 flex-1">
        <div className="flex flex-1 w-full justify-between">
          <div>
            <span className="font-sans text-[#000] font-semibold text-lg leading-[24px] tracking-[-0.48px] ">
              Progress
            </span>
          </div>
          <div>
            {user?.levelData.nextLevelPoints ? (
              <span className="text-2xl lg:text-[30px] font-normal font-mono leading-[24px]">
                {roundNumber(user?.levelData?.progress)}
                <span className="font-mono text-base lg:text-[24px] font-normal text-[#000] leading-[24px] tracking-[-0.32px]">
                  / {user?.levelData.nextLevelPoints.pointsToReach}
                </span>
                <span className="font-mono text-base lg:text-[24px] pl-[11px] text-[#000] leading-[24px] font-normal tracking-[-0.32px]">
                  pts
                </span>
              </span>
            ) : (
              <span className="text-sm">You have reached a max level.</span>
            )}
          </div>
        </div>
        <div className="flex pt-[6px]">
          <div className="relative h-[6px] bg-[rgba(0,0,0,.2)] bg-opacity-30 rounded-[10px] w-full ">
            <div
              className="absolute w-10 h-[6px] rounded-[10px] bg-black"
              style={{width: `${Math.min(filledWidth(), 100)}%`}}
            ></div>
          </div>
        </div>
        <div className="flex pt-[10px] ">
          {user && user.levelData?.nextLevelPoints && (
            <span className="text-[12px] font-normal font-mono leading-[18px] text-gray-600	">
              You are on level {user?.levelData?.currentLevel?.value}. You
              need&nbsp;
              {roundNumber(
                user.levelData.nextLevelPoints.pointsToReach -
                  user.levelData.progress
              )}
              &nbsp;points to reach {''}
              level {user?.levelData?.nextLevelPoints.value}.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
