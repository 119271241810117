import {levelsStore} from '../modules/AccountLevel/store/useLevelsStore';
import {$api} from './interceptor';

const levelsApi = () => {
  const getLevelsData = async () => {
    const {setState} = levelsStore;

    try {
      const response = await $api.get('/levels');
      if (response.status === 200) {
        setState({
          levels: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {getLevelsData};
};

export const levelsService = levelsApi();
