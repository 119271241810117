import React from 'react';

export const BackArrow = (props: React.SVGProps<SVGElement>) => {
  return (
    <svg
      width="31"
      height="6"
      viewBox="0 0 31 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{transition: 'background-color 0.3s, opacity 0.3s'}}
      onClick={props.onClick}
      className={props.className}
    >
      <path
        d="M0 5.5L30.5 5.5V3.57692L15.0391 3.57692V0.5L0 5.5Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
};
