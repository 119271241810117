export enum RoundStatus {
  PENDING = 'PENDING',
  SOLDOUT = 'SOLDOUT',
}
export interface ProjectSchedule {
  id: string;
  title: string;
  totalAmountPerRound: number;
  participantsLimit: number;
  minAllocation: number | null;
  maxAllocation: number | null;
  allocation: number | null;
  isKolUser: string;
  conditionMinUserLevel: number;
  totalRase: number | null;
  tokenPrice: number | null;
  start: string | null;
  status: RoundStatus;
  end: string | null;
  projectId: string;
  hardCap: number;
  vesting: string;
  investContractAddress: string;
  signatureUrl: string;
}

export enum TokenType {
  ERC_20 = 'ERC_20',
  BEP_20 = 'BEP_20',
  NATIVE = 'NATIVE',
}
export enum NetworkType {
  EVM = 'ERC_20',
}

export interface Project {
  id: string;
  name: string;
  type: string;
  image: string;
  imageUrl: string;
  mainImage: string;
  active: boolean;
  mainImageUrl: string;
  /**
   * Timestamp in seconds
   */
  // start_date: number;
  /**
   * Timestamp in seconds
   */
  // finish_date: number;
  current_raise: string;
  allocations: string[];
  vestingDescription: string;
  vestingContractAddress: string;
  vestingStartDate: string;
  details: ProjectDetails;
  token: IToken;
  tokenId: string;
  allocationToken: IToken;
  allocationTokenId: string;
  network: INetwork;
  networkId: string;
  socials: Socials;
  projectRounds: ProjectSchedule[];

  isProjectDataBarShown: boolean;
  isProjectNotifyBlockShown: boolean;
  isProjectInvestButtonShown: boolean;
}

export type IProjectRounds = Array<
  ProjectSchedule & {
    boughtAllocations: Array<{
      id: string;
      roundId: string;
      amountInvested: number;
      price: number;
      recipient: string;
      userId: string;
      createdAt: string;
    }>;
  }
>;

export interface IProjectFCO
  extends Omit<
    Project,
    | 'start_date'
    | 'finish_date'
    | 'current_raise'
    | 'allocations'
    | 'vesting'
    | 'details'
  > {
  projectRounds: IProjectRounds;
}

export interface IToken {
  id: string;
  contractAddress: string;
  symbol: string;
  iconUrl: string;
  type: TokenType;
  networkId: string;
  network: INetwork;
  decimals: number;
}

export interface INetwork {
  id: string;
  name: string;
  chainId: number;
  rpcUrl: string;
  iconUrl: string;
  type: NetworkType;
}

export interface ProjectDetails {
  [key: string]: {
    content: string;
    order: number;
  };
}

export type Socials = {
  twitter: string;
  discord: string;
  website: string;
  telegram: string;
};

export enum ROUND_STATUS {
  UPCOMING = 'UPCOMING',
  FINISHED = 'FINISHED',
  LIVE_NOW = 'LIVE NOW',
}
