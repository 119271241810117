import {createArray} from '../../../../utilities/createArray';
import {LetterState} from '../../constants';
import {KnownWord} from '../../store';
import {Letter} from './Letter';

export const SubmittedWord = ({
  length,
  word,
}: {
  length: number;
  word: KnownWord;
}) => {
  return (
    <div>
      <div className="w-full flex gap-1 md:gap-3 justify-center">
        {createArray(length).map(i => (
          <Letter
            key={i}
            letter={word[i].letter || ''}
            state={word[i].status as LetterState}
          />
        ))}
      </div>
    </div>
  );
};
