import React from 'react';

export const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="14" cy="14" r="14" fill="#7F7F7F" />
      <path d="M7 14.5L12 19.5L22 9.5" stroke="white" strokeWidth="2" />
      <path d="M14.0195 15L9.01953 20" stroke="#7F7F7F" strokeWidth="1.5" />
    </svg>
  );
};
