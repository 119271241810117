import {isMetamaskExtensionInstalled} from '../../utilities/metamask';
import {handleExternalLinkOpen} from '../../utilities/handleExternalLinkOpen';
import {useAccount, useConnect, useSignMessage} from 'wagmi';
import {useWeb3Modal} from '@web3modal/react';
import {Modal} from '../../components/Modal';
import {useBindWalletModalStore} from './store';
import {bindWalletModalService} from './service';
import {Cross} from '../../assets/svgComponents/Cross';
import {signUpService} from '../../services/SignUp';
import {useEffect} from 'react';
import {useUserStore} from '../AccountProgress/store/useUserStore';

export function BindWalletModal() {
  const {address, connector} = useAccount();
  const {user} = useUserStore();
  const {isOpened, isConsciousChange, signMessageErrorCallback} =
    useBindWalletModalStore();
  const {connect, connectors} = useConnect({
    onMutate: () => {
      bindWalletModalService.setIsConsciousChange(true);
    },
    onSettled: () => {
      bindWalletModalService.setIsConsciousChange(false);
    },
  });
  const {open, isOpen} = useWeb3Modal();

  const {signMessage} = useSignMessage({
    onSuccess: async (data: string) => {
      try {
        await signUpService.sendWalletRequest(
          address,
          data,
          signMessageErrorCallback
        );
      } catch (error) {
        console.log(error);
      }
    },
    onSettled: () => {
      bindWalletModalService.setIsConsciousChange(false);
    },
  });

  useEffect(() => {
    if (user && connector && address && isConsciousChange) {
      signMessage({
        message: 'Binding the wallet to an account on the Raiser platform.',
      });
    }
  }, [address, connector, user, signMessage, isConsciousChange]);

  useEffect(() => {
    bindWalletModalService.setIsConsciousChange(isOpen && !address);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleConnectMetamask = () => {
    bindWalletModalService.handleModalClose();
    if (!isMetamaskExtensionInstalled()) {
      handleExternalLinkOpen('https://metamask.app.link/dapp/');
      return;
    }
    connect({
      connector: connectors[1],
    });
    bindWalletModalService.handleModalClose();
  };

  const handleConnectWallet = async () => {
    await open();

    bindWalletModalService.handleModalClose();
  };

  if (!isOpened) {
    return;
  }

  return (
    <Modal handleClose={bindWalletModalService.handleModalClose}>
      <div className="bg-[#0B0D0E] text-white  rounded-[20px] border border-l-100 w-[360px] flex flex-col items-center justify-between p-5 relative">
        <div className="w-full h-5">
          <Cross
            className="absolute -right-1 -top-1 cursor-pointer"
            onClick={bindWalletModalService.handleModalClose}
          />
        </div>
        <div className="font-bold text-2xl mb-4">Connect wallet with</div>
        <div className="flex flex-col justify-around w-full [&>*]:cursor-pointer gap-3">
          <div
            className="bg-[#DDDDDD33] rounded-20 w-full h-12 flex items-center justify-center hover:opacity-90"
            onClick={handleConnectMetamask}
          >
            Metamask
          </div>
          <div
            className="bg-[#DDDDDD33] rounded-20 w-full h-12 flex items-center justify-center hover:opacity-90"
            onClick={handleConnectWallet}
          >
            WalletConnect
          </div>
        </div>
      </div>
    </Modal>
  );
}
