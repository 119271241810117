export const CircleStep = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        id="Ellipse 360"
        cx="6"
        cy="6"
        r="4"
        stroke="#E5FD55"
        strokeWidth="4"
      />
    </svg>
  );
};
