import {$api} from '../../../services/interceptor';
import {Project} from '../../../types/project';
import {MyTasks, Tasks} from '../../../types/tasks';
import {tasksStore} from '../../AccountTask/store/useTasksStore';
import {projectsTasksStore} from '../store/useProjectsTasksStore';
import adaptiveLogo from '../../../assets/RaiserLogo.svg';
import {GENERAL_ID} from '../constants';
import {isCrossTheAgesProject} from '../../../utilities/getIsHardcodedProject';

const projectsTasksApi = () => {
  const {getState, setState} = projectsTasksStore;
  const getProjects = async () => {
    try {
      setState({isProjectsLoading: true});
      await new Promise(resolve => setTimeout(resolve, 1000));

      const response = await $api.get<Array<Project>>('/projects');
      const generalProjects = [
        {
          name: 'General',
          imageUrl: adaptiveLogo as string,
          active: true,
        },
      ];

      setState({
        projects: generalProjects.concat(
          response.data
            .map(({imageUrl, name, id, active}) => ({
              id,
              name,
              imageUrl,
              active,
            }))
            .sort(({active: a}, {active: b}) => Number(b) - Number(a))
            .sort(project => (isCrossTheAgesProject(project) ? -1 : 0))
        ),
        isProjectsLoading: false,
      });
    } catch (error) {
      setState({projects: [], isProjectsLoading: false});
      console.log(error);
    }
  };

  const getProjectTasks = async (projectId: string, withoutLoader = true) => {
    try {
      const projectsTasks = getState().projectsTasks;
      {
        !withoutLoader &&
          setState({
            projectsTasks: {
              ...projectsTasks,
              [projectId]: {
                tasks: {available: [], inProgress: [], done: []},
                isLoading: true,
              },
            },
          });
      }

      const response = await $api.get<{
        available: Tasks;
        inProgress: MyTasks;
        done: MyTasks;
      }>('/tasks/my', {
        params: {projectId: projectId === GENERAL_ID ? undefined : projectId},
      });

      {
        !withoutLoader &&
          setState({
            projectsTasks: {
              ...projectsTasks,
              [projectId]: {tasks: response.data, isLoading: false},
            },
          });
      }

      tasksStore.setState({
        activeTasks: response.data.inProgress,
        availableTasks: response.data.available,
        completeTasks: response.data.done,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const clear = () => {
    setState({
      projects: [],
      projectsTasks: {},
      isProjectsLoading: false,
      selectedProjectId: undefined,
    });
  };

  return {getProjects, getProjectTasks, clear};
};

export const projectsTasksService = projectsTasksApi();
