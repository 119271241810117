import {useEffect, useState} from 'react';
import {useQuizStore} from '../store/useQuizStore';

import {Button} from '../../../components/Button';

import GeneratedCheckCircle3D from '../../../assets/GeneratedCheckCircle3D.png';
import GeneratedCrossCircle3D from '../../../assets/GeneratedCrossCircle3D.png';
import {QuestionState} from '../types';
import {TicketIcon} from '../../../assets/svgComponents/TicketIcon';
import {QuizType} from '../../../types/tasks';
import {quizService} from '../service/service';

export interface IQuizResultsProps {
  handleClose: () => void;
  pointReward?: number;
  ticketReward?: number;
  isCustomRewardTask?: boolean;
  quizType: QuizType;
  userTaskID?: string;
}

export function QuizResults({
  pointReward,
  ticketReward,
  quizType,
  isCustomRewardTask,
  userTaskID,
  handleClose,
}: IQuizResultsProps) {
  const {
    currentQuestionState,
    previousAnswers,
    questionsAmount,
    userPointsReward,
    userTicketReward,
    correctAnswersAmount,
    customReward,
  } = useQuizStore();

  const calculatedCorrectAnswersAmount = previousAnswers.filter(
    ({correct}) => correct
  ).length;

  const resultPointReward =
    quizType === QuizType.REPEATABLE ? pointReward : userPointsReward;

  const resultTicketReward =
    quizType === QuizType.REPEATABLE ? ticketReward : userTicketReward;

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (isCustomRewardTask && customReward) {
      void navigator.clipboard.writeText(customReward);

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (userTaskID && isCustomRewardTask) {
      void quizService.getTaskCustomReward(userTaskID);
    }
  }, [userTaskID, isCustomRewardTask]);

  return (
    <div className="px-5 md:px-10 pt-[75px] md:pt-6 pb-10 md:pb-9 bg-[#0B0D0E] md:border border-[#232526] md:rounded-[20px] rounded-t-20 border-l-1 w-full md:w-[560px] flex flex-col items-center font-sans">
      {currentQuestionState === QuestionState.ERROR ? (
        <>
          <div className="bg-[url('./assets/gridBg.svg')] mb-1 md:min-h-[192px]">
            <img
              className="mx-auto"
              src={GeneratedCrossCircle3D as string}
            ></img>
          </div>
          <p className="mb-4 h-12  leading-9  text-2xl font-plus font-bold capitalize text-white w-min">
            {previousAnswers.length - 1}
            <span className="text-l-600 font-medium text-base">
              <span className="tracking-[1.6]">/</span>
              {questionsAmount}
            </span>
          </p>
          <h3 className="mb-6 w-fit text-white text-2xl leading-9 font-bold">
            Next time you'll make it through
          </h3>
          <p className="mb-[52px] w-[280px] text-center h-12 text-sm leading-[24px] text-l-600">
            {isCustomRewardTask
              ? 'You can take the quiz again after 1 hour.'
              : 'You can take the quiz again after 15 minutes, stay tuned.'}
          </p>
          <div className="flex justify-center gap-3 [&>*]:text-xs [&>*]:h-10 w-full">
            <Button
              text="END QUIZ"
              className="w-full md:w-[280px] font-space"
              onClick={handleClose}
            />
          </div>
        </>
      ) : (
        <>
          <div className="bg-[url('./assets/gridBg.svg')] mb-1 md:min-h-[192px]">
            <img
              className="mx-auto"
              src={GeneratedCheckCircle3D as string}
            ></img>
          </div>
          <p className="mb-4 h-12  leading-9  text-2xl font-plus font-bold capitalize text-white w-min">
            {correctAnswersAmount || calculatedCorrectAnswersAmount}
            <span className="text-l-600 font-medium text-base">
              <span className="tracking-[1.6]">/</span>
              {questionsAmount}
            </span>
          </p>
          <h3 className="mb-6 w-fit text-white text-2xl  text-center leading-9 font-bold">
            Congratulations!
          </h3>
          <p className="mb-[52px] text-center h-12 text-sm leading-9 text-l-600">
            {isCustomRewardTask ? (
              <p className="w-[340px]">
                Now you can take an Exclusive Beta Access Code. <br /> You
                earned it! Just copy it.
              </p>
            ) : (
              <p className="w-[310px]">
                Now you can claim your reward, you earned it ;)
              </p>
            )}
          </p>
          {isCustomRewardTask && (
            <p
              className="mb-10 -mt-6 font-bold text-green-200 w-[310px] text-center h-12 text-xl cursor-pointer"
              onClick={handleCopy}
            >
              {copied ? 'Copied' : customReward}
            </p>
          )}
          <div className="flex justify-center [&>*]:text-xs [&>*]:h-11 w-full">
            <button //should be replaced with Button component
              className="bg-green-200 flex flex-row justify-center items-center px-6 py-2 w-full md:w-[280px] gap-2 h-10 cursor-pointer font-space rounded-[42px]"
              onClick={handleClose}
            >
              <div className="text-center text-xs font-bold tracking-[-0.24] leading-[24px] whitespace-nowrap uppercase">
                {isCustomRewardTask ? 'DONE' : 'CLAIM REWARD'}
              </div>
              {!!resultPointReward && (
                <div className="text-xs text-center font-bold h-7 py-2 px-[10px] leading-[12px] text-green-200 bg-black flex flex-row justify-center w-[72px] items-center rounded-[28px]">
                  <span className="h-[14.4px]">+</span>
                  <>{resultPointReward}PTS</>
                </div>
              )}
              {!!resultTicketReward && (
                <div className="text-xs text-center font-bold h-7 py-2 px-[10px] leading-[12px] text-green-200 bg-black flex flex-row justify-center w-[72px] items-center rounded-[28px]">
                  <span className="h-[14.4px]">+</span>
                  <div className="flex gap-1 items-center justify-center">
                    {resultTicketReward}
                    <TicketIcon width={16} height={16} />
                  </div>
                </div>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
