import React from 'react';

export const AddUser = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        opacity="0.4"
        d="M21.101 9.58811H19.8979V8.41186C19.8979 7.90969 19.4952 7.50024 18.999 7.50024C18.5038 7.50024 18.1 7.90969 18.1 8.41186V9.58811H16.899C16.4027 9.58811 16 9.99755 16 10.4997C16 11.0019 16.4027 11.4113 16.899 11.4113H18.1V12.5886C18.1 13.0908 18.5038 13.5002 18.999 13.5002C19.4952 13.5002 19.8979 13.0908 19.8979 12.5886V11.4113H21.101C21.5962 11.4113 22 11.0019 22 10.4997C22 9.99755 21.5962 9.58811 21.101 9.58811Z"
        fill="black"
      />
      <path
        d="M9.5 15.0161C5.45422 15.0161 2 15.6629 2 18.2472C2 20.8305 5.4332 21.5006 9.5 21.5006C13.5448 21.5006 17 20.8538 17 18.2695C17 15.6852 13.5668 15.0161 9.5 15.0161Z"
        fill="black"
      />
      <path
        opacity="0.4"
        d="M9.49974 12.5547C12.2543 12.5547 14.4624 10.3182 14.4624 7.5281C14.4624 4.73802 12.2543 2.50049 9.49974 2.50049C6.74517 2.50049 4.53711 4.73802 4.53711 7.5281C4.53711 10.3182 6.74517 12.5547 9.49974 12.5547Z"
        fill="black"
      />
    </svg>
  );
};
