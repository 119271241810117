export enum TimeGroups {
  today,
  yesterday,
  week,
  earlier,
}

export const timeGroupsTitles: Record<TimeGroups, string> = {
  [TimeGroups.earlier]: 'Earlier',
  [TimeGroups.week]: 'Last 7 days',
  [TimeGroups.yesterday]: 'Yesterday',
  [TimeGroups.today]: 'Today',
};
