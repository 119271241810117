export const BackButton = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        stroke="white"
        strokeOpacity="0.15"
      />
      <path
        d="M8.75 26.5L39.25 26.5V24.5769L23.7891 24.5769V21.5L8.75 26.5Z"
        fill="white"
      />
    </svg>
  );
};
