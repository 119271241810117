import {IMyTask} from '../../../../types/tasks';
import {PointActions} from '../../../../types/types';
import {activitiesTargets, activitiesTitles} from '../constants';
import {ActivityType, IActionLevelPoint, IActivity} from '../store';

export const getActivityTarget = ({
  type,
  actionsLevelPoint,
  userTask,
  referralActionLog,
}: {
  type: ActivityType;
  actionsLevelPoint: IActionLevelPoint | null;
  userTask?: IMyTask;
  referralActionLog: IActivity;
}) => {
  const res: {target: string; subTarget?: string; title: string} = {
    target: '',
    title: '',
  };

  const isLevelAction = type === ActivityType.LEVEL_ACTION;

  if (isLevelAction) {
    if (actionsLevelPoint) {
      res.target = activitiesTargets[actionsLevelPoint.action];
      res.title = activitiesTitles[actionsLevelPoint.action];
    }
  } else {
    res.target = activitiesTargets[type];
    res.title = activitiesTitles[type];
  }

  switch (type) {
    case ActivityType.TASK:
      if (userTask) {
        res.subTarget = userTask.title;
      }
      break;

    case ActivityType.REFERRAL_ACTION:
      if (referralActionLog.userTask?.title) {
        res.subTarget = referralActionLog.userTask?.title;
      }

      if (
        referralActionLog?.actionsLevelPoint?.action ===
        PointActions.COMPLETE_KYC
      ) {
        res.title = activitiesTitles[PointActions.INVITE_USER];
      }
      break;
  }

  return res;
};
