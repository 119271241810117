import {useNavigate} from 'react-router-dom';
import {TicketIcon} from '../../../../../assets/svgComponents/TicketIcon';
import {userService} from '../../../../../services/userApi';

export const WoodleClaimButton = ({
  pointReward,
  ticketReward,
}: {
  pointReward?: number | null;
  ticketReward?: number | null;
}) => {
  const navigate = useNavigate();
  return (
    <button
      className="bg-green-200 text-black flex flex-row justify-center items-center px-6 py-2 w-full md:w-[280px] gap-2 h-10 cursor-pointer font-space rounded-[42px]"
      onClick={() => {
        navigate('/account');
        void userService.getUserData();
      }}
    >
      <div className="text-center text-xs font-bold tracking-[-0.24] leading-[24px] whitespace-nowrap uppercase">
        CLAIM REWARD
      </div>
      {!!pointReward && (
        <div className="text-xs text-center font-bold h-7 py-2 px-[10px] leading-[12px] text-green-200 bg-black flex flex-row justify-center w-[72px] items-center rounded-[28px]">
          <span className="h-[14.4px]">+</span>
          <>{pointReward}PTS</>
        </div>
      )}
      {!!ticketReward && (
        <div className="text-xs text-center font-bold h-7 py-2 px-[10px] leading-[12px] text-green-200 bg-black flex flex-row justify-center w-[72px] items-center rounded-[28px]">
          <span className="h-[14.4px]">+</span>
          <div className="flex gap-1 items-center justify-center">
            {ticketReward}
            <TicketIcon width={16} height={16} />
          </div>
        </div>
      )}
    </button>
  );
};
