import {roundNumber} from '../../../utilities/roundNumber';
import {useLeaderBoardStore} from '../store/store';
import {TableRow} from './TableRow';

export function LeadersTable() {
  const {leadersList, userResults} = useLeaderBoardStore();

  return (
    <div>
      <div className="grid grid-cols-2 items-center px-5 lg:px-6  h-12 bg-[#1A1C1E] text-l-500 font-medium text-sm leading-6">
        <div className="font-bold">#</div>
        <div className="font-bold">No. of points</div>
      </div>
      <div className="px-5 lg:px-6 max-h-[521px] overflow-auto thin-scrollbar font-mono leading-6 font-bold">
        {!!userResults && (
          <TableRow
            position={userResults.position}
            points={roundNumber(userResults.points)}
            reward={userResults.reward}
            className="sticky top-0"
            personal
          />
        )}
        {leadersList.map(({points, reward, position}, i) => (
          <TableRow
            position={position}
            points={points}
            reward={reward}
            key={i}
          />
        ))}
      </div>
    </div>
  );
}
