import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {HandleQuizModalOpenProps, MyTaskStatus} from '../../../../types/tasks';
import {isQuizOnCooldown} from '../../../Quiz/helpers';
import {tasksService} from '../../service/TasksService';

export const HandleQuizButton = ({
  userQuizResult,
  quiz,
  id,
  rewardPointsAmount,
  rewardTicketsAmount,
  maxSuccessfullyCompletions,
  userTaskID,
}: HandleQuizModalOpenProps) => {
  return (
    <div
      className={`flex items-center gap-[10px] text-xs font-bold uppercase leading-3 text-green-200 hover:underline cursor-pointer ${
        isQuizOnCooldown(userQuizResult?.retryAfter)
          ? 'opacity-40 cursor-default'
          : ''
      }`}
      onClick={() => {
        tasksService.handleQuizModalOpen({
          quiz,
          userQuizResult,
          rewardPointsAmount,
          id,
          rewardTicketsAmount,
          maxSuccessfullyCompletions,
          userTaskID,
        });
      }}
    >
      {status === MyTaskStatus.IN_PROGRESS ? 'Continue' : 'Start'} quiz
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  );
};
