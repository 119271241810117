import {BigNumber, Contract, providers} from 'ethers';
import {useEffect, useMemo, useState} from 'react';
import {useEVMSigner} from '../../../../hooks/useEthersProvider';

import {vestingContractABI} from './vestingContractABI';
import {formatUnits} from 'ethers/lib/utils';
import {VestingContract} from './types';
import {claimModalService} from '../../components/ClaimModal/service/service';

const getTotalTokens = async (
  contract: VestingContract,
  userWallet: string,
  setTokens: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    const res: BigNumber = await contract.reservedForBeneficiary(userWallet);

    setTokens(parseFloat(formatUnits(res.toString())) || 0);
  } catch (error) {
    console.log(error);
  }
};

const getClaimableTokens = async (
  contract: VestingContract,
  userWallet: string,
  setTokens: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    const res = await contract.releasableAmount(userWallet);

    setTokens(parseFloat(formatUnits(res.toString())) || 0);
  } catch (error) {
    console.log(error);
  }
};

const getClaimedTokens = async (
  contract: VestingContract,
  userWallet: string,
  setTokens: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    const res: BigNumber = await contract.releasedToBeneficiary(userWallet);

    setTokens(parseFloat(formatUnits(res.toString())) || 0);
  } catch (error) {
    console.log(error);
  }
};

const getVestingInfo = async ({
  contract,
  userAddress,
  setTotalTokens,
  setClaimableTokens,
  setClaimedTokens,
}: {
  contract: VestingContract;
  userAddress: string;
  setTotalTokens: React.Dispatch<React.SetStateAction<number>>;
  setClaimableTokens: React.Dispatch<React.SetStateAction<number>>;
  setClaimedTokens: React.Dispatch<React.SetStateAction<number>>;
}) => {
  await Promise.allSettled([
    await getTotalTokens(contract, userAddress, setTotalTokens),
    await getClaimableTokens(contract, userAddress, setClaimableTokens),
    await getClaimedTokens(contract, userAddress, setClaimedTokens),
  ]);
};

export const useVestingContract = (
  contractAdress: string,
  userAddress: string,
  network: {chainId: number; chainName: string},
  providerURL: string
) => {
  const [purchasedTokens, setPurchasedTokens] = useState(0);
  const [claimableTokens, setClaimableTokens] = useState(0);
  const [claimedTokens, setClaimedTokens] = useState(0);

  const vestedAmount = useMemo(
    () => claimableTokens + claimedTokens,
    [claimableTokens, claimedTokens]
  );

  const claimedPercent = useMemo(
    () => 1 - (purchasedTokens - claimedTokens) / purchasedTokens || 0,
    [purchasedTokens, claimedTokens]
  );

  const signer = useEVMSigner(network);

  const provider = useMemo(
    () => new providers.JsonRpcProvider(providerURL),
    [providerURL]
  );

  const contract = useMemo(() => {
    return new Contract(
      contractAdress,
      vestingContractABI,
      provider
    ) as VestingContract;
  }, [contractAdress, provider]);

  useEffect(() => {
    if (contract) {
      void getVestingInfo({
        contract,
        userAddress,
        setClaimableTokens,
        setClaimedTokens,
        setTotalTokens: setPurchasedTokens,
      });
    }
  }, [contract, userAddress]);

  const claim = async () => {
    try {
      const claimContract = new Contract(
        contractAdress,
        vestingContractABI,
        signer
      ) as VestingContract;
      const tx = await claimContract.release(userAddress);
      await tx.wait();
      await getVestingInfo({
        contract,
        userAddress,
        setClaimableTokens,
        setClaimedTokens,
        setTotalTokens: setPurchasedTokens,
      });

      claimModalService.clearSelectedAllocation();
    } catch (error) {
      console.log(error);
      claimModalService.setClaimError('Something went wrong. Please try again');
    }
  };

  return {
    purchasedTokens,
    claimableTokens,
    vestedAmount,
    claimedTokens,
    claimedPercent,
    claim,
  };
};
