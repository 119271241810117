import {CircularProgressbar} from 'react-circular-progressbar';
import {calculateProgress} from '../../utilities/calculateProgress';

interface ProgressCircleProps {
  currentValue: number;
  maxValue: number;
  prevValue: number;
}

export const ProgressCircle = ({
  currentValue,
  maxValue,
  prevValue,
}: ProgressCircleProps) => {
  const progressbarValue = calculateProgress(currentValue, maxValue, prevValue);
  const filledColor = '#E5FD55';
  const strokeColor = 'rgba(255,255,255,.2)';

  return (
    <CircularProgressbar
      value={progressbarValue}
      styles={{
        path: {
          stroke: filledColor,
          strokeLinecap: 'round',
        },
        trail: {
          stroke: strokeColor,
        },
      }}
      strokeWidth={14}
    />
  );
};
