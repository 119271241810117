export const parseOAuthState = (state: string) => {
  try {
    const stateObject = JSON.parse(state) as {redirectTo: string; type: string};

    const redirectTo: string = stateObject.redirectTo || '';
    const type: string = stateObject.type || '';

    return {redirectTo, type};
  } catch {
    return {};
  }
};
