import {useEffect} from 'react';
import {LayoutDashboardView} from '../../layouts/LayoutDashboardView';
import {projectService} from '../../services/projectApi';
import {BlogView} from '../Blog/BlogView';
import {FeaturedCampaign} from './FeaturedCampaign';
import {LevelPanelView} from './LevelPanelView';
import {useDashboardStore} from './store/store';
import {userService} from '../../services/userApi';
import {LeaderBoard} from '../LeaderBoard';

export const DashboardView = () => {
  const {projects, invitedCount} = useDashboardStore();

  useEffect(() => {
    if (!projects.length) {
      projectService.getProjects().catch(err => console.error(err));
    }

    if (invitedCount <= 0) {
      userService.getInvitedCountData().catch(err => console.error(err));
      void userService.getReferralBonus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutDashboardView>
      <div className="grid-layout py-8 px-4 2xl:px-10 flex flex-col gap-5 items-start mb-10">
        <FeaturedCampaign />
        <LevelPanelView />
        <LeaderBoard />
        <BlogView />
      </div>
    </LayoutDashboardView>
  );
};
