import {WoodleLayout} from './layout/WoodleLayout';
import {woodleStore} from './store';
import {Navigate} from 'react-router-dom';
import {useShallow} from 'zustand/react/shallow';
import {WoodleContent} from './components/Woodle';

export const Woodle = () => {
  const currentWoodle = woodleStore(useShallow(state => state.currentWoodle));

  if (!currentWoodle) {
    return <Navigate to={'/dashboard'} />;
  }

  return (
    <WoodleLayout>
      <WoodleContent currentWoodle={currentWoodle} />
    </WoodleLayout>
  );
};
