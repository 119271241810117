import {BigNumber, Contract, ethers, providers} from 'ethers';
import {erc20ABI} from 'wagmi';
import {formatUnits, isAddress} from 'ethers/lib/utils';
import {zeroAddress} from 'viem';

export const isEvmAddress = (address?: string) => {
  if (!address || !isAddress(address) || address === zeroAddress) {
    return false;
  }
  return true;
};

export function getContract(
  address: string,
  ABI: ethers.ContractInterface,
  provider: ethers.Signer | ethers.providers.Provider
): Contract {
  if (!isEvmAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'. `);
  }

  return new ethers.Contract(address, ABI, provider);
}

export class BaseContract {
  contractAddress: string;
  contract: Contract;
  constructor(
    address: string,
    ABI: ethers.ContractInterface,
    provider: ethers.providers.Provider | ethers.Signer
  ) {
    this.contractAddress = address;
    this.contract = getContract(address, ABI, provider);
  }
}

export class Erc20Contract extends BaseContract {
  contractAddress: string;
  provider: ethers.providers.Provider | ethers.Signer;
  constructor(
    address: string,
    provider: ethers.providers.Provider | ethers.Signer,
    abi = erc20ABI
  ) {
    super(address, abi, provider);
    this.contractAddress = address;
    this.provider = provider;
  }

  async balanceOf(account: string) {
    const balance = (await this.contract.balanceOf(account)) as bigint;
    const decimals = await this.decimals();
    const parsedBalance = formatUnits(balance, decimals);

    return {balance, parsedBalance};
  }

  async allowance(owner: string, spender: string) {
    try {
      const allowance = (await this.contract.allowance(
        owner,
        spender
      )) as bigint;
      const decimals = await this.decimals();
      const parsedAllowace = formatUnits(allowance, decimals);

      return {allowance, parsedAllowace};
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async symbol() {
    try {
      const tx = (await this.contract.symbol()) as string;
      return tx;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async approve(spender: string, value: BigNumber) {
    try {
      const tx = await this.contract.approve(spender, value);
      const result = (await tx.wait(1)) as Promise<unknown>;
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async decimals(): Promise<number> {
    try {
      const result = (await this.contract.decimals()) as bigint;

      return Number(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export const getBalance = async (
  userAddress: string,
  contractAdress: string,
  rpcAdress: string
) => {
  const provider = new providers.JsonRpcProvider(rpcAdress);

  const contract = new Erc20Contract(contractAdress, provider);

  const balance = await contract.balanceOf(userAddress);
  return balance;
};
