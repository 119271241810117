import {useState} from 'react';
import {Button} from '../../../../components/Button';
import {TooltipInfoIcon} from '../../../../components/Tooltip/TooltipInfoIcon';
import {TokenCard} from './TokenCard';
import {claimModalService} from './service/service';
import {
  ISelectedAllocation,
  useClaimModalStore,
} from './store/useClaimModalStore';
import {VestInfo} from './VestInfo';

export const ClaimModalBody = ({
  selectedAllocation,
}: {
  selectedAllocation: ISelectedAllocation;
}) => {
  const [isClaimPending, setIsClaimPending] = useState(false);
  const {claimError} = useClaimModalStore();

  const {
    userWallet,
    token,
    claimedTokensAmount,
    claimableTokensAmount,
    tokensAmount,
    claim,
  } = selectedAllocation;

  const handleClose = claimModalService.clearSelectedAllocation;

  const handleClaim = async () => {
    setIsClaimPending(true);
    await claim();
    setIsClaimPending(false);
  };

  const vested = claimableTokensAmount + claimedTokensAmount;
  const remainingToVest = tokensAmount - vested;

  return (
    <div className="bg-[#0B0D0E] uppercase text-xs leading-6  text-white md:rounded-[20px] rounded-t-20 md:border border-[#232526] border-l-1 w-screen md:w-[560px] relative">
      <div
        className="md:hidden relative top-[10px] w-[78px] border-2 border-white rounded-[32px] m-auto"
        onClick={handleClose}
        onDragStart={handleClose}
        onTouchEnd={handleClose}
      ></div>
      <h3 className="border-b border-l-100 capitalize md:border-b-0 px-5 py-[22px] md:px-10 md:pt-10 text-2xl leading-9 font-bold">
        Claim
      </h3>

      <div className="p-5 md:p-10 md:pt-0">
        <div className="pb-6 border-b border-l-100 mb-5">
          <div className="flex justify-between items-center mb-5">
            <div className="opacity-40 flex items-center gap-1">
              Wallet used <TooltipInfoIcon content="tooltip" />
            </div>
            <div>{userWallet}</div>
          </div>
          <div className="mb-6 flex flex-col md:flex-row w-full gap-[6px]">
            <TokenCard
              title="total tokens"
              icon={token.iconUrl}
              symbol={token.symbol}
              tooltip=""
              amount={tokensAmount}
            />
            <TokenCard
              title="Available to claim"
              icon={token.iconUrl}
              symbol={token.symbol}
              tooltip=""
              amount={claimableTokensAmount}
            />
          </div>
          <Button
            text={'Claim'}
            className="w-full"
            onClick={handleClaim}
            loading={isClaimPending}
            disabled={!claimableTokensAmount}
          />
          {claimError && (
            <div className="text-red-500 font-medium text-center">
              {claimError}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-5">
          <VestInfo label={'Already vested'} value={vested} tooltip={''} />
          <VestInfo
            label={'Remaining to vest'}
            value={remainingToVest}
            tooltip={''}
          />
          <VestInfo
            label={'Already claimed'}
            value={claimedTokensAmount}
            tooltip={''}
          />
        </div>
      </div>
    </div>
  );
};
