import {CircleCross} from '../../../../assets/svgComponents/CircleCross';
import {IAnswerResult} from '../../types';
import {AnswerResult} from './AnswerResult';

export const MistakenAnswerResult = ({
  text,
}: Omit<IAnswerResult, 'Icon' | 'title'>) => {
  return (
    <AnswerResult
      text={text}
      title="You're wrong"
      Icon={<CircleCross className="h-6 w-6" />}
    />
  );
};
