import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as Popover from '@radix-ui/react-popover';
import {useWindowResize} from '../../hooks/useWindowResize';

export interface TooltipProps {
  content: string | React.ReactNode;
  children?: React.ReactNode;
  contentClassName?: string;
  side?: 'top' | 'left' | 'bottom' | 'right';
  align?: 'start' | 'center' | 'end';
  triggerClassName?: string;
  arrowFill?: string;
  withoutPopover?: boolean;
}

export const Tooltip = ({
  content,
  children,
  contentClassName,
  triggerClassName,
  side,
  align,
  arrowFill = '#1D1E1E',
  withoutPopover = false,
}: TooltipProps) => {
  const {isAdaptive} = useWindowResize(768);

  return !isAdaptive || withoutPopover ? (
    <TooltipPrimitive.Provider delayDuration={500}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>
          <span className={triggerClassName}>{children}</span>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            className={`TooltipContent text-xs text-white !py-1 !px-2 rounded-md bg-[#1D1E1E] z-50 ${
              contentClassName ? contentClassName : ''
            }`}
            side={side ? side : 'top'}
            align={align ? align : 'center'}
            avoidCollisions={false}
            sideOffset={5}
            style={{
              minWidth: 'var(--radix-popover-trigger-width)',
            }}
          >
            {content}
            <TooltipPrimitive.Arrow
              style={{
                fill: arrowFill,
              }}
              className="TooltipArrow"
              width={11}
              height={5}
            />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  ) : (
    <Popover.Root>
      <Popover.Trigger asChild onClick={e => e.stopPropagation()}>
        <span className={triggerClassName}>{children}</span>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={`TooltipContent text-xs !outline-none text-white !py-1 !px-2 rounded-md bg-[#1D1E1E] z-50 ${
            contentClassName ? contentClassName : ''
          }`}
          side={side ? side : 'top'}
          align={align ? align : 'center'}
          avoidCollisions={true}
          sideOffset={5}
          style={{
            minWidth: 'var(--radix-popover-trigger-width)',
          }}
        >
          {content}
          <Popover.Arrow
            style={{
              fill: arrowFill,
            }}
            className="TooltipArrow"
            width={11}
            height={5}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
