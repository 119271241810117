import {create, useStore} from 'zustand';
import {IProjectFCO} from '../../../types/project';

export interface AllocationsStore {
  allocations: IProjectFCO[];
  isLoading: boolean;
}

export const allocationsStore = create<AllocationsStore>(() => ({
  allocations: [],
  isLoading: true,
}));

export const useAllocationsStore = () => useStore(allocationsStore);
