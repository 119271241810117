import React from 'react';

export const BlocksIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M7.1013 11.2251C8.2763 11.2251 9.21797 12.1759 9.21797 13.3592V16.2001C9.21797 17.3751 8.2763 18.3334 7.1013 18.3334H4.28464C3.11797 18.3334 2.16797 17.3751 2.16797 16.2001V13.3592C2.16797 12.1759 3.11797 11.2251 4.28464 11.2251H7.1013ZM16.7181 11.2251C17.8847 11.2251 18.8347 12.1759 18.8347 13.3592V16.2001C18.8347 17.3751 17.8847 18.3334 16.7181 18.3334H13.9014C12.7264 18.3334 11.7847 17.3751 11.7847 16.2001V13.3592C11.7847 12.1759 12.7264 11.2251 13.9014 11.2251H16.7181ZM16.7181 1.66699C17.8847 1.66699 18.8347 2.62533 18.8347 3.80116V6.64199C18.8347 7.82533 17.8847 8.77533 16.7181 8.77533H13.9014C12.7264 8.77533 11.7847 7.82533 11.7847 6.64199V3.80116C11.7847 2.62533 12.7264 1.66699 13.9014 1.66699H16.7181Z"
        fill="black"
      />
      <path
        opacity="0.4"
        d="M9.21797 3.80116C9.21797 2.62533 8.2763 1.66699 7.1013 1.66699H4.28464C3.11797 1.66699 2.16797 2.62533 2.16797 3.80116V6.64199C2.16797 7.82533 3.11797 8.77533 4.28464 8.77533H7.1013C8.2763 8.77533 9.21797 7.82533 9.21797 6.64199V3.80116Z"
        fill="black"
      />
    </svg>
  );
};
