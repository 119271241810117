import {userStore} from '../modules/AccountProgress/store/useUserStore';

export const isWalletMatching = (address: string) => {
  const {user} = userStore.getState();

  if (user?.connectedWallets) {
    return user?.connectedWallets[0]?.address === address;
  }

  return false;
};
