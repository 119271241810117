import {useEffect, useMemo} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link, useNavigate} from 'react-router-dom';
import {NextArrow} from '../../assets/svgComponents/NextArrow';
import {Button} from '../../components/Button';
import {useWindowResize} from '../../hooks/useWindowResize';
import {SkeletonColorClasses} from '../../layouts/constants';
import {projectService} from '../../services/projectApi';
import {formatNumber} from '../../utilities/number';
import {calcRoundStatus, getClosestRound} from '../../utilities/project';
import {SOCIAL_ICONS} from '../ProjectDetails';
import {navigateToDisclaimer} from '../ProjectInvest/helpers/navigateToDisclaimer';
import {useDashboardStore} from './store/store';
import {StatusLabel} from '../ProjectDetails/components/StatusLabel';
import {TooltipInfoIcon} from '../../components/Tooltip/TooltipInfoIcon';
import {Timer} from '../../components/Timer';
import {
  getIsHardcodedProject,
  isCrossTheAgesProject,
} from '../../utilities/getIsHardcodedProject';
import {RoundStatus} from '../../types/project';

export const FeaturedCampaign = () => {
  const navigate = useNavigate();
  const {featuredCampaign, isLoading} = useDashboardStore();

  const {isAdaptive} = useWindowResize(768);
  const featuredCampaignInfoClasses =
    'flex flex-row-reverse md:flex-row items-center justify-between';

  useEffect(() => {
    if (!featuredCampaign) void projectService.getFeatureCampaign();
  }, [featuredCampaign]);

  const currentRound = useMemo(
    () => featuredCampaign && getClosestRound(featuredCampaign),
    [featuredCampaign]
  );

  if (isLoading) {
    return (
      <div className="rounded-20 p-4 xl:p-6 border-[1px] bg-[#0B0D0E] border-l-100 border-solid">
        <Skeleton style={{marginBottom: SkeletonColorClasses.mb}} height={34} />
        <Skeleton
          style={{marginBottom: SkeletonColorClasses.mb}}
          height={580}
        />
      </div>
    );
  }

  if (!featuredCampaign || !currentRound) return null;

  const bgCompanyImage = featuredCampaign.mainImageUrl;

  const isInvestAvailable = !!(
    currentRound?.minAllocation &&
    currentRound?.maxAllocation &&
    currentRound?.tokenPrice
  );

  const isHardcodedProject = getIsHardcodedProject(featuredCampaign);
  const isCrossTheAges = isCrossTheAgesProject(featuredCampaign);

  return (
    <div className="grid-layout__featured rounded-20 p-4 xl:p-6 border-[1px] bg-[#0B0D0E] border-l-100 border-solid">
      <div className="text-xl font-bold mb-6">Featured campaign</div>
      <div className="rounded-[1.25rem] border-l-50 border-solid border-[1px] bg-l-50 overflow-hidden">
        <div className="min-h-[240px] xl:min-h-[374px] relative">
          <div className="absolute top-0 left-0 w-full h-full">
            <img
              className="w-full h-full object-cover"
              src={bgCompanyImage as string}
              alt="bgCompanyImage"
            />
          </div>
          <div className="absolute w-full top-0 left-0 flex items-center gap-[6px] flex-wrap p-4 xl:p-6">
            <div>
              <StatusLabel roundStatus={calcRoundStatus(currentRound)} />
            </div>
            <div className="rounded-20 min-h-[28px] hidden xl:flex justify-center text-center items-center bg-green-200 px-[10px] font-mono">
              <span className="text-black text-xs uppercase">
                {currentRound.title}
              </span>
            </div>
          </div>
          <div className="w-[72px] xl:w-[104px] h-[72px] xl:h-[104px] rounded-full bg-[#1A1C1E] p-1 overflow-hidden absolute left-3 xl:left-6 -bottom-[34px] xl:-bottom-[3rem]">
            <div className="w-full h-full rounded-full flex justify-center items-center">
              <img
                className="w-full h-full rounded-full box-border"
                src={featuredCampaign.imageUrl}
                alt="coinIcon"
              />
            </div>
          </div>
        </div>
        <div className="relative px-5 xl:px-6 pt-10 xl:pt-[54px] pb-6">
          <div className="mb-[2.125rem] md:mb-3 flex gap-2 items-start justify-between">
            <div className="w-full xl:w-auto">
              <span className="pr-[7.5rem] md:pr-0 text-xl xl:text-2xl font-bold leading-9 block mb-4 xl:mb-0">
                {featuredCampaign.name}{' '}
              </span>
              {isHardcodedProject ? (
                <div
                  className={`text-xs leading-[200%] block font-mono ${featuredCampaignInfoClasses}`}
                >
                  2000 Exclusive Beta Access Codes
                </div>
              ) : (
                <div className="md:flex md:gap-2 items-start">
                  {currentRound.totalRase ? (
                    <div
                      className={`text-xs leading-[300%] block font-mono ${featuredCampaignInfoClasses}`}
                    >
                      <span>
                        {currentRound.totalRase}
                        &nbsp;
                        {isAdaptive && featuredCampaign.allocationToken.symbol}
                      </span>
                      <div className="flex items-center gap-1">
                        <span className="opacity-50 md:opacity-100">
                          {isAdaptive
                            ? 'TOTAL RAISE'
                            : `${featuredCampaign.allocationToken.symbol} ${
                                isCrossTheAges ? 'prize pool' : 'to raise'
                              }`}
                        </span>
                        {!isCrossTheAges && (
                          <div className="hidden md:block relative ml-[5.5px] -mb-1 w-[2px] h-[3px] bg-white rounded-full top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
                        )}
                        <div className="flex md:hidden">
                          <TooltipInfoIcon content="1200" />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {currentRound.tokenPrice ? (
                    <div
                      className={`text-xs leading-[300%] block font-mono ${featuredCampaignInfoClasses}`}
                    >
                      <span>
                        {formatNumber(currentRound.tokenPrice)}
                        &nbsp;
                        {isAdaptive && featuredCampaign.allocationToken.symbol}
                      </span>
                      <div className="flex items-center gap-1">
                        <span className="uppercase md:lowercase opacity-50 md:opacity-100">
                          token price
                        </span>
                        <div className="flex md:hidden">
                          <TooltipInfoIcon content="1200" />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className="absolute top-10 right-4 xl:right-0 xl:top-0 xl:relative">
              <ul className="flex gap-[0.875rem] items-center flex-wrap">
                {Object.entries(featuredCampaign.socials).map(
                  ([key, value]) => (
                    <li className="transition-all hover:scale-110" key={key}>
                      <a
                        href={value as string}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {SOCIAL_ICONS[key]}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-between flex-col xl:flex-row gap-y-4">
            {featuredCampaign.isProjectInvestButtonShown && (
              <Timer
                type="startsIn"
                soldOut={currentRound.status === RoundStatus.SOLDOUT}
                transparentTimer={true}
                targetDate={
                  currentRound.start ? new Date(currentRound.start) : null
                }
                endDate={currentRound.end ? new Date(currentRound.end) : null}
                className="w-full xl:w-full xl:max-w-[300px] flex justify-center items-center gap-3 rounded-[42px] border-l-200 border-solid border-[1px] min-h-[3rem]"
                signatureUrl={currentRound.signatureUrl}
                onClick={
                  isInvestAvailable
                    ? () => {
                        navigateToDisclaimer(navigate, {
                          id: featuredCampaign.id,
                          name: featuredCampaign.name,
                          description:
                            featuredCampaign.details.disclaimer?.content,
                          token: featuredCampaign.token,
                          allocationToken: featuredCampaign.allocationToken,
                          network: featuredCampaign.network,
                          minAllocation: currentRound.minAllocation!,
                          maxAllocation: currentRound.maxAllocation!,
                          tokenPrice: currentRound.tokenPrice!,
                          signatureUrl: currentRound.signatureUrl,
                          investContractAddress:
                            currentRound.investContractAddress,
                          decimals: featuredCampaign.allocationToken.decimals,
                        });
                      }
                    : null
                }
              />
            )}
            <Link
              to={`/dashboard/project/${featuredCampaign.id}`}
              className="relative w-full xl:w-auto"
            >
              <Button
                text="view launch"
                className="bg-white font-bold pr-10 !h-[3rem] w-full xl:w-[208px] text-sm"
              />
              <div className="absolute top-1/2 -translate-y-1/2 left-[calc(50%+2.5rem)] xl:right-10">
                <NextArrow width={24} height={4} fill="black" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
