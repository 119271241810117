import {MyTasks, Tasks} from './tasks';

export interface TaskStateData {
  stateIcon: string;
  stateHeader: string;
  stateDescription: string;
  stateAction: string;
  deleteButton?: boolean;
  progressValue?: number;
}
export interface CollapsedData {
  isCollapsed: boolean;
  activeIndex: null | number;
}

export interface TasksListProps {
  isDescriptionToggleShown?: boolean;
  taskRowDirection: boolean;
  numberOfVisibleTasks?: number;
  tasks: Tasks | MyTasks;
  refetchTasks: () => void;
  showToggleTextLength: boolean;
}

export enum PointActions {
  INVITE_USER = 'INVITE_USER',
  ENTER_REFERRAL_CODE = 'ENTER_REFERRAL_CODE',
  ENTER_NAME_AND_SURNAME = 'ENTER_NAME_AND_SURNAME',
  CONNECT_TELEGRAM = 'CONNECT_TELEGRAM',
  CONNECT_TWITTER = 'CONNECT_TWITTER',
  CONNECT_DISCORD = 'CONNECT_DISCORD',
  CONNECT_WALLET = 'CONNECT_WALLET',
  COMPLETE_KYC = 'COMPLETE_KYC',
}

export interface EventPoints {
  ENTER_REFERRAL_CODE: number;
  INVITE_USER: number;
  ENTER_NAME_AND_SURNAME: number;
  CONNECT_TWITTER: number;
  CONNECT_TELEGRAM: number;
  CONNECT_DISCORD: number;
  CONNECT_WALLET: number;
  COMPLETE_KYC: number;
}
