import {PointActions} from '../../../types/types';
import {ActivityType} from './store';

export const activitiesTargets = {
  [ActivityType.DAILY_REWARD]: 'Daily reward',
  [ActivityType.REFERRAL_ACTION]: 'Referral program',
  [ActivityType.TASK]: 'Raiser Tasks',
  [PointActions.COMPLETE_KYC]: 'KYC',
  [PointActions.CONNECT_DISCORD]: 'Discord',
  [PointActions.CONNECT_TELEGRAM]: 'Telegram',
  [PointActions.CONNECT_TWITTER]: 'Twitter',
  [PointActions.CONNECT_WALLET]: 'Wallet',
  [PointActions.ENTER_NAME_AND_SURNAME]: 'Registration',
  [PointActions.ENTER_REFERRAL_CODE]: 'Referral program',
  [PointActions.INVITE_USER]: 'Referral program',
};
export const activitiesTitles = {
  [ActivityType.DAILY_REWARD]: 'Daily reward',
  [ActivityType.REFERRAL_ACTION]: 'Your referral recieved some points',
  [ActivityType.TASK]: 'You’ve completed the task',
  [PointActions.COMPLETE_KYC]: 'You’ve passed the KYC',
  [PointActions.CONNECT_DISCORD]: 'You’ve connected the Discord',
  [PointActions.CONNECT_TELEGRAM]: 'You’ve connected the Telegram',
  [PointActions.CONNECT_TWITTER]: 'You’ve connected the Twitter',
  [PointActions.CONNECT_WALLET]: 'You’ve connected your Wallet',
  [PointActions.ENTER_NAME_AND_SURNAME]: 'You’ve added your name',
  [PointActions.ENTER_REFERRAL_CODE]: 'You’ve entered referral code',
  [PointActions.INVITE_USER]: 'Your friend has been verified',
};
