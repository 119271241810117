import {Loader} from '../assets/svgComponents/Loader';
import GoogleIcon from '../assets/GoogleIcon.png';

type ButtonGoogleProps = {
  className?: string;
  loading?: boolean;
  text: string;
} & JSX.IntrinsicElements['button'];

export const ButtonGoogle = ({
  text,
  loading,
  disabled,
  className = '',
  ...rest
}: ButtonGoogleProps) => {
  return (
    <button
      disabled={disabled || loading}
      className={`h-[56px] relative text-black' ${
        disabled
          ? 'bg-[#3C3D3E]'
          : 'hover:bg-gray-300 transition-all duration-200 ease-in-out active:bg-gray-400 bg-[#FFF]'
      }
      } text-[16px] rounded-[42px] text-center justify-center uppercase self-center font-mono font-bold   ${className}`}
      {...rest}
    >
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="flex justify-center gap-4 items-center">
          <div className="flex items-center pl-[16px]">
            <img src={GoogleIcon as string} alt="" />
          </div>
          <span>{text}</span>
        </div>
      )}
    </button>
  );
};
