import {useShallow} from 'zustand/react/shallow';
import {woodleStore} from '../../../store';

export const WoodleSuccess = () => {
  const correctWord = woodleStore(useShallow(({word}) => word)) || 'SSFSF';

  return (
    <div className="max-w-[533px] w-full my-14 md:my-3 text-[#00AF46] text-4xl font-sans font-bold text-center uppercase">
      {correctWord}
    </div>
  );
};
