import {$api} from '../../../services/interceptor';
import {AxiosResponse} from 'axios';
import {Participant, leaderBoardStore} from '../store/store';
import {roundNumber} from '../../../utilities/roundNumber';

const leaderboardApi = () => {
  const {setState} = leaderBoardStore;

  const getLeadersList = async () => {
    try {
      const response: AxiosResponse<{
        participants: Array<Participant>;
        currentUser: Participant;
      }> = await $api.get('/leaderboards');

      setState({
        leadersList: response.data.participants.map(({points, ...rest}) => ({
          points: roundNumber(points),
          ...rest,
        })),
        userResults: response.data.currentUser,
      });
    } catch (error) {
      console.log(error);
      setState({leadersList: [], userResults: null});
    }
  };

  return {
    getLeadersList,
  };
};

export const leaderboardService = leaderboardApi();
