import {Button} from '../Button';
import gridFrame from '../../assets/walletModalGridFrame.svg';
import walletImage from '../../assets/walletModalImage.png';
import {useAccount, useSignMessage} from 'wagmi';
import {shortenAddress} from '../../utilities/idex';
import {signUpService} from '../../services/SignUp';

export const WalletNotificationDialog = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const {address, connector} = useAccount();

  const {signMessage} = useSignMessage({
    onSuccess: async (data: string) => {
      try {
        await signUpService.sendWalletRequest(address as string, data);
        handleClose();
      } catch (error) {
        console.log(error);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleRebindWallet = async () => {
    if (connector) {
      try {
        await signMessage({
          message: 'Binding the wallet to an account on the Raiser platform.',
        });
      } catch (error) {
        console.log('function error');
      }
    }
  };

  return (
    <div
      className={
        'fixed z-[500] transition-all top-0 left-0 w-full h-full flex justify-center items-center p-4 bg-[rgba(0,0,0,.8)] opacity-1 pointer-events-auto'
      }
    >
      <div className="bg-red bg-[#0B0D0E] border-solid border-[1px] border-l-50 px-4 md:px-10 py-6 pt-0 max-w-[560px] min-h-[240px] w-full rounded-[1.25rem] flex relative flex-col">
        <span className="w-full md:w-[85%] h-[234px] absolute left-1/2 -translate-x-1/2 top-6 pointer-events-none">
          <img src={gridFrame} alt="gridFrame" />
        </span>
        <div className="w-[200px] md:w-[260px] h-[200px] md:h-[260px] relative left-1/2 -translate-x-1/2 top-0 mb-5 md:mb-10">
          <img
            className="h-full object-cover"
            src={walletImage as string}
            alt="walletImage"
          />
        </div>
        <div className="flex-1 flex flex-col relative">
          <div className="flex-1 mb-5 md:mb-10">
            <span className="text-white capitalize text-xl md:text-2xl font-bold block mb-5">
              You are adding a new wallet
            </span>
            <p className="text-l-600 text-xs md:text-sm leading-6">
              We noticed that you are connecting a new wallet (
              <span className="text-white font-bold">
                {address ? shortenAddress(address, 7) : '0x7d6cb...537E5'}
              </span>
              ) that is different from the one you were using previously. Do you
              want to continue with a new wallet?
            </p>
          </div>
          <div className="max-w-[500px] m-auto w-full flex flex-col justify-center items-center gap-3">
            <div className="flex flex-col md:flex-row items-center gap-3 w-full">
              <Button
                onClick={handleClose}
                className="w-full min-h-[36px] md:min-h-fit md:w-auto tracking-[-0.24px] text-xs !h-10 flex-1 bg-l-200 text-white"
                text="cancel"
              />
              <Button
                onClick={handleRebindWallet}
                className="w-full min-h-[36px] md:min-h-fit md:w-auto tracking-[-0.24px] text-xs !h-10 flex-1"
                text="sure, continue"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
