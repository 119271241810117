import * as Popover from '@radix-ui/react-popover';
import {ArrowDown} from '../../assets/svgComponents/ArrowDown';
import {Project, ProjectSchedule} from '../../types/project';
import {calcRoundStatus, getDateInMs} from '../../utilities/project';
import {ProjectPopover} from './ProjectPopover';
import {StatusLabel} from './components/StatusLabel';
import {formatDateFromIso} from '../../utilities/date';
import {TooltipInfoIcon} from '../../components/Tooltip/TooltipInfoIcon';
import {useMemo} from 'react';
import {MAX_DATE} from '../../constants/dates';
import {isCrossTheAgesProject} from '../../utilities/getIsHardcodedProject';

const DataDivider = () => {
  return (
    <div className="h-[3.5rem] border-l border-white border-opacity-10 hidden xl:block" />
  );
};

interface DataBarProps {
  project: Project;
  currentRound: ProjectSchedule;
  setSelectedRoundId: (id: string) => void;
}

export const DataBar = ({
  project,
  currentRound,
  setSelectedRoundId,
}: DataBarProps) => {
  const formattedDateStart = currentRound.start ? (
    formatDateFromIso(currentRound.start)
  ) : (
    <span className="text-lg">TBA</span>
  );

  const formattedDateEnd = currentRound.end ? (
    formatDateFromIso(currentRound.end)
  ) : (
    <span className="text-lg">TBA</span>
  );

  const rounds = useMemo(
    () =>
      project.projectRounds.sort(
        ({start: a}, {start: b}) =>
          getDateInMs(a || MAX_DATE) - getDateInMs(b || MAX_DATE)
      ),
    [project]
  );

  const isCrossTheAges = isCrossTheAgesProject(project);

  return (
    <div className="w-full mt-5 flex flex-col sm:flex-wrap xl:flex-nowrap sm:flex-row px-5 sm:px-6 py-5 gap-6 border border-white border-opacity-10 rounded-[1.25rem]">
      <div className="-mt-3 -mb-3 -ml-3 -mr-3 sm:-ml-4 sm:mr-0">
        <ProjectPopover
          trigger={
            <button className="flex w-full gap-2 justify-between items-center px-5 py-5 bg-[#1D1E1E] border border-white border-opacity-[0.05] rounded-xl sm:py-7 sm:justify-center">
              <span className="flex items-center gap-2 font-sans text-xl leading-6 font-semibold">
                {currentRound.title.length <= 15
                  ? currentRound.title
                  : `${currentRound.title.slice(0, 12)}...`}

                <span className="sm:hidden">
                  <StatusLabel roundStatus={calcRoundStatus(currentRound)} />
                </span>
              </span>
              <ArrowDown />
            </button>
          }
        >
          <div className="flex flex-col divide-y divide-y-reverse divide-[#262729]">
            <span className="px-5 py-[19px] text-white opacity-50 text-xs leading-normal">
              SELECT ROUND
            </span>
            {rounds.map(round => {
              const roundStatus = calcRoundStatus(round);
              return (
                <Popover.Close
                  key={round.id}
                  onClick={() => setSelectedRoundId(round.id)}
                  className="flex items-center px-[19px] py-[13px] text-left hover:bg-[#262729]"
                >
                  <span className="mr-2">{round.title}</span>
                  <StatusLabel roundStatus={roundStatus} />
                  <ArrowDown className="ml-2 -rotate-90" />
                </Popover.Close>
              );
            })}
          </div>
        </ProjectPopover>
      </div>
      <div
        className={`grow flex flex-row justify-between text-xs text-white font-mono leading-6 font-bold sm:flex-col sm:justify-start ${
          currentRound.start ? 'gap-[0.66rem]' : 'gap-1.5'
        }`}
      >
        <span className="flex items-center font-normal">
          <span className="opacity-40">ROUND LAUNCH DATE</span>
          <span className="flex pl-1">
            <TooltipInfoIcon content="Round launch date" />
          </span>
        </span>
        {formattedDateStart}{' '}
      </div>
      <DataDivider />
      <div
        className={`grow flex flex-row justify-between text-xs text-white font-mono leading-6 font-bold sm:flex-col sm:justify-start ${
          currentRound.end ? 'gap-[0.66rem]' : 'gap-1.5'
        }`}
      >
        <span className="flex items-center font-normal">
          <span className="opacity-40">END DATE</span>
          <span className="flex pl-1">
            <TooltipInfoIcon content="End date" />
          </span>
        </span>
        {formattedDateEnd}{' '}
      </div>
      <DataDivider />
      <div className="grow flex flex-row justify-between gap-1.5 text-xs text-white font-mono leading-6 font-bold sm:flex-col sm:justify-start">
        <span className="flex items-center font-normal">
          <span className="opacity-40">
            {isCrossTheAges ? 'ALLOCATION' : 'MIN ALLOCATION'}
          </span>
          <span className="flex pl-1">
            <TooltipInfoIcon content="Min allocation" />
          </span>
        </span>
        <span className="text-lg">
          {currentRound.minAllocation ? (
            <>
              {currentRound.minAllocation} {project.allocationToken.symbol}
            </>
          ) : (
            <>TBA</>
          )}
        </span>
      </div>
      <DataDivider />
      <div className="grow flex flex-row justify-between gap-1.5 text-xs text-white font-mono leading-6 font-bold sm:flex-col sm:justify-start">
        <span className="flex items-center font-normal">
          <span className="opacity-40">
            {isCrossTheAges ? 'TOTAL REWARD' : 'TOTAL RAISE'}
          </span>
          <span className="flex pl-1">
            <TooltipInfoIcon content="Total raise" />
          </span>
        </span>
        <span className="text-lg">
          {currentRound.totalRase ? (
            <>
              {currentRound.totalRase} {project.allocationToken.symbol}
            </>
          ) : (
            <>TBA</>
          )}
        </span>
      </div>
      <DataDivider />
      <div className="grow flex flex-row justify-between gap-1.5 text-xs text-white font-mono leading-6 font-bold sm:flex-col sm:justify-start">
        <span className="flex items-center font-normal">
          <span className="opacity-40">TOKEN PRICE</span>
          <span className="flex pl-1">
            <TooltipInfoIcon content="Total price" />
          </span>
        </span>
        <span className="text-lg">
          {currentRound.tokenPrice ? (
            <>
              {currentRound.tokenPrice} {project.allocationToken.symbol}
            </>
          ) : (
            <>TBA</>
          )}
        </span>
      </div>
      {!isCrossTheAges && (
        <div className="grow flex sm:hidden justify-between gap-1.5 text-xs text-white font-mono leading-6 font-bold flex-col sm:justify-start">
          <span className="flex items-center font-normal">
            <span className="opacity-40">VESTING</span>
            <span className="flex pl-1">
              {/* <TooltipInfoIcon content="Lorem ipsum dolor sit amet consectetur, adipisicing elit." /> */}
            </span>
          </span>
          <span className="uppercase">
            {project.vestingDescription || 'TBA'}
          </span>
        </div>
      )}
    </div>
  );
};
