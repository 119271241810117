import {useShallow} from 'zustand/react/shallow';
import {woodleStore} from '../../../store';
import {WoodleFailed} from './WoodleFailed';
import {WoodleSuccessed} from './WoodleSucceed';
import {isTimePassed} from '../../../../../utilities/isTimePassed';

export const WoodleFinishedBlock = ({
  isWoodleSucceed,
}: {
  isWoodleSucceed: boolean;
}) => {
  const pointReward = woodleStore(
    useShallow(({currentWoodle}) => currentWoodle?.pointReward || 0)
  );
  const ticketReward = woodleStore(
    useShallow(({currentWoodle}) => currentWoodle?.ticketReward || 0)
  );

  const isRetryAvailable = woodleStore(
    useShallow(
      ({currentWoodle}) =>
        !!(
          currentWoodle?.retryAfter &&
          !isTimePassed(new Date(currentWoodle.retryAfter))
        )
    )
  );

  return (
    <div className="mt-6">
      {isWoodleSucceed ? (
        <WoodleSuccessed
          pointReward={pointReward}
          ticketReward={ticketReward}
        />
      ) : (
        <WoodleFailed isRetryAvailable={isRetryAvailable} />
      )}
    </div>
  );
};
