import React from 'react';

export const BrokenStar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9373 3.18573L8.79888 7.56999C8.62888 7.90999 8.29688 8.13999 7.91888 8.17899L2.94888 8.89999C2.53588 8.96499 2.19388 9.25399 2.05988 9.64999C1.92188 10.047 2.02688 10.487 2.32888 10.78L5.93988 14.28C6.19888 14.535 6.31788 14.9 6.25888 15.259L5.36888 20.179C5.27188 20.774 5.66688 21.338 6.25888 21.45C6.50188 21.489 6.74988 21.45 6.96888 21.34L10.8551 19.3039L9.03742 12.3966C8.83755 11.6371 9.54299 10.95 10.297 11.1699L11.8458 11.6217L10.9373 3.18573ZM12.9257 19.3134L16.8689 21.37C17.2419 21.57 17.6969 21.54 18.0389 21.29C18.3879 21.049 18.5639 20.627 18.4889 20.21L17.5999 15.29C17.5409 14.934 17.6599 14.571 17.9189 14.32L21.6589 10.78C21.9669 10.491 22.0799 10.051 21.9489 9.64999C21.8099 9.24999 21.4589 8.95999 21.0389 8.89999L16.0689 8.17899C15.7099 8.12399 15.3989 7.89699 15.2389 7.56999L13.0199 3.09999C12.9916 3.04507 12.9591 2.99312 12.9229 2.94446L13.9927 12.8781C14.0696 13.593 13.396 14.1572 12.7057 13.9558L11.4169 13.5799L12.9257 19.3134Z"
        fill="white"
      />
    </svg>
  );
};
