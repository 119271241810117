interface FooterLink {
  text: string;
  url: string;
}

export interface FooterProps {
  isInSidebar?: boolean;
}

export const Footer = ({isInSidebar}: FooterProps) => {
  const firstFooterLinks: FooterLink[] = [
    {text: 'user agreement', url: 'https://raiser.co/terms'},
    {text: 'privacy policy', url: 'https://raiser.co/privacy'},
  ];
  const secondFooterLinks: FooterLink[] = [
    {text: 'Twitter', url: 'https://twitter.com/raiser_co'},
    {text: 'Telegram', url: 'https://t.me/raiser_co'},
  ];

  const currentYear = new Date().getUTCFullYear();

  return (
    <footer>
      <div
        className={`${
          isInSidebar ? 'pb-0 !px-0' : 'pb-8 px-[0.875rem]'
        } flex md:px-6 xl:px-10 sm:justify-center`}
      >
        <div
          className={` md:pt-[1.875rem] w-full ${
            isInSidebar
              ? 'pt-[1.75rem]'
              : 'pt-5 border-t-[rgba(255,255,255,.1)] border-solid border-t-[1px]'
          }`}
        >
          <div className="md:max-w-[660px] w-full m-auto flex-col justify-center xl:flex-row flex xl:gap-[4rem] items-start md:items-center gap-3">
            <span className="text-white uppercase text-xs opacity-30 md:opacity-[.5]">
              {currentYear} © RAISER, INC.
            </span>
            <ul className="flex items-center gap-8">
              {firstFooterLinks.map(firstColFooterLink => (
                <li key={firstColFooterLink.text} className="leading-5">
                  <a
                    href={firstColFooterLink.url}
                    target="_blank"
                    className="capitalize leading-5 text-xs font-semibold hover:underline opacity-80 md:opacity-100"
                  >
                    {firstColFooterLink.text}
                  </a>
                </li>
              ))}
            </ul>
            <ul className="flex items-center gap-8">
              {secondFooterLinks.map((item: FooterLink, index: number) => (
                <li key={index} className="leading-4">
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="uppercase whitespace-nowrap leading-4 text-xs font-semibold hover:underline opacity-80 md:opacity-100"
                  >
                    {item.text} ↗
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
