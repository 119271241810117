import {TooltipInfoIcon} from '../../../components/Tooltip/TooltipInfoIcon';

export const WalletUsed = ({wallet}: {wallet: string}) => {
  return (
    <div className="flex flex-col uppercase">
      <div className="flex gap-1 items-center mb-[10px] opacity-40">
        <div className="text-white text-xs leading-6">Wallet used</div>
        <TooltipInfoIcon
          content="You will be able to claim the tokens from this wallet"
          hidden={false}
        />
      </div>
      <div className="flex text-xs leading-[18px]">
        <div>{wallet}</div>
      </div>
    </div>
  );
};
