import {FinishedWoodleFooterWrapper} from './FinishedWoodleFooterWrapper';
import {WoodleCloseButton} from './WoodleCloseButton';

export const WoodleFailed = ({
  isRetryAvailable,
}: {
  isRetryAvailable: boolean;
}) => {
  return (
    <div>
      <FinishedWoodleFooterWrapper
        title={"You didn't guess the word of the day"}
        details={
          isRetryAvailable
            ? 'Come back tomorrow and try again'
            : 'You have used all the attempts'
        }
      >
        <WoodleCloseButton />
      </FinishedWoodleFooterWrapper>
    </div>
  );
};
