import infoIcon from '../../assets/infoIcon.png';
import {isDevelopmentStage} from '../../constants/appStages';
import {Tooltip, TooltipProps} from '.';

interface TooltipInfoIconProps extends TooltipProps {
  icon?: string;
  hidden?: boolean;
}

export const TooltipInfoIcon = ({
  icon,
  hidden = !isDevelopmentStage,
  ...tooltipProps
}: TooltipInfoIconProps) => {
  if (hidden) {
    return null;
  }

  return (
    <Tooltip {...tooltipProps}>
      <img
        src={icon || (infoIcon as string)}
        alt="infoIcon"
        className="cursor-pointer w-5 h-5 select-none"
      />
    </Tooltip>
  );
};
