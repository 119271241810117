import {Modal} from '../../../../components/Modal';
import {ClaimModalBody} from './ClaimModalBody';
import {claimModalService} from './service/service';
import {useClaimModalStore} from './store/useClaimModalStore';

export const ClaimModal = () => {
  const {selectedAllocation} = useClaimModalStore();

  if (!selectedAllocation) {
    return null;
  }

  return (
    <Modal handleClose={claimModalService.clearSelectedAllocation}>
      <ClaimModalBody selectedAllocation={selectedAllocation} />
    </Modal>
  );
};
