import {create, useStore} from 'zustand';

export const retryTimersStore = create<{
  refetchingCooldowns: Record<
    string,
    {intervalId: NodeJS.Timeout; time: number}
  >;
}>(() => ({
  refetchingCooldowns: {},
}));

export const useRetryTimersStore = () => useStore(retryTimersStore);
