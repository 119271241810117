type SkipButtonProps = {
  text: string;
} & JSX.IntrinsicElements['button'];

export const SkipButton = ({text, ...rest}: SkipButtonProps) => {
  return (
    <button className="flex mt-[1.5rem] mb-6 cursor-pointer" {...rest}>
      <span className="text-white text-[16px] font-mono leading-[24px] font-bold uppercase">
        <span className="text-white text-[16px] font-mono leading-[24px] font-bold uppercase">
          {text}
        </span>
      </span>
    </button>
  );
};
