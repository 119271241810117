import {useQuizStore} from '../store/useQuizStore';

import {RadioButtonIcon} from '../../../assets/svgComponents/RadioButtonIcon';

import {radioButtonProps} from '../constants';
import {QuizAnswerState} from '../types';
import {MobileRadioButtonIcon} from '../../../assets/svgComponents/MobileRadioButtonIcon';

export interface IQuizOptionProps {
  questionId: string | null;
  answerId: string;
  text: string;
  isSingle: boolean;
}

export function Option({
  questionId = null,
  answerId,
  text,
  isSingle,
}: IQuizOptionProps) {
  const {
    getAnswerState,
    addAnswerId,
    removeAnswerId,
    replaceAnswerIds,
    currentQuestionNumber,
    previousAnswers,
  } = useQuizStore();

  const optionState = getAnswerState({questionId, answerId});

  const handleOptionClick = () => {
    if (currentQuestionNumber < previousAnswers.length) {
      return;
    }

    if (optionState === QuizAnswerState.SELECTED) {
      removeAnswerId(answerId);
      return;
    }
    if (optionState === QuizAnswerState.NOT_SELECTED) {
      if (isSingle) {
        return replaceAnswerIds(answerId);
      }
      addAnswerId(answerId);
    }
  };

  return (
    <div
      onClick={handleOptionClick}
      className="flex gap-[10px] cursor-pointer break-all"
    >
      <div>
        {optionState === QuizAnswerState.NOT_SELECTED ? (
          <RadioButtonIcon
            {...radioButtonProps[QuizAnswerState.NOT_SELECTED]}
            className="w-5 h-5"
          />
        ) : (
          <>
            <MobileRadioButtonIcon
              outlineColor={radioButtonProps[optionState].outlineColor}
              className="w-5 h-5 md:hidden block"
            />
            <RadioButtonIcon
              {...radioButtonProps[optionState]}
              className="w-5 h-5 hidden md:block"
            />
          </>
        )}
      </div>
      <div
        className={`${
          optionState === QuizAnswerState.SELECTED ||
          optionState === QuizAnswerState.CORRECT
            ? 'text-white'
            : 'text-l-400'
        }`}
      >
        {text}
      </div>
    </div>
  );
}
