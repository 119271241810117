export const RadioButtonIcon = ({
  outlineColor = '#61B60C',
  circleColor = '#61B60C',
  ...props
}: React.SVGProps<SVGSVGElement> & {
  outlineColor: string;
  circleColor: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke={outlineColor}
      />
      <rect x="4" y="4" width="12" height="12" rx="6" fill={circleColor} />
    </svg>
  );
};
