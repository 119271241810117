import {useConnect} from 'wagmi';
import {InfoIcon} from '../../../../assets/svgComponents/InfoIcon';
import {Button} from '../../../../components/Button';
import {useWeb3Modal} from '@web3modal/react';

export const ConnectWalletNotification = () => {
  const {open} = useWeb3Modal();
  const {connect, connectors} = useConnect();

  return (
    <div className="px-6 w-full">
      <div className="p-6 pt-8 border-solid border-l-300 bg-[#1a1c1e] flex flex-col items-center w-full border rounded-lg">
        <InfoIcon width={36} height={36} className="mb-3" />
        <div className="text-center text-xl font-bold leading-[32px] mb-[38px] max-w-[300px] text-white w-full">
          You must connect your wallet to participate in this project
        </div>
        <Button
          text={'Connect with metamask'}
          className="w-full mb-2"
          bigger
          onClick={() =>
            connect({
              connector: connectors[1],
            })
          }
        />
        <Button
          text={'Connect with walletconnect'}
          className="w-full"
          bigger
          onClick={open}
        />
      </div>
    </div>
  );
};
