import React from 'react';

export const NotificationCircleCrossIcon = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <g>
        <title>Layer 1</title>
        <g id="svg_4">
          <circle
            id="svg_1"
            fillOpacity="0.4"
            fill="white"
            r="9.33333"
            cy="10"
            cx="10"
          />
          <path
            id="svg_2"
            fill="white"
            d="m8.2325,10.7063l-2.65162,2.6517l1.06066,1.0606l2.65166,-2.6517l-1.0607,-1.0606zm0.7072,-0.7071l1.0606,1.0606l4.4194,-4.41936l-1.0606,-1.06066l-4.4194,4.41942z"
            clipRule="evenodd"
            fillRule="evenodd"
          />
          <path
            id="svg_3"
            strokeWidth="1.5"
            stroke="white"
            d="m6.10808,6.11005l7.77822,7.77815"
          />
        </g>
      </g>
    </svg>
  );
};
