import {create, useStore} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';
import {ConnectSocialsView} from '../../ConnectSocials';
import {ConnectWalletView} from '../../ConnectWallet';
import {KYCView} from '../../KYC';
import {NameView} from '../../Name';
import {ReferralCodeView} from '../../ReferralCode/ReferralCodeView';
import {signUpStore} from './useSignUpStore';

export enum SignUpSteps {
  REFERRAL,
  NAME,
  SOCIALS,
  WALLET,
  KYC,
}

export interface Step {
  name: string;
  id: number;
  number: number;
  isSkipped: boolean;
  isActive: boolean;
  isCompleted: boolean;
}
interface ProgressStore {
  activeStep: number;
  isActiveButton?: boolean;
  filledInputs: number;
  isActivePosition: boolean;
  setActiveStep: (step: number) => void;
  nextStep: () => void;
  stepsArray: Step[];
  setSteps: (stepsArray: Step[]) => void;
  setIsActiveButton: (isActiveButton: boolean) => void;
  setIsActivePosition: (isActivePosition: boolean) => void;
  reset: () => void;
  recalculateSteps: () => void;
}

interface StepComponentProps {
  key: number;
}

export const stepsArray = [
  {
    name: 'Referral code',
    id: SignUpSteps.REFERRAL,
    number: 1,
    isSkipped: false,
    isActive: true,
    isCompleted: false,
  },
  {
    name: 'Your name',
    id: SignUpSteps.NAME,
    number: 2,
    isSkipped: false,
    isActive: false,
    isCompleted: false,
  },
  {
    name: 'Connect socials',
    id: SignUpSteps.SOCIALS,
    number: 3,
    isSkipped: false,
    isActive: false,
    isCompleted: false,
  },
  {
    name: 'Connect wallet',
    id: SignUpSteps.WALLET,
    number: 4,
    isSkipped: false,
    isActive: false,
    isCompleted: false,
  },
  {
    name: 'KYC',
    id: SignUpSteps.KYC,
    number: 5,
    isSkipped: false,
    isActive: false,
    isCompleted: false,
  },
];

export const stepsComponent = [
  {
    component: ReferralCodeView as React.ComponentType<StepComponentProps>,
  },
  {
    component: NameView as React.ComponentType<StepComponentProps>,
  },
  {
    component: ConnectSocialsView as React.ComponentType<StepComponentProps>,
  },
  {
    component: ConnectWalletView as React.ComponentType<StepComponentProps>,
  },
  {
    component: KYCView as React.ComponentType<StepComponentProps>,
  },
];

const getFilteredStepsArray = () =>
  stepsArray.filter(({id}) => {
    if (id !== SignUpSteps.REFERRAL) {
      return true;
    }
    return signUpStore.getState().isNew;
  });

export const progressStore = create<ProgressStore>()(
  persist(
    set => ({
      activeStep: 0,
      filledInputs: 0,
      isActivePosition: false,
      stepsArray: getFilteredStepsArray(),
      isActiveButton: false,
      setActiveStep: step =>
        set({
          activeStep: step,
        }),
      nextStep: () => set(state => ({activeStep: state.activeStep + 1})),
      setIsActiveButton: isActiveButton => set({isActiveButton}),
      setIsActivePosition: isActivePosition => set({isActivePosition}),
      setSteps: stepsArray => set({stepsArray}),
      reset: () =>
        set({
          activeStep: 0,
          filledInputs: 0,
          isActivePosition: false,
          stepsArray: getFilteredStepsArray(),
        }),
      recalculateSteps: () => set({stepsArray: getFilteredStepsArray()}),
    }),
    {
      name: 'progressStorage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useStepsStore = () => useStore(progressStore);
