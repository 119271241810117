import {useSwitchNetwork} from 'wagmi';
import {InfoIcon} from '../../../../assets/svgComponents/InfoIcon';
import {Button} from '../../../../components/Button';
import {Tooltip} from '../../../../components/Tooltip';

interface ISwitchNetworkNotificationProps {
  requiredChainId: number;
  requiredNetworkName: string;
}

export const SwitchNetworkNotification = ({
  requiredChainId,
  requiredNetworkName,
}: ISwitchNetworkNotificationProps) => {
  const {switchNetwork} = useSwitchNetwork();

  const handleNetworkSwitch = () => {
    if (switchNetwork) {
      switchNetwork(requiredChainId);
    }
  };

  return (
    <div className="px-6 w-full">
      <div className="p-6 pt-8 border-solid border-l-300 bg-[#1a1c1e] flex flex-col items-center w-full border rounded-lg">
        <Tooltip
          content="To invest in this round, you must switch your wallet from your current network to the network required for this round."
          contentClassName={'w-[240px] text-xl !py-[12px] !px-[12px]'}
          triggerClassName={'text-black ml-1'}
        >
          <InfoIcon
            width={36}
            height={36}
            className="mb-3"
            iIconProps={{stroke: '#ffffff'}}
            circleProps={{fill: 'gray', fillOpacity: '0.4'}}
          />
        </Tooltip>
        <div className="text-center text-xl font-bold leading-[32px] mb-[38px] max-w-[300px] text-white w-full">
          You must switch to {requiredNetworkName} chain to participate in this
          project
        </div>
        <Button
          text={`switch to ${requiredNetworkName}`}
          className="w-full"
          bigger
          onClick={handleNetworkSwitch}
        />
      </div>
    </div>
  );
};
