import {QuizStatus} from '../modules/Quiz/types';
import {LetterState} from '../modules/Woodle/constants';
import {WoodleTaskStatus} from '../modules/Woodle/store';
import {TaskStateData} from './types';

export enum TaskType {
  QUIZ = 'QUIZ',
  twitter = 'TWITTER',
  snapshot = 'SNAPSHOT',
  referral = 'REFERRAL',
  telegram = 'TELEGRAM',
  nft = 'NFT',
  gitcoin = 'GITCOIN',
  discord = 'DISCORD',
  woodle = 'WODL',
}

export interface UserWodleAnswer {
  id: string;
  status: WoodleTaskStatus;
  result: Record<string, LetterState>[];
  createdAt: string;
}

export interface WoodleResult {
  id: string;
  status: WoodleTaskStatus;
  answers: Array<UserWodleAnswer>;
  wordLength: number;
  createdAt: number;
  finishedAt: string | null;
  retryAfter: string | null;
}

export enum UserSnapshotTaskStatusesEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REVIEW = 'REVIEW',
  REJECTED = 'REJECTED',
}

export enum AvailableTaskStatus {
  completed,
  failed,
}

export enum MyTaskStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
}

export enum TaskStatus {
  Available = 'available',
  Active = 'active',
  Completed = 'completed',
}

export interface IUserQuizResult {
  answers: [
    {
      questionId: string;
      correct: boolean;
    },
  ];
  id: string;
  status: QuizStatus;
  createdAt: string;
  finishedAt: string;
  retryAfter: string;
}

export enum TwitterTaskType {
  SUBSCRIBE = 'SUBSCRIBE',
  RETWEET_POST = 'RETWEET_POST',
  LIKE_POST = 'LIKE_POST',
}

export interface ITwitterTask {
  id: string;
  type: TwitterTaskType;
  twitterResourceId: string;
  taskId: string;
}

export interface UserSnapshotResult {
  id: string;
  status: UserSnapshotTaskStatusesEnum;
  snapshotsUrls: Array<string> | null;
  userTaskId: string;
}

export enum QuizType {
  REPEATABLE = 'REPEATABLE',
  NOT_REPEATABLE = 'NOT_REPEATABLE',
}

export interface WodlTaskInfo {
  id: string;
  attemptsToGuess: number;
  topic: string;
}

export interface ITask {
  id: string;
  type: TaskType;
  availableFromLevel: number;
  title: string;
  description: string;
  rewardPointsAmount: number;
  rewardTicketsAmount?: number;
  projectId?: string;
  quiz?: {
    id: string;
    approximateTime: number;
    createdAt: string;
    questionsCount: number;
    type: QuizType;
  };
  wodl?: WodlTaskInfo;
  twitterTask?: ITwitterTask;
  created_at: string;
  status?: MyTaskStatus;
  redirectUrl?: string;
  inviteCount: number;
  maxSuccessfullyCompletions?: number;
}

export interface IMyTask extends ITask {
  status: MyTaskStatus;
  userId: string;
  userQuizResult?: IUserQuizResult;
  userSnapshotResult?: UserSnapshotResult;
  userTaskId: string;
  userWodlResult?: WoodleResult;
  customReward?: string;
}

export interface IStartTaskResponse {
  id: string;
  status: MyTaskStatus;
  task: ITask;
  userId: string;
  createdAt: string;
  userQuizResult: IUserQuizResult;
  userTaskId: string;
  userWodlResult?: WoodleResult;
  wodl?: WodlTaskInfo;
  rewardPointsAmount?: number;
  rewardTicketsAmount?: number;
}

export type MyTasks = Array<IMyTask>;
export type Tasks = Array<ITask>;

export interface ITasksStore {
  availableTasks: Tasks;
  activeTasks: MyTasks;
  completeTasks: MyTasks;
  refetchingTasksUserIds: Array<string>;
}

export interface TasksData {
  id: number;
  socialIcon: string;
  completed: boolean;
  taskName: string;
  campaign: boolean;
  taskDescription: string;
  state?: TaskStateData;
}

export interface ISelectedTaskInfo {
  taskID?: string;
  userTaskID?: string;
  quizID: string;
  questionsAmount: number;
  quizType: QuizType;
  pointReward?: number;
  ticketReward?: number;
  isCustomRewardTask?: boolean;
}

export type HandleQuizModalOpenProps = Pick<
  IMyTask,
  'id' | 'userQuizResult' | 'rewardPointsAmount' | 'rewardTicketsAmount'
> &
  Required<Pick<IMyTask, 'quiz'>> & {
    maxSuccessfullyCompletions?: number;
    userTaskID?: string;
  };
