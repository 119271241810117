import {Activities} from '../Activies';
import {Notifications} from '../Notifications';
import {activityFeedService} from '../service';
import {ActivityFeedTabs, useActivityFeedStore} from '../store';
import {Tabs} from './Tabs';

export const ActivityFeedContent = () => {
  const {openedTab} = useActivityFeedStore();

  return (
    <div className="absolute bottom-0 left-0 right-0 sm:static z-20 p-5 bg-[#0B0D0E] rounded-t-20">
      <div className="mb-6">
        <div
          className="sm:hidden relative -top-[10px] w-[78px] border-2 border-white rounded-[32px] m-auto"
          onClick={activityFeedService.handleClose}
          onDragStart={activityFeedService.handleClose}
          onTouchEnd={activityFeedService.handleClose}
        ></div>
        <div className="mb-4 text-lg leading-6 font-semibold">
          Activity feed
        </div>
        <Tabs />
      </div>
      <div className="invisible-scrollbar max-h-[calc(100vh-350px)] overflow-auto">
        {openedTab === ActivityFeedTabs.notifications && <Notifications />}
        {openedTab === ActivityFeedTabs.activity && <Activities />}
      </div>
    </div>
  );
};
