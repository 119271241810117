import emailIcon from '../../assets/emailIcon.svg';
import tgIcon from '../../assets/tgIcon.svg';

interface ContactSupportProps {
  variant: 'large' | 'small';
}

export const ContactSupport = ({variant}: ContactSupportProps) => {
  const buttonClassnames =
    'min-h-[44px] bg-[#3C3D3E] flex justify-center items-center gap-2 rounded-[42px] font-mono uppercase font-bold tracking-[-0.24px] transition-all hover:bg-[rgba(255,255,255,.15)]';
  const boxClassnames =
    'rounded-[20px] bg-dark border-solid border-l-100 border-[1px]';

  return (
    <>
      {variant === 'large' ? (
        <div className={`p-6 ${boxClassnames} flex flex-col gap-6`}>
          <div>
            <img className="w-12 h-12" src={emailIcon} alt="emailIcon" />
          </div>
          <div className="flex flex-col xl:grid grid-cols-2 xl:items-end gap-6">
            <div>
              <div className="text-xl font-semibold mb-2 capitalize">
                Still have questions?
              </div>
              <p className="text-l-800 text-sm leading-6">
                If you can’t find answers in your convenient FAQ, contact us by
                email or in Telegram! To get an answer to your question faster,
                form it clearly and understandably. Average response time from
                15 minutes to 2 hours.
              </p>
            </div>
            <div className="flex flex-col lg:grid grid-cols-2 gap-4">
              <a
                href="mailto:support@raiser.co"
                target="_blank"
                className={buttonClassnames}
              >
                <span>E-mail</span>
                <img width={20} height={20} src={emailIcon} alt="icon" />
              </a>
              <a
                href="https://t.me/raiser_co"
                target="_blank"
                className={buttonClassnames}
              >
                <span>Telegram</span>
                <img width={20} height={20} src={tgIcon} alt="icon" />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${boxClassnames} p-5 flex flex-col max-w-[250px] gap-y-8 gap-x-4`}
        >
          <span className="text-xl font-semibold capitalize">
            Still have questions?
          </span>
          <div className="flex flex-col gap-3">
            <a
              href="mailto:support@raiser.co"
              target="_blank"
              className={buttonClassnames}
            >
              <span>E-mail</span>
              <img width={20} height={20} src={emailIcon} alt="icon" />
            </a>
            <a
              href="https://t.me/raiser_co"
              target="_blank"
              className={buttonClassnames}
            >
              <span>Telegram</span>
              <img width={20} height={20} src={tgIcon} alt="icon" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};
