import {TooltipInfoIcon} from '../../../components/Tooltip/TooltipInfoIcon';
import {formatDateFromIso} from '../../../utilities/date';

export const AppliedOn = ({appliedAt}: {appliedAt: string}) => {
  const formattedDate = formatDateFromIso(appliedAt);
  return (
    <div className="flex flex-col uppercase">
      <div className="flex gap-1 items-center mb-[10px] opacity-40">
        <div className="text-white text-xs leading-6">You’ve applied on</div>
        <TooltipInfoIcon content="" />
      </div>
      <div className="flex text-xs leading-[18px]">
        <div>{formattedDate}</div>
      </div>
    </div>
  );
};
