import React, {useCallback, useState} from 'react';
import {FileRejection, useDropzone} from 'react-dropzone';
import {NextArrow} from '../../../assets/svgComponents/NextArrow';
import {tasksService} from '../service/TasksService';
import {PaperIcon} from '../../../assets/svgComponents/PaperIcon';
import {CheckIcon} from '../../../assets/svgComponents/CheckIcon';
import {FileUploaderInfo} from './FileUploaderInfo';
import {Loader} from '../../../components/Loader';

const sizeFormatter = (size: number) => {
  let currentSize = size;
  let divisionAmount = 0;
  const sizes = ['Bytes', 'KB', 'MB'];
  while (currentSize > 1024) {
    currentSize /= 1024;
    divisionAmount++;
  }

  return `${currentSize.toFixed()}${sizes[divisionAmount]}`;
};

export const FileUploader = ({
  isPending = false,
  isRejected = false,
  taskId,
}: {
  isPending?: boolean;
  isRejected?: boolean;
  taskId: string;
}) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [error, setError] = useState(
    isRejected ? 'Your request have been rejected. Try again' : ''
  );
  const [isLoading, setIsLoading] = useState(false);

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      setError('');
      setFiles([...acceptedFiles]);
      setIsLoading(true);
      await tasksService.sendImageTaskResponse(taskId, acceptedFiles, setError);
      setIsLoading(false);
    },
    [taskId]
  );

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    if (fileRejections[0]?.errors[0].code === 'file-too-large') {
      setError('File is larger than 1 MB');
    } else {
      setError(fileRejections[0]?.errors[0]?.message);
    }
  }, []);

  const {getRootProps, getInputProps, open} = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
    },
    disabled: isPending || isLoading,
    maxFiles: 10,
    maxSize: 1048576,
  });

  const handleOpen = () => {
    if (!isLoading) {
      open();
    }
  };

  return (
    <div
      {...getRootProps()}
      className={
        'border flex gap-2 items-start rounded-lg border-l-100 bg-l-50 pt-3 pl-3 pr-[14px] pb-[19px] mb-4'
      }
    >
      <div className="max-w-sm">
        {isPending ? (
          <CheckIcon width={24} height={24} />
        ) : (
          <PaperIcon width={24} height={24} />
        )}
      </div>
      <div className="flex-1">
        <input {...getInputProps()} />
        <div className="w-full font-sans font-bold text-sm mb-4">
          {!isPending &&
            (files.length ? (
              files.map((file, index) => (
                <FileUploaderInfo
                  key={index}
                  header={file.name}
                  details={sizeFormatter(file.size)}
                />
              ))
            ) : (
              <FileUploaderInfo
                header={'Action Required'}
                details={
                  'You must upload your result to confirm completion of this task.SVG, PNG, JPG or GIF (max. 800x400px)'
                }
              />
            ))}
          {isPending && (
            <FileUploaderInfo
              header="Pending Check"
              details="We’ll check your result in a while and confrim task completion."
            />
          )}
          {error && <div className=" text-red-700">{error}</div>}
        </div>
        {!isPending &&
          (isLoading ? (
            <Loader />
          ) : (
            <div
              className="flex gap-[10px] text-green-200 items-center font-bold text-xs leading-6 font-mono cursor-pointer"
              onClick={handleOpen}
            >
              Upload result <NextArrow fill="#E5FD55" width={24} height={4} />
            </div>
          ))}
      </div>
    </div>
  );
};
