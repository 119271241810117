import React from 'react';

export const NotificationDotSeparator = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="3"
      height="4"
      viewBox="0 0 3 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity="0.4" y="0.5" width="3" height="3" rx="1.5" fill="white" />
    </svg>
  );
};
