import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Timer} from '../../../components/Timer';
import {Project, ProjectSchedule, RoundStatus} from '../../../types/project';
import {navigateToDisclaimer} from '../../ProjectInvest/helpers/navigateToDisclaimer';

interface ProjectMobileActionProps {
  project: Project;
  currentRound: ProjectSchedule;
}

export const ProjectMobileAction = ({
  project,
  currentRound,
}: ProjectMobileActionProps) => {
  const [isSideBarInPlace, setIsSideBarInPlace] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const sideBar = document.getElementById('project-side-bar');
    const projectCard = document.getElementById('project-card');

    if (!sideBar || !projectCard) return;

    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSideBarInPlace(e.isIntersecting);
      },
      {
        rootMargin: '0px 0px -80% 0px',
      }
    );

    const cardObserver = new IntersectionObserver(
      ([e]) => {
        setIsCardVisible(e.isIntersecting);
      },
      {
        rootMargin: '0% 0px 0% 0px',
      }
    );

    observer.observe(sideBar);
    cardObserver.observe(projectCard);

    return () => {
      observer.unobserve(sideBar);
      cardObserver.unobserve(projectCard);
    };
  }, []);

  const isInvestAvailable = !!(
    currentRound?.minAllocation &&
    currentRound?.maxAllocation &&
    currentRound?.tokenPrice
  );

  if (!project.isProjectInvestButtonShown) {
    return null;
  }

  return (
    <div
      className={classNames(
        'sticky bottom-0 z-10 -mx-3 w-[calc(100%+1.5rem)] md:-mx-6 md:w-[calc(100%+3rem)] p-5 pr-4 bg-[#040506] border-t border-[#1D1E1E] transition-all duration-300 ease-in-out sm:hidden',
        isSideBarInPlace && !isCardVisible
          ? 'translate-none'
          : 'translate-y-[200%]',
        isCardVisible && 'opacity-0 pointer-events-none'
      )}
    >
      <Timer
        type="startsIn"
        targetDate={currentRound.start ? new Date(currentRound.start) : null}
        endDate={currentRound.end ? new Date(currentRound.end) : null}
        className="bg-[rgba(221,221,221,0.20)]"
        soldOut={currentRound.status === RoundStatus.SOLDOUT}
        signatureUrl={currentRound.signatureUrl}
        onClick={
          isInvestAvailable
            ? () => {
                navigateToDisclaimer(navigate, {
                  id: project.id,
                  name: project.name,
                  description: project.details.disclaimer?.content,
                  token: project.token,
                  allocationToken: project.allocationToken,
                  network: project.network,
                  minAllocation: currentRound.minAllocation!,
                  maxAllocation: currentRound.maxAllocation!,
                  tokenPrice: currentRound.tokenPrice!,
                  signatureUrl: currentRound.signatureUrl,
                  investContractAddress: currentRound.investContractAddress,
                  decimals: project.allocationToken.decimals,
                });
              }
            : null
        }
      />
    </div>
  );
};
