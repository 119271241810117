import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {tasksService} from '../../service/TasksService';

export const HandleFinishedQuizButton = ({
  quizID,
  questionsAmount,
  userTaskID,
}: {
  quizID: string;
  questionsAmount: number;
  userTaskID: string;
}) => {
  return (
    <div
      className={
        'flex items-center gap-[10px] text-xs font-bold uppercase leading-3 text-green-200 hover:underline cursor-pointer'
      }
      onClick={() => {
        tasksService.handleDoneQuizOpen({questionsAmount, userTaskID, quizID});
      }}
    >
      View results
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  );
};
