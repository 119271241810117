import React from 'react';

export const NotificationKYCStatus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_3526_249)">
        <path
          d="M8.96623 10.7578C6.57778 10.7578 4.5625 11.1481 4.5625 12.6544C4.5625 14.1613 6.59079 14.5379 8.96623 14.5379C11.3547 14.5379 13.37 14.1476 13.37 12.6413C13.37 11.1344 11.3417 10.7578 8.96623 10.7578Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M8.9649 9.3237C10.5822 9.3237 11.879 8.01942 11.879 6.39281C11.879 4.76557 10.5822 3.46191 8.9649 3.46191C7.3476 3.46191 6.05078 4.76557 6.05078 6.39281C6.05078 8.01942 7.3476 9.3237 8.9649 9.3237Z"
          fill="white"
        />
        <path
          d="M4.15204 1.38477H3.5982C2.82274 1.38477 2.43501 1.38477 2.13883 1.53568C1.87829 1.66843 1.66647 1.88025 1.53373 2.14078C1.38281 2.43696 1.38281 2.82469 1.38281 3.60015V4.154"
          stroke="white"
          strokeOpacity="0.4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.15204 16.6152H3.5982C2.82274 16.6152 2.43501 16.6152 2.13883 16.4643C1.87829 16.3316 1.66647 16.1198 1.53373 15.8592C1.38281 15.563 1.38281 15.1753 1.38281 14.3998V13.846"
          stroke="white"
          strokeOpacity="0.4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.848 1.38477H14.4018C15.1773 1.38477 15.565 1.38477 15.8612 1.53568C16.1217 1.66843 16.3335 1.88025 16.4663 2.14078C16.6172 2.43696 16.6172 2.82469 16.6172 3.60015V4.154"
          stroke="white"
          strokeOpacity="0.4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.848 16.6152H14.4018C15.1773 16.6152 15.565 16.6152 15.8612 16.4643C16.1217 16.3316 16.3335 16.1198 16.4663 15.8592C16.6172 15.563 16.6172 15.1753 16.6172 14.3998V13.846"
          stroke="white"
          strokeOpacity="0.4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3526_249">
          <rect
            width="16.6154"
            height="16.6154"
            fill="white"
            transform="translate(0.691406 0.692383)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
