import React from 'react';

export const NotificationRegularTask = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.27196 5.49846C4.27196 5.49846 6.6151 12.0501 7.12375 13.4238L9.33735 19.4021"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        opacity="0.4"
        d="M7.12375 13.4238C7.81812 13.1667 9.37821 12.6176 10.5654 12.2021C11.541 11.8606 12.611 11.919 13.545 12.3619C14.5032 12.8162 15.604 12.865 16.5986 12.4972L19.1451 11.5555C19.5337 11.4118 19.7322 10.9802 19.5883 10.5916L17.2038 4.15166C17.0631 3.77162 16.6459 3.5721 16.2617 3.70109L13.6091 4.59174C12.6357 4.91857 11.5735 4.8554 10.6457 4.41548C9.68033 3.95774 8.57114 3.90861 7.56905 4.27918L4.27196 5.49846C4.27196 5.49846 6.6151 12.0501 7.12375 13.4238Z"
        fill="white"
      />
      <path
        d="M3.375 19.5H20.625"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
    </svg>
  );
};
