import {
  notificationIcon,
  notificationTarget,
  notificationActions,
} from '../constants';
import {INotification, NotificationWorkflowsEnum} from '../store';
import {Notification} from './Notification';

function formatTimeAgo(notificationTime: string): string {
  const currentTime = new Date();
  const parsedNotificationTime = new Date(notificationTime);

  const elapsedMilliseconds =
    currentTime.getTime() - parsedNotificationTime.getTime();
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

  if (elapsedSeconds < 60) {
    return '1m';
  }

  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  if (elapsedMinutes < 60) {
    return `${elapsedMinutes}m`;
  }

  const elapsedHours = Math.floor(elapsedMinutes / 60);
  if (elapsedHours < 24) {
    return `${elapsedHours}h`;
  }

  const elapsedDays = Math.floor(elapsedHours / 24);
  return `${elapsedDays}d`;
}

export const UserNotification = ({
  type,
  notification,
}: {
  type: NotificationWorkflowsEnum;
  notification: INotification;
}) => {
  const formattedTime = formatTimeAgo(notification.createdAt);

  return (
    <Notification
      header={notification?.payload?.title || ''}
      icon={notificationIcon[type]}
      time={formattedTime}
      description={notification?.payload?.description || ''}
      notificationTarget={notificationTarget[type]}
      notificationSubTarget={notification.payload?.taskTitle}
      messageId={notification._id}
      onClickAction={notificationActions[type]}
    />
  );
};
