import {AxiosResponse} from 'axios';
import {$api} from '../../services/interceptor';
import {dailyRewardStore} from './store';
import {userService} from '../../services/userApi';

interface GetDailyResponse {
  lastClaim: string;
  nextClaim: string;
  amount: number;
}

const dailyRewardsApi = () => {
  const {setState} = dailyRewardStore;
  const getDailyReward = async () => {
    try {
      const response: AxiosResponse<GetDailyResponse> =
        await $api.get('/daily-reward');

      setState({
        dailyReward: response.data.amount,
        nextClaim: response.data.nextClaim,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const claimDailyReward = async () => {
    try {
      await $api.post('/daily-reward');

      void getDailyReward();
      void userService.getUserData();
    } catch (error) {
      console.log(error);
    }
  };

  return {getDailyReward, claimDailyReward};
};

export const dailyRewardsService = dailyRewardsApi();
