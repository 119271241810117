import {
  SocialName,
  UserData,
  userStore,
} from '../modules/AccountProgress/store/useUserStore';
import {dashboardStore} from '../modules/Dashboard/store/store';
import {$api} from './interceptor';

const userApi = () => {
  const {setState, getState} = userStore;
  const {setState: setDashboardState} = dashboardStore;

  const getUserData = async () => {
    try {
      const response = await $api.get<UserData>('/users/@me');
      setState({loading: true});
      if (response.status === 200) {
        setState({
          user: response.data,
        });
      }
    } catch (error) {
      setState({error: true});
    }
  };

  const isTwitterConnected = () => {
    const {user} = getState();

    return !!user?.connectedSocials?.find(
      ({social: {name}}) => name === SocialName.Twitter
    );
  };
  const isTelegramConnected = () => {
    const {user} = getState();

    return !!user?.connectedSocials?.find(
      ({social: {name}}) => name === SocialName.Telegram
    );
  };

  const isDiscordConnected = () => {
    const {user} = getState();

    return !!user?.connectedSocials?.find(
      ({social: {name}}) => name === SocialName.Discord
    );
  };

  const isWalletBinded = () => {
    const {user} = getState();

    return !!((user?.connectedWallets?.length || 0) > 0);
  };

  const getInvitedCountData = async () => {
    try {
      const response = await $api.get<number>('/referral/invited/count');
      setDashboardState({isLoading: true});

      if (response.status === 200) {
        setDashboardState({invitedCount: response.data});
      }
    } catch (error) {
      setDashboardState({error: true});
    } finally {
      setDashboardState({isLoading: false});
    }
  };

  const getReferralBonus = async () => {
    try {
      const response = await $api.get<{bonusPercent: number}>(
        '/referral/bonus-settings'
      );
      setDashboardState({isLoading: true});

      if (response.status === 200) {
        setDashboardState({referralBonus: response.data?.bonusPercent || 0});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDashboardState({isLoading: false});
    }
  };

  return {
    getUserData,
    isTwitterConnected,
    isTelegramConnected,
    isDiscordConnected,
    getInvitedCountData,
    getReferralBonus,
    isWalletBinded,
  };
};

export const userService = userApi();
