import {raiserBlogRSSURL} from '../constants';

interface RSSItem {
  [key: string]: string;
}

const parseElement = (element: Element): RSSItem => {
  const parsedObject: RSSItem = {};
  const childNodes = element.childNodes;

  for (let i = 0; i < childNodes.length; i++) {
    const childNode = childNodes[i] as unknown as Element;

    if (childNode.nodeType === 1) {
      const key = childNode.nodeName;
      const value =
        childNode.textContent ||
        childNode.attributes.getNamedItem('url')?.value ||
        '';
      parsedObject[key] = value;
    }
  }
  return parsedObject;
};

const parseRSS = async (rssUrl: string) => {
  try {
    const response = await fetch(rssUrl);
    const xmlData = await response.text();

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData, 'text/xml');

    const parseItems = (items: NodeListOf<Element>): RSSItem[] => {
      return Array.from(items).map(item => parseElement(item));
    };

    const itemData = parseItems(xmlDoc.querySelectorAll('item'));

    const rssItems = itemData;

    return rssItems;
  } catch (error) {
    console.log(error);
  }

  return [];
};

export interface Post {
  description: string;
  guid: string;
  link: string;
  pubDate: string;
  thumbnail: string;
  title: string;
}

const blogApi = () => {
  const getPosts = async (setPosts: (posts: Array<Post>) => void) => {
    try {
      const rssItems = await parseRSS(raiserBlogRSSURL);

      setPosts(
        rssItems.map(({title, link, guid, description, pubDate, ...rest}) => ({
          title: title,
          link: link,
          guid: guid,
          description: description,
          pubDate: pubDate,
          thumbnail: rest['media:thumbnail'],
        }))
      );

      return;
    } catch (error) {
      console.log(error);
      return;
    }
  };

  return {getPosts};
};

export const blogService = blogApi();
