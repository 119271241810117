import {useEffect} from 'react';

import {projectsTasksService} from '../service/ProjectsTasksService';
import {
  ProjectsTasksID,
  projectsTasksStore,
} from '../store/useProjectsTasksStore';
import {useShallow} from 'zustand/react/shallow';
import {Loader} from '../../../components/Loader';
import {AccountTasksList} from './AccountTasksList';
import {GENERAL_ID} from '../constants';
import {tasksService} from '../../AccountTask/service/TasksService';

export const ProjectTasksList = ({
  projectId = GENERAL_ID,
  isProjectsLoading,
  projectName,
}: {
  projectId?: ProjectsTasksID;
  projectName: string;
  isProjectsLoading: boolean;
}) => {
  const projectTasks = projectsTasksStore(
    useShallow(state => state.projectsTasks[projectId])
  );

  useEffect(() => {
    void projectsTasksService.getProjectTasks(projectId, false);

    return tasksService.clearTasks;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <div>
      {projectTasks?.isLoading || isProjectsLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <AccountTasksList
            projectName={projectName}
            refetch={() => projectsTasksService.getProjectTasks(projectId)}
          />
        </>
      )}
    </div>
  );
};
