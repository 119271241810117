import {$api} from '../../../services/interceptor';
import {IActivity, activitiesStore} from './store';

const activitiesApi = () => {
  const {setState} = activitiesStore;

  const getActivities = async () => {
    try {
      const response = await $api.get<Array<IActivity>>('/level-points-logs');

      setState({activities: response.data});
    } catch (error) {
      console.log(error);
    }
  };

  const getActivitiesCount = async () => {
    try {
      const response = await $api.get<number>(
        '/level-points-logs/unread-count'
      );

      setState({unreadCount: response.data});
    } catch (error) {
      console.log(error);
    }
  };

  const markActivityAsRead = async (id: string) => {
    try {
      await $api.post('/level-points-logs/mark-as-read', null, {
        params: {levelPointsLogId: id},
      });
      void getActivities();
      void getActivitiesCount();
    } catch (error) {
      console.log(error);
    }
  };
  const markAllActivitiesAsRead = async () => {
    try {
      await $api.post('/level-points-logs/mark-as-read');
      void getActivities();
      void getActivitiesCount();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getActivities,
    getActivitiesCount,
    markActivityAsRead,
    markAllActivitiesAsRead,
  };
};

export const activitiesService = activitiesApi();
