/**
 * @param timestamp Timestamp in seconds
 * @returns Date string in the format DD.MM.YYYY
 */
export const formatDateFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const day = date.getDate();

  const month = Intl.DateTimeFormat('en-US', {month: 'long'})
    .format(date)
    .toUpperCase();

  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
};

export const formatDateFromIso = (isoTimestamp: string) => {
  const timestamp = new Date(isoTimestamp).getTime();
  const date = new Date(timestamp);

  const day = date.getDate();

  const month = Intl.DateTimeFormat('en-US', {month: 'long'})
    .format(date)
    .toUpperCase();

  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
};
