import {TimeGroups} from '../constants';

export const calculateTimeGroup = (date: string) => {
  const targetDate = new Date(date);
  const now = new Date();

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  if (targetDate < lastWeek) {
    return TimeGroups.earlier;
  }
  if (targetDate >= lastWeek && targetDate < yesterday) {
    return TimeGroups.week;
  }
  if (targetDate >= yesterday && targetDate < today) {
    return TimeGroups.yesterday;
  }
  if (targetDate >= today) {
    return TimeGroups.today;
  }

  return TimeGroups.earlier;
};
