import {create, useStore} from 'zustand';
import {
  AnswerType,
  QuestionState,
  QuizAnswerState,
  QuizStore,
  QuizStoreState,
} from '../types';

const initialState: QuizStoreState = {
  questions: [],
  previousAnswers: [],
  questionsAmount: 0,
  lastAnswer: null, // current quiz question answer
  currentQuestionNumber: 0,
  currentQuestionState: QuestionState.IN_PROGRESS,
  isQuizLoading: false,
  isSubmitPending: false,
  isQuizFinished: false,
  userPointsReward: 0,
  userTicketReward: 0,
};

export const quizStore = create<QuizStore>((set, get) => ({
  questions: [],
  previousAnswers: [],
  questionsAmount: 0,
  lastAnswer: null,
  currentQuestionNumber: 0,
  currentQuestionState: QuestionState.IN_PROGRESS,
  isQuizLoading: false,
  isSubmitPending: false,
  isQuizFinished: false,
  userPointsReward: 0,
  userTicketReward: 0,
  setQuestions: questions =>
    set(() => ({
      questions,
    })),
  addQuestion: question => set({questions: [...get().questions, question]}),
  addAnswerId: answerId => {
    const lastAnswer = get().lastAnswer;
    const lastAnswerIds = lastAnswer?.answerIds || [];
    const currentAnswer = {
      ...lastAnswer!,
      answerIds: [...lastAnswerIds, answerId] || [],
    };

    set({
      lastAnswer: {...currentAnswer},
    });
  },
  replaceAnswerIds: answerId => {
    const lastAnswer = get().lastAnswer;

    const currentAnswer = {
      ...lastAnswer!,
      answerIds: [answerId],
    };

    set({
      lastAnswer: {...currentAnswer},
    });
  },
  initilaizeAnswer: questionId => {
    set({
      lastAnswer: {questionId, answerIds: []},
      currentQuestionState: QuestionState.IN_PROGRESS,
    });
  },
  removeAnswerId: answerId => {
    const lastAnswer = get().lastAnswer;

    const currentAnswer = {
      ...lastAnswer!,
      answerIds: lastAnswer?.answerIds?.filter(id => id !== answerId) || [],
    };

    set({lastAnswer: {...currentAnswer}});
  },
  getAnswerState: ({questionId, answerId}) => {
    if (!questionId) {
      return get().lastAnswer?.answerIds.includes(answerId)
        ? QuizAnswerState.SELECTED
        : QuizAnswerState.NOT_SELECTED;
    }

    const previousAnswer = get().previousAnswers.find(
      ({questionId: prevQuestionId}) => questionId === prevQuestionId
    );

    if (previousAnswer?.correctAnswersIds?.includes(answerId)) {
      if (
        !previousAnswer?.answerIds?.includes(answerId) &&
        previousAnswer.answerType === AnswerType.MULTIPLE &&
        previousAnswer.answerIds.some(answerId =>
          previousAnswer.correctAnswersIds.includes(answerId)
        )
      ) {
        return QuizAnswerState.NOT_SELECTED_CORRECT;
      }

      return QuizAnswerState.CORRECT;
    }

    if (previousAnswer?.answerIds?.includes(answerId)) {
      return QuizAnswerState.INCORRECT;
    }

    return QuizAnswerState.NOT_SELECTED;
  },
  submitAnswer: validatedLastAnswer => {
    const previousAnswers = get().previousAnswers;

    set({
      previousAnswers: [...previousAnswers, {...validatedLastAnswer}],
      lastAnswer: null,
    });
  },
  incrementQuestionNumber: () => {
    set({currentQuestionNumber: get().currentQuestionNumber + 1});
  },
  decrementQuestionNumber: () => {
    set({currentQuestionNumber: get().currentQuestionNumber - 1});
  },
  resetStore: () => {
    set({...initialState});
  },
}));

export const useQuizStore = () => useStore(quizStore);
