import {NotificationDotSeparator} from '../../../../assets/svgComponents/NotificationDotSeparator';
import {TicketIcon} from '../../../../assets/svgComponents/TicketIcon';
import {roundNumber} from '../../../../utilities/roundNumber';
import {activitiesService} from '../service';
import {IActivity} from '../store';
import {getActivityTarget} from '../utils/getActivityTarget';

export const Activity = ({activity}: {activity: IActivity}) => {
  const {
    id,
    type,
    isRead,

    points,
    pointsBefore,
    pointsAfter,

    tickets,
    ticketsBefore,
    ticketsAfter,

    actionsLevelPoint,
    userTask,
    referralActionLog,
  } = activity;

  const {target, subTarget, title} = getActivityTarget({
    type,
    actionsLevelPoint,
    userTask,
    referralActionLog,
  });

  return (
    <div className="border-b border-l-100 py-4">
      <div className="flex justify-between">
        <div className="flex flex-col justify-between items-start py-1">
          <div className="flex justify-between gap-2 text-l-600 text-xs leading-5">
            {target}
            {!!subTarget && (
              <>
                <div>
                  <NotificationDotSeparator className="relative top-[40%]" />
                </div>
                <div className="truncate max-w-[219px]">{subTarget}</div>
              </>
            )}
          </div>
          <div className="font-semibold text-sm leading-6">{title}</div>
        </div>

        <div className="flex flex-col justify-between items-end gap-1">
          <div className="flex gap-1">
            {!!points && (
              <div className="py-2 px-[10px] bg-[#E5FD55] rounded-[28px] text-black font-bold text-xs leading-3">
                {`${points > 0 ? '+' : ''}${roundNumber(points)}`} PTS
              </div>
            )}
            {!!tickets && (
              <div className="py-2 px-[10px] bg-[#E5FD55] rounded-[28px] text-black font-bold text-xs leading-3 flex items-center gap-1">
                +{roundNumber(tickets)}
                <TicketIcon
                  width={16}
                  height={16}
                  ticketTopFill="black"
                  ticketBottomFill="black"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-0">
            {!!Number.isFinite(pointsBefore && pointsAfter) && (
              <div className="text-l-600 text-xs leading-5">
                {roundNumber(pointsBefore || 0)} →
                {roundNumber(pointsAfter || 0)}
                PTS
              </div>
            )}
            {!!Number.isFinite(ticketsBefore && ticketsAfter) && (
              <div className="text-l-600 text-xs leading-5 flex items-center gap-1">
                {roundNumber(ticketsBefore || 0)} →{' '}
                {roundNumber(ticketsAfter || 0)}
                <TicketIcon width={16} height={16} />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isRead && (
        <div className="flex flex-row-reverse items-center justify-between font-mono uppercase">
          <div
            className="text-xs font-bold leading-6 cursor-pointer hover:underline"
            onClick={() => activitiesService.markActivityAsRead(id)}
          >
            Mark as read
          </div>
        </div>
      )}
    </div>
  );
};
