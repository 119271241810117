import {progressStore} from '../store/useStepsStore';
import {signUpStore} from '../store/useSignUpStore';

export const handleNextStep = () => {
  const {setState: setProgressState, getState: getProgressState} =
    progressStore;
  const {stepsArray, activeStep} = getProgressState();
  const newActiveStep = activeStep + 1;
  setProgressState({activeStep: newActiveStep});

  const updatedStepsArray = new Array(...stepsArray);
  const updatedSteps = updatedStepsArray.map(step => {
    if (step.id === activeStep) {
      step.isCompleted = true;
      step.isSkipped = false;
    }
    return step;
  });
  setProgressState({stepsArray: updatedSteps});
};

export const handleSkipStep = () => {
  const {setState: setProgressState, getState: getProgressState} =
    progressStore;
  const {setState: setSignUpState} = signUpStore;
  const {stepsArray, activeStep} = getProgressState();
  const newActiveStep = activeStep + 1;
  setProgressState({activeStep: newActiveStep});

  const updatedStepsArray = new Array(...stepsArray);
  const updatedSteps = updatedStepsArray.map((step, index) => {
    if (step.id === activeStep) {
      step.isSkipped = true;
    }
    if (step.isCompleted) {
      step.isCompleted = true;
      step.isActive = false;
      step.isSkipped = false;
    }
    if (
      index === updatedStepsArray.length - 1 &&
      step.isCompleted &&
      step.isActive
    ) {
      step.isCompleted = true;
      step.isActive = false;
      step.isSkipped = false;
    }
    return step;
  });
  setProgressState({stepsArray: updatedSteps});
  setSignUpState({
    messageFromRequestSignUp: '',
  });
};

export const handleBackStep = () => {
  const {setState: setProgressState, getState: getProgressState} =
    progressStore;
  const {setState: setSignUpState} = signUpStore;
  const {stepsArray, activeStep} = getProgressState();
  const newActiveStep = activeStep - 1;
  setProgressState({activeStep: newActiveStep});

  const updatedStepsArray = new Array(...stepsArray);
  const updatedSteps = updatedStepsArray.map(step => {
    if (step.id === activeStep) {
      step.isSkipped = true;
    }
    if (step.isCompleted) {
      step.isSkipped = false;
      step.isActive = false;
      step.isCompleted = true;
    }
    return step;
  });
  setProgressState({stepsArray: updatedSteps});
  setSignUpState({
    messageFromRequestSignUp: '',
  });
};

export const getActionPointsForStep = () => {
  const {getState: getSignUpState} = signUpStore;
  const {actionPoints} = getSignUpState();
  const {getState: getProgressState} = progressStore;
  const {activeStep} = getProgressState();

  if (actionPoints !== undefined && actionPoints !== null) {
    switch (activeStep) {
      case 0:
        return actionPoints.ENTER_REFERRAL_CODE;
      case 1:
        return actionPoints.ENTER_NAME_AND_SURNAME;
      case 2:
        return (
          actionPoints.CONNECT_TWITTER +
          actionPoints.CONNECT_TELEGRAM +
          actionPoints.CONNECT_DISCORD
        );
      case 3:
        return actionPoints.CONNECT_WALLET;
      case 4:
        return actionPoints.COMPLETE_KYC;
      default:
        return undefined;
    }
  } else {
    return undefined;
  }
};
