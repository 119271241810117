import {InfoIcon} from '../../../../assets/svgComponents/InfoIcon';
import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {useProjectInvestStore} from '../store/useProjectInvestStore';

export const ErrorMessage = () => {
  const {errorMessage} = useProjectInvestStore();

  if (!errorMessage) {
    return null;
  }

  return (
    <div className="border-solid border-[#FB4949FB] bg-[#FB49490D] flex flex-col w-full font-sans items-start px-3 pt-3 border rounded-lg">
      <div className="flex flex-row gap-2 items-start">
        <InfoIcon
          width={24}
          height={24}
          iIconProps={{stroke: '#FB4949'}}
          circleProps={{fill: '#FB494947'}}
        />
        <div>
          <div className="text-sm font-bold leading-6 capitalize text-white">
            {errorMessage?.title}
          </div>
          <div className="text-xs leading-5 text-l-600 mb-4">
            {errorMessage.description}
          </div>
          {!!errorMessage.retryEvent && (
            <div
              className="flex items-center gap-[10px] text-xs leading-6 uppercase font-mono mb-4 cursor-pointer"
              onClick={errorMessage?.retryEvent}
            >
              try again
              <NextArrow fill="white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
