import {memo} from 'react';
import {tasksStore} from '../store/useTasksStore';
import {ReloadArrow} from '../../../assets/svgComponents/ReloadArrow';
import {tasksService} from '../service/TasksService';
import {MyTaskStatus, TaskType} from '../../../types/tasks';
import {retryTimersStore} from '../store/useRetryTimersStore';
import {useShallow} from 'zustand/react/shallow';
import {Tooltip} from '../../../components/Tooltip';

export interface IRetryButtonProps {
  userTaskId: string;
  status: MyTaskStatus;
  taskType:
    | TaskType.twitter
    | TaskType.telegram
    | TaskType.nft
    | TaskType.gitcoin
    | TaskType.discord;
}

export const RetryButton = memo(
  ({userTaskId, status, taskType = TaskType.twitter}: IRetryButtonProps) => {
    const ifTaskFetching = tasksStore(
      useShallow(state => state.refetchingTasksUserIds.includes(userTaskId))
    );

    const time = retryTimersStore(
      useShallow(state => state.refetchingCooldowns[userTaskId]?.time)
    );

    return (
      <div>
        {time ? (
          <Tooltip content={<div className="text-lg">{time}</div>}>
            <ReloadArrow
              className={'fill-l-600 pointer-events-auto  cursor-pointer'}
            />
          </Tooltip>
        ) : (
          <ReloadArrow
            className={`fill-l-600 pointer-events-auto cursor-pointer ${
              ifTaskFetching ? 'animate-spin' : ''
            }`}
            onClick={() => {
              if (userTaskId) {
                void tasksService.refetchTask(userTaskId, status, taskType);
              }
            }}
          />
        )}
      </div>
    );
  }
);
