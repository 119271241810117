import {AxiosResponse} from 'axios';
import {$api} from '../../../services/interceptor';
import {quizModalStore} from '../store/useQuizModalStore';
import {quizStore} from '../store/useQuizStore';

import {
  Answer,
  AnswerType,
  IQuestionResponse,
  Question,
  QuestionState,
  QuizState,
  QuizStatus,
  QuizStore,
} from '../types';
import {tasksService} from '../../AccountTask/service/TasksService';
import {userService} from '../../../services/userApi';
import {tasksModalStore} from '../../AccountTask/store/useTasksModalStore';
import {IMyTask} from '../../../types/tasks';

interface IGetQuizResponse {
  id: string;
  status: QuizStatus;
  createdAt: string;
  finishedAt: string;
  retryAfter: string;
  currentQuestion: {
    id: string;
    text: string;
    answerType: AnswerType;
    answers: Array<Answer>;
  };

  previousQuestionsAnswers: Array<{
    question: Question;
    userAnswers: Array<Answer>;
    correct: boolean;
    id: string;
    questionDescription?: string;
  }>;
}

const isAnswerCorrect = (
  userAnswers: Array<string>,
  correctAnswers: Array<string>
) => {
  return (
    correctAnswers.every(id => userAnswers.includes(id)) &&
    userAnswers.every(answerId => correctAnswers.find(id => id === answerId))
  );
};

function Quiz() {
  const {setState, getState} = quizStore;
  const {getState: getQuizModalState} = quizModalStore;

  const {
    submitAnswer,
    addQuestion,
    initilaizeAnswer,
    incrementQuestionNumber,
    decrementQuestionNumber,
  } = getState();

  const {setQuizModalState} = getQuizModalState();

  const postAnswer = async (
    answerPayload: {
      quizId: string;
      answerIds: Array<string>;
      questionId: string;
    },
    answerType: AnswerType
  ) => {
    setState({isSubmitPending: true});
    try {
      const response: AxiosResponse<IQuestionResponse> = await $api.post(
        '/quizes/answer',
        answerPayload
      );

      const {
        quizStatus,
        nextQuestion,
        correctAnswers,
        userPointsReward,
        userTicketReward,
        questionDescription,
      } = response.data;

      submitAnswer({
        ...answerPayload,
        correctAnswersIds: correctAnswers.map(({id}) => id),
        answerType,
        correct: isAnswerCorrect(
          answerPayload.answerIds,
          correctAnswers.map(({id}) => id)
        ),
        description: questionDescription,
      });

      const {
        userPointsReward: oldUserPointsReward,
        userTicketReward: oldUserTicketReward,
      } = getState();

      setState({
        userPointsReward: userPointsReward ?? oldUserPointsReward,
        userTicketReward: userTicketReward ?? oldUserTicketReward,
      });

      if (quizStatus === QuizStatus.DONE) {
        void userService.getUserData();
        setState({currentQuestionState: QuestionState.DONE});
      }
      if (nextQuestion) {
        addQuestion({
          correctAnswers: [],
          ...nextQuestion,
        });

        setState({currentQuestionState: QuestionState.DONE});
      } else {
        setState({isQuizFinished: true});
      }

      if (quizStatus === QuizStatus.FAILED) {
        setState({currentQuestionState: QuestionState.ERROR});
      }
    } catch (error) {
      console.log(error);
    }

    setState({isSubmitPending: false});
  };

  const validate = (quizId: string) => {
    const lastAnswer = getState().lastAnswer;

    const currentQuestion = getState().questions.at(-1);

    if (lastAnswer && currentQuestion) {
      void postAnswer({quizId, ...lastAnswer}, currentQuestion?.answerType);
    }
  };

  const isPreviousPage = (shift = 0) => {
    const store = getState();

    return store.currentQuestionNumber + shift < store.previousAnswers.length;
  };

  const isLastAnsweredPage = () => {
    const {previousAnswers, currentQuestionNumber} = getState();

    return currentQuestionNumber + 1 === previousAnswers.length;
  };

  const handleNext = () => {
    const store = getState();

    // next question initialization
    if (
      !store.lastAnswer &&
      store.currentQuestionState !== QuestionState.ERROR &&
      isLastAnsweredPage()
    ) {
      const questionId = store.questions[store.currentQuestionNumber + 1].id;
      initilaizeAnswer(questionId);
    }

    incrementQuestionNumber();
  };

  const isLastQuizPage = () => {
    const {currentQuestionNumber, questionsAmount, currentQuestionState} =
      getState();

    if (currentQuestionState === QuestionState.ERROR) {
      return isLastAnsweredPage();
    }

    if (currentQuestionState === QuestionState.IN_PROGRESS) {
      return currentQuestionNumber === questionsAmount;
    }

    return currentQuestionNumber + 1 === questionsAmount;
  };

  const handleBack = () => {
    decrementQuestionNumber();
  };

  const getQuiz = async (quizID: string, questionsAmount: number) => {
    try {
      const result: AxiosResponse<IGetQuizResponse> = await $api.get(
        `/quizes/${quizID}`
      );
      const {previousQuestionsAnswers, currentQuestion, status} = result.data;
      const questions = [
        ...previousQuestionsAnswers.map(({question}) => question),
        currentQuestion,
      ];

      const currentQuestionNumber = previousQuestionsAnswers.length;
      const quizStoreState: Partial<QuizStore> = {
        questions: questions,
        questionsAmount,
        currentQuestionNumber,
        previousAnswers: previousQuestionsAnswers.map(
          ({question, userAnswers, correct, questionDescription}) => ({
            questionId: question.id,
            correctAnswersIds: question.correctAnswers.map(({id}) => id),
            answerIds: userAnswers.map(({id}) => id),
            answerType: question.answerType,
            correct,
            description: questionDescription,
          })
        ),
      };

      if (status === QuizStatus.IN_PROGRESS) {
        setQuizModalState(QuizState.IN_PROGRESS);
      } else {
        setQuizModalState(QuizState.FINISHED);

        quizStoreState.currentQuestionState =
          status === QuizStatus.FAILED
            ? QuestionState.ERROR
            : QuestionState.DONE;
      }

      quizStore.setState(quizStoreState);
      const store = quizStore.getState();

      const currentQuestionID = store.questions?.at(-1)?.id;

      if (currentQuestionID) {
        store.initilaizeAnswer(currentQuestionID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const startQuiz = async (
    taskID: string,
    quizID: string,
    questionsAmount: number
  ) => {
    try {
      setState({isQuizLoading: true});

      const response = await tasksService.startTask(taskID);

      const currentSelectedTaskInfo =
        tasksModalStore.getState().selectedTaskInfo;

      if (currentSelectedTaskInfo) {
        tasksModalStore.setState({
          selectedTaskInfo: {
            ...currentSelectedTaskInfo,
            userTaskID: response.data.userTaskId,
          },
        });
      }

      await getQuiz(quizID, questionsAmount);
    } catch (error) {
      console.log(error);
    } finally {
      setState({isQuizLoading: false});
    }
  };

  const handleQuizStart = (
    taskID: string,
    quizID: string,
    questionsAmount: number
  ) => {
    void startQuiz(taskID, quizID, questionsAmount);
  };

  const getTaskCustomReward = async (taskID: string) => {
    try {
      const response = await $api.get<IMyTask>(`/user-tasks/my/${taskID}`);

      quizStore.setState({customReward: response.data.customReward});
    } catch (error) {
      console.log(error);
    }
  };

  return {
    postAnswer,
    validate,
    isPreviousPage,
    isLastAnsweredPage,
    handleNext,
    isLastQuizPage,
    handleBack,
    getQuiz,
    handleQuizStart,
    getTaskCustomReward,
  };
}

export const quizService = Quiz();
