import {TooltipInfoIcon} from '../../../components/Tooltip/TooltipInfoIcon';
import {roundNumber} from '../../ProjectInvest/Allocation/helpers/roundNumber';

export const TokensPurchased = ({
  tokenSymbol,
  allocationTokenSymbol,
  iconUrl,
  price,
  amountInvested,
}: {
  tokenSymbol: string;
  allocationTokenSymbol: string;
  iconUrl: string;
  price: number;
  amountInvested: number;
}) => {
  return (
    <div className="flex flex-col uppercase">
      <div className="flex gap-1 items-center mb-[10px] opacity-40">
        <div className="text-white text-xs leading-6">tokens purchased</div>
        <TooltipInfoIcon content="" />
      </div>
      <div className="flex text-lg leading-6 font-bold">
        <div className="mr-[10px]">
          <img className="rounded-full h-6 w-6" src={iconUrl} />
        </div>
        <div>{roundNumber(amountInvested / price)}&nbsp;</div>
        <div>{tokenSymbol}</div>
      </div>
      <div className="text-l-400 text-xs leading-6">
        {roundNumber(amountInvested)} {allocationTokenSymbol}
      </div>
    </div>
  );
};
