import {create, useStore} from 'zustand';

interface DailyRewardStore {
  dailyReward: number;
  nextClaim: string | null;
}

export const dailyRewardStore = create<DailyRewardStore>(() => ({
  dailyReward: 0,
  nextClaim: null,
}));

export const useDailyRewardStore = () => useStore(dailyRewardStore);
