import {Button} from '../../components/Button';
import documentIcon from '../../assets/documentIcon.svg';
import {blogData} from '../Dashboard/constants';
import {useEffect, useState} from 'react';
import {handleExternalLinkOpen} from '../../utilities/handleExternalLinkOpen';
import {formatToENUSDateFormat} from '../../utilities/dateFormatters';
import {raiserBlogURL} from './constants';
import {Post, blogService} from './service/blogService';

export const BlogView = () => {
  const [posts, setPosts] = useState<Array<Post>>([]);
  useEffect(() => {
    void blogService.getPosts(setPosts);
  }, []);

  return (
    <div className="grid-layout__blog border-l-100 border-solid border-[1px] bg-[#0B0D0E] rounded-20 overflow-hidden">
      <div className="p-4 xl:p-6 border-b-l-100 border-solid border-b-[1px] flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img src={documentIcon} alt="headingIcon" />
          <span className="text-xl font-bold">Latest from our blog</span>
        </div>
        <a
          href={raiserBlogURL}
          target="_blank"
          className={
            'flex transition-all hover:border-l-400 hover:scale-95 justify-center items-center w-9 h-9 rounded-full border-l-200 border-solid border-[1px]'
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="3"
            viewBox="0 0 18 3"
            fill="none"
          >
            <path
              d="M18 3L0 3L0 1.84615L9.12449 1.84615V0L18 3Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      <div className="py-8 px-5 xl:py-6 xl:p-6">
        <ul className="flex flex-col gap-5">
          {posts
            .slice(0, 3)
            .map(({link, pubDate, title, thumbnail, guid}, blogItemIndex) => (
              <li
                key={guid}
                className={`${
                  blogItemIndex !== blogData.length - 1
                    ? 'border-b-l-50 border-solid border-b-[1px] pb-5'
                    : ''
                }`}
              >
                <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-5">
                  <div className="flex flex-col gap-4 pointer-events-none [&:hover_~_*>a>img]:scale-105">
                    <span className="text-l-600 uppercase text-xs font-mono line-clamp-1">
                      {formatToENUSDateFormat(pubDate)}
                    </span>
                    <a
                      href={link}
                      target="_blank"
                      className="leading-8 text-xl xl:text-2xl font-bold pointer-events-auto"
                    >
                      {title}
                    </a>
                  </div>
                  <div className="rounded-lg overflow-hidden relative h-[160px] group">
                    <a
                      href={link}
                      target="_blank"
                      className="leading-8 text-xl xl:text-2xl font-bold pointer-events-auto"
                    >
                      <img
                        className="absolute top-0 left-0 transition-all w-full h-full object-cover group-hover:scale-[1.05]"
                        src={thumbnail}
                        alt="blogItemImage"
                      />
                    </a>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="flex justify-center xl:justify-end py-5 px-4 xl:px-6 border-t-[1px] border-t-l-100 border-solid">
        <Button
          className="bg-l-100 text-white w-full md:w-[320px]"
          text="VIEW MORE articles & updates"
          onClick={() => {
            handleExternalLinkOpen(raiserBlogURL);
          }}
        />
      </div>
    </div>
  );
};
