export const CrossCancelMob = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.5"
        y="5.5"
        width="35"
        height="35"
        rx="17.5"
        stroke="white"
        strokeOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6569 16.3131L23 21.97L17.3431 16.3131L16.3146 17.3416L21.9715 22.9985L21.9713 22.9987L22.9998 24.0272L23 24.027L28.6569 29.6839L29.6854 28.6553L24.0285 22.9985L29.6854 17.3416L28.6569 16.3131ZM21.9713 25.0557L20.9428 24.0272L16.3146 28.6553L17.3431 29.6839L21.9713 25.0557Z"
        fill="white"
      />
    </svg>
  );
};
