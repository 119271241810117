import {IMessage} from '@novu/headless';
import {create, useStore} from 'zustand';

export enum NotificationWorkflowsEnum {
  IN_APP_NEW_LEVEL = 'IN_APP_NEW_LEVEL', // новый левел
  IN_APP_NEW_REFERRAL = 'IN_APP_NEW_REFERRAL', // новый юзер заюзал реферал
  IN_APP_RETRY_QUIZ = 'IN_APP_RETRY_QUIZ', // можно перепройти квиз
  IN_APP_COMPLETED_TASK = 'IN_APP_COMPLETED_TASK', // таска успешно завершена
  IN_APP_REJECTED_TASK = 'IN_APP_REJECTED_TASK', //  таска не успешно завершена
  IN_APP_NEED_TO_CONNECT_TWITTER = 'IN_APP_NEED_TO_CONNECT_TWITTER', // надо конектить твитер (это когда выполнил твитер таску и откенектил твиттер
  IN_APP_KOL_RECIEVED = 'IN_APP_KOL_RECIEVED',
  IN_APP_KOL_REJECTED = 'IN_APP_KOL_REJECTED',
  UPDATE_KYC_STATUS = 'UPDATE_KYC_STATUS',
  IN_APP_NEW_TASK = 'IN_APP_NEW_TASK',
  IN_APP_REVERTED_GITCOIN_TASK = 'IN_APP_REVERTED_GITCOIN_TASK',
  IN_APP_NEW_WODL_TRY = 'IN_APP_NEW_WODL_TRY',
}

export interface INotification extends IMessage {
  templateIdentifier: NotificationWorkflowsEnum;
  payload: {title?: string; description?: string; taskTitle?: string};
}

export interface NotificationsStore {
  notifications: Array<INotification>;
}

export const notificationsStore = create<NotificationsStore>(() => ({
  notifications: [],
}));

export const useNotificationsStore = () => useStore(notificationsStore);
