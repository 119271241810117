export enum LetterState {
  initial,
  wrong = 'WRONG',
  included = 'INCLUDED',
  correct = 'CORRECT',
}

export const keys = [
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'backspace',
  'z',
  'x',
  'c',
  'v',
  'b',
  'n',
  'm',
  'enter',
];

export const ordinals = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
];
