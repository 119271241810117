import React from 'react';

export const ActivityGraph = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.1">
        <path
          d="M75.0186 23.332C74.8019 24.4458 74.6939 25.5779 74.6961 26.7125C74.6961 36.8921 82.9604 45.1442 93.1549 45.1442C94.2774 45.1387 95.3971 45.0309 96.5 44.8223V81.3637C96.5 96.7369 87.4319 105.832 72.0362 105.832H38.5042C23.0681 105.832 14 96.7369 14 81.3637V47.8808C14 32.5076 23.0681 23.332 38.5042 23.332H75.0186ZM75.0347 50.8393C74.1384 50.9682 73.3328 51.4545 72.8019 52.1869L61.7589 66.393L49.1844 56.493C48.4665 55.9337 47.5532 55.6868 46.6508 55.8079C45.7484 55.9291 44.9328 56.4081 44.3884 57.1369L30.8466 74.6028C30.3706 75.1961 30.1144 75.9353 30.1212 76.6954L30.133 77.2013C30.2643 78.5305 31.176 79.679 32.4817 80.0911C33.9507 80.5548 35.548 79.9697 36.3681 78.6674L47.6932 64.0186L60.2677 73.8784C60.9829 74.455 61.9022 74.7164 62.8143 74.6025C63.7264 74.4887 64.553 74.0094 65.1041 73.2747L78.2025 56.3723V56.2918C79.3238 54.785 79.0377 52.6603 77.5576 51.5028C76.8413 50.9498 75.9309 50.7104 75.0347 50.8393Z"
          fill="white"
        />
        <path
          d="M94.2083 37.0807C100.537 37.0807 105.667 31.9507 105.667 25.6224C105.667 19.2941 100.537 14.1641 94.2083 14.1641C87.8801 14.1641 82.75 19.2941 82.75 25.6224C82.75 31.9507 87.8801 37.0807 94.2083 37.0807Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
