import React from 'react';
import './index.css';

export const Loader = (props: React.JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      className={'loader-spin'}
      onClick={props.onClick}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5542 12.2748C15.9319 14.1048 13.1109 17.2054 11.6305 20.9841L15.2005 21.9407C16.4195 19.1076 18.6178 16.808 21.3933 15.4628C24.1687 14.1175 27.3356 13.8165 30.3148 14.6148C33.294 15.413 35.8861 17.2572 37.6171 19.8099C39.348 22.3627 40.102 25.4533 39.7412 28.5163L43.3112 29.4729C43.9185 25.4603 43.0258 21.3646 40.8037 17.9686C38.5817 14.5727 35.1862 12.1146 31.2662 11.0642C27.3461 10.0139 23.1765 10.4449 19.5542 12.2748Z"
        fill="black"
      />
      <path
        opacity="0.6"
        d="M13.1872 36.0326C10.9651 32.6366 10.0724 28.541 10.6797 24.5283L14.2497 25.4849C13.8889 28.548 14.6429 31.6386 16.3738 34.1913C18.1048 36.7441 20.697 38.5882 23.6761 39.3865C26.6553 40.1848 29.8223 39.8837 32.5977 38.5385C35.3731 37.1932 37.5714 34.8937 38.7904 32.0606L42.3604 33.0172C40.88 36.7958 38.0591 39.8964 34.4368 41.7264C30.8144 43.5564 26.6448 43.9874 22.7248 42.937C18.8047 41.8866 15.4092 39.4286 13.1872 36.0326Z"
        fill="black"
      />
    </svg>
  );
};
