import {NavigateFunction, useNavigate} from 'react-router-dom';
import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {NotificationDotSeparator} from '../../../../assets/svgComponents/NotificationDotSeparator';
import {notificationsService} from '../service';
import {activityFeedService} from '../../service';

export const Notification = ({
  icon,
  header,
  time,
  description,
  notificationTarget,
  notificationSubTarget,
  messageId,
  onClickAction,
}: {
  icon: JSX.Element;
  header: string;
  time: string;
  description: string;
  notificationTarget: string;
  notificationSubTarget?: string;
  messageId: string;
  onClickAction: ((navigate: NavigateFunction) => void) | null;
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-[10px] border-l-100 border-b pb-3 last:border-b-0 last:pb-0 pt-3 first:pt-0 ">
      <div className="w-7 h-7 p-1 rounded-md bg-l-100 flex justify-center items-center">
        {icon}
      </div>
      <div className="flex-1">
        <div className="flex justify-between items-center text-l-600 text-xs leading-5">
          <div className="flex gap-2 items-center">
            <div>{notificationTarget}</div>
            {!!notificationSubTarget && (
              <>
                <div>
                  <NotificationDotSeparator className="relative top-[1px]" />
                </div>
                <div className="truncate max-w-[219px]">
                  {notificationSubTarget}
                </div>
              </>
            )}
          </div>
          <div>{time}</div>
        </div>
        <div className="font-semibold text-sm leading-6">{header}</div>
        <div className="text-l-600 text-xs leading-5 mb-3">{description}</div>
        <div className="flex flex-row-reverse items-center justify-between font-mono uppercase">
          <div
            className="text-xs font-bold leading-6 cursor-pointer hover:underline"
            onClick={() =>
              notificationsService.markNotificationAsRead(messageId)
            }
          >
            Mark as read
          </div>
          {onClickAction && (
            <div
              className={
                'flex items-center gap-[10px] text-xs font-bold uppercase leading-3 text-green-200 hover:underline cursor-pointer'
              }
              onClick={() => {
                onClickAction(navigate);
                activityFeedService.handleClose();
              }}
            >
              learn more
              <NextArrow width={24} height={4} fill="#E5FD55" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
