import {create, useStore} from 'zustand';

export interface LevelsStore {
  levels: LevelStore[] | null;
}

export interface LevelStore {
  id: string;
  value: number;
  pointsToReach: number;
  platformFee: number;
  maxAllocation: number;
}

export const levelsStore = create<LevelsStore>(set => ({
  levels: [],
}));

export const useLevelsStore = () => useStore(levelsStore);
