import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {userService} from '../../../../services/userApi';
import {tasksService} from '../../service/TasksService';
import {MyTaskStatus} from '../../../../types/tasks';
import {bindWalletModalService} from '../../../BindWalletModal/service';
import {useUserStore} from '../../../AccountProgress/store/useUserStore';

export const HandleWalletRelatedTaskButton = ({
  id,
  status,
}: {
  id: string;
  status?: Exclude<MyTaskStatus, MyTaskStatus.DONE>;
}) => {
  useUserStore();

  const isWalletBinded = userService.isWalletBinded();

  return (
    <div
      className={
        'flex items-center gap-[10px] text-xs font-bold uppercase leading-6 text-green-200 hover:underline cursor-pointer'
      }
      onClick={() => {
        if (isWalletBinded) {
          tasksService.handleTaskClickWithRefetch(id, status);
        } else {
          bindWalletModalService.handleModalOpen();
        }
      }}
    >
      {isWalletBinded ? 'Start' : 'Connect wallet first'}

      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  );
};
