import {SvgArc} from './SvgArc';

interface ProgressProps {
  progress: number; // accepts value from 0 to 1 (>=0 || <=1) 80% === 0.8
}

export const ProgressArc = ({progress}: ProgressProps) => {
  const strokeWidth = 8;
  const radius = 80;
  const coordinate = radius + strokeWidth;
  const startAngle = -120;
  const endAngle = 120;

  const endAngleProgress =
    (endAngle + Math.abs(startAngle)) * progress + startAngle;

  return (
    <svg className="relative -left-2 -top-2 z-50 overflow-visible w-[160px]">
      <defs>
        <marker
          id="circle"
          viewBox="0 0 31 31"
          refX="15"
          refY="15"
          markerWidth={31}
          orient="auto-start-reverse"
        >
          <circle cx="15.7779" cy="15.7037" r="15.8519" fill="#0E1012" />
          <circle
            cx="15.7779"
            cy="15.7037"
            r="9.35185"
            stroke="#E5FD55"
            strokeWidth="5"
          />
        </marker>
      </defs>
      <SvgArc
        x={coordinate}
        y={coordinate}
        radius={radius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="transparent"
        strokeWidth={strokeWidth}
        stroke="#262729"
      />
      <SvgArc
        x={coordinate}
        y={coordinate}
        radius={radius}
        startAngle={startAngle}
        endAngle={endAngleProgress}
        fill="transparent"
        strokeWidth={strokeWidth}
        stroke="#E5FD55"
        markerStart={progress === 1 ? undefined : 'url(#circle)'}
      />
    </svg>
  );
};
