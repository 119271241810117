import {isMetamaskExtensionInstalled} from '../../utilities/metamask';
import {handleExternalLinkOpen} from '../../utilities/handleExternalLinkOpen';
import {useConnect} from 'wagmi';
import {useWeb3Modal} from '@web3modal/react';
import {Modal} from '../../components/Modal';
import {useConnectWalletModalStore} from './store';
import {connectWalletModalService} from './service';
import {Cross} from '../../assets/svgComponents/Cross';

export function ConnectWalletModal() {
  const {isOpened} = useConnectWalletModalStore();
  const {connect, connectors} = useConnect();
  const {open} = useWeb3Modal();

  const handleConnectMetamask = () => {
    connectWalletModalService.handleModalClose();
    if (!isMetamaskExtensionInstalled()) {
      handleExternalLinkOpen('https://metamask.app.link/dapp/');
      return;
    }
    connect({
      connector: connectors[1],
    });
    connectWalletModalService.handleModalClose();
  };

  const handleConnectWallet = async () => {
    await open();

    connectWalletModalService.handleModalClose();
  };

  if (!isOpened) {
    return;
  }

  return (
    <Modal handleClose={connectWalletModalService.handleModalClose}>
      <div className="bg-[#0B0D0E] text-white  rounded-[20px] border border-l-100 w-[360px] flex flex-col items-center justify-between p-5 relative">
        <div className="w-full h-5">
          <Cross
            className="absolute -right-1 -top-1 cursor-pointer"
            onClick={connectWalletModalService.handleModalClose}
          />
        </div>
        <div className="font-bold text-2xl mb-4">Connect wallet with</div>
        <div className="flex flex-col justify-around w-full [&>*]:cursor-pointer gap-3">
          <div
            className="bg-[#DDDDDD33] rounded-20 w-full h-12 flex items-center justify-center hover:opacity-90"
            onClick={handleConnectMetamask}
          >
            Metamask
          </div>
          <div
            className="bg-[#DDDDDD33] rounded-20 w-full h-12 flex items-center justify-center hover:opacity-90"
            onClick={handleConnectWallet}
          >
            WalletConnect
          </div>
        </div>
      </div>
    </Modal>
  );
}
