import {useEffect} from 'react';
import {useWindowResize} from '../../hooks/useWindowResize';
import {levelsService} from '../../services/levelsApi';
import {LevelStore, useLevelsStore} from '../AccountLevel/store/useLevelsStore';
import {useUserStore} from '../AccountProgress/store/useUserStore';
import {AccountRoadmapItemView} from './AccountRoadmapItemView';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {getSlicedLevels} from '../../utilities/getSlicedLevels';

interface AccountRoadmapProps {
  isLevelsVisible: boolean;
  handleLevelsVisible?: () => void;
}

export const AccountRoadmapView = ({
  isLevelsVisible,
  handleLevelsVisible,
}: AccountRoadmapProps) => {
  const {isAdaptive} = useWindowResize(1280);
  const {levels} = useLevelsStore();
  const {user} = useUserStore();

  useEffect(() => {
    if (!levels?.length) {
      void levelsService.getLevelsData();
    }
  }, []);

  return (
    <div className="h-fit rounded-tl-[1.25rem] rounded-tr-[1.25rem] rounded-none bottom-0 left-0 bg-[#0B0D0E] bg-transparent w-full relative after:hidden xl:after:block after:w-full after:top-0 after:left-0 after:pointer-events-none after:absolute after:h-11 after:bg-account-panel-gradient">
      {isAdaptive && (
        <div className="bg-[#0B0D0E]">
          <div className="text-2xl px-5 font-bold pb-6 border-solid border-b-[1px] border-l-50 rounded-tr-[1rem] rounded-tl-[1rem]">
            Levels
          </div>
        </div>
      )}
      {!user ? (
        <div className="px-4 pt-8 max-h-[28rem] overflow-hidden">
          {Array.from({length: 12}).map((_, skeletonIndex) => (
            <Skeleton
              style={{marginBottom: 10}}
              height={90}
              key={skeletonIndex}
            />
          ))}
        </div>
      ) : (
        <div className="xl:max-h-[28rem] max-h-full overflow-y-scroll invisible-scrollbar">
          <div className="hidden xl:block h-[30px] bg-green-200 w-[1px] ml-[36px]"></div>
          <ul className="px-4 flex flex-col-reverse xl:flex-col pb-0 xl:pb-8 gap-[5px] overflow-y-scroll xl:overflow-y-auto max-h-[500px] xl:max-h-full">
            {getSlicedLevels(levels || [], user)?.map((level: LevelStore) => (
              <AccountRoadmapItemView key={level.value} level={level} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
