import {useNavigate} from 'react-router-dom';
import {useUserStore} from '../modules/AccountProgress/store/useUserStore';
import {useStepsStore} from '../modules/Login/store/useStepsStore';
import {Button} from './Button';
import Skeleton from 'react-loading-skeleton';
import {useSignUpStore} from '../modules/Login/store/useSignUpStore';

interface Props {
  hidden: boolean;
}

export const AccountSetupProgress = ({hidden}: Props) => {
  const navigate = useNavigate();
  const {stepsArray, setSteps, setActiveStep} = useStepsStore();
  const {user} = useUserStore();
  const {setIsNew} = useSignUpStore();

  const handleRedirect = () => {
    setActiveStep(1);
    setSteps(stepsArray.filter(item => item.id !== 0) ?? []);
    setIsNew(false);
    navigate('/registration');
  };

  if ((user?.profileSetupProgress || 0) >= 100) {
    return null;
  }

  return (
    <>
      {!user ? (
        <Skeleton height={190} />
      ) : (
        <div
          className={`bg-green-200 rounded-xl text-black p-4 ${
            hidden ? 'hidden' : 'block'
          }`}
        >
          <div className="flex justify-between items-end flex-wrap mb-[14px]">
            <span className="text-sm font-semibold leading-6">
              Account setup progress
            </span>
            <div className="uppercase text-[26px] leading-5 font-mono tracking-[-0.5344px]">
              {user.profileSetupProgress ? user.profileSetupProgress : 0}
              <span className="text-[13px] leading-[-0.267px]">%</span>
            </div>
          </div>
          <div className="w-full relative rounded-[5px] bg-[rgba(0,0,0,.2)] h-1 mb-[10px]">
            <div
              className="bg-black rounded-[5px] absolute left-0 top-0 h-full"
              style={{width: `${user.profileSetupProgress ?? 0}%`}}
            ></div>
          </div>
          <p className="text-xs leading-[18px] opacity-60 mb-5">
            Finish setup to unlock full Raiser features & functions
          </p>
          <Button
            className="w-full h-[40px] bg-black text-green-200 hover:bg-white hover:text-black"
            text="FINISH setup"
            onClick={handleRedirect}
          />
        </div>
      )}
    </>
  );
};
