import {useEffect, useRef, useState} from 'react';
import {IProjectFCO} from '../../../types/project';
import dropdownArrow from '../../../assets/dropdownArrow.svg';
import {Link} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {NextArrow} from '../../../assets/svgComponents/NextArrow';
import {TooltipInfoIcon} from '../../../components/Tooltip/TooltipInfoIcon';
import {AllocationsList} from './AllocationsList';
import {useVestingContract} from '../hooks/useVestingContract/useVestingContract';
import {ClaimButton} from './ClaimButton';
import {claimModalService} from './ClaimModal/service/service';

export const ProjectFCO: React.FC<{project: IProjectFCO}> = ({project}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen]);

  const onToggleContent = () => {
    setIsOpen(state => !state);
  };

  const userAddress = project.projectRounds[0].boughtAllocations[0].recipient;
  const contractAddress = project.vestingContractAddress || '';
  const {
    claimableTokens,
    claimedTokens,
    purchasedTokens,
    vestedAmount,
    claimedPercent,
    claim,
  } = useVestingContract(
    contractAddress,
    userAddress,
    {
      chainId: project.network.chainId,
      chainName: project.network.name,
    },
    project.network.rpcUrl
  );

  const handleClaimModalOpen = () => {
    claimModalService.setSelectedAllocation({
      claimableTokensAmount: claimableTokens,
      claimedTokensAmount: claimedTokens,
      vestedTokensAmount: vestedAmount,
      tokensAmount: purchasedTokens,
      token: project.token,
      allocationToken: project.allocationToken,
      tokenPrice: project.projectRounds[0].tokenPrice || 1,
      userWallet: userAddress,
      claim,
    });
  };

  return (
    <div className="border-b-[1px] border-l-50 rounded-20 bg-[#111213] border-solid last:border-none transition-all w-full">
      <div className="flex flex-col xl:flex-row justify-between xl:items-center gap-5 xl:gap-9 w-full text-left text-sm p-5 xl:p-6">
        <div className="flex items-center gap-5 h-[48px] w-[48px] xl:h-[76px] xl:w-[76px]">
          <img
            className="rounded-full flex-shrink-0 h-[48px] w-[48px] xl:h-[76px] xl:w-[76px] p-1 bg-[#1A1C1E]"
            src={project.imageUrl}
          />
          <h1 className="font-sans xl:hidden text-xl xl:text-2xl leading-8 xl:leading-9 font-semibold mb-2">
            {project.name}
          </h1>
        </div>
        <div className="flex-1 min-w-[230px]">
          <h1 className="font-sans hidden xl:block text-xl xl:text-2xl leading-8 xl:leading-9 font-semibold mb-2">
            {project.name}
          </h1>
          <div className="font-mono uppercase flex items-center gap-3">
            <div className="flex gap-[6px]">
              <div className="text-l-400">type</div>
              <div className="font-bold">{project.type}</div>
            </div>
            <span className="w-[3px] h-[3px] bg-white opacity-40 rounded-full"></span>
            <div className="flex items-center gap-[6px]">
              <div className="text-l-400">chain</div>
              <div className="flex items-center gap-2">
                <img
                  className="rounded-full h-5 w-5"
                  src={project.network.iconUrl}
                />
                <div className="font-bold break-inside-auto">
                  {project.network.name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex uppercase gap-1 mb-2 opacity-40">
            <div className="text-white text-xs leading-6 flex-shrink-0">
              rounds you participated in
            </div>
            <div className="flex-shrink-0">
              <TooltipInfoIcon content="" />
            </div>
          </div>

          <div className="flex flex-wrap gap-[6px]">
            {project.projectRounds.slice(0, 2).map(({title}, i) => (
              <div
                className={`w-fit flex-shrink-0 font-mono text-nowrap text-xs flex items-center px-2.5 py-[5px] rounded-full h-7 ${
                  i ? 'bg-[#FD5573]' : 'bg-[#E5FD55] text-black'
                }`}
              >
                {title}
              </div>
            ))}
            {project.projectRounds.length > 2 && (
              <div
                className={
                  'w-fit font-mono flex items-center px-2.5 py-[5px] rounded-full h-7 bg-[#3B3D3E] text-xs'
                }
              >
                {project.projectRounds.length - 2} more
              </div>
            )}
          </div>
        </div>
        <div className="pt-4 border-t border-[#1D1E1E] xl:border-0 xl:mb-0 xl:pt-0 flex gap-4 items-center">
          <Link
            to={`/dashboard/project/${project.id}`}
            className="relative w-full xl:w-auto"
          >
            <Button
              text="view launch"
              className="font-bold pr-10 !h-[3rem] w-full xl:w-[181px] min-w-[181px] text-sm [&&]:bg-transparent border border-l-200 text-white"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-[calc(50%+2.5rem)] xl:right-10">
              <NextArrow width={24} height={4} fill="white" />
            </div>
          </Link>
          <button
            className="w-9 h-9 min-w-9 flex-shrink-0 bg-l-200 rounded-full flex items-center justify-center"
            onClick={onToggleContent}
          >
            <img
              className={`${isOpen ? 'rotate-180' : 'rotate-0'} transition-all`}
              src={dropdownArrow}
              alt="dropdownArrow"
            />
          </button>
        </div>
      </div>

      <div
        className="bg-[#1A1C1E] mt-2 text-sm xl:text-base text-white leading-5 rounded-b-20"
        style={{
          overflow: 'hidden',
          maxHeight: contentHeight + 'px',
          transition: 'max-height .3s ease-in-out',
        }}
        ref={contentRef}
      >
        <div className="px-6">
          <div className="py-5 border-b gap-5 border-l-100 flex xl:justify-between xl:items-center flex-col xl:flex-row font-mono">
            <div className="flex flex-col uppercase">
              <div className="flex gap-1 items-center mb-[10px] opacity-40">
                <div className="text-white text-xs leading-6">Vesting</div>
                <TooltipInfoIcon content="" />
              </div>
              <div className="flex text-xs">
                <div>{project.vestingDescription || 'TBA'}</div>
              </div>
            </div>
            <ClaimButton
              onClick={handleClaimModalOpen}
              targetDate={project.vestingStartDate}
              requiredWallet={userAddress}
              requiredNetwork={project.network.chainId}
              vestingContract={contractAddress}
            />
          </div>
          <AllocationsList
            projectRounds={project.projectRounds}
            allocationTokenSymbol={project.allocationToken.symbol}
            tokenSymbol={project.token.symbol}
            tokenIconUrl={project.token.iconUrl}
            claimedTokensPercent={claimedPercent}
          />
        </div>
      </div>
    </div>
  );
};
