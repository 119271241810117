import {SOCIAL_ICONS} from '..';
import {Project} from '../../../types/project';

interface ProjectSocialsProps {
  project: Project;
}

export const ProjectSocials = ({project}: ProjectSocialsProps) => {
  return (
    <div className="flex items-center gap-2">
      {Object.entries(project.socials).map(([key, value]) => (
        <a
          href={value}
          key={key}
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center w-8 h-8"
        >
          {SOCIAL_ICONS[key]}
        </a>
      ))}
    </div>
  );
};
