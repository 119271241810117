import {create, useStore} from 'zustand';

interface SignInStore {
  emailSignIn: string;
  messageFromRequestSignIn: string;
  isLoading: boolean;
  isLoadingAuth: boolean;
  setEmailSignIn: (emailSignIn: string) => void;
  setMessageFromRequestSignIn: (messageFromRequestSignIn: string) => void;
}

const initialState: SignInStore = {
  emailSignIn: '',
  messageFromRequestSignIn: '',
  isLoading: false,
  isLoadingAuth: false,
  setEmailSignIn: () => {},
  setMessageFromRequestSignIn: () => {},
};

export const signInStore = create<SignInStore>(set => ({
  ...initialState,
  setEmailSignIn: newEmail => set({emailSignIn: newEmail}),
  setMessageFromRequestSignIn: (message: string) => {
    set({messageFromRequestSignIn: message});
  },
}));

export const useSignInStore = () => useStore(signInStore);
