import {connectWalletModalStore} from './store';

const connectWalletModalApi = () => {
  const {setState} = connectWalletModalStore;
  const handleModalOpen = () => {
    setState({
      isOpened: true,
    });
  };
  const handleModalClose = () => {
    setState({isOpened: false});
  };

  return {
    handleModalOpen,
    handleModalClose,
  };
};

export const connectWalletModalService = connectWalletModalApi();
