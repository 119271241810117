import React, {PropsWithChildren} from 'react';
import {NextGameTimer} from './NextGameTimer';

export const FinishedWoodleFooterWrapper: React.FC<
  PropsWithChildren<{title: string; details: string}>
> = ({children, title, details}) => {
  return (
    <div className="flex flex-col items-center gap-[28px]">
      <div className="flex flex-col gap-3 items-center">
        <div className="text-2xl leading-9 text-center font-sans font-bold text-white">
          {title}
        </div>
        <div className="text-sm leading-6 text-center text-l-600 font-sans">
          {details}
        </div>
        <NextGameTimer />
      </div>
      {children}
    </div>
  );
};
