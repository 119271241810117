import {useEffect} from 'react';
import {notificationsService} from './service';
import {useNotificationsStore} from './store';
import {UserNotification} from './components/UserNotification';
import {NotificationsEmpty} from './components/NotificationsEmpty';

export const Notifications = () => {
  const {notifications} = useNotificationsStore();

  useEffect(() => {
    notificationsService.getNotificationsList();
  }, []);

  const isNotificationsExists = !!notifications.length;

  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div className="font-semibold text-sm leading-6">Notifications</div>
        {isNotificationsExists && (
          <div
            className="font-mono font-bold text-green-200 text-xs cursor-pointer hover:underline"
            onClick={notificationsService.markAllNotificationsAsRead}
          >
            Mark as read
          </div>
        )}
      </div>
      <div>
        {isNotificationsExists ? (
          notifications.map(notification => (
            <UserNotification
              type={notification.templateIdentifier}
              key={notification._id}
              notification={notification}
            />
          ))
        ) : (
          <NotificationsEmpty />
        )}
      </div>
    </>
  );
};
