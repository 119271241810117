import React, {ChangeEvent, useEffect, useMemo} from 'react';
import {BackButton} from '../../assets/svgComponents/BackButton';
import {Button} from '../../components/Button';
import {SkipButton} from '../../components/SkipButton';
import {ENGLISH_LETTERS_REGEX} from '../../constants/regularExpression';
import {
  stepsContentHeadingFlexboxClasses,
  stepsMainHeadingClasses,
} from '../../constants/stepsLayoutVariables';
import {signUpService} from '../../services/SignUp';
import {useUserStore} from '../AccountProgress/store/useUserStore';
import {useSignUpStore} from '../Login/store/useSignUpStore';
import {useStepsStore} from '../Login/store/useStepsStore';
import {
  handleBackStep,
  handleNextStep,
  handleSkipStep,
} from '../Login/helpers/handleSteps';

export const NameView = () => {
  const {
    firstName,
    lastName,
    setFirstName,
    setLastName,
    isActiveButton,
    setIsActiveButton,
    loading,
  } = useSignUpStore();
  const {activeStep, stepsArray} = useStepsStore();
  const {user} = useUserStore();

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const containsOnlyEnglishLetters = ENGLISH_LETTERS_REGEX.test(inputValue);

    if (containsOnlyEnglishLetters || inputValue === '') {
      setFirstName(inputValue);
    }
  };

  const handleNextStepClick = async () => {
    const response = await signUpService.sendNameSurnameRequest();

    if (response?.status === 200) {
      handleNextStep();
    }
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const containsOnlyEnglishLetters = ENGLISH_LETTERS_REGEX.test(inputValue);

    if (containsOnlyEnglishLetters || inputValue === '') {
      setLastName(inputValue);
    }
  };

  const isPrevExist = useMemo(() => {
    return stepsArray.find(step => step.id === activeStep - 1) !== undefined;
  }, [stepsArray, activeStep]);

  const handleBackStepClick = () => {
    if (!isPrevExist) return;
    handleBackStep();
  };

  const handleNameSkip = () => {
    handleSkipStep();
  };

  useEffect(() => {
    if (user) {
      setFirstName(user.profile.name);
      setLastName(user.profile.surname);
    }
  }, [user]);

  useEffect(() => {
    setIsActiveButton(!!firstName && !!lastName);
  }, [firstName, lastName, setIsActiveButton]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className={stepsContentHeadingFlexboxClasses}>
        <span className={stepsMainHeadingClasses}>What’s Your Name?</span>
      </div>
      <div className="w-full lg:w-[25.875rem] pt-[30px] lg:pt-[40px]">
        <div className="pb-[6px]">
          <span className="uppercase text-[12px] leading-[20px] text-white">
            First name
          </span>
        </div>
        <input
          type="text"
          value={firstName}
          placeholder="Enter your first name"
          className="px-5 py-[15px] w-full focus:outline-none border-[#535456] border-[1px] font-mono text-[14px] font-normal bg-[#0A0B0D] text-white rounded-[12px] w-[414px] placeholder-[#535456]"
          onChange={handleFirstNameChange}
        />
        <div className="pt-[22px] lg:pt-[24px] pb-[6px]">
          <span className="uppercase text-[12px] leading-[20px] text-white">
            Last name
          </span>
        </div>
        <input
          type="text"
          value={lastName}
          placeholder="Enter your last name"
          className="px-5 w-full py-[15px] focus:outline-none font-mono text-[14px] font-normal bg-[#0A0B0D] text-white  border-[#535456] border-[1px] rounded-[12px] w-[414px] placeholder-[#535456]"
          onChange={handleLastNameChange}
        />
      </div>
      <div className="flex relative w-full lg:w-fit justify-center  items-center text-center pt-[71px] lg:pt-[96px]">
        {isPrevExist && (
          <div
            className="pr-[10px] cursor-pointer"
            onClick={handleBackStepClick}
          >
            <BackButton />
          </div>
        )}
        <Button
          loading={loading}
          className="flex-1 lg:w-[356px] h-12"
          text="Next step"
          onClick={handleNextStepClick}
          disabled={!isActiveButton}
          withArrow
        />
      </div>
      <SkipButton text="skip step" onClick={handleNameSkip} />
    </div>
  );
};
