import React from 'react';

export const NotificationTwitterIcon = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.4573 7.794L16.1532 1.125H14.8032L9.85881 6.91537L5.90781 1.125H1.35156L7.32531 9.882L1.35156 16.875H2.70156L7.92381 10.7595L12.0964 16.875H16.6527L10.4573 7.794ZM8.60894 9.9585L8.00369 9.08662L3.18756 2.14875H5.26094L9.14669 7.74788L9.75194 8.61975L14.8043 15.8985H12.7309L8.60894 9.9585Z"
        fill="white"
      />
    </svg>
  );
};
