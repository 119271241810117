import {QuizAnswerState} from './types';

export const radioButtonProps: Record<
  QuizAnswerState,
  {
    outlineColor: string;
    circleColor: string;
  }
> = {
  [QuizAnswerState.SELECTED]: {
    outlineColor: '#E5FD55',
    circleColor: '#E5FD55',
  },
  [QuizAnswerState.CORRECT]: {outlineColor: '#61B60C', circleColor: '#61B60C'},
  [QuizAnswerState.INCORRECT]: {
    outlineColor: '#FB494966',
    circleColor: '#FB494966',
  },
  [QuizAnswerState.NOT_SELECTED]: {
    outlineColor: '#232526',
    circleColor: 'transparent',
  },
  [QuizAnswerState.NOT_SELECTED_CORRECT]: {
    outlineColor: 'yellow',
    circleColor: 'yellow',
  },
};
