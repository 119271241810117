import classNames from 'classnames';
import {TooltipInfoIcon} from '../../../../components/Tooltip/TooltipInfoIcon';
import {roundNumber} from '../../../../utilities/roundNumber';

export const TokenCard = ({
  title,
  tooltip,
  amount,
  icon,
  symbol,
  greenBorder = false,
  greenText = false,
}: {
  title: string;
  tooltip: string;
  amount: number;
  icon: string;
  symbol: string;
  greenBorder?: boolean;
  greenText?: boolean;
}) => {
  return (
    <div
      className={classNames(
        'flex-1 w-full flex flex-col uppercase p-5 bg-[#1C1E20] border border-l-300 rounded-2xl',
        greenBorder && 'border-[#E5FD55]'
      )}
    >
      <div className="flex gap-1 items-center mb-5 opacity-40">
        <div className="text-white text-xs leading-6">{title}</div>
        <TooltipInfoIcon content={tooltip} />
      </div>
      <div className="flex text-lg leading-6 font-bold mb-1">
        <div className="mr-[10px]">
          <img className="rounded-full h-6 w-6" src={icon} />
        </div>
        <div className={classNames(greenText && 'text-[#E5FD55]')}>
          {roundNumber(amount)}&nbsp;
        </div>
        <div>{symbol}</div>
      </div>
    </div>
  );
};
