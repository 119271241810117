import {
  IMyTask,
  ITask,
  MyTaskStatus,
  TaskType,
  UserSnapshotTaskStatusesEnum,
} from '../../../../types/tasks';
import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {handleExternalLinkOpen} from '../../../../utilities/handleExternalLinkOpen';
import {tasksService} from '../../service/TasksService';
import {HandleQuizButton} from './HandleQuizButton';
import {HandleTwitterButton} from './HandleTwitterButton';
import {HandleReferalButton} from './HandleReferalButton';
import {HandleTelegramButton} from './HandleTelegramButton';
import {HandleWalletRelatedTaskButton} from './HandleWalletRelatedTaskButton';
import {HandleDiscordButton} from './HandleDiscordButton';
import {HandleWoodleButton} from './HandleWoodleButton';
import {HandleFinishedQuizButton} from './HandleFinishedQuizButton';

export interface ITaskActionButtonProps {
  task: ITask;
  myTaskInfo: Partial<
    Pick<
      IMyTask,
      'userSnapshotResult' | 'userQuizResult' | 'userWodlResult' | 'userTaskId'
    >
  >;
}

export const TaskActionButton = ({
  task,
  myTaskInfo,
}: ITaskActionButtonProps) => {
  const {
    status,
    rewardPointsAmount,
    type,
    quiz,
    twitterTask,
    id,
    redirectUrl,
    rewardTicketsAmount,
    wodl,
    maxSuccessfullyCompletions,
  } = task;

  const {userQuizResult, userSnapshotResult, userWodlResult} = myTaskInfo;

  const isQuizTask = type === TaskType.QUIZ;
  const isQuizButtonShown =
    isQuizTask && quiz?.id && status !== MyTaskStatus.DONE;

  const isOpenFinishedQuizButtonShown =
    isQuizTask &&
    quiz?.id &&
    status === MyTaskStatus.DONE &&
    myTaskInfo.userTaskId &&
    task.maxSuccessfullyCompletions;

  const isTwitterButtonShown =
    type === TaskType.twitter && twitterTask && status !== MyTaskStatus.DONE;

  const isSnapshotTask = type === TaskType.snapshot;
  const isSnapshotTaskButtonShown =
    redirectUrl &&
    isSnapshotTask &&
    userSnapshotResult?.status !== UserSnapshotTaskStatusesEnum.DONE;

  const isReferalTask = type === TaskType.referral;
  const isReferalButtonShown = isReferalTask && status !== MyTaskStatus.DONE;

  const isTelegramButtonShown =
    type === TaskType.telegram && redirectUrl && status !== MyTaskStatus.DONE;

  const isNftButtonShown = type === TaskType.nft && !status;

  const isGitButtonShown = type === TaskType.gitcoin && !status;

  const isDiscordButtonShown =
    type === TaskType.discord && redirectUrl && status !== MyTaskStatus.DONE;

  const isWoodlButtonShown =
    type === TaskType.woodle && status !== MyTaskStatus.DONE;

  return (
    <>
      {isTwitterButtonShown && (
        <HandleTwitterButton
          twitterTask={twitterTask}
          id={id}
          status={status}
        />
      )}
      {isQuizButtonShown && (
        <HandleQuizButton
          userQuizResult={userQuizResult}
          quiz={quiz}
          id={id}
          rewardPointsAmount={rewardPointsAmount}
          rewardTicketsAmount={rewardTicketsAmount}
          maxSuccessfullyCompletions={maxSuccessfullyCompletions}
          userTaskID={myTaskInfo.userTaskId}
        />
      )}
      {isSnapshotTaskButtonShown && (
        <div
          className={
            'flex items-center gap-[10px] text-xs font-bold uppercase leading-3 text-green-200 hover:underline cursor-pointer'
          }
          onClick={async () => {
            handleExternalLinkOpen(redirectUrl);

            if (status !== MyTaskStatus.IN_PROGRESS) {
              await tasksService.startTask(id);
              void tasksService.getAllTasks();
            }
          }}
        >
          Start
          <NextArrow width={24} height={4} fill="#E5FD55" />
        </div>
      )}
      {isReferalButtonShown && <HandleReferalButton id={id} status={status} />}

      {isTelegramButtonShown && (
        <HandleTelegramButton
          resourceId={redirectUrl}
          id={id}
          status={status}
        />
      )}
      {isNftButtonShown && (
        <HandleWalletRelatedTaskButton id={id} status={status} />
      )}

      {isGitButtonShown && (
        <HandleWalletRelatedTaskButton id={id} status={status} />
      )}

      {isDiscordButtonShown && (
        <HandleDiscordButton resourceId={redirectUrl} id={id} status={status} />
      )}
      {isWoodlButtonShown && (
        <HandleWoodleButton
          id={id}
          status={userWodlResult?.status}
          userWodlResult={userWodlResult}
          wodl={wodl}
          rewardPointsAmount={rewardPointsAmount}
          rewardTicketsAmount={rewardTicketsAmount}
        />
      )}

      {isOpenFinishedQuizButtonShown && (
        <HandleFinishedQuizButton
          quizID={quiz.id}
          questionsAmount={quiz.questionsCount}
          userTaskID={myTaskInfo.userTaskId!}
        />
      )}
    </>
  );
};
