import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import OTPInput from 'react-otp-input';
import logoIcon from '../../assets/logoIcon.svg';
import {signInStore, useSignInStore} from './store/useSignInStore';
import {useSignUpStore} from './store/useSignUpStore';
import {signInService} from '../../services/SignIn';
import {isCodeValid} from './helpers/isCodeValid';
import {stepsMainHeadingClasses} from '../../constants/stepsLayoutVariables';
import {ResendCodeTimer} from './components/ResendCodeTimer';
import {Button} from '../../components/Button';
import {BackArrow} from '../../assets/svgComponents/BackArrow';

export const VerificationCodeView = () => {
  const {code, setCode, isActiveButton, setIsActiveButton} = useSignUpStore();
  const {
    emailSignIn,
    messageFromRequestSignIn,
    isLoading,
    setMessageFromRequestSignIn,
  } = useSignInStore();

  const [showErrorVerifCode, setShowErrorVerifCode] = useState<boolean>(false);

  const lastOtpInputRef = useRef<HTMLElement | null>(null);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');

    signInStore.setState({
      messageFromRequestSignIn: '',
      emailSignIn: '',
    });
  };

  const handleNextClickLogin: () => Promise<void> = useCallback(async () => {
    if (!showErrorVerifCode) {
      try {
        await signInService.sendOtpLoginRequest(navigate);
      } catch (error) {
        setShowErrorVerifCode(!showErrorVerifCode);
      }
    }
  }, [showErrorVerifCode, navigate]);

  const handleCodeChange = (otpValue: string) => {
    setShowErrorVerifCode(false);
    setMessageFromRequestSignIn('');
    setCode(otpValue);
  };

  useEffect(
    () => () => {
      setCode('');
    },
    [setCode]
  );

  useEffect(() => {
    setIsActiveButton(isCodeValid(code));
  }, [code, setIsActiveButton]);

  useEffect(() => {
    if (isActiveButton) {
      lastOtpInputRef.current?.blur();
      void handleNextClickLogin();
    }
  }, [isActiveButton, handleNextClickLogin]);

  return (
    <div className="flex mx-auto w-full relative max-w-[calc(414px+40px)] self-center sm:self-auto md:px-5 pb-4 pt-[130px] md:pt-0">
      <div className=" md:hidden block absolute top-4 max-w-[102px]">
        <img className="" src={logoIcon} alt="raiser" />
      </div>
      <div className="flex flex-col w-full mx-auto lg:justify-start">
        <div className="flex relative flex-col items-center justify-center">
          <div
            className="w-full flex justify-start lg:self-baseline cursor-pointer"
            onClick={handleBackClick}
          >
            <div className="flex self-center pr-[16px] pb-[2px]">
              <BackArrow />
            </div>
            <span className="font-mono text-base lg:text-[16px] hover:text-opacity-70 font-bold text-[#9c9c9d] uppercase">
              back to sign in
            </span>
          </div>
          <div className="flex w-full text-left sm:justify-center lg:self-baseline">
            <div className="pt-[20px] w-full">
              <span className={stepsMainHeadingClasses}>
                Enter Verification <br></br> Code
              </span>
            </div>
          </div>
          <div className="flex w-full justify-start lg:self-baseline">
            <span className="text-white text-opacity-60 text-xs md:text-[14px] font-normal pt-[20px] font-mono leading-[21px]">
              The code was sent to{' '}
              <span className="text-white font-mono text-[14px] leading-[21px]">
                {emailSignIn || 'your@email.com'}
              </span>
            </span>
          </div>
          <OTPInput
            renderInput={({ref, ...inputProps}, index) => {
              return (
                <input
                  ref={el => {
                    if (index === 5) {
                      lastOtpInputRef.current = el;
                    }
                    ref(el);
                  }}
                  {...inputProps}
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className={`text-center text-lg lg:text-[28px] selection:bg-none border-[#515253] bg-black text-white
                 rounded-[12px] w-full font-mono aspect-square lg:w-[59px]
                border ${
                  showErrorVerifCode || messageFromRequestSignIn
                    ? 'border-red-500 placeholder-red-500'
                    : ''
                } focus:outline-none`}
                />
              );
            }}
            onChange={handleCodeChange}
            value={code}
            numInputs={6}
            containerStyle={
              '!grid grid-cols-6 md:flex gap-2 sm:gap-3 pt-[48px] sm:justify-center lg:self-baseline w-full'
            }
            shouldAutoFocus
            skipDefaultStyles
          />
          {messageFromRequestSignIn ? (
            <div className="flex self-baseline sm:self-center lg:self-baseline pt-[6px]">
              <span className="text-[#ca3c3c] text-[12px] font-mono font-normal leading-[20px]">
                {messageFromRequestSignIn}
              </span>
            </div>
          ) : null}
          <ResendCodeTimer />
          <div className="rounded-[42px] mt-[48px] w-full">
            <div className="relative justify-center items-center text-center">
              <Button
                loading={isLoading}
                className="w-full"
                text="Enter"
                onClick={handleNextClickLogin}
                disabled={!isActiveButton}
                withArrow
                bigger
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
