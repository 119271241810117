// import {RatingArrowDown} from '../../../assets/svgComponents/RatingArrowDown';
// import {RatingArrowUp} from '../../../assets/svgComponents/RatingArrowUp';

// NOTE: Commented code will be used in future. Don't remove it
export function PositionLabel({
  position,
  //   positionChange = 0,
  personal = false,
}: {
  position: number | string;
  positionChange?: number;
  personal?: boolean;
}) {
  return (
    <div className="flex items-center bg-[#262729] rounded-[28px] w-fit px-2 text-xs leading-6">
      {/* <span className="mr-[11px]">
        {positionChange === 0 ? (
          <>—</>
        ) : positionChange > 0 ? (
          <RatingArrowUp />
        ) : (
          <RatingArrowDown />
        )}
      </span> */}
      {position}
      <div className="text-[#636363]">{personal && <>&nbsp;YOU</>}</div>
    </div>
  );
}
