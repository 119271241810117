import {memo, useState} from 'react';
import {
  IMyTask,
  ITask,
  IUserQuizResult,
  MyTaskStatus,
  TaskType,
  UserSnapshotResult,
  UserSnapshotTaskStatusesEnum,
  WoodleResult,
} from '../../../types/tasks';
import {taskIcon} from '../constants';
import {isIMyTask} from '../helpers/isIMyTask';
import {TaskStatus} from './TaskStatus';
import {QuizApproximateTime} from './QuizApproximateTime';
import {DescriptionToggleButton} from './DescriptionToggleButton';
import {FileUploader} from './FileUploader';
import {TaskActionButton} from './TaskActionButtons/TaskActionButton';
import {TaskDetails} from './TaskDetails';
import {useDashboardStore} from '../../Dashboard';

export const Task = memo(
  ({
    task,
    taskRowDirection,
  }: {
    task: ITask | IMyTask;
    taskRowDirection: boolean;
  }) => {
    const {
      status,
      rewardPointsAmount,
      type,
      title,
      description,
      quiz,
      twitterTask,
      id,
      inviteCount,
      rewardTicketsAmount,
      wodl,
    } = task;
    const {invitedCount} = useDashboardStore();

    let userQuizResult: IUserQuizResult | undefined;
    let userTaskId: string | undefined;
    let userSnapshotResult: UserSnapshotResult | undefined;
    let userWodlResult: WoodleResult | undefined;

    if (isIMyTask(task)) {
      userTaskId = task.userTaskId;

      if (task.userQuizResult) {
        userQuizResult = task.userQuizResult;
      }
      if (task.userSnapshotResult) {
        userSnapshotResult = task.userSnapshotResult;
      }

      if (task.userWodlResult) {
        userWodlResult = task.userWodlResult;
      }
    }

    const [isTaskCollapsed, setIsTaskCollapsed] = useState(true);
    const toggleTaskCollapsed = () => {
      setIsTaskCollapsed(!isTaskCollapsed);
    };

    const isQuizTask = type === TaskType.QUIZ;

    const isSnapshotTask = type === TaskType.snapshot;

    // CRUTCH
    const isFileUploaderHidden =
      task.title === 'Register to Pixelverse Dashboard and reach level 2 there';

    const isFileUploaderShown =
      !isFileUploaderHidden &&
      isSnapshotTask &&
      userSnapshotResult?.status &&
      userSnapshotResult.status !== UserSnapshotTaskStatusesEnum.DONE;

    const isFilesSubmitPending =
      userSnapshotResult?.status === UserSnapshotTaskStatusesEnum.REVIEW;

    const isFileRejected =
      userSnapshotResult?.status === UserSnapshotTaskStatusesEnum.REJECTED;

    const isReferralDescriptionShown =
      type === TaskType.referral && status === MyTaskStatus.IN_PROGRESS;

    return (
      <li
        className={`pb-5 relative flex gap-4 flex-col border-solid border-l-100 border-b-[1px] last:border-none ${
          taskRowDirection ? 'md:flex-row' : 'md:flex-col'
        }`}
      >
        <div>
          <div className="w-10 h-10 bg-l-100 rounded-lg flex justify-center items-center flex-shrink-0">
            <img className=" p-1" src={taskIcon[type]} alt="socialIcon" />
          </div>
        </div>
        <div className="max-w-[30rem] w-full 2xl:max-w-[40rem]">
          <div className="mb-5">
            <div className="text-base font-bold mb-2.5">{title}</div>
            {!!wodl?.topic && (
              <span className="inline-flex gap-2 items-center justify-center font-mono text-xs leading-6 uppercase">
                <span className="text-l-400">Topic</span>
                <span className="font-bold truncate max-w-[150px]">
                  {wodl.topic}
                </span>
              </span>
            )}
            <div className="xl:absolute top-0 right-0 w-full flex-wrap xl:pl-56 mb-2">
              <TaskStatus
                quiz={quiz}
                userQuizResult={userQuizResult}
                rewardPointsAmount={rewardPointsAmount}
                wodl={wodl}
                userWodlResult={userWodlResult}
                type={type}
                twitterTask={twitterTask}
                status={status}
                userTaskId={userTaskId}
                rewardTicketsAmount={rewardTicketsAmount}
              />
            </div>
            {isQuizTask && (
              <div className="flex items-center gap-y-1 gap-x-6 flex-wrap mb-2">
                {quiz && quiz.approximateTime > 0 && (
                  <QuizApproximateTime quiz={quiz} />
                )}

                <div className="uppercase text-xs font-mono flex items-center gap-2">
                  <span className="opacity-40">questions</span>

                  <span className="font-bold">{quiz?.questionsCount}</span>
                </div>
              </div>
            )}
            {isFileUploaderShown && (
              <FileUploader
                taskId={id}
                isRejected={isFileRejected}
                isPending={isFilesSubmitPending}
              />
            )}
            {isReferralDescriptionShown && (
              <TaskDetails
                inviteCount={inviteCount}
                invitedAmount={invitedCount}
              />
            )}
            <p
              style={{
                wordBreak: 'break-word',
              }}
              className={`text-xs text-l-600 leading-5 ${
                isTaskCollapsed ? 'line-clamp-2' : ''
              }`}
            >
              {description}
            </p>
          </div>

          <div className="flex flex-row-reverse flex-wrap justify-between items-center gap-y-4 relative min-h-[1rem]">
            <TaskActionButton
              task={task}
              myTaskInfo={{
                userQuizResult,
                userSnapshotResult,
                userWodlResult,
                userTaskId,
              }}
            />

            {description.length > 100 ? (
              <DescriptionToggleButton
                isTaskCollapsed={isTaskCollapsed}
                toggleTaskCollapsed={toggleTaskCollapsed}
              />
            ) : null}
          </div>
        </div>
      </li>
    );
  }
);
