import {Link, useLocation} from 'react-router-dom';
import walletIcon from '../assets/blackWalletIcon.svg';
import {Button} from './Button';
import {BurgerButton} from './burgerButton';
import classNames from 'classnames';
import {useWindowScroll} from '../hooks/useWindowScroll';
import {useWindowResize} from '../hooks/useWindowResize';
import {LogoutButton} from './LogoutButton';
import {getHeaderNavigationIcon} from '../utilities/getHeaderNavigationIcon';
import {BackwardNavigation} from './HeaderContent/BackwardNavigation';
import projectLogo from '../assets/sidebarLogo.svg';
import {bindWalletModalService} from '../modules/BindWalletModal/service';

import {useUserStore} from '../modules/AccountProgress/store/useUserStore';
import {UserWallet} from './UserWallet';
import {ActivityFeed} from '../modules/ActivityFeed';

export interface HeaderProps {
  isOpened: boolean;
  handleTranslateSidebar: () => void;
  backwardNavigation?: {
    currentLocationName: string;
    targetLocationName: string;
    targetLocationPath: string;
  };
  logo?: boolean;
}

export const Header = ({
  isOpened,
  handleTranslateSidebar,
  backwardNavigation,
  logo = false,
}: HeaderProps) => {
  const {user} = useUserStore();

  const location = useLocation();

  const isProjectPage = location.pathname.includes('/dashboard/project/');
  const {y: scrollY} = useWindowScroll();
  const {isAdaptive} = useWindowResize(768);

  const navigationLinkConfig = getHeaderNavigationIcon();

  const shouldSetBackgroundTransparent =
    ((isProjectPage && isAdaptive && scrollY < 100) ||
      (isProjectPage && !isAdaptive)) &&
    !isOpened;

  return (
    <>
      <header
        className={classNames(
          'fixed w-full top-0 left-0 md:relative z-50 transition-colors duration-200',
          shouldSetBackgroundTransparent ? 'bg-transparent' : 'bg-[#040506]'
        )}
      >
        <div
          className={classNames(
            'px-4 2xl:px-10 border-b border-solid',
            isProjectPage
              ? 'border-white border-opacity-10'
              : 'border-[#363738]'
          )}
        >
          <div className="min-h-[4.25rem] 2xl:min-h-[5.625rem] flex items-center justify-between">
            <div className="flex items-center gap-[1.125rem]">
              <BurgerButton
                isOpened={isOpened}
                handleTranslateSidebar={handleTranslateSidebar}
              />
              {backwardNavigation && (
                <BackwardNavigation {...backwardNavigation} />
              )}{' '}
              {logo && (
                <Link to={'/dashboard'}>
                  <img src={projectLogo} alt="Logo" width={119} height={28} />
                </Link>
              )}
              {!logo &&
                !backwardNavigation &&
                navigationLinkConfig !== undefined && (
                  <div className="flex items-center gap-2 2xl:gap-4">
                    <img
                      className="w-4 h-4 2xl:w-6 2xl:h-6 hidden md:block"
                      src={navigationLinkConfig.navIcon}
                      alt="dashboardIcon"
                    />
                    <Link
                      to={navigationLinkConfig.url as string}
                      className="text-base 2xl:text-xl font-medium uppercase"
                    >
                      {navigationLinkConfig.linkName}
                    </Link>
                  </div>
                )}
            </div>
            <div className="flex items-center gap-2 2xl:gap-5">
              {!!user && <ActivityFeed />}

              <span className="w-[1px] h-5 2xl:h-8 hidden md:block  bg-l-200"></span>

              {user?.connectedWallets?.at(0) ? (
                <UserWallet
                  bindedUserWallet={user.connectedWallets[0].address}
                />
              ) : (
                <>
                  <Button
                    className="hidden md:block px-4 2xl:px-8 !h-[2.25rem] 2xl:!h-12"
                    text={'Connect wallet'}
                    onClick={() => bindWalletModalService.handleModalOpen()}
                  />
                  <button
                    onClick={() => bindWalletModalService.handleModalOpen()}
                    className="flex items-center justify-center md:hidden w-11 h-11 rounded-full bg-green-200"
                  >
                    <img
                      className="w-6 h-6"
                      src={walletIcon}
                      alt="walletIcon"
                    />
                  </button>
                </>
              )}
              <LogoutButton />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
