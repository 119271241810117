import {TooltipInfoIcon} from '../../../../components/Tooltip/TooltipInfoIcon';

export const VestInfo = ({
  label,
  value,
  tooltip,
}: {
  label: string;
  value: number;
  tooltip: string;
}) => {
  const formattedValue = value.toLocaleString();

  return (
    <div className="flex justify-between items-center">
      <div className="opacity-40 flex items-center gap-1">
        {label} <TooltipInfoIcon content={tooltip} />
      </div>
      <div className="text-lg font-bold font-mono">{formattedValue}</div>
    </div>
  );
};
