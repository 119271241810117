import {useEffect, useState} from 'react';
import {useTimeDifference} from './useTimeDifferenceExtended';
import {isTimePassed} from '../utilities/isTimePassed';

export const useTimer = (targetDate: Date) => {
  const {
    d: daysLeft,
    h: hoursLeft,
    m: minutesLeft,
    s: secondsLeft,
  } = useTimeDifference(targetDate.getTime());

  const [time, setTime] = useState({
    days: daysLeft,
    hour: hoursLeft,
    minute: minutesLeft,
    seconds: secondsLeft,
  });

  const [isTimerFinished, setIsTimerFinished] = useState<boolean>(
    isTimePassed(targetDate)
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!isTimePassed(targetDate)) {
      timer = setTimeout(() => {
        setTime({
          days: daysLeft,
          minute: minutesLeft,
          hour: hoursLeft,
          seconds: secondsLeft,
        });
      }, 1000);
    } else {
      setIsTimerFinished(true);
    }

    return () => clearTimeout(timer);
  }, [hoursLeft, minutesLeft, secondsLeft, daysLeft, time, targetDate]);

  return [time, isTimerFinished] as [
    {days: number; hour: number; minute: string; seconds: string},
    boolean,
  ];
};
