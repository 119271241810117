import React, {useLayoutEffect, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import stepsBoxFrame from '../assets/stepsBoxFrame.svg';
import {CircleStep} from '../assets/svgComponents/CircleStep';
import {CrossCancel} from '../assets/svgComponents/CrossCancel';
import {CrossCancelMob} from '../assets/svgComponents/CrossCancelMob';
import {
  SignUpSteps,
  stepsComponent,
  useStepsStore,
} from '../modules/Login/store/useStepsStore';
import {StepsOrdersView} from '../modules/Steps/StepsOrdersView';
import {StepsPanel} from '../modules/Steps/StepsPanelView';
import {signUpService} from '../services/SignUp';
import {ProgressBlockAdaptive} from './ProgressBlockAdaptive';
import {useUserStore} from '../modules/AccountProgress/store/useUserStore';
import {userService} from '../services/userApi';
import {updateStepStatus} from '../modules/Login/helpers/updateStepStatus';
import {useSignUpStore} from '../modules/Login/store/useSignUpStore';
import {getActionPointsForStep} from '../modules/Login/helpers/handleSteps';
import {SocialStates} from '../constants/common';

export interface Step {
  id: number;
  isCompleted: boolean;
  isSkipped: boolean;
  isActive: boolean;
  name?: string;
}
export const LayoutStepsSignUp = () => {
  const {actionPoints, isNew, setIsNew} = useSignUpStore();
  const navigate = useNavigate();
  const {stepsArray, activeStep, setActiveStep, recalculateSteps} =
    useStepsStore();
  const {user} = useUserStore();
  const handleClose = () => {
    navigate('/dashboard');
    setIsNew(false);
  };
  const [searchParams] = useSearchParams();

  const renderStepStatusAdaptive = (item: Step) => {
    if (activeStep === item.id) {
      return item.isCompleted ? 'bg-[#E5FD55]' : 'bg-[#9d9d9e]';
    } else if (item.isSkipped) {
      return 'bg-[#3b3c3d]';
    } else if (item.isCompleted) {
      return 'bg-[#E5FD55]';
    } else {
      return 'bg-[#3b3c3d]';
    }
  };
  const activeStepIndex = stepsArray.findIndex(item => item.id === activeStep);

  useEffect(() => {
    void userService.getUserData();
  }, []);

  useEffect(() => {
    updateStepStatus();
  }, [user]);

  useLayoutEffect(() => {
    const state = searchParams.get('state') as SocialStates | null;
    const code = searchParams.get('code');

    if (state && code) {
      setActiveStep(SignUpSteps.SOCIALS);

      if (state === SocialStates.twitter) {
        void signUpService.sendTwitterData(code);
      } else {
        void signUpService.sendDiscordData(code);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (!actionPoints) {
      void signUpService.getActionPoints();
    }
  }, []);

  useEffect(() => {
    recalculateSteps();
  }, [isNew, recalculateSteps]);

  return (
    <div className="flex min-h-screen bg-[url('./assets/bg_step.svg')] sm:bg-no-repeat bg-cover p-2 lg:px-4 ">
      <div
        className="h-fit flex flex-col lg:grid grid-cols-[.36fr_1fr] bg-[#0A0B0D] w-full max-w-[1280px] z-50 lg:min-h-[740px] lg:self-center rounded-[20px] mx-auto justify-start p-4 pt-[32px] lg:p-0"
        style={{zIndex: 1}}
      >
        <StepsPanel />
        <button
          onClick={handleClose}
          className="flex lg:hidden absolute right-0 top-5 transform -translate-x-1/2  cursor-pointer"
        >
          <CrossCancelMob />
        </button>
        <div className="flex lg:hidden">
          <ProgressBlockAdaptive />
        </div>
        <div className="flex mt-10 lg:hidden">
          <span className="text-[#E5FD55] font-mono text-xs font-normal uppercase leading-[20px]">
            {stepsArray.map((item, key) => (
              <div
                key={key}
                className="gap-2"
                style={{display: activeStepIndex === key ? 'flex' : 'none'}}
              >
                <div className="flex h-[20px] items-center	">
                  <CircleStep />
                </div>
                step {activeStepIndex + 1}
                {actionPoints && (
                  <span className="text-white lowercase text-xs font-mono font-bold leading-[20px] mb-[13px]">
                    +{getActionPointsForStep()} points
                  </span>
                )}
              </div>
            ))}
          </span>
        </div>
        <div className="lg:px-6 flex-1 flex relative flex-col mx-auto w-full lg:mt-[43px]">
          <div className="hidden lg:flex justify-center">
            <div className="flex min-w-[110px] bg-red justify-center relative z-10">
              <div className="flex w-[28px] h-[20px] items-center	">
                <CircleStep />
              </div>
              <StepsOrdersView />
              <div className="absolute -z-[1] w-[505px] 2xl:w-[670px] top-2 left-1/2 -translate-x-1/2 after:bg-[#0a0b0d] after:left-1/2 after:-translate-x-1/2 after:w-[124px] after:h-[22px] after:absolute after:top-0">
                <img src={stepsBoxFrame} alt="frameLine" />
              </div>
            </div>
            <button
              onClick={handleClose}
              className="flex absolute right-9 top-[-23px] cursor-pointer z-[1]"
            >
              <CrossCancel />
            </button>
          </div>
          <div className="flex items-center relative justify-center mx-auto lg:pt-[80px] w-full">
            {activeStep !== undefined &&
              !!stepsComponent[activeStep]?.component && (
                <div
                  className="w-full justify-center"
                  key={activeStep}
                  style={{display: 'flex'}}
                >
                  {React.createElement(stepsComponent[activeStep].component, {
                    key: activeStep,
                  })}
                </div>
              )}
          </div>
          <div className="flex lg:hidden justify-center">
            <div className="w-[338px] h-px justify-start items-start gap-1.5 inline-flex">
              {stepsArray.map((item, key) => (
                <div
                  key={key}
                  className={`grow shrink basis-0 h-px ${renderStepStatusAdaptive(
                    item
                  )} rounded-sm`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
