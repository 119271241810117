export const calculateProgress = (
  currentValue?: number,
  maxValue?: number,
  prevValue = 0
): number => {
  if (!currentValue) {
    return 0;
  }

  if (!maxValue) {
    return 100;
  }

  const totalRange = maxValue - prevValue;
  const currentRange = currentValue - prevValue;
  const percentage = totalRange !== 0 ? (currentRange / totalRange) * 100 : 0;
  return percentage;
};
