import {PropsWithChildren} from 'react';

export const ActivityGroup = ({
  title,
  children,
}: PropsWithChildren<{title: string}>) => {
  return (
    <div className="mb-5">
      <div className="text-sm leading-6">{title}</div>
      {children}
    </div>
  );
};
