import {useShallow} from 'zustand/react/shallow';
import {activityFeedService} from '../service';
import {ActivityFeedTabs, activityFeedStore} from '../store';

export const Tab = ({
  tabID,
  name,
  unreadCount,
  labelColor,
  onClick = activityFeedService.setActiveTab,
}: {
  tabID: ActivityFeedTabs;
  name: string;
  unreadCount: number;
  labelColor: string;
  onClick?: (tabID: ActivityFeedTabs) => void;
}) => {
  const isActive = activityFeedStore(
    useShallow(state => state.openedTab === tabID)
  );

  return (
    <div
      className={`w-full rounded-[42px] py-[6px] flex gap-2 items-center justify-center font-mono text-l-600 font-bold text-xs leading-6 uppercase cursor-pointer ${
        isActive ? 'text-white bg-l-200' : ''
      }`}
      onClick={() => onClick(tabID)}
    >
      {name}
      {!!unreadCount && (
        <div
          className={`text-[#040506] rounded-[28px] px-2 pt-[3px] pb-[5px] leading-3 bg-[${labelColor}]`}
        >
          {unreadCount}
        </div>
      )}
    </div>
  );
};
