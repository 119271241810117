import axios, {AxiosRequestConfig} from 'axios';
import {signUpService} from './SignUp';
import {AppStage} from '../types/common';
import {appStage} from '../constants/appStages';

export const accessToken: string = localStorage.getItem('accessToken') || '';

const appAPIURLS: Record<AppStage, string> = {
  production: 'https://api.raiser.co/api',
  development: 'https://raiser-launchpad-backend-dev.up.railway.app/api',
  staging: 'https://raiser-launchpad-backend-stage.up.railway.app/api',
};

export const API_URL = appAPIURLS[appStage];

export const $api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _isRetry?: boolean;
}

$api.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('accessToken');
  config.headers['Authorization'] = `${accessToken!}`;
  return config;
});

$api.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const originalRequest: CustomAxiosRequestConfig = error.config;
    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        await signUpService.sendRefreshRequest();
        return $api.request(originalRequest);
      } catch (e) {
        localStorage.removeItem('accessToken');
        window.location.replace('/#/');
      }
    }

    throw error;
  }
);
