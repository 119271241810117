import {create, useStore} from 'zustand';

import {ITasksStore} from '../../../types/tasks';

export const tasksStore = create<ITasksStore>(() => ({
  availableTasks: [],
  activeTasks: [],
  completeTasks: [],
  refetchingTasksUserIds: [],
}));

export const useTasksStore = () => useStore(tasksStore);
