import {useEffect, useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useNavigate, useParams} from 'react-router-dom';
import {LayoutDashboardView} from '../../layouts/LayoutDashboardView';
import {SkeletonColorClasses} from '../../layouts/constants';
import {projectService} from '../../services/projectApi';
import {dashboardStore, useDashboardStore} from '../Dashboard';
import {DataBar} from './DataBar';
import {ProjectContent} from './ProjectContent';
import './ProjectDetailsStyles.css';
import {ProjectHeader} from './ProjectHeader';
import {ProjectMobileAction} from './ProjectMobileAction';
import {ProjectSideBar} from './ProjectSideBar';
import {BlockNotify} from './ProjectSideBar/BlockNotify';
import {getClosestRound} from '../../utilities/project';
import classNames from 'classnames';

export const ProjectDetailsView = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {
    isLoading,
    setIsLoading,
    selectedProject: project,
    projects,
    setProject,
    featuredCampaign,
  } = useDashboardStore();
  const [selectedRoundId, setSelectedRoundId] = useState<string>('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!id) return navigate('/dashboard');

    if (id && featuredCampaign && featuredCampaign.id === id) {
      const closestRoundId = getClosestRound(featuredCampaign)?.id;
      if (closestRoundId) {
        setSelectedRoundId(closestRoundId);
      }

      return setProject(featuredCampaign);
    }
    setIsLoading(true);

    void projectService.getProject(
      id,
      () => {
        const project = dashboardStore.getState().selectedProject;
        if (project) {
          const closestRoundId = getClosestRound(project)?.id;
          if (closestRoundId) {
            setSelectedRoundId(closestRoundId);
          }
        }
      },
      () => {
        setIsLoading(false);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, featuredCampaign, projects, navigate, setIsLoading, setProject]);

  const currentRound = useMemo(
    () =>
      project && project.projectRounds.find(({id}) => id === selectedRoundId),
    [project, selectedRoundId]
  );

  const closestRound = useMemo(
    () => project && getClosestRound(project),
    [project]
  );

  if (isLoading || !project || !currentRound || !closestRound)
    return (
      <LayoutDashboardView>
        <div className="relative px-3 md:px-6 2xl:px-10 flex flex-col xl:grid gap-5 items-start mb-10">
          <div className="flex flex-col gap-5 w-full max-w-[1080px] mx-auto">
            <Skeleton
              style={{marginBottom: SkeletonColorClasses.mb}}
              height={580}
            />
            <Skeleton
              style={{marginBottom: SkeletonColorClasses.mb}}
              height={34}
            />
            <Skeleton
              style={{marginBottom: SkeletonColorClasses.mb}}
              height={280}
            />
          </div>
        </div>
      </LayoutDashboardView>
    );

  return (
    <LayoutDashboardView
      headerProps={{
        backwardNavigation: {
          targetLocationName: 'Dashboard',
          currentLocationName: project.name,
          targetLocationPath: '/dashboard',
        },
      }}
    >
      <div className="relative px-3 md:px-6 2xl:px-10 flex flex-col xl:grid gap-5 items-start mb-10">
        <div className="flex flex-col gap-5 w-full max-w-[1080px] mx-auto">
          <ProjectHeader currentRound={closestRound} project={project} />
          {project.isProjectDataBarShown && (
            <DataBar
              currentRound={currentRound}
              setSelectedRoundId={setSelectedRoundId}
              project={project}
            />
          )}
          <div className="flex flex-col items-start w-full mt-9 md:mt-16 mx-auto justify-center gap-6 lg:flex-row">
            <div className="flex flex-col items-start w-full gap-6 lg:flex-row">
              <ProjectSideBar />
              <div
                className={classNames(
                  project.isProjectNotifyBlockShown
                    ? 'md:max-w-[568px] md:mr-12'
                    : 'flex-1'
                )}
              >
                <ProjectContent project={project} />{' '}
              </div>
              {project.isProjectNotifyBlockShown && (
                <div className="hidden sticky top-3 ml-auto xl:block">
                  <BlockNotify currentRound={closestRound} project={project} />
                </div>
              )}
            </div>
          </div>
          {project.isProjectNotifyBlockShown && (
            <div
              className="w-full sm:w-auto block mx-auto xl:hidden"
              id="project-card"
            >
              <BlockNotify currentRound={closestRound} project={project} />
            </div>
          )}
          {project.isProjectInvestButtonShown && (
            <ProjectMobileAction
              currentRound={closestRound}
              project={project}
            />
          )}
        </div>
      </div>
    </LayoutDashboardView>
  );
};
