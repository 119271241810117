import {useNavigate} from 'react-router-dom';
import {Button} from '../../../../../components/Button';

export const WoodleCloseButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      className="w-full md:w-[280px]"
      text={'END GAME'}
      onClick={() => {
        navigate('/account');
      }}
    />
  );
};
