import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {TaskStatus} from '../../../types/tasks';

import {TasksTabs} from './TasksTabs';
import {TasksView} from '../../AccountTask/TasksView';
import {tasksService} from '../../AccountTask/service/TasksService';
import {useTasksStore} from '../../AccountTask/store/useTasksStore';

export const AccountTasksList = ({
  projectName,
  refetch,
}: {
  projectName: string;
  refetch: () => Promise<void>;
}) => {
  const [searchParams] = useSearchParams();
  const [activeTabId, setTabId] = useState<TaskStatus>(
    (searchParams.get('default') as TaskStatus) || TaskStatus.Available
  );

  const tasksStore = useTasksStore();

  const totalAmountTasks =
    tasksStore.availableTasks.length +
    tasksStore.activeTasks.length +
    tasksStore.completeTasks.length;
  const completedTasksPercent = Math.floor(
    (tasksStore.completeTasks.length / totalAmountTasks) * 100
  );

  const currentTasks = tasksService.getStoredTasks(activeTabId, tasksStore);

  return (
    <div>
      <div className="px-4 lg:px-6 pt-6 flex flex-col gap-6 border-solid border-b-l-200 border-b-[1px]">
        <div className="flex items-center gap-2">
          <span className="text-xl font-bold">
            {projectName === 'Genaral'
              ? 'Raiser Tasks'
              : `${projectName} Tasks`}
          </span>
          <div className="bg-green-200 rounded-[1.75rem] px-2 min-h-[1.25rem] text-black text-xs flex justify-center items-center font-bold">
            {completedTasksPercent ? completedTasksPercent : 0}% Done
          </div>
        </div>
        <TasksTabs setTabId={setTabId} activeTabId={activeTabId} />
      </div>
      <div className="py-8 px-6">
        <TasksView
          showToggleTextLength
          taskRowDirection
          tasks={currentTasks}
          refetchTasks={refetch}
        />
      </div>
    </div>
  );
};
