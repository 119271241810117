import React from 'react';

export const Star = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8294 15.6428C16.6181 15.8475 16.521 16.1437 16.5692 16.4341L17.2944 20.4478C17.3556 20.788 17.212 21.1323 16.9273 21.3289C16.6483 21.5329 16.2771 21.5573 15.9728 21.3942L12.3596 19.5097C12.234 19.4428 12.0945 19.4069 11.9517 19.4028H11.7307C11.654 19.4142 11.5789 19.4387 11.5104 19.4762L7.89639 21.3697C7.71773 21.4594 7.51541 21.4913 7.31717 21.4594C6.83422 21.3681 6.51198 20.908 6.59111 20.4226L7.31717 16.4088C7.3653 16.1159 7.26822 15.8182 7.05693 15.6101L4.11107 12.7548C3.8647 12.5158 3.77904 12.1569 3.89162 11.833C4.00094 11.5099 4.27994 11.2742 4.61687 11.2211L8.6714 10.6329C8.97978 10.6011 9.25062 10.4135 9.38931 10.1361L11.1759 6.47317C11.2183 6.39158 11.273 6.31653 11.3391 6.2529L11.4125 6.19579C11.4508 6.15337 11.4949 6.11829 11.5438 6.08974L11.6328 6.05711L11.7714 6H12.1149C12.4216 6.03182 12.6917 6.21537 12.8328 6.48948L14.6431 10.1361C14.7736 10.4029 15.0273 10.5881 15.3202 10.6329L19.3747 11.2211C19.7174 11.2701 20.0037 11.5067 20.1171 11.833C20.224 12.1601 20.1318 12.5191 19.8805 12.7548L16.8294 15.6428Z"
        fill="white"
      />
      <path d="M6 7L4 5" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M12 2.82843L12 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18 7L20 5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
