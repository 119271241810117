import {useEffect} from 'react';

import {useQuizModalStore} from './store/useQuizModalStore';
import {useQuizStore} from './store/useQuizStore';

import {Quiz} from './components/Quiz';
import {QuizResults} from './components/QuizResults';
import {QuizBeginScreen} from './components/QuizBeginScreen';

import {QuizState} from './types';
import {quizService} from './service/service';
import {ISelectedTaskInfo} from '../../types/tasks';

export interface IQuizModalBodyProps {
  handleClose: () => void;
  selectedQuizInfo: ISelectedTaskInfo;
}

export function QuizModalBody({
  selectedQuizInfo: {
    taskID,
    quizID,
    pointReward,
    ticketReward,
    questionsAmount,
    quizType,
    isCustomRewardTask,
    userTaskID,
  },
  handleClose,
}: IQuizModalBodyProps) {
  const {quizModalState, resetStore: resetModalStore} = useQuizModalStore();
  const {resetStore: resetQuizStore} = useQuizStore();

  useEffect(() => {
    if (!taskID) {
      void quizService.getQuiz(quizID, questionsAmount);
    }

    return () => {
      resetModalStore();
      resetQuizStore();
    };
  }, [quizID, taskID, questionsAmount, resetModalStore, resetQuizStore]);

  switch (quizModalState) {
    case QuizState.IN_PROGRESS:
      return <Quiz handleClose={handleClose} quizID={quizID} />;

    case QuizState.FINISHED:
      return (
        <QuizResults
          handleClose={handleClose}
          pointReward={pointReward}
          ticketReward={ticketReward}
          quizType={quizType}
          isCustomRewardTask={isCustomRewardTask}
          userTaskID={userTaskID}
        />
      );

    case QuizState.NOT_INITIATED:
    default:
      return (
        taskID && (
          <QuizBeginScreen
            handleClose={handleClose}
            quizID={quizID}
            taskID={taskID}
            questionsAmount={questionsAmount}
            quizType={quizType}
            isCustomRewardTask={!!isCustomRewardTask}
          />
        )
      );
  }
}
