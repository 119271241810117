import {ActivityFeedTabs, activityFeedStore} from './store';

const activityFeedApi = () => {
  const {setState} = activityFeedStore;

  const handleClose = () => {
    setState({isOpened: false});
  };

  const handleIsOpenedChange = (isOpened: boolean) => {
    setState({isOpened: isOpened});
  };

  const setActiveTab = (tabID: ActivityFeedTabs) => {
    setState({openedTab: tabID});
  };

  return {
    handleClose,
    handleIsOpenedChange,
    setActiveTab,
  };
};

export const activityFeedService = activityFeedApi();
