import React from 'react';

interface NextArrowProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
  isActiveButton?: boolean;
  useGrayBackground?: boolean;
}

export const NextArrow: React.FC<NextArrowProps> = ({fill, ...props}) => {
  return (
    <svg
      width="32"
      height="6"
      viewBox="0 0 32 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Vector 2"
        d="M31.25 5.5L0.75 5.5L0.75 3.57692L16.2109 3.57692V0.5L31.25 5.5Z"
        fill={fill || 'black'}
      />
    </svg>
  );
};
