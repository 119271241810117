import {$api} from '../../../services/interceptor';
import {Project} from '../../../types/project';
import {
  getIsHardcodedProject,
  isCrossTheAgesProject,
} from '../../../utilities/getIsHardcodedProject';
import {campaignsStore} from '../store/useCampaignsStore';

const campaignsApi = () => {
  const {setState} = campaignsStore;

  const getCampaigns = async () => {
    try {
      setState({isLoading: true});
      const response = await $api.get<Project[]>('projects/all/campaigns');

      if (response.status === 200) {
        setState({
          campaigns: response.data.map(project => {
            const isHardcodedProject =
              getIsHardcodedProject(project) || isCrossTheAgesProject(project);

            return {
              ...project,
              isProjectDataBarShown: !isHardcodedProject,
              isProjectInvestButtonShown: !isHardcodedProject,
              isProjectNotifyBlockShown: !isHardcodedProject,
            };
          }),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setState({isLoading: false});
    }
  };

  return {
    getCampaigns,
  };
};

export const campaignsService = campaignsApi();
