import {TaskType} from '../../types/tasks';
import tasksTwitterIcon from '../../assets/tasksTwitterIcon.svg';
import taskTelegramIcon from '../../assets/taskTelegramIcon.svg';
import quizIcon from '../../assets/quizIcon.svg';
import referalIcon from '../../assets/referalIcon.svg';
import NFTIcon from '../../assets/NFTIcon.svg';
import gitIcon from '../../assets/gitCoin.svg';
import discordIcon from '../../assets/discordIcon.svg';
import woodlTaskIcon from '../../assets/woodleTaskIcon.svg';

export const taskIcon: Record<TaskType, string> = {
  [TaskType.QUIZ]: quizIcon,
  [TaskType.twitter]: tasksTwitterIcon,
  [TaskType.snapshot]: quizIcon,
  [TaskType.referral]: referalIcon,
  [TaskType.telegram]: taskTelegramIcon,
  [TaskType.nft]: NFTIcon,
  [TaskType.gitcoin]: gitIcon,
  [TaskType.discord]: discordIcon,
  [TaskType.woodle]: woodlTaskIcon,
};
