import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {useProjectsTasksStore} from '../store/useProjectsTasksStore';

import {projectsTasksService} from '../service/ProjectsTasksService';
import {signUpService} from '../../../services/SignUp';
import {parseOAuthState} from '../helpers/parseOAuthState';

import {Loader} from '../../../components/Loader';
import {Tooltip} from '../../../components/Tooltip';
import {SocialStates} from '../../../constants/common';
import {ProjectTasksList} from './ProjectTasksList';

const beforeStyles =
  "before:rounded-r-md before:absolute before:h-[49px] before:-top-[2px] before:left-0 before:w-[3px] before:md:w-1 before:bg-green-200 before:content-[''] before:md:top-[3px]";
const afterStyles =
  ' after:absolute after:left-[6px] after:md:left-3 after:bg-transparent after:w-[100px] after:border-2 after:border-green-200 after:w-[52px] after:h-[52px] after:md:w-[68px] after:md:h-[68px] after:rounded-[18px] after:block after:-top-1';

export const AccountTasksView = () => {
  const {projects, isProjectsLoading, selectedProjectId, setSelectedProjectId} =
    useProjectsTasksStore();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!projects.length) {
      void projectsTasksService.getProjects();
    }

    return projectsTasksService.clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const state = searchParams.get('state') as string | null;
    const code = searchParams.get('code');

    if (state) {
      const {redirectTo, type} = parseOAuthState(state);

      if (type === SocialStates.discord && code) {
        void signUpService.sendDiscordData(code, true, () => {
          const url = new URL(redirectTo);

          setSearchParams(url.searchParams);
        });
      }
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const defaultProjectId = searchParams.get('project');

    if (defaultProjectId && projects.some(({id}) => id === defaultProjectId)) {
      setSelectedProjectId(defaultProjectId);
    }
  }, [projects, searchParams, setSelectedProjectId]);

  const handleProjectClick = (projectId?: string) => {
    if (searchParams.has('project')) {
      searchParams.delete('project');

      if (searchParams.has('default')) {
        searchParams.delete('default');
      }

      setSearchParams(searchParams);
    }

    setSelectedProjectId(projectId);
  };

  const selectedProjectName = selectedProjectId
    ? projects.find(({id}) => id === selectedProjectId)?.name || ''
    : 'All';

  return (
    <div
      className={
        'border-l-100 bg-[#0E1012] border-solid border-[1px] rounded-20 w-full grid grid-cols-[64px_1fr] md:grid-cols-[92px_1fr]'
      }
    >
      <div className="flex flex-col gap-6 border-r border-l-100 py-7">
        {isProjectsLoading ? (
          <div className="flex w-full h-full items-center justify-center">
            <Loader />
          </div>
        ) : (
          projects.map(({id, imageUrl, name, active}) => (
            <Tooltip
              content={name !== 'General' ? name : 'All Tasks'}
              side="right"
              arrowFill="transparent"
              withoutPopover
            >
              <div className="relative px-[10px] md:px-4">
                <div
                  className={` w-11 h-11 md:w-[60px] md:h-[60px] cursor-pointer ${
                    active ? '' : 'opacity-30'
                  } [&_img]:rounded-[30px] ${
                    selectedProjectId === id
                      ? `[&&_img]:rounded-2xl [&>span]:rounded-2xl ${beforeStyles} ${afterStyles}`
                      : ''
                  }`}
                  onClick={() => handleProjectClick(id)}
                >
                  <span className={'w-full h-full inline-block'}>
                    {selectedProjectId === id ? (
                      <img
                        src={imageUrl}
                        className="w-full h-full object-cover rounded-[100%]"
                      />
                    ) : (
                      <img
                        src={imageUrl}
                        className="w-full object-cover h-full"
                      />
                    )}
                  </span>
                </div>
              </div>
            </Tooltip>
          ))
        )}
      </div>
      <ProjectTasksList
        projectId={selectedProjectId}
        projectName={selectedProjectName}
        isProjectsLoading={isProjectsLoading}
      />
    </div>
  );
};
