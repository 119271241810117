export const FileUploaderInfo = ({
  header,
  details,
}: {
  header: string;
  details: string;
}) => (
  <div className="w-full">
    <div
      className="w-full leading-6 flex justify-between flex-wrap"
      style={{
        overflowWrap: 'anywhere',
      }}
    >
      <span className="word-break">{header}</span>
    </div>
    <div className="text-xs leading-5 text-l-600 font-normal">{details}</div>
  </div>
);
