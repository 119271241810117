import {useTimer} from '../../../../../hooks/useTimer';
import {isTimePassed} from '../../../../../utilities/isTimePassed';
import {woodleStore} from '../../../store';
import {useShallow} from 'zustand/react/shallow';

export const NextGameTimer = () => {
  const retryAfter = woodleStore(
    useShallow(({currentWoodle}) => currentWoodle?.retryAfter)
  );

  const [time] = useTimer(new Date(retryAfter || 0));

  if (!retryAfter || isTimePassed(new Date(retryAfter))) {
    return null;
  }

  return (
    <div className="font-mono  text-xs leading-5 flex gap-2 text-center">
      <span className="text-l-600 uppercase">NEXT GAME IN:</span>
      <span className="text-white font-bold flex gap-[6px]">
        <span>{time.hour}h</span>
        <span>{time.minute}m</span>
        <span>{time.seconds}s</span>
      </span>
    </div>
  );
};
