import {AxiosError, AxiosResponse} from 'axios';

import {$api} from '../../../services/interceptor';
import {
  HandleQuizModalOpenProps,
  IMyTask,
  ISelectedTaskInfo,
  IStartTaskResponse,
  ITasksStore,
  MyTaskStatus,
  QuizType,
  TaskStatus,
  TaskType,
  Tasks,
  TwitterTaskType,
} from '../../../types/tasks';
import {tasksStore} from '../store/useTasksStore';
import {handleExternalLinkOpen} from '../../../utilities/handleExternalLinkOpen';
import {baseTwitterAdress} from '../../../constants/socialLinks';
import {retryTimersStore} from '../store/useRetryTimersStore';
import {tasksModalStore} from '../store/useTasksModalStore';
import {isQuizOnCooldown} from '../../Quiz/helpers';
import {QuizState, QuizStatus} from '../../Quiz/types';
import {userService} from '../../../services/userApi';
import {projectsTasksService} from '../../AccountLevel/service/ProjectsTasksService';
import {projectsTasksStore} from '../../AccountLevel/store/useProjectsTasksStore';
import {GENERAL_ID} from '../../AccountLevel/constants';
import {getDiscordLoginLink} from '../../../configs/discord.config';
import {signUpService} from '../../../services/SignUp';
import {SocialName} from '../../AccountProgress/store/useUserStore';
import {CurrentWoodle, KnownWords, woodleStore} from '../../Woodle/store';
import {NavigateFunction} from 'react-router-dom';
import {quizStore} from '../../Quiz/store/useQuizStore';
import {quizModalStore} from '../../Quiz/store/useQuizModalStore';

const TasksApi = () => {
  const {setState, getState} = tasksStore;
  const {getState: getRetryTimerStoreState, setState: setRetryTimerStoreState} =
    retryTimersStore;

  const getAvailableTasks = async () => {
    try {
      const response: AxiosResponse<Tasks> = await $api.get('/tasks/available');
      if (response.status === 200) {
        setState({
          availableTasks: response.data,
        });
      }
      void userService.getUserData();
    } catch (error) {
      setState({
        availableTasks: [],
      });
    }
  };

  const startTask = async (taskID: string) => {
    const res: AxiosResponse<IStartTaskResponse> = await $api.post(
      `/tasks/start/${taskID}`
    );

    return res;
  };

  const setAvailableTaskActive = (activeTask: IMyTask) => {
    const {activeTasks, availableTasks} = getState();

    if (activeTask) {
      setState({
        availableTasks: availableTasks.filter(({id}) => id !== activeTask.id),
        activeTasks: [...activeTasks, activeTask],
      });
    }
  };

  const setActiveTaskCompleted = (completedTask: IMyTask) => {
    const {activeTasks, completeTasks} = getState();

    setState({
      activeTasks: activeTasks.filter(({id}) => id !== completedTask.id),
      completeTasks: [...completeTasks, completedTask],
    });
  };

  const decrementTaskCooldown = (userTaskId: string) => {
    const {refetchingCooldowns} = getRetryTimerStoreState();
    const currentCooldown = refetchingCooldowns[userTaskId];

    setRetryTimerStoreState({
      refetchingCooldowns: {
        ...refetchingCooldowns,
        [userTaskId]: {...currentCooldown, time: currentCooldown.time - 1},
      },
    });
  };

  const removeTaskCooldown = (userTaskId: string) => {
    const {refetchingCooldowns} = getRetryTimerStoreState();
    const filteredCooldowns = Object.fromEntries(
      Object.entries(refetchingCooldowns).filter(([key]) => key !== userTaskId)
    );
    clearInterval(refetchingCooldowns[userTaskId].intervalId);
    setRetryTimerStoreState({refetchingCooldowns: filteredCooldowns});
  };

  const startTaskRefetchCooldown = (userTaskId: string) => {
    const intervalId = setInterval(() => {
      const cooldown =
        getRetryTimerStoreState().refetchingCooldowns[userTaskId];

      if (cooldown?.time > 0) {
        decrementTaskCooldown(userTaskId);
      } else {
        removeTaskCooldown(userTaskId);
      }
    }, 1000);

    const {refetchingCooldowns} = getRetryTimerStoreState();

    setRetryTimerStoreState({
      refetchingCooldowns: {
        ...refetchingCooldowns,
        [userTaskId]: {intervalId, time: 60},
      },
    });
  };

  const checkTelegramTask = async (userTaskId: string) => {
    await $api.post(`/telegram-tasks/subscribe/${userTaskId}/check`);
  };

  const checkGitcoinTask = async (userTaskId: string) => {
    await $api.post(`/gitcoin-tasks/${userTaskId}/check`);
  };

  const checkDiscordTask = async (userTaskId: string) => {
    try {
      await $api.post(`/discord-tasks/join/${userTaskId}/check`);
    } catch (error) {
      const typedError = error as AxiosError<{code: string}>;

      if (
        typedError.response?.data?.code ===
        'InvalidDiscordRefreshTokenException'
      ) {
        await signUpService.disconnectSocial(SocialName.Discord);
        window.open(getDiscordLoginLink(), '_self');
      }
    }
  };

  const refetchTask = async (
    userTaskId: string,
    initialTaskStatus?: MyTaskStatus,
    taskType:
      | TaskType.twitter
      | TaskType.telegram
      | TaskType.nft
      | TaskType.gitcoin
      | TaskType.discord = TaskType.twitter
  ) => {
    const {refetchingTasksUserIds} = getState();
    setState({
      refetchingTasksUserIds: [...refetchingTasksUserIds, userTaskId],
    });

    try {
      if (taskType === TaskType.telegram) {
        await checkTelegramTask(userTaskId);
      }

      if (taskType === TaskType.gitcoin) {
        await checkGitcoinTask(userTaskId);
      }

      if (taskType === TaskType.discord) {
        await checkDiscordTask(userTaskId);
      }

      const response: AxiosResponse<IMyTask> = await $api.get(
        `/user-tasks/my/${userTaskId}`
      );
      const task = response.data;

      if (task.status === MyTaskStatus.DONE) {
        setActiveTaskCompleted(task);
      }

      if (
        task.status === MyTaskStatus.IN_PROGRESS &&
        initialTaskStatus !== task.status
      ) {
        setAvailableTaskActive(task);
      }
    } catch (e) {
      console.log(e);
    } finally {
      startTaskRefetchCooldown(userTaskId);

      setState({
        refetchingTasksUserIds: getState().refetchingTasksUserIds.filter(
          id => id !== userTaskId
        ),
      });
    }
  };

  const getAllTasks = async () => {
    await Promise.all([
      projectsTasksService.getProjectTasks(
        projectsTasksStore.getState().selectedProjectId || GENERAL_ID
      ),
    ]);

    void userService.getUserData();
  };

  const getTasksAmount = (
    taskType: TaskStatus,
    {availableTasks, activeTasks, completeTasks}: ITasksStore
  ) => {
    switch (taskType) {
      case TaskStatus.Available:
        return availableTasks.length;
      case TaskStatus.Active:
        return activeTasks.length;
      case TaskStatus.Completed:
        return completeTasks.length;
    }
  };

  const getStoredTasks = (
    taskType: TaskStatus,
    {availableTasks, activeTasks, completeTasks}: ITasksStore
  ) => {
    switch (taskType) {
      case TaskStatus.Available:
        return availableTasks;
      case TaskStatus.Active:
        return activeTasks;
      case TaskStatus.Completed:
        return completeTasks;
    }
  };

  const handleTwitterTaskClick = (
    taskType: TwitterTaskType,
    resourceId: string,
    taskId: string,
    taskStatus?: MyTaskStatus
  ) => {
    switch (taskType) {
      case TwitterTaskType.SUBSCRIBE:
        handleExternalLinkOpen(
          `${baseTwitterAdress}/user?user_id=${resourceId}`
        );
        break;
      case TwitterTaskType.RETWEET_POST:
        handleExternalLinkOpen(
          `${baseTwitterAdress}/retweet?tweet_id=${resourceId}`
        );
        break;
      case TwitterTaskType.LIKE_POST:
        handleExternalLinkOpen(
          `${baseTwitterAdress}/like?tweet_id=${resourceId}`
        );
        break;

      default:
        return;
    }

    if (!taskStatus) {
      tasksService
        .startTask(taskId)
        .then(({data}) => {
          void tasksService.refetchTask(data.userTaskId, taskStatus);
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  const handleTaskClickWithRefetch = (
    taskId: string,
    taskStatus?: MyTaskStatus
  ) => {
    if (!taskStatus) {
      tasksService
        .startTask(taskId)
        .then(({data}) => {
          void tasksService.refetchTask(data.userTaskId, taskStatus);
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  const handleResourceTaskClick = (
    resourceId: string,
    taskId: string,
    taskStatus?: MyTaskStatus
  ) => {
    handleExternalLinkOpen(resourceId);

    if (!taskStatus) {
      tasksService
        .startTask(taskId)
        .then(({data}) => {
          void tasksService.refetchTask(data.userTaskId, taskStatus);
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  const setSelectedTaskInfo = (selectedTaskInfo: ISelectedTaskInfo | null) => {
    tasksModalStore.setState({selectedTaskInfo});
  };

  const handleQuizModalOpen = ({
    id,
    quiz,
    userQuizResult,
    rewardPointsAmount,
    rewardTicketsAmount,
    maxSuccessfullyCompletions,
    userTaskID,
  }: HandleQuizModalOpenProps) => {
    if (!isQuizOnCooldown(userQuizResult?.retryAfter)) {
      const taskInfo: ISelectedTaskInfo = {
        quizID: quiz.id,
        questionsAmount: quiz.questionsCount,
        quizType: quiz.type,
        pointReward: quiz.type === QuizType.REPEATABLE ? rewardPointsAmount : 0,
        ticketReward:
          quiz.type === QuizType.REPEATABLE ? rewardTicketsAmount : 0,
        isCustomRewardTask: !!maxSuccessfullyCompletions,
        userTaskID,
      };

      if (!userQuizResult || userQuizResult.status === QuizStatus.FAILED) {
        taskInfo.taskID = id;
      }
      tasksService.setSelectedTaskInfo(taskInfo);
    }
  };

  const handleDoneQuizOpen = ({
    questionsAmount,
    userTaskID,
    quizID,
  }: {
    quizID: string;
    questionsAmount: number;
    userTaskID: string;
  }) => {
    tasksModalStore.setState({
      selectedTaskInfo: {
        questionsAmount: questionsAmount,
        quizID: quizID,
        quizType: QuizType.REPEATABLE,
        isCustomRewardTask: true,
        userTaskID,
      },
    });
    quizStore.setState({
      questionsAmount,
      correctAnswersAmount: questionsAmount,
    });
    quizModalStore.setState({quizModalState: QuizState.FINISHED});
  };

  const handleWoodleTaskStart = async (
    taskID: string,
    navigate: NavigateFunction
  ) => {
    const task = await startTaskWithRefetch(taskID);
    if (!task || !task.userWodlResult) {
      return;
    }

    const {
      userWodlResult: {status, wordLength, retryAfter},
      wodl,
    } = task;

    if (!wodl) {
      return;
    }

    handleWoodleTaskOpen(
      {
        id: wodl.id,
        wordLength,
        attempts: wodl.attemptsToGuess,
        status: status,
        pointReward: task.rewardPointsAmount,
        ticketReward: task.rewardTicketsAmount,
        retryAfter: retryAfter,
        topic: wodl.topic,
      },
      [],
      navigate
    );
  };

  const handleWoodleTaskOpen = (
    currentWoodle: CurrentWoodle,
    submittedWords: KnownWords,
    navigate: NavigateFunction
  ) => {
    woodleStore.setState({
      submittedWords: submittedWords,
      currentWoodle: currentWoodle,
      word: '',
    });

    navigate('/woodle');
  };

  const postSnapshotImages = async (payload: {
    urls: Array<string>;
    taskId: string;
  }) => {
    await $api.post('/snapshot-tasks/add-snapshots', payload);
  };

  const sendImageTaskResponse = async (
    taskID: string,
    images: File[],
    setError: (errorMessage: string) => void
  ) => {
    try {
      const imagesPayload = new FormData();
      images.forEach(image => {
        imagesPayload.append('files', image);
      });

      const res: AxiosResponse<Array<{imageName: string; imageUrl: string}>> =
        await $api.post('/files/upload', imagesPayload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'multipart/form-data',
          },
        });

      await postSnapshotImages({
        taskId: taskID,
        urls: res.data.map(({imageUrl}) => imageUrl),
      });

      await getAllTasks();
    } catch (e) {
      const error = e as AxiosError<{message: string}>;
      setError(error?.response?.data?.message || '');
    }
  };

  const startTaskWithRefetch = async (
    taskId: string
  ): Promise<IStartTaskResponse | null> => {
    try {
      const taskData = await tasksService.startTask(taskId);
      await projectsTasksService.getProjectTasks(
        projectsTasksStore.getState().selectedProjectId || GENERAL_ID
      );

      return taskData.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const clearTasks = () => {
    setState({availableTasks: [], activeTasks: [], completeTasks: []});
  };

  return {
    getTasksAmount,
    getAllTasks,
    getStoredTasks,
    getAvailableTasks,
    startTask,
    refetchTask,
    handleTwitterTaskClick,
    startTaskRefetchCooldown,
    removeTaskCooldown,
    setSelectedTaskInfo,
    handleQuizModalOpen,
    sendImageTaskResponse,
    setAvailableTaskActive,
    startTaskWithRefetch,
    handleResourceTaskClick,
    checkTelegramTask,
    handleTaskClickWithRefetch,
    handleWoodleTaskOpen,
    handleWoodleTaskStart,
    handleDoneQuizOpen,
    clearTasks,
  };
};

export const tasksService = TasksApi();
