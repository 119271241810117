import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {userService} from '../../../../services/userApi';
import {tasksService} from '../../service/TasksService';
import {
  ITwitterTask,
  MyTaskStatus,
  TwitterTaskType,
} from '../../../../types/tasks';

export const HandleTwitterButton = ({
  twitterTask,
  id,
  status,
}: {
  twitterTask: ITwitterTask;
  id: string;
  status?: Exclude<MyTaskStatus, MyTaskStatus.DONE>;
}) => {
  const isTwitterConnected = userService.isTwitterConnected();

  return (
    <div
      className={`flex items-center gap-[10px] text-xs font-bold uppercase leading-6 text-green-200 hover:underline cursor-pointer ${
        isTwitterConnected ? '' : 'opacity-40 !cursor-default'
      }`}
      onClick={() => {
        if (isTwitterConnected) {
          tasksService.handleTwitterTaskClick(
            twitterTask.type,
            twitterTask.twitterResourceId,
            id,
            status
          );
        }
      }}
    >
      {twitterTask.type === TwitterTaskType.SUBSCRIBE && 'Follow'}
      {twitterTask.type === TwitterTaskType.LIKE_POST && 'Like'}
      {twitterTask.type === TwitterTaskType.RETWEET_POST && 'Retweet'}
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  );
};
