import {keys} from '../../constants';
import {Key} from './components/Key';

export const Keyboard = () => {
  return (
    <div className="grid grid-cols-10 w-full gap-[6px] max-w-[532px] mt-10">
      {keys.map(key => (
        <Key letter={key} />
      ))}
    </div>
  );
};
