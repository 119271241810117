import {IMyTask} from '../../../types/tasks';
import {QuizStatus} from '../../Quiz/types';
import {getNextDate} from '../helpers/getNextDate';

export const QuizResultStatus = ({
  quiz,
  userQuizResult,
}: Pick<IMyTask, 'quiz' | 'userQuizResult'>) => {
  const retryDate = getNextDate(userQuizResult?.retryAfter);

  return (
    <div className="font-bold text-xs uppercase font-mono tracking-[-0.24px] flex items-center gap-x-3 gap-y-1 flex-wrap">
      <div
        className={`${
          userQuizResult?.status === 'FAILED' ? 'text-[#FB4949]' : 'text-white'
        }`}
      >
        {userQuizResult?.status === QuizStatus.IN_PROGRESS && 'In progress'}
        {userQuizResult?.status === QuizStatus.FAILED && 'Failed'}
      </div>
      {quiz && userQuizResult && (
        <div>
          {userQuizResult?.answers.filter(answer => answer.correct).length}/
          {quiz?.questionsCount} Answered
        </div>
      )}
      {!!retryDate && (
        <div>
          <span>{retryDate}</span>
        </div>
      )}
    </div>
  );
};
