import {Scanner} from '../components/Scanner/Scanner';

export const MaintenancePage = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen text-[#fff] max-w-[700px] mx-auto px-4">
      <Scanner />

      <p className="font-sans font-[700] text-[32px] lg:text-[48px] -tracking-[2%] text-center leading-[48px] pb-[1.25rem] lg:pb-[2rem]">
        Sorry! We’re under
        <br /> construction maintenance!
      </p>

      <p className="max-w-[500px] font-mono text-[14px] text-[#fff] text-opacity-60 text-center leading-[21px] tracking-[-0.96px]">
        This page is under scheduled maintenance, we’ll be back soon! Thank you
        for being so patient.
      </p>
    </div>
  );
};
