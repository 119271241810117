import {Synaps} from '@synaps-io/verify-sdk';
import {signUpService} from '../services/SignUp';
import {userService} from '../services/userApi';

export const handleKYCVerification = async (finishCallback?: () => void) => {
  const kyc = await signUpService.getKYC();
  let sessionId = null;
  let status = null;

  if (!kyc || !kyc.sessionId || kyc.status === 'REJECTED') {
    const res = await signUpService.sendKYC();

    if (!res || !res.sessionId) {
      finishCallback && finishCallback();
      return;
    }

    sessionId = res.sessionId;
    status = res.status;
  } else {
    sessionId = kyc.sessionId;
    status = kyc.status;
  }

  if (status === 'APPROVED') {
    void userService.getUserData();
    finishCallback && finishCallback();
    return;
  }

  Synaps.init({
    sessionId: sessionId,
    onFinish: async () => {
      const kyc = await signUpService.getKYC();

      if (kyc && kyc.status === 'APPROVED') {
        void userService.getUserData();
      }

      finishCallback && finishCallback();
    },
    mode: 'modal',
  });
  Synaps.show();
};
