import {useShallow} from 'zustand/react/shallow';

import {projectsTasksStore} from '../../../AccountLevel/store/useProjectsTasksStore';

import {userService} from '../../../../services/userApi';
import {tasksService} from '../../service/TasksService';
import {MyTaskStatus, TaskStatus} from '../../../../types/tasks';
import {getDiscordLoginLink} from '../../../../configs/discord.config';
import {NextArrow} from '../../../../assets/svgComponents/NextArrow';

export const HandleDiscordButton = ({
  resourceId,
  id,
  status,
}: {
  resourceId: string;
  id: string;
  status?: Exclude<MyTaskStatus, MyTaskStatus.DONE>;
}) => {
  const selectedProjectId = projectsTasksStore(
    useShallow(state => state.selectedProjectId)
  );

  const isDiscordConnected = userService.isDiscordConnected();

  return isDiscordConnected ? (
    <div
      className={
        'flex items-center gap-[10px] text-xs font-bold uppercase leading-6 text-green-200 hover:underline cursor-pointer'
      }
      onClick={() => {
        tasksService.handleResourceTaskClick(resourceId, id, status);
      }}
    >
      Join
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  ) : (
    <div
      className={
        'flex items-center gap-[10px] text-xs font-bold uppercase leading-6 text-green-200 hover:underline cursor-pointer'
      }
      onClick={() => {
        window.open(
          getDiscordLoginLink(
            `${window.location.origin + window.location.pathname}?project=${
              selectedProjectId || ''
            }&default=${status ? TaskStatus.Active : TaskStatus.Available}`
          ),
          '_self'
        );
      }}
    >
      Connect discord
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  );
};
