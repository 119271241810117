import {InfoIcon} from '../../../assets/svgComponents/InfoIcon';

export const TaskDetails = ({
  inviteCount,
  invitedAmount,
}: {
  inviteCount: number;
  invitedAmount: number;
}) => {
  return (
    <div
      className={
        'border flex gap-2 items-start rounded-lg border-l-100 bg-l-50 pt-3 pl-3 pr-[14px] pb-[19px] mb-4'
      }
    >
      <div className="max-w-sm">
        <InfoIcon width={24} height={24} />
      </div>
      <div className="flex-1">
        <div className="w-full font-sans font-bold text-sm mb-2">
          Invite more friends
        </div>
        <div className="w-full">
          <div
            className="w-full leading-6 flex justify-between flex-wrap mb-1"
            style={{
              overflowWrap: 'anywhere',
            }}
          >
            <span className="word-break">
              You need to invite {inviteCount} friends
            </span>
          </div>
          <div className="text-sm leading-5 text-l-600 font-normal flex items-center gap-1">
            Progress
            <span className="font-bold text-white">
              {invitedAmount}/{inviteCount}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
