import {useCallback, useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useProjectDisclaimerStore} from './store/useProjectDisclaimerStore';
import {LayoutDashboardView} from '../../../layouts/LayoutDashboardView';
import {Button} from '../../../components/Button';
import {useProjectInvestStore} from '../Allocation/store/useProjectInvestStore';
import {investService} from '../Allocation/service/investService';
import {useUserStore} from '../../AccountProgress/store/useUserStore';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

export function ProjectDisclaimer() {
  const {investedProject, clear} = useProjectDisclaimerStore();
  const {setInvestedProject} = useProjectInvestStore();
  const navigate = useNavigate();
  const {user} = useUserStore();
  const [verifyToken, setVerifyToken] = useState<string>();

  useEffect(() => {
    const bindedWallet = user?.connectedWallets?.at(0)?.address;

    if (investedProject && bindedWallet) {
      void investService.getMaxAllocation(
        investedProject.signatureUrl,
        bindedWallet,
        investedProject.maxAllocation,
        investedProject.decimals
      );
    }
  }, [investedProject, user]);

  const handleVerifyToken = useCallback(
    (token: string) => setVerifyToken(token),
    []
  );

  if (!investedProject) {
    return <Navigate to={'/dashboard'} replace />;
  }

  return (
    <LayoutDashboardView
      headerProps={{
        backwardNavigation: {
          targetLocationName: investedProject.name,
          targetLocationPath: `/dashboard/project/${investedProject.id}`,
          currentLocationName: 'Disclaimer',
        },
      }}
      footer={false}
    >
      <div className="w-full pt-16">
        <div className="mx-auto max-w-[714px]">
          <div className="flex flex-col gap-4 mb-10">
            <h2 className="font-bold text-2xl leading-9">
              THE TOKENS OFFERED FOR SALE ARE AVAILABLE ONLY TO INDIVIDUALS
              OUTSIDE THE UNITED STATES AND ARE NOT INTENDED FOR U.S. PERSONS,
              IN ACCORDANCE WITH REGULATION S UNDER THE U.S. SECURITIES ACT OF
              1933, AS AMENDED. THE OFFERING AND SALE OF THESE TOKENS WILL NOT
              BE REGISTERED UNDER THE SECURITIES ACT, AND THESE TOKENS MAY NOT
              BE OFFERED OR SOLD IN THE UNITED STATES WITHOUT REGISTRATION OR
              UNLESS PURSUANT TO AN EXEMPTION FROM THE REGISTRATION
              REQUIREMENTS.
            </h2>
            <p className="leading-7 text-l-600">
              This offering is not available to investors from the following
              countries: Afghanistan, American Samoa, Anguilla, Antigua and
              Barbuda, Belize, Burundi, Central African Republic, Cuba, DR
              Congo, Ethiopia, Fiji, Guam, Guinée, Guinée-Bissau, Haïti, Hong
              Kong, Iran, Iraq, Lebanon, Libya, Mali, Myanmar, Nicaragua, North
              Korea, Palau, Panama, Samoa, Seychelles, Somalia, South Sudan,
              Sudan, Syria, The Bahamas, Trinidad and Tobago, Turks And Caicos
              Islands, US Virgin Islands, UAE, Vanuatu, Venezuela, Yemen,
              Zimbabwe.
              <br />
              <br />
              Investors are advised to ensure they are not subject to any legal
              restrictions that may prohibit them from participating in the
              token sale.
            </p>
          </div>
          <GoogleReCaptcha onVerify={handleVerifyToken} />

          <div className="flex gap-4">
            <Button
              text="I agree"
              className="w-full max-w-[212px]"
              disabled={!verifyToken}
              onClick={() => {
                if (investedProject) {
                  setInvestedProject({
                    ...investedProject,
                  });
                  navigate('/invest');
                }
              }}
            />
            <Button
              text="I disagree"
              className="w-full max-w-[212px] bg-zinc-700 text-white active:bg-zinc-800 hover:bg-zinc-600"
              onClick={() => {
                navigate(`/dashboard/project/${investedProject?.id || ''}`);
                clear();
              }}
            />
          </div>
        </div>
      </div>
    </LayoutDashboardView>
  );
}
