import {useAccount, useNetwork, useSwitchNetwork} from 'wagmi';
import {Button} from '../../../components/Button';
import {useTimer} from '../../../hooks/useTimer';
import {connectWalletModalService} from '../../ConnectWalletModal/service';
import {shortenAddress} from '../../../utilities/idex';

export const ClaimButton = ({
  onClick,
  targetDate = '',
  requiredNetwork,
  requiredWallet,
  vestingContract,
}: {
  onClick: () => void;
  requiredWallet: string;
  requiredNetwork: number;
  targetDate?: string;
  vestingContract?: string | null;
}) => {
  const [time, isTimePassed] = useTimer(new Date(targetDate));
  const {address} = useAccount();
  const {switchNetwork} = useSwitchNetwork();
  const {chain} = useNetwork();

  if (!targetDate || !vestingContract) {
    return (
      <Button
        text="TBA"
        className="font-bold w-full xl:min-w-[231px] xl:w-[231px] text-sm [&&]:bg-transparent border border-l-200 text-white"
      />
    );
  }

  if (!isTimePassed) {
    return (
      <Button
        text={
          <div>
            CLAIM in: {time.days}d {time.hour}h {time.minute}m {time.seconds}s
          </div>
        }
        className="font-bold w-full xl:min-w-[231px] xl:w-[231px] text-xs [&&]:bg-transparent border border-l-200 text-white"
      />
    );
  }

  if (requiredWallet.toLowerCase() !== (address || '').toLowerCase()) {
    return (
      <Button
        className="font-bold w-full xl:min-w-[231px] xl:w-[231px] text-sm"
        text={
          address
            ? `Connect wallet ${shortenAddress(requiredWallet)}`
            : 'Connect wallet'
        }
        onClick={() => connectWalletModalService.handleModalOpen()}
      />
    );
  }
  if (requiredNetwork !== chain?.id) {
    return (
      <Button
        className="font-bold w-full xl:min-w-[231px] xl:w-[231px] text-sm"
        text={'Switch network'}
        onClick={() => switchNetwork && switchNetwork(requiredNetwork)}
      />
    );
  }

  return (
    <Button
      className="font-bold w-full xl:min-w-[231px] xl:w-[231px] text-sm"
      text={'Claim'}
      onClick={onClick}
    />
  );
};
