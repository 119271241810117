import {useSearchParams} from 'react-router-dom';
import {TaskStatus} from '../../../types/tasks';
import {tasksService} from '../../AccountTask/service/TasksService';
import {useTasksStore} from '../../AccountTask/store/useTasksStore';
import {tasksTabs} from '../constants';
import {useEffect} from 'react';

export const TasksTabs = ({
  setTabId,
  activeTabId,
}: {
  activeTabId: TaskStatus;
  setTabId: (tabId: TaskStatus) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tasksStore = useTasksStore();

  useEffect(() => {
    const defaultTab = searchParams.get('default');

    if (
      defaultTab &&
      Object.values(TaskStatus).includes(defaultTab as TaskStatus)
    ) {
      setTabId(defaultTab as TaskStatus);
    }
  }, [searchParams, setTabId]);

  const handleTabToggle = (tabId: TaskStatus) => {
    if (searchParams.has('default')) {
      searchParams.delete('default');
      setSearchParams(searchParams);
    }
    setTabId(tabId);
  };

  return (
    <>
      <div className="flex  pb-4 w-full sm:w-fit gap-1 justify-between sm:justify-normal sm:gap-8 items-center max-w-[calc(100vw-160px)] invisible-scrollbar overflow-x-auto">
        {tasksTabs.map(({name, id}) => (
          <button
            onClick={() => handleTabToggle(id)}
            key={id}
            className={`flex flex-shrink-0 items-center gap-1 sm:gap-2 relative after:absolute after:w-full after:opacity-0 after:transition-all after:h-0.5 after:bg-white after:-bottom-4 after:left-0 ${
              activeTabId === id ? 'after:opacity-100' : 'after:opacity-0'
            }`}
          >
            <span
              className={`text-xs xl:text-sm uppercase font-semibold transition-all ${
                activeTabId === id ? 'text-white' : 'text-l-500'
              }`}
            >
              {name}
            </span>
            <span className="rounded-[1.75rem] min-h-[15px] xl:min-h-[1.25rem] flex justify-center items-center text-center px-1 bg-[#262729] xl:px-2 text-xs text-l-600 font-bold">
              {tasksService.getTasksAmount(id, tasksStore)}
            </span>
          </button>
        ))}
      </div>
    </>
  );
};
