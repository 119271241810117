import {useShallow} from 'zustand/react/shallow';
import {notificationsStore} from '../Notifications/store';
import {ActivityFeedTabs} from '../store';
import {Tab} from './Tab';
import {activitiesStore} from '../Activies/store';

export const Tabs = () => {
  const notificationsAmount = notificationsStore(
    useShallow(({notifications}) => notifications.length)
  );
  const unreadActivitiesAmount = activitiesStore(
    useShallow(({unreadCount}) => unreadCount)
  );

  return (
    <div className="w-full rounded-[48px] p-1 flex gap-1 bg-[#040506]">
      <Tab
        name="Notifications"
        tabID={ActivityFeedTabs.notifications}
        unreadCount={notificationsAmount}
        labelColor={'#E5FD55'}
      />
      <Tab
        name="activity"
        tabID={ActivityFeedTabs.activity}
        unreadCount={unreadActivitiesAmount}
        labelColor={'#FD5573'}
      />
    </div>
  );
};
