import React from 'react';
import {Loader} from '../assets/svgComponents/Loader';
import {NextArrow} from '../assets/svgComponents/NextArrow';

type ButtonProps = {
  text: string | JSX.Element;
  loading?: boolean;
  withArrow?: boolean;
  bigger?: boolean;
  smaller?: boolean;
  transparent?: boolean;
  arrowClick?: () => void;
} & JSX.IntrinsicElements['button'];

export const Button = ({
  text,
  className = '',
  disabled,
  loading,
  withArrow,
  arrowClick,
  bigger,
  smaller,
  transparent,
  ...rest
}: ButtonProps) => {
  const isYellowBackground = !disabled;
  const backgroundColorClass = transparent
    ? 'bg-[#0B0D0E] border-2 border-[#3C3D3E]'
    : isYellowBackground
    ? 'bg-[#E5FD55] hover:bg-[#c2db39]'
    : 'bg-[#2c2d2e]';
  const hoverBackgroundColorClass = transparent
    ? ''
    : isYellowBackground
    ? 'hover:bg-[#c2db39]'
    : '';

  return (
    <button
      disabled={disabled || loading}
      className={`${bigger ? 'h-[56px]' : 'h-[48px]'} ${
        disabled
          ? ''
          : `transition-all duration-200 ease-in-out ${
              transparent ? 'active:bg-[#60605d]' : 'active:bg-[#9aac36]'
            }`
      } relative text-black text-[16px] rounded-[42px] text-center justify-center uppercase ${backgroundColorClass} self-center font-mono font-bold ${hoverBackgroundColorClass} ${className}`}
      {...rest}
    >
      {loading ? (
        <div
          style={{
            width: '100%',
            height: `${smaller ? '40px' : '44px'}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="flex justify-center">
          <span>{text}</span>
          {withArrow && (
            <div className="flex items-center pl-[16px]">
              <NextArrow onClick={arrowClick} />
            </div>
          )}
        </div>
      )}
    </button>
  );
};
