import {useState} from 'react';
import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {ITask, MyTaskStatus} from '../../../../types/tasks';
import {tasksService} from '../../service/TasksService';
import {useUserStore} from '../../../AccountProgress/store/useUserStore';
import {Loader} from '../../../../components/Loader';
import {getReferralLink} from '../../../../utilities/getReferralLink';

export const HandleReferalButton = ({
  status,
  id,
}: Required<Pick<ITask, 'id'>> & {
  status?: Exclude<MyTaskStatus, MyTaskStatus.DONE>;
}) => {
  const {user} = useUserStore();
  const [copied, setCopied] = useState(false);
  const [isReferalPending, setReferalPending] = useState(false);

  const handleCopy = () => {
    if (user?.referralCode) {
      void navigator.clipboard.writeText(getReferralLink(user.referralCode));
    }

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return status === MyTaskStatus.IN_PROGRESS ? (
    <div
      className={
        'flex items-center gap-[10px] text-xs font-bold uppercase leading-3 text-green-200 hover:underline cursor-pointer'
      }
      onClick={handleCopy}
    >
      {copied ? 'Copied' : 'Copy referral code'}
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  ) : isReferalPending ? (
    <Loader />
  ) : (
    <div
      className={
        'flex items-center gap-[10px] text-xs font-bold uppercase leading-3 text-green-200 hover:underline cursor-pointer'
      }
      onClick={async () => {
        setReferalPending(true);
        await tasksService.startTaskWithRefetch(id);
        setReferalPending(false);
      }}
    >
      Start
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  );
};
