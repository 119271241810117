import {ForwardedRef, forwardRef, useMemo} from 'react';
import {woodleService} from '../../service';
import {CurrentWoodle, WoodleTaskStatus, useWoodleStore} from '../../store';
import {SubmittedWord} from '../Words/SubmittedWord';
import {CurrentWord} from '../Words/CurrentWord';
import {createArray} from '../../../../utilities/createArray';
import {UntouchedWord} from '../Words/UntouchedWord';

export const WoodleBody = forwardRef(
  (
    {currentWoodle}: {currentWoodle: CurrentWoodle},
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const {word, submittedWords} = useWoodleStore();

    const leftWords = useMemo(
      () => createArray(currentWoodle?.attempts - (submittedWords.length + 1)),
      [currentWoodle?.attempts, submittedWords.length]
    );

    return (
      <div className="flex flex-col gap-[6px] md:gap-3 w-full">
        {submittedWords.map((word, i) => (
          <SubmittedWord
            length={currentWoodle.wordLength}
            word={word}
            key={i}
          />
        ))}
        <CurrentWord length={currentWoodle.wordLength} />
        {leftWords.map(() => (
          <UntouchedWord length={currentWoodle.wordLength} />
        ))}
        {!!(currentWoodle.status === WoodleTaskStatus.IN_PROGRESS) && (
          <input
            className="h-0 hidden md:block"
            onChange={e =>
              woodleService.handleChange(
                e.target.value,
                currentWoodle.wordLength
              )
            }
            value={word}
            ref={ref}
          />
        )}
      </div>
    );
  }
);
