import {useQuizStore} from '../store/useQuizStore';
import {useQuizModalStore} from '../store/useQuizModalStore';

import {quizService} from '../service/service';

import {Button} from '../../../components/Button';

import {QuestionState, QuizState} from '../types';

interface IQuizFooterProps {
  quizID: string;
}

export function QuizFooter({quizID}: IQuizFooterProps) {
  const {
    currentQuestionNumber,
    lastAnswer,
    currentQuestionState,
    previousAnswers,
    isSubmitPending,
    isQuizFinished,
  } = useQuizStore();
  const {setQuizModalState} = useQuizModalStore();

  const isCheckButtonShown =
    currentQuestionState === QuestionState.IN_PROGRESS &&
    currentQuestionNumber >= previousAnswers.length;

  const isViewResultsButtonShown =
    isQuizFinished && !quizService.isPreviousPage(1);

  const isNextButtonShown = !isViewResultsButtonShown && !isCheckButtonShown;

  return (
    <div className="flex flex-row-reverse border-t-2 border-[#232526] gap-3 px-3 pt-2.5 pb-3 justify-between [&>button]:w-full md:[&>button]:w-[216px] [&>button]:h-10">
      {isCheckButtonShown && (
        <Button
          text="check"
          smaller
          disabled={!lastAnswer?.answerIds?.length}
          onClick={() => quizService.validate(quizID)}
          loading={isSubmitPending}
          className="text-xs"
        />
      )}

      {isNextButtonShown && (
        <Button
          text="NEXT QUESTION"
          className="text-xs"
          onClick={() => quizService.handleNext()}
        />
      )}

      {isViewResultsButtonShown && (
        <Button
          text="VIEW RESULTS"
          onClick={() => {
            setQuizModalState(QuizState.FINISHED);
          }}
          className="text-xs"
        />
      )}

      {currentQuestionNumber !== 0 && (
        <Button
          text="Previous Question"
          transparent
          onClick={quizService.handleBack}
          className="text-xs leading-6 text-white"
        />
      )}
    </div>
  );
}
