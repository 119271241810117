import {LevelStore} from '../modules/AccountLevel/store/useLevelsStore';
import {UserData} from '../modules/AccountProgress/store/useUserStore';

export const getSlicedLevels = (levels: Array<LevelStore>, user: UserData) => {
  const currentUserLevelID = user.levelData.currentLevel?.id;
  const currentUserLevelIndex = levels.findIndex(
    ({id}) => id === currentUserLevelID
  );

  let startIndex;
  let endIndex;

  if (currentUserLevelIndex > 0) {
    startIndex = currentUserLevelIndex - 1;
    endIndex = currentUserLevelIndex + 11;

    if (currentUserLevelIndex + 11 > levels.length) {
      startIndex = levels.length - 11;
      endIndex = levels.length;
    }
  } else {
    startIndex = 0;
    endIndex = 11;
  }

  return levels.slice(startIndex, endIndex);
};
