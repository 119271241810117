import {NavigateFunction} from 'react-router-dom';
import {$api} from './interceptor';

const healthApi = () => {
  const checkAPIHealth = async (navigate: NavigateFunction) => {
    try {
      const response = await $api.get<{status: 'Working' | string}>('/health');

      if (response.data.status === 'Working') {
        if (
          response.status === 200 &&
          window.location.pathname === '/server-under-maintenance'
        ) {
          navigate('/');
          return;
        }
      } else {
        navigate('/server-under-maintenance');
      }
    } catch (error) {
      console.log(error);
      navigate('/server-under-maintenance');
    }
  };

  return {checkAPIHealth};
};

export const healthService = healthApi();
