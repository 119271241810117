import React from 'react';

interface TimerProps {
  timeLeft: number;
}
const formatNumber = (number: number) =>
  number < 10 ? `0${number}` : number.toString();

const formatTime = (ms: number) => {
  const hours = Math.floor(ms / 3600000);
  const minutes = Math.floor((ms % 3600000) / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);

  return `${formatNumber(hours)}h ${formatNumber(minutes)}m ${formatNumber(
    seconds
  )}s`;
};

export const TimerButton: React.FC<TimerProps> = ({timeLeft}) => {
  return (
    <div className="uppercase font-bold flex gap-[6px] ">
      Next claim in:
      <div className="font-bold normal-case">{formatTime(timeLeft)}</div>
    </div>
  );
};
