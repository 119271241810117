import {Project} from '../../types/project';

interface ProjectContentProps {
  project: Project;
}

const wrapWithTableWrapper = (htmlString: string) =>
  htmlString.replace(
    /<table[^>]*>[\s\S]*?<\/table>/g,
    '<div class="tableWrapper thin-horizontal-scrollbar ">$&</div>'
  );

export const ProjectContent = ({project}: ProjectContentProps) => {
  const projectDetails = Object.entries(project.details)
    .sort(([, {order: orderA}], [, {order: orderB}]) => orderA - orderB)
    .map(([key, {content}]) => (
      <section
        key={Math.random()}
        id={key}
        dangerouslySetInnerHTML={{
          __html: wrapWithTableWrapper(content),
        }}
      />
    ));

  return (
    <article className="prose prose-sm md:prose" id="prose">
      {projectDetails}
    </article>
  );
};
