import * as Popover from '@radix-ui/react-popover';
import {FC, ReactNode} from 'react';

interface ProjectPopoverProps extends Popover.PopoverProps {
  children: ReactNode;
  trigger: ReactNode;
}

export const ProjectPopover: FC<ProjectPopoverProps> = ({
  children,
  trigger,
  ...props
}) => {
  // TODO: open popover for desktop and dialog for mobile

  return (
    <Popover.Root {...props}>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="bottom"
          align="start"
          avoidCollisions={false}
          className="z-20 border border-white border-opacity-10 rounded-[1.25rem] bg-[#0B0D0E] text-white overflow-auto"
          style={{
            minWidth: 'var(--radix-popover-trigger-width)',
          }}
        >
          {children}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
