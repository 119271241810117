import {FC, PropsWithChildren, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classNames from 'classnames';

import projectLogo from '../../../assets/sidebarLogo.svg';
import {WoodleQuestion} from '../../../assets/svgComponents/WoodleQuestion';
import {DashboardIcon} from '../../../assets/svgComponents/DashboardIcon';
import {HowToPlayModal} from '../components/HowToPlayModal';

export const WoodleLayout: FC<PropsWithChildren> = ({children}) => {
  const navigate = useNavigate();
  const [isModalOpened, setModalOpened] = useState(
    !localStorage.getItem('MODAL_BEEN_CLOSED')
  );

  const handleModalOpen = () => {
    setModalOpened(true);
  };

  const handleModalClose = () => {
    setModalOpened(false);
    localStorage.setItem('MODAL_BEEN_CLOSED', 'true');
  };

  return (
    <>
      <div className="bg-[#040506] text-white min-h-screen flex flex-col">
        <main className="flex-1 flex">
          <div className="flex-1 flex">
            <div className="sidebar-breakpoint:!w-full transition-all w-full pt-[57px] md:pt-0 flex flex-col">
              <header
                className={classNames(
                  'fixed w-full top-0 left-0 md:relative z-50 transition-colors duration-200',

                  'bg-[#040506]'
                )}
              >
                <div
                  className={classNames(
                    'px-4 2xl:px-10 border-b border-solid',

                    'border-[#363738]'
                  )}
                >
                  <div className="min-h-[4.25rem] 2xl:min-h-[5.625rem] flex items-center justify-between">
                    <div className="flex items-center gap-[1.125rem]">
                      <Link to={'/dashboard'}>
                        <img
                          src={projectLogo}
                          alt="Logo"
                          width={119}
                          height={28}
                        />
                      </Link>
                    </div>
                    <div className="flex gap-[10px] md:gap-5">
                      <div
                        className="relative h-[48px] p-3 md:py-3 md:pr-6 md:pl-4 flex justify-center items-center gap-2 rounded-full md:rounded-[100px] border-solid border-l-200 border-[1px]"
                        style={{cursor: 'pointer'}}
                        onClick={handleModalOpen}
                      >
                        <WoodleQuestion />
                        <div className="hidden md:block">How to play</div>
                      </div>
                      <div
                        className="relative h-[48px] p-3 md:py-3 md:pr-6 md:pl-4 flex justify-center items-center gap-2 rounded-full md:rounded-[100px] bg-l-100"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          navigate('/dashboard');
                        }}
                      >
                        <DashboardIcon />
                        <div className="hidden md:block">GO TO DASHBOARD</div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div className="flex-1 items-center justify-center pt-10 pb-20">
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
      {isModalOpened && <HowToPlayModal handleClose={handleModalClose} />}
    </>
  );
};
