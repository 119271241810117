export const formatToENUSDateFormat = (postDate: string) => {
  const date = new Date(postDate);

  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return formattedDate;
};
