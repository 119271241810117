import {useWeb3Modal} from '@web3modal/react';
import {Link} from 'react-router-dom';
import adaptiveLogo from '../assets/Raser.png';
import sidebarArrow from '../assets/sidebarArrow.svg';
import logo from '../assets/sidebarLogo.svg';
import {useWindowResize} from '../hooks/useWindowResize';
import {SidebarClasses} from '../layouts/constants';
import {useUserStore} from '../modules/AccountProgress/store/useUserStore';
import {AccountSetupProgress} from './AccountSetupProgress';
import {Footer} from './Footer';
import {launchpadLinks} from './constants';
import {handleExternalLinkOpen} from '../utilities/handleExternalLinkOpen';

interface LaunchpadLinkConfig {
  id: number;
  icon: string;
  linkName: string;
  url?: string;
  isAdaptive?: boolean;
  externalURl?: string;
}

export interface SidebarProps {
  open: boolean;
  toggle: () => void;
  translated: boolean;
}

export const Sidebar = ({open, toggle, translated}: SidebarProps) => {
  const {user} = useUserStore();
  const isAdaptive = useWindowResize(768);
  const handleWalletModal = useWeb3Modal().open;
  const isWalletModalActive = useWeb3Modal().isOpen;
  const sidebarWidth = open
    ? SidebarClasses.defaultSize
    : SidebarClasses.smallerSize;

  return (
    <div
      style={{width: `${sidebarWidth}px`}}
      className={`bg-dark md:translate-x-0 transition-all fixed z-[100] h-[calc(100%-69px)] top-[69px] md:top-0 left-0 border-solid border-r-[1px] border-[#1D1E1E] md:h-full md:border-none ${
        !translated || isWalletModalActive
          ? '-translate-x-full'
          : 'translate-x-0'
      }`}
    >
      <button
        className={`w-6 h-6 hidden md:flex justify-center items-center rounded-full z-[100] border-solid border-[1px] border-[#363738] bg-[#262729] absolute -right-3 ${
          open && isAdaptive ? 'top-8' : 'rotate-180 top-14'
        }`}
        onClick={toggle}
      >
        <img src={sidebarArrow} alt="arrowIcon" />
      </button>
      <div
        className={`pt-6 pb-4 md:pt-0 flex flex-col h-full overflow-y-scroll md:overflow-auto ${
          open && isAdaptive ? 'px-4' : 'px-2'
        }`}
      >
        <div className="hidden md:block">
          <Link
            to={'/dashboard'}
            className={`px-4 py-8 mb-8 transition-all hover:opacity-70 ${
              open && isAdaptive ? 'block' : 'hidden'
            }`}
          >
            <img src={logo} alt="logo" />
          </Link>
          <Link
            to={'/dashboard'}
            className={`py-4 mb-8 justify-center items-center ${
              open && isAdaptive ? 'hidden' : 'flex py-5'
            }`}
          >
            <img src={adaptiveLogo as string} alt="adaptiveLogo" />
          </Link>
        </div>
        <div className="flex-1 pb-2">
          <span
            className={`px-4 mb-4 text-l-500 text-sm uppercase ${
              open && isAdaptive ? 'block' : 'hidden'
            }`}
          >
            LAUNCHPAD
          </span>
          {launchpadLinks
            .filter(
              (launchpadLink: LaunchpadLinkConfig) =>
                launchpadLink.linkName !== 'Account'
            )
            .map(
              (
                launchpadLink: LaunchpadLinkConfig,
                launchpadLinkIndex: number
              ) =>
                launchpadLink.url ? (
                  <Link
                    className={`flex items-center gap-4 xl:transition-all hover:bg-l-100 mb-2 min-h-[2.75rem] ${
                      launchpadLink.isAdaptive ? 'flex md:hidden' : ''
                    } ${
                      open
                        ? 'md:px-4 md:min-h-[3rem] px-3 justify-start rounded-[100px]'
                        : 'min-h-[2.75rem] w-[2.75rem] px-2 rounded-full justify-center'
                    }`}
                    key={launchpadLink.id}
                    to={launchpadLink.url}
                  >
                    <img
                      className="w-6 h-6"
                      src={launchpadLink.icon}
                      alt="linkIcon"
                    />
                    <span
                      className={`text-sm uppercase font-semibold ${
                        open && isAdaptive ? 'block' : 'hidden'
                      }`}
                    >
                      {launchpadLink.linkName}
                    </span>
                  </Link>
                ) : launchpadLink.externalURl ? (
                  <div
                    className={`flex cursor-pointer items-center gap-4 xl:transition-all hover:bg-l-100 mb-2 min-h-[2.75rem] ${
                      launchpadLink.isAdaptive ? 'flex md:hidden' : ''
                    } ${
                      open
                        ? 'md:px-4 md:min-h-[3rem] px-3 justify-start rounded-[100px]'
                        : 'min-h-[2.75rem] w-[2.75rem] px-2 rounded-full justify-center'
                    }`}
                    key={launchpadLink.id}
                    onClick={() =>
                      handleExternalLinkOpen(launchpadLink.externalURl!)
                    }
                  >
                    <img
                      className="w-6 h-6"
                      src={launchpadLink.icon}
                      alt="linkIcon"
                    />
                    <span
                      className={`text-sm uppercase font-semibold ${
                        open && isAdaptive ? 'block' : 'hidden'
                      }`}
                    >
                      {launchpadLink.linkName}
                    </span>
                  </div>
                ) : (
                  <button
                    key={launchpadLinkIndex}
                    onClick={handleWalletModal}
                    className="flex md:hidden items-center gap-3 min-h-[44px] px-3 w-full hover:bg-l-100 rounded-[100px]"
                  >
                    <img src={launchpadLink.icon} alt="buttonIcon" />
                    <span className="text sm uppercase font-semibold">
                      {launchpadLink.linkName}
                    </span>
                  </button>
                )
            )}
        </div>
        {user?.profileSetupProgress !== 100 && (
          <AccountSetupProgress hidden={!open} />
        )}
        <div className="block md:hidden">
          <Footer isInSidebar />
        </div>
      </div>
    </div>
  );
};
