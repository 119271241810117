import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import logoIcon from '../../assets/logoIcon.svg';
import {TickCheck} from '../../assets/svgComponents/TickCheck';
import {Button} from '../../components/Button';
import {ButtonGoogle} from '../../components/ButtonGoogle';
import {EMAIL_REGEX} from '../../constants/regularExpression';
import {signInService} from '../../services/SignIn';
import {useSignInStore} from './store/useSignInStore';
import {useSignUpStore} from './store/useSignUpStore';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

export const RegistrationView = () => {
  const dividerBarClasses = 'bg-l-50 h-[1px] flex-1 block';
  const [verifyToken, setVerifyToken] = useState<string>();

  const {isActiveButton, setIsActiveButton, setIsNew} = useSignUpStore();
  const {
    emailSignIn,
    isLoading,
    isLoadingAuth,
    messageFromRequestSignIn,
    setEmailSignIn,
  } = useSignInStore();
  const [searchParams] = useSearchParams();

  const [showErrorEmailSignIn, setShowErrorEmailSignIn] =
    useState<boolean>(false);

  const [isTickChecked, setIsTickChecked] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleTick = () => {
    setIsTickChecked(!isTickChecked);
  };

  const handleVerifyToken = useCallback(
    (token: string) => setVerifyToken(token),
    []
  );

  const handleSignInClick = async () => {
    if (isValidEmail(emailSignIn) && isActiveButton && isTickChecked) {
      await signInService.sendOtpRequest(navigate);
    } else {
      setShowErrorEmailSignIn(!showErrorEmailSignIn);
    }
  };

  const handleGoogleClickLogin = async () => {
    const googleData = await signInService.googleLogin();
    if (googleData) {
      const isNew = googleData.user.isNew;
      const referralCode = searchParams.get('ref');

      if (isNew) {
        setIsNew(true);
        navigate(`/registration${referralCode ? '?ref=' + referralCode : ''}`);
      } else {
        setIsNew(false);
        navigate('/dashboard');
      }
    }
  };

  useEffect(() => {
    setIsActiveButton(!!emailSignIn && isTickChecked);
  }, [emailSignIn, isTickChecked, setIsActiveButton]);

  const handleEmailSignInChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEmail = event.target.value;
    setEmailSignIn(newEmail);
    setIsActiveButton(!!newEmail);
    setShowErrorEmailSignIn(newEmail.trim() === '' && isValidEmail(newEmail));
  };

  const isValidEmail = (email: string) => {
    const emailRegex = EMAIL_REGEX.test(email);
    return emailRegex;
  };

  const getClassNameSignIn = () => {
    if (!showErrorEmailSignIn && !messageFromRequestSignIn) {
      return 'border-[#535456]';
    } else if (!showErrorEmailSignIn || !messageFromRequestSignIn) {
      return 'border-red-500';
    } else {
      return 'border-[#535456]';
    }
  };

  return (
    <div className="relative lg:self-auto w-full">
      <div className="flex flex-col w-full max-w-[calc(414px+40px)] md:max-w-full mx-auto">
        <div className=" md:hidden block absolute top-4 max-w-[102px]">
          <img className="" src={logoIcon} alt="raiser" />
        </div>
        <div className="flex flex-col	items-center h-screen md:h-auto xl:justify-center w-full max-w-[calc(414px+40px)] mx-auto md:px-6 md:pb-5">
          <div className="flex flex-col items-center w-full pb-10 md:pb-0">
            <div className="flex items-center mb-[48px] w-full pt-[134px] md:pt-0">
              <div className="text-4xl lg:text-[48px] text-white h-[51px] tracking-[-0.96px] font-sans font-semibold leading-[48px]">
                Sign In
              </div>
              <div className="flex h-[0px] w-[40%] md:w-auto"></div>
            </div>
            <div className="w-full">
              <div className="pb-[6px]">
                <div className="pb-[6px]">
                  <span className="uppercase text-[12px] font-mono leading-[20px] text-white">
                    Your e-mail
                  </span>
                </div>
                <input
                  type="email"
                  id="email"
                  value={emailSignIn}
                  name="email"
                  placeholder="Enter your e-mail"
                  className={`px-5 py-[15px] focus:outline-none ${getClassNameSignIn()} border-[1px] font-mono text-[14px] font-normal bg-transparent text-white rounded-[12px] w-full lg:w-[414px] placeholder-[#535456]`}
                  onChange={handleEmailSignInChange}
                />
              </div>
              {showErrorEmailSignIn || messageFromRequestSignIn ? (
                <span className="uppercase text-[12px] leading-[20px] text-red-500">
                  {messageFromRequestSignIn || 'Invalid E-mail'}
                </span>
              ) : null}
              <div className="flex pt-[36px]">
                <div onClick={toggleTick} className="hover:bg-opacity-80">
                  {isTickChecked ? (
                    <div className="flex cursor-pointer">
                      <TickCheck className="hover:opacity-80" />
                    </div>
                  ) : (
                    <div className="flex cursor-pointer hover:opacity-80">
                      <input
                        type="checkbox"
                        className="appearance-none border cursor-pointer border-[#2C2E2F] rounded-[4px] w-5 h-5 checked:bg-[#E5FD55] checked:border-black text-green-500"
                      />
                    </div>
                  )}
                </div>
                <div className="flex pl-[12px]">
                  <span className="text-white text-[12px] pt-[1px] text-opacity-50	font-mono">
                    I agree with{' '}
                    <span className="text-white text-[12px] font-semibold underline hover:no-underline font-mono cursor-pointer">
                      <a target="_blank" href="http://raiser.co/terms">
                        Terms & Conditions
                      </a>
                    </span>
                  </span>
                </div>
                <div>
                  <GoogleReCaptcha onVerify={handleVerifyToken} />
                </div>
              </div>
            </div>
            <div className="flex relative flex-col w-full gap-y-2.5 md:items-center xl:gap-y-6 mt-12">
              <div className="relative rounded-[42px] w-full">
                <Button
                  loading={isLoading}
                  className="w-full lg:w-[414px]"
                  text={'Sign In'}
                  onClick={handleSignInClick}
                  disabled={!isActiveButton}
                  withArrow
                  bigger
                />
              </div>
              <div className="w-full gap-2.5 mx-auto flex items-center">
                <span className={dividerBarClasses}></span>
                <span className="uppercase text-[12px] text-white font-mono leading-[20px]">
                  or
                </span>
                <span className={dividerBarClasses}></span>
              </div>
              <div className="flex relative justify-center items-center text-center w-full">
                <ButtonGoogle
                  loading={isLoadingAuth}
                  disabled={!isTickChecked || !verifyToken}
                  text={'sign in with Google'}
                  className=" w-full lg:w-[414px]"
                  onClick={handleGoogleClickLogin}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
