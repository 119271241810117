import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

interface PropsTypes {
  children: React.ReactNode;
  redirectCondition?: boolean;
  redirectRoute?: string;
}

export const PrivateRoute = ({
  children,
  redirectCondition = !localStorage.getItem('accessToken'),
  redirectRoute = '/',
}: PropsTypes) => {
  if (redirectCondition) {
    return <Navigate to={redirectRoute} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};
