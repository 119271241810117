import {useEffect} from 'react';
import notificationIcon from '../../assets/notificationIcon.svg';
import * as Popover from '@radix-ui/react-popover';
import {activityFeedService} from './service';
import {useActivityFeedStore} from './store';
import {useNotificationsStore} from './Notifications/store';
import {notificationsService} from './Notifications/service';
import {ActivityFeedContent} from './components/ActivityFeedContent';
import {MobileOverlay} from '../../components/MobileOverlay';
import {activitiesService} from './Activies/service';

export const ActivityFeed = () => {
  const {isOpened} = useActivityFeedStore();

  const {notifications} = useNotificationsStore();

  useEffect(() => {
    notificationsService.getNotificationsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpened) {
      void activitiesService.getActivities();
      void activitiesService.getActivitiesCount();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <Popover.Root
      open={isOpened}
      onOpenChange={activityFeedService.handleIsOpenedChange}
      modal
    >
      <Popover.Trigger asChild>
        <button className="relative backdrop-blur-md w-[2.75rem] h-[2.75rem] 2xl:w-12 2xl:h-12 rounded-full border-l-200 border-solid border-[1px] flex justify-center items-center transition-all hover:border-l-400">
          <img
            className="w-6 h-6"
            src={notificationIcon}
            alt="notificationIcon"
          />
          {!!notifications.length && (
            <div className="bg-green-200 border-4 border-[#040506] box-border rounded-full h-[18px] w-[18px] -translate-y-[3px] translate-x-[5px] absolute right-0 top-0"></div>
          )}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="bottom"
          sideOffset={10}
          align="start"
          avoidCollisions={true}
          className="relative top-[-66px] sm:static border-0 h-screen w-screen z-50 sm:border md:mr-10 font-sans max-w-[100vw] sm:w-[413px] sm:max-w-[413px] sm:h-fit overflow-hidden border-white border-opacity-10 sm:rounded-[1.25rem]  sm:bg-[#0E1012]  text-white"
          style={{
            minWidth: 'var(--radix-popover-trigger-width)',
          }}
        >
          <ActivityFeedContent />
          <MobileOverlay
            className="z-10 bg-black "
            onClick={activityFeedService.handleClose}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
