import {$api} from '../../services/interceptor';
import {getDateInMs} from '../../utilities/project';
import {KeyState} from './components/Keyboard/components/Key';
import {LetterState} from './constants';
import {WoodleTaskStatus, woodleStore} from './store';
import {formatSubmittedAnswers} from './utils/formatSubmittedAnswers';

const woodleAPI = () => {
  const handleChange = (word: string, maxWordLength: number) => {
    const wrongLetters = woodleStore
      .getState()
      .submittedWords.flat(2)
      .filter(({status}) => status === LetterState.wrong)
      .map(({letter}) => letter);

    if (
      word.length <= maxWordLength &&
      word.match(/^[a-zA-Z\s]*$/) &&
      !wrongLetters.includes(word.at(-1) || '')
    ) {
      woodleStore.setState({word: word.toLocaleLowerCase()});
    }
  };

  const postAnswer = async (word: string) => {
    const wordID = woodleStore.getState().currentWoodle?.id;

    if (!wordID) {
      return;
    }

    try {
      const response = await $api.post<{
        answers: Array<{
          result: Record<string, LetterState>[];
          createdAt: string;
        }>;
        retryAfter: string;
        status: WoodleTaskStatus;
      }>(`/wodles/${wordID}/answers`, {word});

      return response.data;
    } catch (error) {
      console.log(error);
    }

    return;
  };

  const triggerShaking = () => {
    const isShaking = woodleStore.getState().isShaking;

    if (!isShaking) {
      woodleStore.setState({isShaking: true});

      setTimeout(() => {
        woodleStore.setState({isShaking: false});
      }, 1300);
    }
  };

  const showNotification = () => {
    const isToastShown = woodleStore.getState().isToastShown;

    if (!isToastShown) {
      woodleStore.setState({isToastShown: true});

      setTimeout(() => {
        woodleStore.setState({isToastShown: false});
      }, 1300);
    }
  };

  const handleSubmit = async () => {
    const word = woodleStore.getState().word;
    const maxWordLength = woodleStore.getState().currentWoodle?.wordLength;

    if (word.length !== maxWordLength) {
      triggerShaking();
      showNotification();
      return;
    }

    const result = await postAnswer(word);

    if (!result) {
      return;
    }

    const currentWoodle = woodleStore.getState().currentWoodle!;
    const currentWord = woodleStore.getState().word;

    woodleStore.setState({
      submittedWords: result.answers
        .sort(
          ({createdAt: a}, {createdAt: b}) => getDateInMs(a) - getDateInMs(b)
        )
        .map(({result}) => formatSubmittedAnswers(result)),
      currentWoodle: {
        ...currentWoodle,
        status: result.status,
        retryAfter: result.retryAfter,
      },
      word: result.status === WoodleTaskStatus.IN_PROGRESS ? '' : currentWord,
    });
  };

  const getKeyboardKeyState = (key: string) => {
    const submittedWords = woodleStore.getState().submittedWords;
    const currentKeyStatuses = submittedWords
      .flat()
      .filter(({letter}) => letter === key)
      .map(({status}) => status);

    if (!currentKeyStatuses.length) {
      return KeyState.initial;
    }

    if (currentKeyStatuses.includes(LetterState.correct)) {
      return KeyState.correct;
    }

    if (currentKeyStatuses.includes(LetterState.included)) {
      return KeyState.included;
    }

    return KeyState.wrong;
  };

  const pushLetter = (letter: string) => {
    const maxLength = woodleStore.getState().currentWoodle?.wordLength;

    if (!maxLength) {
      return;
    }

    const currentWord = woodleStore.getState().word;

    if (currentWord.length < maxLength) {
      woodleStore.setState({word: currentWord + letter});
    }
  };

  const popLetter = () => {
    const currentWord = woodleStore.getState().word;

    woodleStore.setState({word: currentWord.slice(0, -1)});
  };

  return {
    handleChange,
    getKeyboardKeyState,
    pushLetter,
    popLetter,
    handleSubmit,
    showNotification,
  };
};

export const woodleService = woodleAPI();
