import {useEffect} from 'react';

import {leaderboardService} from './service';

import {Header} from './components/Header';
import {LeadersTable} from './components/LeadersTable';
import {useLeaderBoardStore} from './store/store';

export function LeaderBoard() {
  const {leadersList, userResults} = useLeaderBoardStore();

  useEffect(() => {
    if (!(leadersList && userResults)) {
      void leaderboardService.getLeadersList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid-layout__leaderboard border-l-100 border-solid border-[1px] bg-[#0B0D0E] rounded-20 overflow-hidden">
      <Header />
      <LeadersTable />
    </div>
  );
}
