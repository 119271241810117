import {create, useStore} from 'zustand';

export interface BindWalletModalStore {
  isOpened: boolean;
  isConsciousChange: boolean;
  signMessageErrorCallback: null | ((errorText: string) => void);
}

export const bindWalletModalStore = create<BindWalletModalStore>(() => ({
  isOpened: false,
  isConsciousChange: false,
  signMessageErrorCallback: null,
}));

export const useBindWalletModalStore = () => useStore(bindWalletModalStore);
