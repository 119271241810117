import React from 'react';

export const NotificationGitScoreTask = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.0505 43C51.8059 39.9596 52.73 36.5107 52.73 33C52.73 29.4893 51.8059 26.0404 50.0505 23L46.5864 25C47.9907 27.4323 48.73 30.1914 48.73 33C48.73 35.8086 47.9907 38.5677 46.5864 41L50.0505 43Z"
        fill="white"
      />
      <circle cx="32.73" cy="33" r="7.25" stroke="white" stroke-width="3.5" />
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M52.4436 19.3115C49.8277 15.5441 46.1775 12.5928 41.9144 10.8269C37.0333 8.8051 31.6215 8.45039 26.5183 9.81779C21.4151 11.1852 16.9057 14.1983 13.6895 18.3897C10.4733 22.5812 8.72998 27.7168 8.72998 33C8.72998 38.2832 10.4733 43.4188 13.6895 47.6103C16.9057 51.8017 21.4151 54.8148 26.5183 56.1822C28.5575 56.7286 30.6459 57 32.73 57H51.5146C52.6192 57 53.5146 56.1046 53.5146 55V46.1547C53.5146 45.4402 53.1334 44.7799 52.5146 44.4227L50.0505 43L40.5255 37.5008C38.9693 40.1904 36.0609 42 32.73 42C27.7594 42 23.73 37.9706 23.73 33C23.73 28.0294 27.7594 24 32.73 24C36.0609 24 38.9693 25.8096 40.5255 28.4993L51.7825 22C52.7391 21.4477 53.0736 20.2188 52.4436 19.3115Z"
        fill="white"
      />
      <rect x="18.73" y="3" width="4.5" height="21" rx="2.25" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.98 3C28.7373 3 27.73 4.00736 27.73 5.25V9.52661C29.2165 9.20994 30.7232 9.03658 32.23 9.0052V5.25C32.23 4.00736 31.2226 3 29.98 3Z"
        fill="white"
      />
    </svg>
  );
};
