import {Button} from '../../components/Button';
import {signUpService} from '../../services/SignUp';
import {SocialName} from '../AccountProgress/store/useUserStore';

export function DisconnectSocialModal({
  socialName,
  handleClose,
}: {
  socialName: SocialName;
  handleClose: () => void;
}) {
  return (
    <div className="bg-[#0B0D0E] rounded-[20px] border border-l-100 w-[360px] h-[200px] flex flex-col items-center justify-between p-5">
      <div className="text-white flex-1 text-center flex items-center justify-center">
        Are you sure you want to disconnect that social account?
      </div>
      <div className="flex gap-3 w-full">
        <Button
          text={'Yes, sure'}
          className="w-full"
          onClick={async () => {
            await signUpService.disconnectSocial(socialName);
            handleClose();
          }}
        />
        <Button text={'Cancel '} className="w-full" onClick={handleClose} />
      </div>
    </div>
  );
}
