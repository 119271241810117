import classNames from 'classnames';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ArrowDown} from '../../../assets/svgComponents/ArrowDown';
import {Timer} from '../../../components/Timer';
import {Project, ProjectSchedule, RoundStatus} from '../../../types/project';
import {formatDateFromIso} from '../../../utilities/date';
import {navigateToDisclaimer} from '../../ProjectInvest/helpers/navigateToDisclaimer';
import {StatusLabel} from '../components/StatusLabel';
import {calcRoundStatus} from '../../../utilities/project';
import {TooltipInfoIcon} from '../../../components/Tooltip/TooltipInfoIcon';

interface BlockNotifyProps {
  project: Project;
  currentRound: ProjectSchedule;
}

export const BlockNotify = ({project, currentRound}: BlockNotifyProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const formattedDateStart = currentRound.start
    ? formatDateFromIso(currentRound.start)
    : 'TBA';

  const isInvestAvailable = !!(
    currentRound.minAllocation &&
    currentRound.maxAllocation &&
    currentRound.tokenPrice
  );

  return (
    <div className="w-full md:w-[280px] rounded-[20px] bg-[#0E1012] border border-white border-opacity-10 overflow-hidden">
      <div className="flex items-center px-4 py-5 bg-[#0E1012]">
        <img src={project.imageUrl} className="w-10 h-10 mr-4 rounded-full" />
        <span className="mr-3 text-lg font-semibold leading-none">
          {project?.name}
        </span>
        <span className="text-lg font-normal font-mono leading-none text-white text-opacity-50">
          {project.token.symbol.toUpperCase()}
        </span>
      </div>
      <button
        className="flex justify-between items-center w-full px-4 py-3 border-t border-white border-opacity-10 bg-[#0E1012]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-bold leading-6">
          {currentRound.title}
        </span>
        <span className="flex items-center gap-3.5">
          <StatusLabel roundStatus={calcRoundStatus(currentRound)} />
          <ArrowDown className={classNames(!isOpen && 'rotate-90')} />
        </span>
      </button>
      {isOpen && (
        <div className="flex flex-col gap-6 px-4 py-6 bg-black bg-opacity-40">
          <div className="flex justify-between text-xs font-normal font-mono leading-[1.2rem]">
            <span className="flex items-center text-white">
              <div className="opacity-50">LAUNCH DATE</div>
              <span className="flex pl-1">
                <TooltipInfoIcon content="Launch date" />
              </span>
            </span>
            <span>{formattedDateStart}</span>
          </div>
          <div className="flex flex-col justify-between gap-y-2 text-xs font-normal font-mono leading-[1.2rem]">
            <span className="flex items-center text-white">
              <div className="opacity-50">VESTING</div>
              <span className="flex pl-1">
                <TooltipInfoIcon content="Vesting" />
              </span>
            </span>
            <span className="uppercase">
              {project.vestingDescription || 'TBA'}
            </span>
          </div>
          <div className="flex flex-col gap-3">
            <Timer
              type="startsIn"
              hasNotificationIcon={false}
              transparentTimer={true}
              targetDate={
                currentRound.start ? new Date(currentRound.start) : null
              }
              soldOut={currentRound.status === RoundStatus.SOLDOUT}
              endDate={currentRound.end ? new Date(currentRound.end) : null}
              className="w-full max-w-full backdrop:blur-[12px] border-l-200 border-solid border-[1px]"
              signatureUrl={currentRound.signatureUrl}
              onClick={
                isInvestAvailable
                  ? () => {
                      navigateToDisclaimer(navigate, {
                        id: project.id,
                        name: project.name,
                        description: project.details.disclaimer?.content,
                        token: project.token,
                        allocationToken: project.allocationToken,
                        network: project.network,
                        minAllocation: currentRound.minAllocation!,
                        maxAllocation: currentRound.maxAllocation!,
                        tokenPrice: currentRound.tokenPrice!,
                        signatureUrl: currentRound.signatureUrl,
                        investContractAddress:
                          currentRound.investContractAddress,
                        decimals: project.allocationToken.decimals,
                      });
                    }
                  : null
              }
            />
            {/* <button className="flex justify-center items-center gap-2 w-full px-6 py-3 bg-[rgba(221,221,221,0.20)] rounded-full backdrop:blur-[12px]">
              <NotifyIcon />
              <span className="font-bold font-mono text-xs leading-5 tracking-[-0.24px]">
                NOTIFY ME
              </span>
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};
