import React from 'react';

export const NotificationCheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <g>
        <title>Layer 1</title>
        <g id="svg_4">
          <circle
            id="svg_1"
            fillOpacity="0.4"
            fill="white"
            r="9.33333"
            cy="10"
            cx="10"
          />
          <path
            id="svg_2"
            strokeWidth="1.5"
            stroke="white"
            d="m5.33073,10.333l3.33337,3.3334l6.6666,-6.6667"
          />
          <path
            id="svg_3"
            strokeWidth="1.2"
            stroke="#7F7F7F"
            d="m9.7682,10.6667l-3.3333,3.3333"
          />
        </g>
      </g>
    </svg>
  );
};
