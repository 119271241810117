import classNames from 'classnames';
import {LetterState} from '../../constants';

export const Letter = ({
  letter,
  state = LetterState.initial,
}: {
  letter: string;
  state?: LetterState;
}) => {
  return (
    <div
      className={classNames(
        'h-9 max-w-[36px] grow w-full pt-[6px] pb-2 text-center md:py-4 box-border md:max-w-[56px] md:h-14 md:w-14 border border-l-300 rounded-lg uppercase font-bold text-sm md:text-2xl md:leading-none font-sans',
        {
          'bg-[#1D1E1E] border-none': state === LetterState.wrong,
          'bg-[#E5FD55] border-none text-black': state === LetterState.included,
          'bg-[#00AF46] border-none text-black': state === LetterState.correct,
        }
      )}
    >
      {letter}
    </div>
  );
};
