import {Button} from '../../../../components/Button';
import {useProjectInvest} from '../hooks/useProjectInvest';
import {useProjectInvestStore} from '../store/useProjectInvestStore';

export const BuyAllocationButton = ({
  minAllocation,
  balance,
  disabled,
  userAddress,
  decimals,

  tokenContractAdress,
  contractAdress,
  chainId,
  chainName,
  investedSum,

  signatureUrl,

  bindedWallet,
}: {
  minAllocation: number;
  balance: number;
  disabled: boolean;
  userAddress: string;
  decimals: number;
  investedSum: number;

  tokenContractAdress: string;
  contractAdress: string;
  chainId: number;
  chainName: string;

  signatureUrl: string;

  bindedWallet: string;
}) => {
  const {deposit} = useProjectInvest(
    userAddress,
    contractAdress,
    chainId,
    chainName,
    tokenContractAdress,
    decimals
  );
  const {isInvestPending} = useProjectInvestStore();

  return (
    <>
      <Button
        text="BUY ALLOCATION"
        className="w-full"
        disabled={disabled}
        loading={isInvestPending}
        onClick={() =>
          deposit({
            amount: investedSum,
            balance: balance,
            signatureUrl,
            min: minAllocation,
            bindedWallet,
          })
        }
      />
    </>
  );
};
