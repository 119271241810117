import {ActiveCheckTick} from '../../../../assets/svgComponents/ActiveCheckTick';
import {IAnswerResult} from '../../types';
import {AnswerResult} from './AnswerResult';

export const CorrectAnswerResult = ({
  text,
}: Omit<IAnswerResult, 'Icon' | 'title'>) => {
  return (
    <AnswerResult
      text={text}
      title="That's right"
      Icon={<ActiveCheckTick className="h-6 w-6" fillColor="#61B60C" />}
    />
  );
};
