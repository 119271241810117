import {AddUser} from '../../../assets/svgComponents/AddUser';
import LyingPersonIcon from '../../../assets/LyingPersonIcon.png';
import {useUserStore} from '../../AccountProgress/store/useUserStore';
import {useSignUpStore} from '../../Login/store/useSignUpStore';
import Skeleton from 'react-loading-skeleton';
import {SkeletonColorClasses} from '../../../layouts/constants';
import {Tooltip} from '../../../components/Tooltip';
import {useState} from 'react';
import {useDashboardStore} from '../store/store';
import {InfoIcon} from '../../../assets/svgComponents/InfoIcon';
import {getReferralLink} from '../../../utilities/getReferralLink';

export const ReferalCodeWidget = () => {
  const {user} = useUserStore();
  const {actionPoints} = useSignUpStore();
  const [copied, setCopied] = useState(false);
  const {invitedCount, referralBonus} = useDashboardStore();

  const handleClick = () => {
    if (user?.referralCode) {
      void navigator.clipboard.writeText(getReferralLink(user.referralCode));
    }

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  if (!actionPoints) {
    return (
      <div className="rounded-20 p-4 xl:p-6 border-[1px] bg-[#0B0D0E] border-l-100 border-solid">
        <Skeleton style={{marginBottom: SkeletonColorClasses.mb}} height={90} />
      </div>
    );
  }

  return (
    <div className="bg-green-200 rounded-xl text-black p-4 relative font-sans overflow-hidden">
      <div className="flex items-center gap-2 mb-[6px]">
        <AddUser />
        <span className="text-sm font-semibold leading-6">Invite Friends</span>
      </div>
      <img
        className="absolute -top-2.5 -right-2.5 mix-blend-luminosity"
        src={LyingPersonIcon as string}
      />
      <p className="text-sm leading-6 mb-6 pe-[23px]">
        {'You’ve invited '}
        <strong>{`${invitedCount} friends`}</strong>
        {'! Earn '}
        <strong>{`${referralBonus}% of points`}</strong>
        {'  from all people you invited'}
      </p>

      <div className="flex gap-2 items-center mb-[6px]">
        <span className="font-semibold uppercase text-xs">
          YOUR REFERRAL CODE
        </span>

        <Tooltip
          content="Your Friends should paste this code during registration process on Raiser platform."
          contentClassName={'w-[240px] text-sm !py-[12px] !px-[12px]'}
          triggerClassName={'text-black'}
        >
          <InfoIcon
            iIconProps={{stroke: '#E5FD55'}}
            circleProps={{fill: 'black', fillOpacity: '0.4'}}
          />
        </Tooltip>
      </div>

      <button
        disabled={copied}
        className="bg-[right_24px_center] bg-no-repeat bg-[url('../src/assets/copyIconYellow.svg')] hover:bg-[url('../src/assets/copyIconBlack.svg')] relative w-full flex justify-start ps-[24px] pe-[48px] items-center gap-[10px] rounded-[42px] h-[40px] bg-black text-green-200 hover:bg-white hover:text-black uppercase font-mono  font-bold"
        onClick={handleClick}
      >
        {copied ? 'copied' : user?.referralCode}
      </button>
    </div>
  );
};
