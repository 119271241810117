import React, {useEffect} from 'react';
import {Button} from '../../components/Button';
import {SkipButton} from '../../components/SkipButton';
import {
  stepsContentHeadingFlexboxClasses,
  stepsMainHeadingClasses,
} from '../../constants/stepsLayoutVariables';
import {signUpService} from '../../services/SignUp';
import {useSignUpStore} from '../Login/store/useSignUpStore';
import {handleNextStep, handleSkipStep} from '../Login/helpers/handleSteps';
import {useSearchParams} from 'react-router-dom';

export const ReferralCodeView = () => {
  const [searchParams] = useSearchParams();

  const {
    refCode,
    setRefCode,
    isActiveButton,
    setIsActiveButton,
    responseRefCode,
    setResponseRefCode,
    loading,
  } = useSignUpStore();

  const handleRefCodeCheckClick: () => Promise<void> = async () => {
    const response = await signUpService.sendReferralCodeRequest();
    if (response?.status === 200) {
      setResponseRefCode(200);
    } else {
      setResponseRefCode(400);
    }
  };
  const handleRefCodeNextClick = () => {
    if (responseRefCode.status === 200) {
      handleNextStep();
    }
  };

  const handleRefCodeSkip = () => {
    handleSkipStep();
  };

  const handleReferallCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefCode(event.target.value);
  };

  useEffect(() => {
    setIsActiveButton(!!refCode);
  }, [refCode]);

  useEffect(() => {
    const referalLinkCode = searchParams.get('ref');

    if (referalLinkCode && !refCode) {
      setRefCode(referalLinkCode);
    }
  }, []);

  return (
    <div className="flex flex-col items-center w-full">
      <div className={stepsContentHeadingFlexboxClasses}>
        <span
          className={`self-start lg:self-auto w-[100%] ${stepsMainHeadingClasses}`}
        >
          Do You Have Referral Code?
        </span>
      </div>
      <div className="flex w-full lg:w-fit flex-col pt-[36px] lg:pt-[48px] items-center justify-center">
        <div className="flex pb-[6px] self-baseline">
          <span className="uppercase text-xs lg:text-[12px] leading-[20px] text-white">
            Referral code
          </span>
        </div>
        <input
          type="text"
          id="email"
          name="email"
          disabled={responseRefCode.status === 200 ? true : false}
          placeholder="Enter your referral code"
          className={`px-5 py-[15px] focus:outline-none ${
            responseRefCode.status === 200 || responseRefCode.status === 0
              ? 'border-[#535456]'
              : 'border-[#9b383c]'
          } ${
            responseRefCode.status === 200
              ? 'pointer-events-none opacity-50'
              : 'opacity-100 pointer-events-auto'
          } border-[1px] font-mono text-[14px] font-normal bg-[#0A0B0D] text-white rounded-[12px] w-full lg:w-[414px] placeholder-[#535456]`}
          onChange={handleReferallCode}
          value={refCode}
        />
        {responseRefCode.status === 200 || responseRefCode.status === 400 ? (
          <div className="flex self-baseline">
            <span
              className={`text-[12px] leading-[20px] ${
                responseRefCode.status === 200
                  ? 'text-[#76ED00]'
                  : 'text-[#FB4949]'
              }  mt-[6px]`}
            >
              {responseRefCode.status === 200
                ? 'Referral code applied'
                : 'Invalid referral code'}
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
      {responseRefCode.status === 200 ? (
        <></>
      ) : (
        <div className="flex relative justify-center items-center pt-[20px] text-center">
          <Button
            loading={loading}
            text="confirm"
            className={`${
              isActiveButton ? 'bg-white hover:bg-[#cccccc]' : ''
            } w-[338px] h-12 lg:h-14 lg:w-[414px] text-black`}
            onClick={handleRefCodeCheckClick}
            disabled={!isActiveButton || loading}
          />
        </div>
      )}
      <div
        className={`flex relative justify-center items-center text-center ${
          responseRefCode.status === 200
            ? 'pt-[87px] lg:pt-[172px]'
            : 'pt-[70px] lg:pt-[122px]'
        } `}
      >
        <Button
          className="h-12 w-[337px] lg:w-[414px]"
          text="Next step"
          onClick={handleRefCodeNextClick}
          disabled={responseRefCode.status !== 200 || !isActiveButton}
          withArrow
        />
      </div>
      <SkipButton text="skip step" onClick={handleRefCodeSkip} />
    </div>
  );
};
