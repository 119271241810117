import React, {useEffect} from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {SkeletonTheme} from 'react-loading-skeleton';
import {EthereumClient} from '@web3modal/ethereum';
import {Web3Modal} from '@web3modal/react';
import {WagmiConfig} from 'wagmi';

import {chains, projectId, wagmiConfig} from './configs/wagmi.config';
import {Price} from './services/price';

import {LayoutLoginView} from './layouts';
import {SkeletonColorClasses} from './layouts/constants';
import {LayoutStepsSignUp} from './layouts/LayoutStepsSignUp';
import {PrivateRoute} from './components/PrivateRoute/PrivateRoute';

import {DashboardView} from './modules/Dashboard';
import {ProjectDetailsView} from './modules/ProjectDetails';
import {useSignInStore} from './modules/Login/store/useSignInStore';
import {RegistrationView, VerificationCodeView} from './modules/Login';
import {AccountLevelView} from './modules/AccountLevel/AccountLevelView';
import {ProjectDisclaimer} from './modules/ProjectInvest/Disclaimer/ProjectDisclaimer';
import {ProjectInvest} from './modules/ProjectInvest/Allocation';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {FaqAndSupport} from './modules/FaqAndSupport/FaqAndSupport';
import {BindWalletModal} from './modules/BindWalletModal';
import {FCOView} from './modules/FCO';
import {MaintenancePage} from './layouts/MaintenancePage';
import {healthService} from './services/health';
import {Campaigns} from './modules/Campaigns';
import {Woodle} from './modules/Woodle';
import {ConnectWalletModal} from './modules/ConnectWalletModal';

const ethereumClient = new EthereumClient(wagmiConfig, chains);

export const App = () => {
  const {emailSignIn} = useSignInStore();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.classList.add('rootFade');
      setTimeout(() => {
        rootElement?.classList.remove('rootFade');
      }, 600);
    }
  }, [pathname]);

  useEffect(() => {
    void Price.fetchPrice();
    void healthService.checkAPIHealth(navigate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SkeletonTheme
        baseColor={SkeletonColorClasses.baseColor}
        highlightColor={SkeletonColorClasses.highlightColor}
        enableAnimation
      >
        <WagmiConfig config={wagmiConfig}>
          <div>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute
                    redirectCondition={!!localStorage.getItem('accessToken')}
                    redirectRoute={'/dashboard'}
                  >
                    <LayoutLoginView>
                      <RegistrationView />
                    </LayoutLoginView>
                  </PrivateRoute>
                }
              />
              <Route
                path="/code"
                element={
                  <PrivateRoute
                    redirectCondition={!emailSignIn}
                    redirectRoute={'/'}
                  >
                    <LayoutLoginView>
                      <VerificationCodeView />
                    </LayoutLoginView>
                  </PrivateRoute>
                }
              />
              <Route
                path="/registration"
                element={
                  <PrivateRoute>
                    <LayoutStepsSignUp />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/project/:id"
                element={
                  <PrivateRoute>
                    <ProjectDetailsView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/disclaimer"
                element={
                  <PrivateRoute>
                    <ProjectDisclaimer />
                  </PrivateRoute>
                }
              />
              <Route
                path="/invest"
                element={
                  <PrivateRoute>
                    <ProjectInvest />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <DashboardView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/campaigns"
                element={
                  <PrivateRoute>
                    <Campaigns />
                  </PrivateRoute>
                }
              />
              <Route
                path="/FCO"
                element={
                  <PrivateRoute>
                    <FCOView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <AccountLevelView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/faq-and-support"
                element={
                  <PrivateRoute>
                    <FaqAndSupport />
                  </PrivateRoute>
                }
              />
              <Route
                path="/server-under-maintenance"
                element={<MaintenancePage />}
              />
              <Route path="/woodle" element={<Woodle />} />
              <Route
                path="*"
                element={<Navigate to={'/dashboard'} replace />}
              />
            </Routes>
          </div>
          <BindWalletModal />
          <ConnectWalletModal />
        </WagmiConfig>
      </SkeletonTheme>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        closeButton={false}
        limit={3}
      />
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
};
