import {useQuizStore} from '../store/useQuizStore';

import {Option} from './Option';
import {AnswerType} from '../types';

export function QuizQuestions() {
  const {questions, currentQuestionNumber, previousAnswers} = useQuizStore();
  const currentQuestion = questions[currentQuestionNumber];

  return (
    <div className="text-white text-sm leading-6 font-sans mb-10">
      <div
        className="mb-8 f question-wrapper"
        dangerouslySetInnerHTML={{
          __html: currentQuestion.text,
        }}
      />

      <div className="flex flex-col gap-[28px]">
        {currentQuestion.answers.map(({id, text}) => (
          <Option
            key={id}
            text={text}
            answerId={id}
            isSingle={currentQuestion.answerType === AnswerType.SINGLE}
            questionId={
              previousAnswers.length > currentQuestionNumber
                ? currentQuestion.id
                : null
            }
          />
        ))}
      </div>
    </div>
  );
}
