import {SocialStates, isDevelopment} from '../constants/common';

const rootUrl = 'https://twitter.com/i/oauth2/authorize';

const options = {
  redirect_uri: isDevelopment
    ? 'http://www.localhost:3000/registration'
    : window.location.origin + '/registration', // client url cannot be http://localhost:3000/ or http://127.0.0.1:3000/
  client_id: process.env.REACT_APP_TWITTER_CLIENT_ID as string,
  state: SocialStates.twitter,
  response_type: 'code',
  code_challenge: process.env.REACT_APP_TWITTER_CODE_CHALLENGE as string,
  code_challenge_method: 'S256',
  scope: ['users.read', 'tweet.read'].join(' '),
};
// eslint-disable-next-line node/no-unsupported-features/node-builtins
const qs = new URLSearchParams(options).toString();

export const TWITTER_LOGIN_LINK = `${rootUrl}?${qs}`;
