import {getLevelStateIcon} from '../../utilities/getLevelStateIcon';
import {AccountRoadmapItemLineView} from './AccountRoadmapItemLineView';
import {LevelStore, useLevelsStore} from '../AccountLevel/store/useLevelsStore';
import {useUserStore} from '../AccountProgress/store/useUserStore';
import {ProgressCircle} from '../../components/ProgressCircle';
import {roundNumber} from '../../utilities/roundNumber';

export interface AccountRoadmapItemProps {
  level: LevelStore;
}

export const AccountRoadmapItemView = ({level}: AccountRoadmapItemProps) => {
  const {user} = useUserStore();
  const {levels} = useLevelsStore();
  return (
    <>
      {user && levels && (
        <li key={level.id} className="flex flex-col-reverse xl:block">
          <div
            className={`min-h-[2.5rem] flex justify-between items-center pr-4 pl-1.5  rounded-3xl relative ${
              (user?.levelData?.currentLevel?.value || 0) >= level.value
                ? 'bg-[rgba(229,253,85,0.05)]'
                : 'bg-l-50 '
            }`}
          >
            <div className="flex items-center gap-2 font-mono">
              {(user?.levelData?.currentLevel?.value || 0) + 1 ===
              level.value ? (
                <div className="relative w-7 h-7">
                  <ProgressCircle
                    currentValue={user.levelData.progress}
                    prevValue={
                      user?.levelData?.currentLevel?.pointsToReach || 0
                    }
                    maxValue={
                      user?.levelData?.nextLevelPoints?.pointsToReach || 0
                    }
                  />
                </div>
              ) : (
                <img
                  src={getLevelStateIcon(user, level.value)}
                  alt="levelBarIcon"
                />
              )}
              <div className="flex items-center gap-2 font-mono">
                <span
                  className={`${
                    (user?.levelData?.currentLevel?.value || 0) >= level.value
                      ? 'text-green-200'
                      : 'text-l-600'
                  }`}
                >
                  LVL
                </span>
                <span
                  className={`font-bold ${
                    (user?.levelData?.currentLevel?.value || 0) >= level.value
                      ? 'text-green-200'
                      : 'text-white'
                  }`}
                >
                  {level.value}
                </span>
              </div>
            </div>
            {level.pointsToReach !== 0 && (
              <div className="text-l-600 text-xs font-mono uppercase">
                {level.pointsToReach}pts
              </div>
            )}
          </div>
          {level.value !== levels.length - 1 && (
            <div className="flex items-start gap-6">
              <AccountRoadmapItemLineView level={level} />
              {(user?.levelData?.currentLevel?.value || 0) + 1 ===
                level.value && (
                <ul className="py-[14px]">
                  <li className="text-sm font-mono">
                    {roundNumber(
                      (user?.levelData?.nextLevelPoints?.pointsToReach || 0) -
                        user.levelData.progress
                    )}
                    PTS until level up
                  </li>
                </ul>
              )}
            </div>
          )}
        </li>
      )}
    </>
  );
};
