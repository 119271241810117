import {useEffect} from 'react';
import {ActiveCheckTick} from '../../assets/svgComponents/ActiveCheckTick';
import {CircleStatus} from '../../assets/svgComponents/CircleStatus';
import {CircleStatusNotActive} from '../../assets/svgComponents/CircleStatusNotActive';
import {CircleStatusSkip} from '../../assets/svgComponents/CircleStatusSkip';
import {Step} from '../../layouts/LayoutStepsSignUp';

import {signUpService} from '../../services/SignUp';
import {userService} from '../../services/userApi';
import {useUserStore} from '../AccountProgress/store/useUserStore';
import {getActionPointsForStep} from '../Login/helpers/handleSteps';
import {updateStepStatus} from '../Login/helpers/updateStepStatus';
import {useSignUpStore} from '../Login/store/useSignUpStore';
import {useStepsStore} from '../Login/store/useStepsStore';
import {calculateProgress} from '../../utilities/calculateProgress';
import {roundNumber} from '../../utilities/roundNumber';

export const StepsPanel = () => {
  const {stepsArray, setSteps, activeStep, setActiveStep} = useStepsStore();

  const {user} = useUserStore();
  const {actionPoints, setMessageFromRequestSignUp} = useSignUpStore();

  useEffect(() => {
    if (!actionPoints) {
      void signUpService.getActionPoints();
    }
  }, [actionPoints]);

  const renderStepStatus = (item: Step) => {
    if (activeStep === item.id) {
      return item.isCompleted ? <ActiveCheckTick /> : <CircleStatus />;
    } else if (item.isSkipped) {
      return <CircleStatusSkip />;
    } else if (item.isActive) {
      return item.isCompleted ? <ActiveCheckTick /> : <CircleStatus />;
    } else if (item.isCompleted) {
      return <ActiveCheckTick />;
    } else {
      return <CircleStatusNotActive />;
    }
  };

  useEffect(() => {
    updateStepStatus();
  }, [user]);

  useEffect(() => {
    void userService.getUserData();
  }, []);

  const handleStepClick = (stepId: number) => {
    setMessageFromRequestSignUp('');
    const updatedStepsArray = new Array(...stepsArray);
    const prevActiveStep = activeStep;

    const newSteps = updatedStepsArray.map(step => {
      if (step.id !== stepId) return step;

      if (!step.isSkipped && !step.isCompleted) {
        if (!step.isActive) {
          const firstStep = updatedStepsArray[0];
          if (!firstStep.isCompleted) {
            firstStep.isActive = false;
            firstStep.isCompleted = false;
            firstStep.isSkipped = true;
          }
        }

        if (!step.isCompleted && !step.isSkipped) {
          step.isSkipped = true;
          step.isActive = false;
          step.isCompleted = false;
        }
      }
      return step;
    });

    setActiveStep(stepId);

    if (prevActiveStep !== undefined) {
      const lastIndex = updatedStepsArray.length - 1;
      const lastStep = updatedStepsArray[lastIndex];
      if (lastStep.id === prevActiveStep && !lastStep.isCompleted) {
        lastStep.isSkipped = true;
      }
    }
    setSteps(newSteps);
  };

  const getTextColorClass = (item: Step) => {
    if (activeStep === item.id) {
      return item.isCompleted ? 'text-white' : 'text-white';
    } else if (item.isSkipped || item.isActive || item.isCompleted) {
      return 'text-[#E5FD55]';
    } else {
      return 'text-white';
    }
  };

  const currentLevelProgress = user
    ? calculateProgress(
        user?.levelData.progress,
        user?.levelData?.nextLevelPoints?.pointsToReach,
        user?.levelData?.currentLevel?.pointsToReach
      )
    : 0;

  return (
    <div className="lg:min-w-[456px] hidden lg:block bg-[#0E1012] z-50 p-[2.5rem] rounded-l-[20px]">
      <div className="hidden lg:block w-full pt-2 max-w-[305px]">
        <span className="text-white font-sans text-[32px] font-bold leading-[40px] tracking-[-0.64px]">
          Set Up Your <br></br>
          <span className="text-[#E5FD55] font-sans text-[32px] font-bold leading-[40px] tracking-[-0.64px]">
            Raiser Account
          </span>
        </span>
      </div>
      <div className="hidden lg:block pt-[10px]">
        <span className="text-white text-opacity-60 text-[14px] font-mono font-normal leading-[21px]">
          You can skip any step and fill it later<br></br> in settings
        </span>
      </div>
      <div className="hidden lg:flex flex-col pt-[27px] pl-[19px]">
        {stepsArray.map((item, key) => (
          <div
            key={key}
            className={`flex w-[338px] relative mb-[28px] min-h-[46px] cursor-pointer rounded-[39px] flex-row ${
              activeStep === item.id ? 'bg-[#1a1c1e]' : ''
            }`}
            onClick={() => {
              handleStepClick(item.id);
            }}
          >
            {key < stepsArray.length - 1 && (
              <div
                className={`flex flex-col absolute h-[24px] w-[1px] top-[48px] left-[23px] ${
                  item.isSkipped || item.isCompleted
                    ? 'bg-[#E5FD55]'
                    : 'bg-[#3e4041]'
                }`}
              ></div>
            )}
            <div className="flex w-[36px] h-[36px] self-center pl-[8.4px]">
              {renderStepStatus(item)}
            </div>
            <div className="flex flex-1 min-w-[274px] text-center justify-between self-center pr-4 pl-[12px] pt-[1px]">
              <span
                className={`font-mono text-[16px] font-normal leading-[20px] ${getTextColorClass(
                  item
                )}`}
              >
                {item.name}
              </span>
              {actionPoints && activeStep === item.id && (
                <div className="flex self-center left-[250px]">
                  <span className="text-white text-[12px] font-mono font-bold leading-[20px]">
                    +{getActionPointsForStep()} points
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="hidden lg:flex flex-col bg-[#E5FD55] rounded-[10px] px-[32px] py-[24px]">
        <div className="flex justify-between">
          <div>
            <span className="font-sans text-[#000] font-semibold text-[24px] leading-[24px] tracking-[-0.48px] ">
              Progress
            </span>
          </div>
          <div>
            {user?.levelData.nextLevelPoints ? (
              <span className="text-[40px] font-normal font-mono leading-[24px]">
                {roundNumber(user?.levelData.progress, 0)}
                <span className="font-mono text-[16px] font-normal text-[#000] leading-[24px] tracking-[-0.32px]">
                  /{user?.levelData.nextLevelPoints?.pointsToReach ?? 0}
                </span>
                <span className="font-mono text-[16px] pl-[11px] text-[#000] leading-[24px] font-normal tracking-[-0.32px]">
                  pts
                </span>
              </span>
            ) : (
              <span className="text-sm">You have reached a max level</span>
            )}
          </div>
        </div>
        <div className="flex pt-[6px]">
          <div className="relative h-[6px] bg-gray-900 bg-opacity-30 rounded-[10px] w-full ">
            <div
              className="absolute w-10 h-[6px] rounded-[10px] bg-black"
              style={{
                width: `${currentLevelProgress}%`,
              }}
            ></div>
          </div>
        </div>
        <div className="flex pt-[10px] ">
          {user && user.levelData?.nextLevelPoints && (
            <span className="text-[12px] font-normal font-mono leading-[18px] text-gray-600	">
              You are on level {user?.levelData?.currentLevel?.value}. You
              need&nbsp;
              {roundNumber(
                user.levelData.nextLevelPoints.pointsToReach -
                  user.levelData.progress
              )}
              &nbsp;points to reach {''}
              level {user?.levelData?.nextLevelPoints.value}.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
