import {useShallow} from 'zustand/react/shallow';
import {Button} from '../../../../components/Button';
import {LetterState} from '../../constants';
import {woodleStore} from '../../store';
import {Letter} from '../Words/Letter';

export const ModalBody = ({handleClose}: {handleClose: () => void}) => {
  const {topic, reward, attempts} = woodleStore(
    useShallow(({currentWoodle}) => ({
      topic: currentWoodle?.topic || '',
      reward: currentWoodle?.pointReward || '',
      attempts: currentWoodle?.attempts || '',
    }))
  );

  return (
    <div className="bg-[#0B0D0E] md:rounded-[20px] rounded-t-20 md:border border-[#232526] border-l-1 w-screen md:w-[560px] relative">
      <div
        className="sm:hidden relative top-[10px] w-[78px] border-2 border-white rounded-[32px] m-auto"
        onClick={handleClose}
        onDragStart={handleClose}
        onTouchEnd={handleClose}
      ></div>
      <h3 className="border-b border-l-100 px-5 py-7 md:p-10 text-white text-2xl leading-9 font-bold">
        How to play
      </h3>
      <div className="p-5 pt-8 md:px-10 md:pb-8">
        <div className="flex flex-col gap-3 mb-6">
          <p className=" text-xs md:text-sm leading-9 text-l-600">
            Guess the Word in {attempts} tries.
          </p>
          <p className="text-xs md:text-sm leading-9 text-l-600">
            After each guess, the color of the tiles will change to show how
            close your guess was.
          </p>
        </div>
        <div className="text-white flex flex-col gap-4">
          <div>Example</div>
          <div className="flex gap-3">
            <Letter letter="R" state={LetterState.wrong} />
            <Letter letter="A" state={LetterState.included} />
            <Letter letter="I" state={LetterState.wrong} />
            <Letter letter="S" state={LetterState.correct} />
            <Letter letter="E" state={LetterState.correct} />
            <Letter letter="R" state={LetterState.wrong} />
          </div>
          <div className="flex flex-col gap-4 text-l-600 mb-8">
            <div className="flex gap-4">
              <div className="w-5 h-5 shrink-0 rounded-[4px] bg-[#00AF46]" />
              <div>
                The letter(s) <strong className="text-white">S, E</strong> is in
                the word and in the correct position.
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-5 h-5 shrink-0 rounded-[4px] bg-[#CFE44D]" />
              <div>
                The letter <strong className="text-white">A</strong> is in the
                word, but not in the correct position.
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-5 h-5 shrink-0 rounded-[4px] bg-[#1D1E1E]" />
              <div>
                The letter(s) <strong className="text-white">R, I</strong> is
                not in the word.
              </div>
            </div>
          </div>

          <div className="flex gap-14 text-sm leading-6 mb-10">
            <div className="flex flex-col gap-4 ">
              <div className="font-semibold ">Topic</div>
              <div className="text-l-600">{topic}</div>
            </div>
            <div className="flex flex-col gap-4 ">
              <div className="font-semibold ">Reward</div>
              <div className="text-l-600">{reward}PTS</div>
            </div>
            <div className="flex flex-col gap-4 ">
              <div className="font-semibold ">Attempts</div>
              <div className="text-l-600">{attempts}</div>
            </div>
          </div>
          <Button className="w-full" text={'OK'} onClick={handleClose} />
        </div>
      </div>
    </div>
  );
};
