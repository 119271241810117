import {useEffect, useState} from 'react';

import {useUserStore} from '../modules/AccountProgress/store/useUserStore';

import {projectService} from '../services/projectApi';

export const useIsInvestAvailable = (signatureUrl?: string) => {
  const {user} = useUserStore();
  const [isInvestAvailable, setIsInvestAvailable] = useState(false);

  useEffect(() => {
    projectService
      .checkProjectInvestAvailability(
        signatureUrl,
        user?.connectedWallets?.at(0)?.address
      )
      .then(res => {
        setIsInvestAvailable(res);
      })
      .catch(() => {
        setIsInvestAvailable(false);
      });
  }, [user, signatureUrl]);

  return {isInvestAvailable};
};
