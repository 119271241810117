import {IMyTask} from '../../../types/tasks';
import {WoodleTaskStatus} from '../../Woodle/store';
import {getNextDate} from '../helpers/getNextDate';

export const WodlResultStatus = ({
  userWodlResult,
}: Pick<IMyTask, 'wodl' | 'userWodlResult'>) => {
  const retryDate = getNextDate(userWodlResult?.retryAfter);

  return (
    <div className="font-bold text-xs uppercase font-mono tracking-[-0.24px] flex items-center gap-x-3 gap-y-1 flex-wrap">
      <div
        className={`${
          userWodlResult?.status === WoodleTaskStatus.FAILED
            ? 'text-[#FB4949]'
            : 'text-white'
        }`}
      >
        {userWodlResult?.status === WoodleTaskStatus.IN_PROGRESS &&
          'In progress'}
        {userWodlResult?.status === WoodleTaskStatus.FAILED && 'Failed'}
      </div>
      {!!retryDate && (
        <div>
          <span>{retryDate}</span>
        </div>
      )}
    </div>
  );
};
