import {useEffect} from 'react';
import {LayoutDashboardView} from '../../layouts/LayoutDashboardView';
import {Campaign} from './components/Campaign';
import {useCampaignsStore} from './store/useCampaignsStore';
import {campaignsService} from './service/campaignsService';

export const Campaigns = () => {
  const {campaigns} = useCampaignsStore();

  useEffect(() => {
    void campaignsService.getCampaigns();
  }, []);

  return (
    <LayoutDashboardView>
      <div className="p-2 pt-5 md:p-10 flex flex-col gap-5">
        {campaigns.map(campaign => (
          <Campaign campaign={campaign} key={campaign.id} />
        ))}
      </div>
    </LayoutDashboardView>
  );
};
