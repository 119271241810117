import React from 'react';
import './index.css';

export const LongArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="117"
      height="10"
      viewBox="0 0 117 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 5H116M116 5L112 1M116 5L112 9" stroke="#4F5050" />
    </svg>
  );
};
