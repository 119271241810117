import {NextArrow} from '../../../assets/svgComponents/NextArrow';
import {socialsColGradientClasses} from '../../../constants/stepsLayoutVariables';

export const SocialCard = ({
  imgSrc,
  name,
  isConnected,
  username,
  handleConnect,
  handleDisconnect,
}: {
  imgSrc: string;
  name: string;
  isConnected: boolean;
  username?: string;
  handleConnect: () => void;
  handleDisconnect: () => void;
}) => {
  return (
    <div
      className={`flex group bg-[#111214] relative cursor-pointer min-h-[62px] sm:h-[200px] rounded-[10px] overflow-hidden ${
        isConnected ? 'opacity-70' : ''
      }`}
      onClick={isConnected ? handleDisconnect : handleConnect}
    >
      <span className={socialsColGradientClasses}></span>
      <div className="absolute left-[-40px] sm:left-[initial] w-[94px] top-1/2 h-[56px] sm:w-auto sm:h-auto -translate-y-1/2 sm:-translate-y-0 sm:right-0 sm:top-0 scale-75 sm:scale-100">
        <img src={imgSrc} alt="" />
      </div>
      <div className="flex justify-between items-center sm:items-start sm:flex-col self-end py-[1.125rem] gap-y-[14px] px-4 pl-[68px] sm:pl-6 sm:p-5 w-full">
        <span className="text-white text-base text-[14px] sm:text-[20px] font-bold leading-[20px] font-sans">
          {name}
        </span>
        <div className="text-sm leading-none font-medium font-sans text-l-600">
          {!!username && `@${username}`}
        </div>
        <div className="flex justify-between sm:w-full">
          <div className="flex items-center">
            <span className="text-[12px] sm:text-[14px] text-white font-mono uppercase font-bold leading-[24px]tracking-[-0.28px]">
              {!isConnected ? (
                'Connect'
              ) : (
                <span className="text-[12px] sm:text-[14px] text-[#76ED00] font-mono uppercase font-bold leading-[24px]tracking-[-0.28px]">
                  Connected
                </span>
              )}
            </span>
            <div className="relative left-[16px] hidden sm:flex items-center  pb-[1.5px]">
              {!isConnected ? <NextArrow fill="white" /> : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
