export const getNextDate = (retryAfter?: string | null) => {
  if (retryAfter) {
    const targetDate = new Date(retryAfter);
    const currentDate = new Date();
    const timeDifference = targetDate.getTime() - currentDate.getTime();

    const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesLeft = Math.ceil(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    if (hoursLeft > 0) {
      return `Next attempt in ${hoursLeft}h`;
    } else if (minutesLeft > 0) {
      return `Next attempt in ${minutesLeft}m`;
    }
  }

  return null;
};
