import {
  ISelectedAllocation,
  claimModalStore,
} from '../store/useClaimModalStore';

const claimModalApi = () => {
  const {setState} = claimModalStore;

  const setSelectedAllocation = (selectedAllocation: ISelectedAllocation) => {
    setState({selectedAllocation});
  };

  const clearSelectedAllocation = () => {
    setState({selectedAllocation: null});
  };

  const setClaimError = (error: string) => {
    setState({claimError: error});
  };

  const clearClaimError = () => {
    setState({claimError: null});
  };

  return {
    setSelectedAllocation,
    clearSelectedAllocation,
    setClaimError,
    clearClaimError,
  };
};

export const claimModalService = claimModalApi();
