import {CircleCross} from '../../../../assets/svgComponents/CircleCross';
import {IAnswerResult} from '../../types';
import {AnswerResult} from './AnswerResult';

export const ForgottenAnswerResult = ({
  text,
}: Omit<IAnswerResult, 'Icon' | 'title'>) => {
  return (
    <AnswerResult
      text={text}
      title="You're missing an option"
      Icon={<CircleCross className="h-6 w-6" />}
    />
  );
};
