'use strict';

import {create, useStore} from 'zustand';

import {tempStore} from './tempStore';

export interface FaqAndSupportStore {
  list: FaqAndSupportElementType[];
}

export interface FaqAndSupportElementType {
  id: string;
  title: string;
  content: string;
}

const initialState = tempStore;

export const faqAndSupportStore = create<FaqAndSupportStore>(set => ({
  list: initialState,
}));

export const useFaqAndSupportStore = () => useStore(faqAndSupportStore);
