import {HeadlessService} from '@novu/headless';
import {INotification, notificationsStore} from './store';
import {userStore} from '../../AccountProgress/store/useUserStore';

const app_key = process.env.REACT_APP_NOVU_APP_KEY || '';

const notificationsApi = () => {
  const {setState, getState} = notificationsStore;

  let headlessService: HeadlessService | null = null;
  let isSessionInitialized = false;
  let isListenerSettled = false;

  const createNewHandlessService = (successCallback?: () => void) => {
    const {getState} = userStore;
    const user = getState().user;

    if (!user) {
      return;
    }

    try {
      headlessService = new HeadlessService({
        applicationIdentifier: app_key,
        subscriberId: user.id,
        config: {
          retry: 0,
        },
      });

      headlessService.initializeSession({
        listener: () => {},
        onSuccess: () => {
          isSessionInitialized = true;
          successCallback && successCallback();
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const listenNotifications = () => {
    if (isListenerSettled) {
      return;
    }

    if (!headlessService || !isSessionInitialized) {
      return createNewHandlessService(getNotificationsList);
    }

    headlessService?.listenNotificationReceive({
      listener: result => {
        const oldNotifications = getState().notifications;
        setState({
          notifications: [result as INotification, ...oldNotifications],
        });
      },
    });
    isListenerSettled = true;
  };

  const getNotificationsList = () => {
    if (!headlessService || !isSessionInitialized) {
      return createNewHandlessService(getNotificationsList);
    }

    headlessService?.fetchNotifications({
      listener: () => {},
      onSuccess: messages => {
        setState({
          notifications: messages.data.filter(
            ({read}) => !read
          ) as INotification[],
        });
      },
      query: {read: false, limit: 100},
    });

    listenNotifications();
  };

  const markNotificationAsRead = async (messageId: string) => {
    if (!headlessService || !isSessionInitialized) {
      return createNewHandlessService(getNotificationsList);
    }

    await headlessService.markNotificationsAsRead({
      listener: () => {},
      onSuccess: () => {
        getNotificationsList();
      },

      messageId: [messageId],
    });
  };

  const markAllNotificationsAsRead = async () => {
    if (!headlessService || !isSessionInitialized) {
      return createNewHandlessService(getNotificationsList);
    }

    const notificationsIds = getState().notifications.map(({_id}) => _id);

    if (!notificationsIds.length) {
      return;
    }

    await headlessService.markNotificationsAsRead({
      listener: () => {},
      onSuccess: () => {
        setState({notifications: []});
      },

      messageId: notificationsIds,
    });
  };

  return {
    getNotificationsList,
    createNewHandlessService,
    listenNotifications,
    markNotificationAsRead,
    markAllNotificationsAsRead,
  };
};

export const notificationsService = notificationsApi();
