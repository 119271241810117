import React from 'react';
import {useWoodleStore} from '../../store';
import {NotificationInfoIcon} from '../../../../assets/svgComponents/NotificationInfoIcon';

import './Toast.css';

export const Toast: React.FC = () => {
  const {isToastShown} = useWoodleStore();

  return (
    <div className="absolute left-0 right-0 w-full">
      {isToastShown && (
        <div className="w-[260px] toast animate-toast bg-white text-white p-3 h-[68px] rounded absolute bottom-0 left-1/2 transform -translate-x-1/2">
          <div className="w-fit">
            <div className="flex items-center gap-[10px]">
              <div className="w-5 h-5">
                <NotificationInfoIcon className="[&_path]:fill-d-900 w-5 h-5" />
              </div>
              <div className="text-black font-bold text-sm leading-6 font-sans">
                Not enough letters
              </div>
            </div>
            <div className="text-black opacity-60 text-xs leading-5 ml-[30px]">
              Please complete the word
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
