import {createArray} from '../../../../utilities/createArray';
import {Letter} from './Letter';

export const UntouchedWord = ({length}: {length: number}) => {
  return (
    <div className="w-full flex gap-1 md:gap-3 justify-center">
      {createArray(length).map(i => (
        <Letter key={i} letter={''} />
      ))}
    </div>
  );
};
