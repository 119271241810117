import {CurrentWoodle} from '../store';
import {WoodleTop} from './WoodleTop/WoodleTop';
import {WoodleBody} from './WoodleBody/WoodleBody';
import {WoodleFooter} from './WoodleFooter/WoodleFooter';
import {useEffect, useLayoutEffect, useRef} from 'react';
import {woodleService} from '../service';

export const WoodleContent = ({
  currentWoodle,
}: {
  currentWoodle: CurrentWoodle;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const setInputFocus = () => {
    ref.current?.focus();
  };

  useEffect(() => {
    const listener = ({key}: KeyboardEvent) => {
      if (key === 'Enter') {
        void woodleService.handleSubmit();
      }
    };

    addEventListener('keydown', listener);

    return () => removeEventListener('keydown', listener);
  }, []);

  useLayoutEffect(() => {
    setInputFocus();
  }, []);

  return (
    <div
      className="w-full h-full flex flex-col items-center justify-center px-3"
      onFocusCapture={setInputFocus}
      onClick={setInputFocus}
    >
      <WoodleTop currentWoodle={currentWoodle} />

      <WoodleBody currentWoodle={currentWoodle} ref={ref} />

      <WoodleFooter currentWoodle={currentWoodle} />
    </div>
  );
};
