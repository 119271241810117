import React from 'react';

export const PaperIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        d="M19.1206 9.68457C18.6691 9.68457 18.071 9.67457 17.3264 9.67457C15.5105 9.67457 14.0173 8.17157 14.0173 6.33857V3.12257C14.0173 2.86957 13.8153 2.66357 13.5648 2.66357H8.27539C5.80693 2.66357 3.81177 4.68957 3.81177 7.17257V17.9476C3.81177 20.5526 5.90199 22.6636 8.48135 22.6636H16.358C18.8176 22.6636 20.8118 20.6506 20.8118 18.1656V10.1346C20.8118 9.88057 20.6108 9.67557 20.3593 9.67657C19.9365 9.67957 19.4295 9.68457 19.1206 9.68457Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M16.3958 3.23088C16.0968 2.91988 15.5748 3.13388 15.5748 3.56488V6.20188C15.5748 7.30788 16.4858 8.21788 17.5918 8.21788C18.2888 8.22588 19.2568 8.22788 20.0788 8.22588C20.4998 8.22488 20.7138 7.72188 20.4218 7.41788C19.3668 6.32088 17.4778 4.35488 16.3958 3.23088Z"
        fill="white"
      />
      <path
        d="M14.7298 15.5564C15.1408 15.5564 15.4748 15.8904 15.4748 16.3014C15.4748 16.7124 15.1408 17.0454 14.7298 17.0454H9.28577C8.87477 17.0454 8.54177 16.7124 8.54177 16.3014C8.54177 15.8904 8.87477 15.5564 9.28577 15.5564H14.7298ZM12.6707 10.5622C13.0817 10.5622 13.4157 10.8962 13.4157 11.3072C13.4157 11.7182 13.0817 12.0512 12.6707 12.0512H9.28567C8.87467 12.0512 8.54167 11.7182 8.54167 11.3072C8.54167 10.8962 8.87467 10.5622 9.28567 10.5622H12.6707Z"
        fill="white"
      />
    </svg>
  );
};
