import {create, useStore} from 'zustand';

export enum ActivityFeedTabs {
  notifications = 'notifications',
  activity = 'activity',
}

export interface ActivityFeedStore {
  isOpened: boolean;
  openedTab: ActivityFeedTabs;
}

export const activityFeedStore = create<ActivityFeedStore>(() => ({
  isOpened: false,
  openedTab: ActivityFeedTabs.notifications,
}));

export const useActivityFeedStore = () => useStore(activityFeedStore);
