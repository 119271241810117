import {create, useStore} from 'zustand';
import {LetterState} from './constants';

interface KnownLetter {
  letter: string;
  status: Omit<LetterState, LetterState.initial>;
}

export interface CurrentWoodle {
  id: string;
  attempts: number;
  wordLength: number;
  status: WoodleTaskStatus;
  pointReward?: number | null;
  ticketReward?: number | null;
  retryAfter?: string | null;
  topic: string;
}

export type KnownWord = Array<KnownLetter>;
export type KnownWords = Array<KnownWord>;

export enum WoodleTaskStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  DONE = 'DONE',
}
export type WoodleAnswer = Record<string, LetterState>;

interface WoodleStore {
  word: string;
  submittedWords: KnownWords;
  currentWoodle: CurrentWoodle | null;
  isShaking: boolean;
  isToastShown: boolean;
}

export const woodleStore = create<WoodleStore>(() => ({
  word: '',
  submittedWords: [],
  currentWoodle: null,
  isShaking: false,
  isToastShown: false,
}));

export const useWoodleStore = () => useStore(woodleStore);
