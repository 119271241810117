import {CurrentWoodle, WoodleTaskStatus} from '../../store';
import {Keyboard} from '../Keyboard';
import {WoodleFinishedBlock} from './components/WoodleFinishedBlock';

export const WoodleFooter = ({
  currentWoodle,
}: {
  currentWoodle: CurrentWoodle;
}) => {
  return (
    <div className="w-full flex justify-center">
      {currentWoodle.status === WoodleTaskStatus.IN_PROGRESS ? (
        <Keyboard />
      ) : (
        <WoodleFinishedBlock
          isWoodleSucceed={currentWoodle.status === WoodleTaskStatus.DONE}
        />
      )}
    </div>
  );
};
