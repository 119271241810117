import {create, useStore} from 'zustand';
import {EventPoints} from '../../../types/types';

interface SignUpState {
  code: string;
  loading: boolean;
  isActiveButton: boolean;
  responseRefCode: {
    status?: number | undefined;
  };
  refCode: string;
  firstName: string;
  lastName: string;
  messageFromRequestSignUp: string;
  messageFromRequestTelegram: string;
  valueGoogleToken: string;
  valueGoogleEmail: string | null;
  isConnectedTelegram: boolean;
  isConnectedTwitter: boolean;
  isConnectedDiscord: boolean;
  isVerified: boolean;
  actionPoints: EventPoints | null;
  isNew: boolean;
  setActionPoints: (actionPoints: null) => void;
  setCode: (code: string) => void;
  setResponseRefCode: (status: number) => void;
  setRefCode: (refCode: string) => void;
  setIsActiveButton: (isActiveButton: boolean) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setMessageFromRequestSignUp: (messageFromRequestSignUp: string) => void;
  setMessageFromRequestTelegram: (messageFromRequestTelegram: string) => void;
  setIsConnectedTelegram: (isConnectedTelegram: boolean) => void;
  setIsConnectedTwitter: (isConnectedTwitter: boolean) => void;
  setIsVerified: (isVerified: boolean) => void;
  setValueGoogleToken: (valueGoogleToken: string) => void;
  setValueGoogleEmail: (valueGoogleEmail: string | null) => void;
  setIsNew: (isNew: boolean) => void;
}

const initialState: SignUpState = {
  actionPoints: null,
  code: '',
  refCode: '',
  firstName: '',
  lastName: '',
  responseRefCode: {
    status: 0,
  },
  messageFromRequestSignUp: '',
  messageFromRequestTelegram: '',
  valueGoogleToken: '',
  valueGoogleEmail: '',
  isActiveButton: false,
  loading: false,
  isConnectedTelegram: false,
  isConnectedTwitter: false,
  isConnectedDiscord: false,
  isVerified: false,
  isNew: false,
  setActionPoints: () => {},
  setCode: () => {},
  setResponseRefCode: () => {},
  setRefCode: () => {},
  setIsActiveButton: () => {},
  setFirstName: () => {},
  setLastName: () => {},
  setIsConnectedTelegram: () => {},
  setIsConnectedTwitter: () => {},
  setIsVerified: () => {},
  setValueGoogleToken: () => {},
  setMessageFromRequestSignUp: () => {},
  setMessageFromRequestTelegram: () => {},
  setValueGoogleEmail: () => {},
  setIsNew: () => {},
};

export const signUpStore = create<SignUpState>(set => ({
  ...initialState,
  setRefCode: newRefCode => set({refCode: newRefCode}),
  setIsActiveButton: isActiveButton => set({isActiveButton}),
  setFirstName: firstName => set({firstName}),
  setLastName: lastName => set({lastName}),
  setMessageFromRequestSignUp: (message: string) => {
    set({messageFromRequestSignUp: message});
  },
  setMessageFromRequestTelegram: (message: string) => {
    set({messageFromRequestTelegram: message});
  },
  setActionPoints: newActionPoints => set({actionPoints: newActionPoints}),
  setIsConnectedTelegram: isConnectedTelegram => set({isConnectedTelegram}),
  setIsConnectedTwitter: isConnectedTwitter => set({isConnectedTwitter}),
  setIsVerified: isVerified => set({isVerified}),
  setResponseRefCode: (status: number | undefined) => {
    set(state => ({
      responseRefCode: {
        ...state.responseRefCode,
        status: status !== undefined ? status : state.responseRefCode.status,
      },
    }));
  },
  setValueGoogleToken: valueGoogleToken => set({valueGoogleToken}),
  setValueGoogleEmail: valueGoogleEmail => set({valueGoogleEmail}),
  setCode: (code: string) => {
    set({code});
  },
  setIsNew: (isNew: boolean) => {
    set({isNew});
  },
}));

export const useSignUpStore = () => useStore(signUpStore);
