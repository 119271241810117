import {IProjectRounds} from '../../../types/project';
import {shortenAddress} from '../../../utilities/idex';
import {AppliedOn} from './AppliedOn';
import {TokensClaimed} from './TokensClaimed';
import {TokensPurchased} from './TokensPurchased';
import {WalletUsed} from './WalletUsed';

export const AllocationsList = ({
  projectRounds,
  allocationTokenSymbol,
  tokenSymbol,
  tokenIconUrl,
  claimedTokensPercent,
}: {
  projectRounds: IProjectRounds;
  allocationTokenSymbol: string;
  tokenSymbol: string;
  tokenIconUrl: string;
  claimedTokensPercent: number;
}) => {
  return (
    <>
      {projectRounds.map(({title, boughtAllocations, id}) => (
        <div className="pb-4 border-b border-l-100 last:border-b-0" key={id}>
          <div className="flex justify-between mb-5 mt-8">
            <div className="font-sans text-2xl leading-9 font-semibold mb-2">
              {title}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            {boughtAllocations.map(
              ({amountInvested, price, id, createdAt, recipient}) => (
                <div
                  className="grid grid-cols-1 gap-y-5 xl:grid-cols-4 font-mono"
                  key={id}
                >
                  <TokensPurchased
                    allocationTokenSymbol={allocationTokenSymbol}
                    tokenSymbol={tokenSymbol}
                    amountInvested={amountInvested}
                    price={price}
                    iconUrl={tokenIconUrl}
                  />
                  <TokensClaimed
                    iconUrl={tokenIconUrl}
                    amount={(amountInvested / price) * claimedTokensPercent}
                  />
                  <AppliedOn appliedAt={createdAt} />
                  <WalletUsed wallet={shortenAddress(recipient)} />
                </div>
              )
            )}
          </div>
        </div>
      ))}
    </>
  );
};
