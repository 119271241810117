import {PositionLabel} from './PlaceLabel';
import {TableCell} from './TableCell';

export function TableRow({
  position,
  points,
  personal,
  className,
  positionChange,
}: {
  position: number | string;
  points: number;
  reward?: number | null;
  className?: string;
  positionChange?: number;
  personal?: boolean;
}) {
  return (
    <div
      className={`grid grid-cols-2 border-b-[1px] border-l-100 h-[56px] bg-[#0B0D0E] align-center ${
        className || ''
      }`}
    >
      <TableCell
        value={
          <PositionLabel
            position={position}
            positionChange={positionChange}
            personal={personal}
          />
        }
      />
      <TableCell value={points} />
    </div>
  );
}
