import {AccountRoadmapItemProps} from './AccountRoadmapItemView';
import {useUserStore} from '../AccountProgress/store/useUserStore';

export const AccountRoadmapItemLineView = ({
  level,
}: AccountRoadmapItemProps) => {
  const {user} = useUserStore();

  return (
    <>
      {user && (
        <div
          className={`w-[1px] ml-5
      ${
        (user?.levelData?.currentLevel?.value || 0) >= level.value
          ? 'bg-green-200'
          : 'bg-l-200'
      } ${
        (user?.levelData?.currentLevel?.value || 0) !== level.value
          ? 'h-[60px]'
          : 'h-[50px]'
      }`}
        ></div>
      )}
    </>
  );
};
