import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {userService} from '../../../../services/userApi';
import {tasksService} from '../../service/TasksService';
import {MyTaskStatus} from '../../../../types/tasks';

export const HandleTelegramButton = ({
  resourceId,
  id,
  status,
}: {
  resourceId: string;
  id: string;
  status?: Exclude<MyTaskStatus, MyTaskStatus.DONE>;
}) => {
  const isTelegramConnected = userService.isTelegramConnected();

  return (
    <div
      className={`flex items-center gap-[10px] text-xs font-bold uppercase leading-6 text-green-200 hover:underline cursor-pointer ${
        isTelegramConnected ? '' : 'opacity-40 !cursor-default'
      }`}
      onClick={() => {
        if (isTelegramConnected) {
          tasksService.handleResourceTaskClick(resourceId, id, status);
        }
      }}
    >
      Join
      <NextArrow width={24} height={4} fill="#E5FD55" />
    </div>
  );
};
