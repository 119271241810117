import {useQuizStore} from '../../store/useQuizStore';
import {quizService} from '../../service/service';
import {CorrectAnswerResult} from './CorrectAnswerResult';
import {MistakenAnswerResult} from './MistakenAnswerResult';
import {ForgottenAnswerResult} from './ForgottenAnswerResult';

export function QuizAnswerResult() {
  const {previousAnswers, questions, currentQuestionNumber} = useQuizStore();

  const currentAnswer = previousAnswers.at(currentQuestionNumber);
  const currentQuestion = questions.at(currentQuestionNumber);

  if (!currentAnswer || !quizService.isPreviousPage()) {
    return;
  }

  return (
    <div className="flex flex-col gap-3">
      {currentAnswer?.description ? (
        currentAnswer.correct ? (
          <CorrectAnswerResult text={currentAnswer?.description || ''} />
        ) : (
          <MistakenAnswerResult text={currentAnswer?.description || ''} />
        )
      ) : (
        <>
          {currentAnswer.answerIds.map(answerId =>
            currentAnswer.correctAnswersIds.includes(answerId) ? (
              <CorrectAnswerResult
                text={
                  currentQuestion?.answers.find(({id}) => id === answerId)
                    ?.text || ''
                }
                key={answerId}
              />
            ) : (
              <MistakenAnswerResult
                text={
                  currentQuestion?.answers.find(({id}) => id === answerId)
                    ?.text || ''
                }
                key={answerId}
              />
            )
          )}

          {currentAnswer.answerIds.some(answerId =>
            currentAnswer.correctAnswersIds.includes(answerId)
          ) &&
            currentAnswer.correctAnswersIds
              .filter(id => !currentAnswer.answerIds.includes(id))
              .map(answerId => (
                <ForgottenAnswerResult
                  text={
                    currentQuestion?.answers.find(({id}) => id === answerId)
                      ?.text || ''
                  }
                  key={answerId}
                />
              ))}
        </>
      )}
    </div>
  );
}
