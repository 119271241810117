import {providers} from 'ethers';
import {useMemo} from 'react';
import type {Account, Chain, Client, Transport} from 'viem';
import {useWalletClient} from 'wagmi';

export function clientToSigner(
  client: Client<Transport, Chain, Account>,
  chainId: number,
  chainName: string
) {
  const {account, transport} = client;
  const network = {
    chainId: chainId,
    name: chainName,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

export function useEVMSigner({
  chainId,
  chainName,
}: {
  chainId: number;
  chainName: string;
}) {
  const {data: client} = useWalletClient({chainId});

  return useMemo(
    () => (client ? clientToSigner(client, chainId, chainName) : undefined),
    [client, chainId, chainName]
  );
}
