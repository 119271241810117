import {Modal} from '../../../../components/Modal';
import {ModalBody} from './ModalBody';

export const HowToPlayModal = ({handleClose}: {handleClose: () => void}) => {
  return (
    <Modal>
      <ModalBody handleClose={handleClose} />
    </Modal>
  );
};
