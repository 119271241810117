import {MAX_DATE} from '../constants/dates';
import {Project, ProjectSchedule, ROUND_STATUS} from '../types/project';

export const calcRoundStatus = (round?: ProjectSchedule) => {
  const now = Date.now();

  if (!round || !round.start) return ROUND_STATUS.UPCOMING;

  if (now < new Date(round.start).getTime()) return ROUND_STATUS.UPCOMING;

  if (!round.end) return ROUND_STATUS.LIVE_NOW;

  if (now > new Date(round.end).getTime()) return ROUND_STATUS.FINISHED;
  if (
    now > new Date(round.start).getTime() &&
    now < new Date(round.end).getTime()
  ) {
    return ROUND_STATUS.LIVE_NOW;
  }

  return ROUND_STATUS.FINISHED;
};

export const getDateInMs = (date: string) => new Date(date).getTime();

export const getClosestRound = (project: Project) => {
  const now = Date.now();

  if (!project.projectRounds) {
    return;
  }

  const sortedSchedules = [...project.projectRounds].sort(
    (a, b) =>
      getDateInMs(a.start || MAX_DATE) - getDateInMs(b.start || MAX_DATE)
  );

  const activeRound = sortedSchedules.find(
    ({start, end}) =>
      getDateInMs(start || MAX_DATE) <= now &&
      getDateInMs(end || MAX_DATE) >= now
  );

  if (activeRound) {
    return activeRound;
  }

  const closestRound = sortedSchedules.find(
    ({start}) => getDateInMs(start || MAX_DATE) >= now
  );

  if (closestRound) {
    return closestRound;
  }

  const lastRound = sortedSchedules.at(-1);

  return lastRound;
};
