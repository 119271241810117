import {NavigateFunction} from 'react-router-dom';
import {NotificationWorkflowsEnum} from './store';
import {NotificationCheckIcon} from '../../../assets/svgComponents/NotificationCheckIcon';
import {BrokenStar} from '../../../assets/svgComponents/BrokenStar';
import {NotificationCircleCrossIcon} from '../../../assets/svgComponents/NotificationCircleCrossIcon';
import {NotificationGitScoreTask} from '../../../assets/svgComponents/NotificationGitScoreTask';
import {NotificationKYCStatus} from '../../../assets/svgComponents/NotificationKYCStatus';
import {NotificationRegularTask} from '../../../assets/svgComponents/NotificationRegularTask';
import {NotificationTwitterIcon} from '../../../assets/svgComponents/NotificationTwitterIcon';
import {Star} from '../../../assets/svgComponents/Star';
import {TimeSquare} from '../../../assets/svgComponents/TimeSquare';
import {TaskStatus} from '../../../types/tasks';

export const notificationIcon: Record<NotificationWorkflowsEnum, JSX.Element> =
  {
    [NotificationWorkflowsEnum.IN_APP_NEW_LEVEL]: <Star />,
    [NotificationWorkflowsEnum.IN_APP_NEW_REFERRAL]: <Star />,
    [NotificationWorkflowsEnum.IN_APP_RETRY_QUIZ]: <TimeSquare />,
    [NotificationWorkflowsEnum.IN_APP_COMPLETED_TASK]: (
      <NotificationCheckIcon />
    ),
    [NotificationWorkflowsEnum.IN_APP_REJECTED_TASK]: (
      <NotificationCircleCrossIcon />
    ),
    [NotificationWorkflowsEnum.IN_APP_NEED_TO_CONNECT_TWITTER]: (
      <NotificationTwitterIcon />
    ),
    [NotificationWorkflowsEnum.IN_APP_KOL_RECIEVED]: <Star />,
    [NotificationWorkflowsEnum.IN_APP_KOL_REJECTED]: <BrokenStar />,
    [NotificationWorkflowsEnum.UPDATE_KYC_STATUS]: <NotificationKYCStatus />,
    [NotificationWorkflowsEnum.IN_APP_NEW_TASK]: <NotificationRegularTask />,
    [NotificationWorkflowsEnum.IN_APP_REVERTED_GITCOIN_TASK]: (
      <NotificationGitScoreTask />
    ),
    [NotificationWorkflowsEnum.IN_APP_NEW_WODL_TRY]: <TimeSquare />,
  };
export const notificationTarget: Record<NotificationWorkflowsEnum, string> = {
  [NotificationWorkflowsEnum.IN_APP_NEW_LEVEL]: 'New level',
  [NotificationWorkflowsEnum.IN_APP_NEW_REFERRAL]: 'New referral',
  [NotificationWorkflowsEnum.IN_APP_RETRY_QUIZ]: 'Raiser Tasks',
  [NotificationWorkflowsEnum.IN_APP_COMPLETED_TASK]: 'Raiser Tasks',
  [NotificationWorkflowsEnum.IN_APP_REJECTED_TASK]: 'Raiser Tasks',
  [NotificationWorkflowsEnum.IN_APP_NEED_TO_CONNECT_TWITTER]: 'Raiser Tasks',
  [NotificationWorkflowsEnum.IN_APP_KOL_RECIEVED]: 'Get KOL Status',
  [NotificationWorkflowsEnum.IN_APP_KOL_REJECTED]: 'Get KOL Status',
  [NotificationWorkflowsEnum.UPDATE_KYC_STATUS]: 'KYC',
  [NotificationWorkflowsEnum.IN_APP_NEW_TASK]: 'Raiser Tasks',
  [NotificationWorkflowsEnum.IN_APP_REVERTED_GITCOIN_TASK]: 'Raiser Tasks',
  [NotificationWorkflowsEnum.IN_APP_NEW_WODL_TRY]: 'Raiser Tasks',
};

export const notificationActions: Record<
  NotificationWorkflowsEnum,
  null | ((navigate: NavigateFunction) => void)
> = {
  [NotificationWorkflowsEnum.IN_APP_NEW_LEVEL]: null,
  [NotificationWorkflowsEnum.IN_APP_NEW_REFERRAL]: null,
  [NotificationWorkflowsEnum.IN_APP_RETRY_QUIZ]: navigate => {
    navigate(`/account?default=${TaskStatus.Active}`);
  },
  [NotificationWorkflowsEnum.IN_APP_COMPLETED_TASK]: null,
  [NotificationWorkflowsEnum.IN_APP_REJECTED_TASK]: navigate => {
    navigate(`/account?default=${TaskStatus.Active}`);
  },
  [NotificationWorkflowsEnum.IN_APP_NEED_TO_CONNECT_TWITTER]: navigate => {
    navigate(`/account?default=${TaskStatus.Available}`);
  },
  [NotificationWorkflowsEnum.IN_APP_KOL_RECIEVED]: () => {},
  [NotificationWorkflowsEnum.IN_APP_KOL_REJECTED]: () => {},
  [NotificationWorkflowsEnum.UPDATE_KYC_STATUS]: null,
  [NotificationWorkflowsEnum.IN_APP_NEW_TASK]: navigate => {
    navigate(`/account?default=${TaskStatus.Available}`);
  },
  [NotificationWorkflowsEnum.IN_APP_REVERTED_GITCOIN_TASK]: navigate => {
    navigate(`/account?default=${TaskStatus.Active}`);
  },
  [NotificationWorkflowsEnum.IN_APP_NEW_WODL_TRY]: navigate => {
    navigate(`/account?default=${TaskStatus.Active}`);
  },
};
