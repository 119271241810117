export const WoodleQuestion = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2Z"
        fill="white"
        fill-opacity="0.4"
      />
      <path
        d="M12 16C11.7348 16 11.4804 16.1054 11.2929 16.2929C11.1054 16.4804 11 16.7348 11 17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16ZM12 6.5C11.0386 6.5 10.1166 6.88192 9.43674 7.56174C8.75692 8.24156 8.375 9.16359 8.375 10.125C8.375 10.3902 8.48036 10.6446 8.66789 10.8321C8.85543 11.0196 9.10978 11.125 9.375 11.125C9.64022 11.125 9.89457 11.0196 10.0821 10.8321C10.2696 10.6446 10.375 10.3902 10.375 10.125C10.3753 9.83004 10.4559 9.54072 10.6082 9.28809C10.7604 9.03545 10.9786 8.82902 11.2392 8.69092C11.4998 8.55282 11.7932 8.48827 12.0877 8.50419C12.3822 8.52011 12.6669 8.61589 12.9111 8.78127C13.1553 8.94666 13.35 9.1754 13.4741 9.44297C13.5982 9.71054 13.6472 10.0069 13.6157 10.3001C13.5843 10.5934 13.4736 10.8726 13.2955 11.1078C13.1175 11.3429 12.8788 11.5252 12.605 11.635C11.929 11.905 11 12.597 11 13.75V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14C13 13.756 13.05 13.634 13.261 13.53L13.348 13.49C14.1288 13.1759 14.776 12.6 15.1787 11.8609C15.5814 11.1219 15.7145 10.2658 15.5551 9.43938C15.3958 8.61299 14.9539 7.86776 14.3052 7.33147C13.6566 6.79518 12.8416 6.50122 12 6.5Z"
        fill="white"
      />
    </svg>
  );
};
