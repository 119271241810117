import {ROUND_STATUS} from '../../../types/project';

const roundStatusStyles = {
  [ROUND_STATUS.FINISHED]: 'bg-[#8155FD]',
  [ROUND_STATUS.LIVE_NOW]: 'bg-[#E5FD55] text-black',
  [ROUND_STATUS.UPCOMING]: 'bg-[#FD5573]',
};

interface IStatusLabelProps {
  roundStatus: ROUND_STATUS;
}

export const StatusLabel = ({roundStatus}: IStatusLabelProps) => {
  return (
    <div
      className={`ml-auto font-mono flex justify-center items-center px-2.5 py-[5px] rounded-full h-7 ${
        roundStatusStyles[roundStatus as ROUND_STATUS] || 'bg-[#8155FD]'
      } text-xs`}
    >
      {roundStatus}
    </div>
  );
};
