import {useShallow} from 'zustand/react/shallow';
import {CurrentWoodle, woodleStore} from '../../../store';
import {WoodleInfoField} from './WoodleInfoField';
import classNames from 'classnames';

export const WoodleInfo = ({currentWoodle}: {currentWoodle: CurrentWoodle}) => {
  const attemptsLeft = woodleStore(
    useShallow(
      ({submittedWords}) => currentWoodle.attempts - submittedWords.length
    )
  );

  return (
    <div className="max-w-[533px] w-full rounded-xl bg-[#1D1E1E] px-5 md:px-0 py-3 flex flex-col md:flex-row gap-6 md:gap-3 md:[&>*]:border-r [&>*:last-child]:border-r-0 [&>*]:border-l-100">
      <WoodleInfoField
        label="Reward"
        value={`${currentWoodle.pointReward || 0}pts`}
      />
      <WoodleInfoField label="Topic" value={currentWoodle.topic} />
      <WoodleInfoField
        label="Attempts left"
        value={
          <div className={classNames(!attemptsLeft && 'text-[#FB4949]')}>
            {attemptsLeft}
          </div>
        }
      />
    </div>
  );
};
