function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

export const SvgArc = ({
  x,
  y,
  radius,
  startAngle,
  endAngle,
  ...rest
}: {
  x: number;
  y: number;
  radius: number;
  startAngle: number;
  endAngle: number;
} & React.SVGProps<SVGPathElement>) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return (
    <path
      stroke="black"
      strokeLinecap="round"
      strokeWidth="1"
      d={d}
      {...rest}
    />
  );
};
