import {NextArrow} from '../../../../assets/svgComponents/NextArrow';
import {WodlTaskInfo, WoodleResult} from '../../../../types/tasks';
import {isTimePassed} from '../../../../utilities/isTimePassed';
import {getDateInMs} from '../../../../utilities/project';
import {WoodleTaskStatus} from '../../../Woodle/store';
import {formatSubmittedAnswers} from '../../../Woodle/utils/formatSubmittedAnswers';
import {tasksService} from '../../service/TasksService';

import {useNavigate} from 'react-router-dom';

export const HandleWoodleButton = ({
  id,
  status,
  userWodlResult,
  wodl,
  rewardPointsAmount,
  rewardTicketsAmount,
}: {
  id: string;
  status?: WoodleTaskStatus;
  userWodlResult?: WoodleResult;
  wodl?: WodlTaskInfo;
  rewardPointsAmount?: number | null;
  rewardTicketsAmount?: number | null;
}) => {
  const navigate = useNavigate();

  if (status === WoodleTaskStatus.DONE) {
    return;
  }

  return (
    <div
      className={
        'flex items-center gap-[10px] text-xs font-bold uppercase leading-6 text-green-200 hover:underline cursor-pointer'
      }
      onClick={() => {
        if (!status) {
          void tasksService.handleWoodleTaskStart(id, navigate);
        } else {
          if (userWodlResult && wodl) {
            const {status, answers, wordLength, retryAfter} = userWodlResult;
            const {id, attemptsToGuess, topic} = wodl;
            const isWodleAvailable = isTimePassed(new Date(retryAfter || 0));

            tasksService.handleWoodleTaskOpen(
              {
                id: id,
                wordLength,
                attempts: attemptsToGuess,
                status: isWodleAvailable
                  ? WoodleTaskStatus.IN_PROGRESS
                  : status,
                pointReward: rewardPointsAmount,
                ticketReward: rewardTicketsAmount,
                retryAfter: retryAfter,
                topic: topic,
              },
              answers
                .sort(
                  ({createdAt: a}, {createdAt: b}) =>
                    getDateInMs(a) - getDateInMs(b)
                )
                .map(({result}) => formatSubmittedAnswers(result)),
              navigate
            );
          }
        }
      }}
    >
      {status ? (
        status === WoodleTaskStatus.IN_PROGRESS ? (
          <>
            Continue <NextArrow width={24} height={4} fill="#E5FD55" />
          </>
        ) : (
          <>
            Try again <NextArrow width={24} height={4} fill="#E5FD55" />
          </>
        )
      ) : (
        <>
          Start
          <NextArrow width={24} height={4} fill="#E5FD55" />
        </>
      )}
    </div>
  );
};
