import {userStore} from '../../AccountProgress/store/useUserStore';
import {SignUpSteps, progressStore} from '../store/useStepsStore';

export const updateStepStatus = () => {
  const {setState: setProgressState, getState: getProgressState} =
    progressStore;
  const {stepsArray} = getProgressState();
  const {getState: getUserState} = userStore;
  const {user} = getUserState();
  const updatedStepsArray = new Array(...stepsArray);

  const newSteps = updatedStepsArray.map(step => {
    const newStep = {...step};

    // TODO: add validation all steps
    if (
      (step.id === SignUpSteps.NAME && user?.profile.name) ||
      (step.id === SignUpSteps.WALLET && user?.connectedWallets.length) ||
      (step.id === SignUpSteps.SOCIALS &&
        user?.connectedSocials.length === 3) ||
      (step.id === SignUpSteps.KYC &&
        user?.kyc?.status &&
        user?.kyc?.status === 'APPROVED')
    ) {
      newStep.isActive = false;
      newStep.isSkipped = false;
      newStep.isCompleted = true;
    }

    // TODO: should be refactored or even rewritten
    if (step.id === SignUpSteps.SOCIALS) {
      newStep.isCompleted = user?.connectedSocials.length === 3;
    }

    return newStep;
  });
  setProgressState({stepsArray: newSteps});
};
