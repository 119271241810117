import React from 'react';

export const CircleStatusNotActive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame 66">
        <circle
          id="Ellipse 1"
          cx="19"
          cy="19"
          r="14.5"
          stroke="#3e4041"
          strokeWidth="5"
        />
      </g>
    </svg>
  );
};
