import React from 'react';

export const Bell = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.1">
        <path
          d="M98.8456 58.2277C95.1932 53.9624 93.5334 50.2662 93.5334 43.9868V41.8516C93.5334 33.6687 91.65 28.3963 87.5553 23.124C81.2443 14.9359 70.6199 10.001 60.2192 10.001H59.7769C49.5948 10.001 39.3034 14.7093 32.883 22.565C28.5647 27.9431 26.4627 33.4421 26.4627 41.8516V43.9868C26.4627 50.2662 24.9123 53.9624 21.1505 58.2277C18.3826 61.3699 17.498 65.4085 17.498 69.7795C17.498 74.1555 18.9342 78.2999 21.8164 81.6687C25.5781 85.7073 30.8903 88.2856 36.3168 88.7338C44.1733 89.6301 52.0298 89.9675 60.0005 89.9675C67.9663 89.9675 75.8228 89.4035 83.6842 88.7338C89.1058 88.2856 94.4179 85.7073 98.1797 81.6687C101.057 78.2999 102.498 74.1555 102.498 69.7795C102.498 65.4085 101.614 61.3699 98.8456 58.2277Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M70.037 96.1435C67.5374 95.6097 52.3065 95.6097 49.8069 96.1435C47.6701 96.637 45.3594 97.7851 45.3594 100.303C45.4836 102.705 46.8899 104.825 48.8379 106.17L48.8329 106.175C51.3524 108.138 54.3091 109.387 57.405 109.836C59.0548 110.062 60.7344 110.052 62.4439 109.836C65.5348 109.387 68.4915 108.138 71.0109 106.175L71.006 106.17C72.9539 104.825 74.3603 102.705 74.4845 100.303C74.4845 97.7851 72.1738 96.637 70.037 96.1435Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
