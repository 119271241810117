import axios from 'axios';
import {dashboardStore} from '../modules/Dashboard/store/store';
import {Project} from '../types/project';
import {$api} from './interceptor';
import {
  getIsHardcodedProject,
  isCrossTheAgesProject,
} from '../utilities/getIsHardcodedProject';

const projectApi = () => {
  const {setState} = dashboardStore;

  const getProjects = async () => {
    try {
      setState({isLoading: true});
      const response = await $api.get<Project[]>('/projects');
      if (response.status === 200) {
        setState({
          projects: response.data.map(project => {
            const isHardcodedProject = getIsHardcodedProject(project);

            const isCrossTheAges = isCrossTheAgesProject(project);

            const isProjectDataBarShown = !isHardcodedProject;

            const isProjectInvestButtonShown =
              !isHardcodedProject && !isCrossTheAges;
            const isProjectNotifyBlockShown =
              !isHardcodedProject && !isCrossTheAges;

            return {
              ...project,
              isProjectDataBarShown,
              isProjectInvestButtonShown,
              isProjectNotifyBlockShown,
            };
          }),
        });
      }
    } catch (error) {
      setState({error: true});
    } finally {
      setState({isLoading: false});
    }
  };

  const checkProjectInvestAvailability = async (
    signatureURL?: string,
    userWalletAdress?: string
  ) => {
    if (!(signatureURL && userWalletAdress)) {
      return false;
    }

    try {
      await axios.get(`${signatureURL}/${userWalletAdress}.json`);

      return true;
    } catch {
      return false;
    }
  };

  const getProject = async (
    id: string,
    successCallback?: () => void,
    finalCallback?: () => void
  ) => {
    try {
      const response = await $api.get<Project>(`/projects/${id}`);

      const isHardcodedProject = getIsHardcodedProject(response.data);

      const isCrossTheAges = isCrossTheAgesProject(response.data);

      const isProjectDataBarShown = !isHardcodedProject;

      const isProjectInvestButtonShown = !isHardcodedProject && !isCrossTheAges;
      const isProjectNotifyBlockShown = !isHardcodedProject && !isCrossTheAges;

      setState({
        selectedProject: {
          ...response.data,
          isProjectDataBarShown,
          isProjectInvestButtonShown,
          isProjectNotifyBlockShown,
        },
      });
      successCallback && successCallback();

      return response;
    } catch (error) {
      console.log(error);
      return undefined;
    } finally {
      finalCallback && finalCallback();
    }
  };

  const getFeatureCampaign = async () => {
    try {
      setState({isLoading: true});
      const response = await $api.get<Project>('/projects/future/campain');
      if (response.status === 200) {
        const isHardcodedProject = getIsHardcodedProject(response.data);

        const isCrossTheAges = isCrossTheAgesProject(response.data);

        const isProjectDataBarShown = !isHardcodedProject;

        const isProjectInvestButtonShown =
          !isHardcodedProject && !isCrossTheAges;
        const isProjectNotifyBlockShown =
          !isHardcodedProject && !isCrossTheAges;

        setState({
          featuredCampaign: {
            ...response.data,
            isProjectDataBarShown,
            isProjectInvestButtonShown,
            isProjectNotifyBlockShown,
          },
        });
      }
    } catch (error) {
      setState({error: true});
    } finally {
      setState({isLoading: false});
    }
  };

  return {
    getProjects,
    getProject,
    getFeatureCampaign,
    checkProjectInvestAvailability,
  };
};

export const projectService = projectApi();
