import {create, useStore} from 'zustand';
import {IMyTask} from '../../../types/tasks';
import {PointActions} from '../../../types/types';

export enum ActivityType {
  DAILY_REWARD = 'DAILY_REWARD',
  TASK = 'TASK',
  LEVEL_ACTION = 'LEVEL_ACTION',
  REFERRAL_ACTION = 'REFERRAL_ACTION',
}

export interface IActionLevelPoint {
  id: string;
  action: PointActions;
  points: number;
  tickets: number;
}

export interface IActivity {
  id: string;

  isRead: boolean;

  type: ActivityType;

  points: number | null;
  pointsBefore: number | null;
  pointsAfter: number | null;

  tickets: number | null;
  ticketsBefore: number | null;
  ticketsAfter: number | null;

  userTask?: IMyTask;
  userTaskId?: string;

  actionsLevelPoint: IActionLevelPoint | null;

  actionLevelPointId?: string;
  referralActionLog: IActivity;

  referralActionLogId?: string;
  createdAt: string;
}

export interface ActivitiesStore {
  activities: Array<IActivity>;
  unreadCount: number;
}

export const activitiesStore = create<ActivitiesStore>(() => ({
  activities: [],
  unreadCount: 0,
}));

export const useActivitiesStore = () => useStore(activitiesStore);
