import classNames from 'classnames';
import {Backspace} from '../../../../../../assets/svgComponents/Backspace';
import {woodleService} from '../../../../service';
import {CheckKey} from '../CheckKey';
import {woodleStore} from '../../../../store';
import {useShallow} from 'zustand/react/shallow';

export enum KeyType {
  common,
  backspace = 'backspace',
  enter = 'enter',
}

export enum KeyState {
  initial,
  wrong = 'WRONG',
  included = 'INCLUDED',
  correct = 'CORRECT',
  disabled = 'disabled',
}

export const Key = ({letter}: {letter: string}) => {
  woodleStore(useShallow(({submittedWords}) => submittedWords));

  if (letter === KeyType.backspace) {
    return (
      <div
        className="border cursor-pointer rounded-md md:rounded-lg border-l-300 flex items-center justify-center h-9"
        onClick={woodleService.popLetter}
      >
        <Backspace className="w-5 h-5 md:w-6 md:h-6" />
      </div>
    );
  }

  if (letter === KeyType.enter) {
    return <CheckKey />;
  }

  const keyState = woodleService.getKeyboardKeyState(letter);

  return (
    <div
      className={classNames(
        'border cursor-pointer rounded-md md:rounded-lg border-l-300 flex items-center justify-center h-9 uppercase font-bold text-sm md:text-lg leading-6',
        {
          'border-none bg-[#1D1E1E]': keyState === KeyState.wrong,
          'border-none bg-[#CFE44D] text-black': keyState === KeyState.included,
          'border-none bg-[#00AF46] text-black': keyState === KeyState.correct,
        }
      )}
      onClick={() => {
        if (keyState !== KeyState.wrong) {
          woodleService.pushLetter(letter);
        }
      }}
    >
      {letter}
    </div>
  );
};
