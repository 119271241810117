import {create, useStore} from 'zustand';
import {IInvestedProject} from '../../Disclaimer/store/useProjectDisclaimerStore';

interface IErrorMessage {
  title: string;
  description: string;
  retryEvent?: () => void;
}

interface ProjectInvestStore {
  investedProject: IInvestedProject | null;
  investedSum: number | null;
  errorMessage: IErrorMessage | null;
  isSubmitted: boolean;
  isInvestPending: boolean;
  maxAllocation: number;

  setInvestedProject: (investedProject: IInvestedProject) => void;
  setErrorMessage: (errorMessage: IErrorMessage | null) => void;
  setInvestedSum: (investedSum: number | null) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
  clear: () => void;
}

const initialState: Pick<
  ProjectInvestStore,
  | 'investedProject'
  | 'investedSum'
  | 'errorMessage'
  | 'isSubmitted'
  | 'isInvestPending'
  | 'maxAllocation'
> = {
  investedProject: null,
  investedSum: null,
  errorMessage: null,
  isSubmitted: false,
  isInvestPending: false,
  maxAllocation: 0,
};

export const projectInvestStore = create<ProjectInvestStore>(set => ({
  investedProject: null,
  investedSum: null,
  errorMessage: null,
  isSubmitted: false,
  isInvestPending: false,
  maxAllocation: 0,

  setInvestedProject: investedProject => set({investedProject}),
  setErrorMessage: errorMessage => set({errorMessage}),
  setInvestedSum: investedSum => set({investedSum}),
  setIsSubmitted: isSubmitted => set({isSubmitted}),
  clear: () => set({...initialState}),
}));

export const useProjectInvestStore = () => useStore(projectInvestStore);
