import {useEffect, useState} from 'react';
import {useAccount, useNetwork} from 'wagmi';
import {jsNumberForAddress} from 'react-jazzicon';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';

import {useProjectInvestStore} from '../store/useProjectInvestStore';
import {shortenAddress} from '../../../../utilities/idex';
import {Price} from '../../../../services/price';

import {ErrorMessage} from './ErrorMessage';
import {AllocationInput} from './AllocationInput';
import {SwitchNetworkNotification} from './SwitchNetworkNotification';
import {isWalletMatching} from '../../../../utilities/isWalletMatching';
import {ConnectWalletNotification} from './ConnectWalletNotification';
import {walletRequiredMessage} from '../constants';
import {BuyAllocationButton} from './BuyAllocationButton';
import {getBalance} from '../../../../utilities/contractUtilities';
import {getIncorrectWalletErrorMessage} from '../helpers/getErrorMessage';
import {useUserStore} from '../../../AccountProgress/store/useUserStore';
import {roundNumber} from '../helpers/roundNumber';

export function ProjectInvestBody() {
  const [balance, setBalace] = useState('0.0');

  const {
    investedProject,
    investedSum,
    errorMessage,
    maxAllocation,
    setErrorMessage,
  } = useProjectInvestStore();
  const {address} = useAccount();
  const {chain} = useNetwork();
  const {user} = useUserStore();

  useEffect(() => {
    if (address && investedProject) {
      getBalance(
        address,
        investedProject.allocationToken.contractAddress,
        investedProject.allocationToken.network.rpcUrl
      )
        .then(response => {
          setBalace(response.parsedBalance);
        })
        .catch(() => {});
    }
  }, [address, investedProject]);

  useEffect(() => {
    if (!address) {
      setErrorMessage(walletRequiredMessage);
    } else if (!isWalletMatching(address)) {
      setErrorMessage(
        getIncorrectWalletErrorMessage(
          user?.connectedWallets?.at(0)?.address || ''
        )
      );
    } else if (errorMessage) {
      setErrorMessage(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, investedSum, setErrorMessage]);

  if (!investedProject) {
    return;
  }

  const isChainCorrect =
    chain?.id === investedProject.allocationToken.network.chainId;

  const isAllocationButtonDisabled = !investedSum || !!errorMessage;

  return (
    <div className="w-full pt-16">
      <div className="mx-auto max-w-[530px]">
        <div className="pb-6 border-solid flex flex-col justify-center items-center border-l-100 bg-[#0e1012] w-full font-sans border rounded-20">
          <div className="p-6 mb-6 text-xl font-bold leading-[24px] text-white w-full border-b border-[#1D1E1E] ">
            Invest
          </div>
          {address ? (
            <div className="px-6 w-full">
              <div className="flex flex-row w-full items-start mb-6">
                <div className="flex flex-row gap-16 font-mono">
                  <div className="flex flex-col gap-4 justify-between">
                    <div className="opacity-40 text-xs uppercase text-white">
                      wallet
                    </div>
                    <div className="flex flex-row gap-3 w-full font-mono">
                      {address && (
                        <>
                          <Jazzicon
                            diameter={24}
                            seed={jsNumberForAddress(address)}
                          />
                          <div className="text-center text-sm leading-[24px] uppercase text-white">
                            {shortenAddress(address)}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col justify-between w-full items-start leading-6 uppercase text-white font-mono">
                    <div className="opacity-40 text-xs">Balance</div>
                    <div className="flex [&>div]:leading-6 gap-[14px]">
                      <div className="text-lg font-bold ">
                        $
                        {roundNumber(
                          Price.get(investedProject.allocationToken.symbol)
                            .usd * Number(balance)
                        )}{' '}
                        USD
                      </div>
                      <div className="border-l border-[#1D1E1E]"></div>
                      <div className="text-xs">
                        {roundNumber(Number(balance))}{' '}
                        {investedProject.allocationToken.symbol}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isChainCorrect ? (
                <>
                  <AllocationInput
                    min={investedProject.minAllocation}
                    max={maxAllocation}
                    tokenPrice={investedProject.tokenPrice}
                    balance={Number(balance)}
                  />
                  <div className="pt-6">
                    <ErrorMessage />
                  </div>
                  <div className="w-fullpm-[34px] pt-4">
                    <BuyAllocationButton
                      disabled={isAllocationButtonDisabled}
                      balance={Number(balance)}
                      investedSum={investedSum || 0}
                      minAllocation={investedProject.minAllocation}
                      userAddress={address}
                      chainId={investedProject.allocationToken.network.chainId}
                      chainName={investedProject.allocationToken.network.name}
                      contractAdress={investedProject.investContractAddress}
                      signatureUrl={investedProject.signatureUrl}
                      tokenContractAdress={
                        investedProject.allocationToken.contractAddress
                      }
                      decimals={investedProject.allocationToken.decimals}
                      bindedWallet={
                        user?.connectedWallets?.at(0)?.address || ''
                      }
                    />
                  </div>
                </>
              ) : (
                <SwitchNetworkNotification
                  requiredChainId={
                    investedProject.allocationToken.network.chainId
                  }
                  requiredNetworkName={
                    investedProject.allocationToken.network.name
                  }
                />
              )}
            </div>
          ) : (
            <ConnectWalletNotification />
          )}
        </div>
      </div>
    </div>
  );
}
