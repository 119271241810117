import {ITask} from '../../../types/tasks';

export const QuizApproximateTime = ({quiz}: Required<Pick<ITask, 'quiz'>>) => {
  return (
    <div className="uppercase text-xs font-mono flex items-center gap-2">
      <span className="opacity-40">approximate time</span>
      <span className="font-bold">
        {quiz?.approximateTime && quiz.approximateTime > 60
          ? Math.floor(quiz.approximateTime / 60) + 'h'
          : quiz.approximateTime + 'm'}
      </span>
    </div>
  );
};
