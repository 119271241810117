import {Bell} from '../../../../assets/svgComponents/Bell';

export const NotificationsEmpty = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-[10px]">
      <div className="mb-2">
        <Bell />
      </div>

      <div className="font-semibold text-sm leading-6">No notifications</div>
      <div className="flex justify-between items-center text-l-600 text-xs leading-5 max-w-[287px] text-center">
        Updates and important information will be collected here, stay tuned!
      </div>
    </div>
  );
};
