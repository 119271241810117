import classNames from 'classnames';
import {LongArrow} from '../../../../assets/svgComponents/LongArrow';
import {createArray} from '../../../../utilities/createArray';
import {WoodleTaskStatus, useWoodleStore} from '../../store';
import {Letter} from './Letter';
import {ordinals} from '../../constants';
import {Toast} from './Toast';

export const CurrentWord = ({length}: {length: number}) => {
  const {word, submittedWords, currentWoodle, isShaking} = useWoodleStore();

  const submittedWordsLength = submittedWords.length;
  if (currentWoodle?.status !== WoodleTaskStatus.IN_PROGRESS) {
    return null;
  }

  return (
    <div className={'flex justify-center'}>
      <div className="relative w-full md:w-fit flex gap-1 md:gap-3 justify-center">
        <div
          className={classNames(
            'absolute -left-[132px] flex-col max-w-[116px] uppercase hidden md:flex',
            word.length ? 'top-[30%]' : '-top-[20%]'
          )}
        >
          {word.length ? (
            <div className="font-bold font-mono text-xs leading-6 text-l-800 break-keep w-28">
              <span className="text-l-300">
                Attempt {submittedWordsLength + 1}
              </span>{' '}
              of {''}
              {currentWoodle?.attempts}
            </div>
          ) : (
            <>
              <div className="max-w-[100px] font-mono text-l-800 text-xs">
                Enter {ordinals[submittedWordsLength]} word
              </div>
              <div>
                <LongArrow />
              </div>
            </>
          )}
        </div>
        <Toast />
        <div
          className={classNames(
            'w-full md:w-fit flex gap-1 md:gap-3 justify-center',
            isShaking && 'animate-shake'
          )}
        >
          {createArray(length).map(i => (
            <Letter key={i} letter={word[i] || ''} />
          ))}
        </div>
      </div>
    </div>
  );
};
