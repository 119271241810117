import {create, useStore} from 'zustand';
import {MyTasks, Tasks} from '../../../types/tasks';

export type ProjectsTasksID = string | 'RAISER';

export const projectsTasksStore = create<{
  projects: Array<{
    id?: string;
    name: string;
    imageUrl: string;
    active: boolean;
  }>;
  projectsTasks: Record<
    ProjectsTasksID,
    {
      tasks: {available: Tasks; inProgress: MyTasks; done: MyTasks};
      isLoading: boolean;
    }
  >;
  isProjectsLoading: boolean;
  selectedProjectId?: string;
  setSelectedProjectId: (selectedProjectId?: string) => void;
}>(set => ({
  projects: [],
  projectsTasks: {},
  isProjectsLoading: false,
  setSelectedProjectId: selectedProjectId => set({selectedProjectId}),
}));

export const useProjectsTasksStore = () => useStore(projectsTasksStore);
