import {Button} from '../../../components/Button';
import QuestionCheckMarks from '../../../assets/QuestionAndCheckMarks3D.png';

import {quizService} from '../service/service';
import {useQuizStore} from '../store/useQuizStore';
import {QuizType} from '../../../types/tasks';

export interface IQuizBeginScreenProps {
  handleClose: () => void;
  quizID: string;
  taskID: string;
  questionsAmount: number;
  isCustomRewardTask: boolean;
  quizType: QuizType;
}

export function QuizBeginScreen({
  taskID,
  quizID,
  questionsAmount,
  quizType,
  isCustomRewardTask,
  handleClose,
}: IQuizBeginScreenProps) {
  const {isQuizLoading} = useQuizStore();
  return (
    <div className="px-5 md:px-10 pt-6 pb-9 bg-[#0B0D0E] md:rounded-[20px] rounded-t-20 md:border border-[#232526] border-l-1 w-full md:w-[560px] ">
      <div className="bg-[url('./assets/gridBg.svg')] md:min-h-[192px]">
        <img className="mb-3 mx-auto" src={QuestionCheckMarks as string}></img>
      </div>
      <h3 className="mb-5 text-white text-2xl leading-9 font-bold">
        Before We Begin
      </h3>
      <p className="mb-5 md:mb-12 md:h-[132px] text-xs md:text-sm leading-9 text-l-600">
        {quizType === QuizType.NOT_REPEATABLE ? (
          <>
            Remember, you will have only one attempt to answer each question.
            Each correct answer will earn you Points. Good luck.
          </>
        ) : isCustomRewardTask ? (
          <>
            Remember, you need to get all the answers right to receive an
            Exclusive Beta Access Code. Don't show your winning code to prevent
            losing your access. If you don't succeed in this attempt, don't
            worry – you'll get another chance in 1 hour.
          </>
        ) : (
          <>
            Remember, you need to get all the answers right to receive the
            Points. If you don't succeed in this attempt, don't worry – you'll
            get another chance in 15 min. Good luck.
          </>
        )}
      </p>
      <div className="flex gap-3 [&>*]:text-xs [&>*]:h-10">
        <Button
          text="I’M NOT READY YET"
          className="w-full bg-l-200 text-white hover:bg-l-100"
          onClick={handleClose}
        />
        <Button
          text="START QUIZ"
          className="w-full"
          smaller
          loading={isQuizLoading}
          onClick={() => {
            quizService.handleQuizStart(taskID, quizID, questionsAmount);
          }}
        />
      </div>
    </div>
  );
}
