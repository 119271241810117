import {Navigate} from 'react-router-dom';

import {useProjectInvestStore} from './store/useProjectInvestStore';

import {LayoutDashboardView} from '../../../layouts/LayoutDashboardView';
import {
  useEffect,
  // useState
} from 'react';
// import {WalletNotificationDialog} from '../../../components/WalletNotificationDialog/WalletNotificationDialog';
// import {useAccount} from 'wagmi';
// import {isWalletMatching} from '../../../utilities/isWalletMatching';
import {SuccessfulAllocation} from './components/SuccessfulAllocation';
import {ProjectInvestBody} from './components/ProjectInvestBody';
import {isDevelopmentStage} from '../../../constants/appStages';

export function ProjectInvest() {
  const {
    investedProject,
    isSubmitted,
    // setErrorMessage,
    clear,
  } = useProjectInvestStore();
  // const [isWalletModalRevealed, setWalletModalRevealed] = useState(false);
  // const {address} = useAccount();

  // useEffect(() => {
  //   if (address) {
  //     if (!isWalletMatching(address)) {
  //       setWalletModalRevealed(true);
  //     } else {
  //       setWalletModalRevealed(false);
  //     }
  //   }
  // }, [address, setErrorMessage]);

  useEffect(() => {
    // it's written in so filthy way due to strict mode is enabled in the dev stage
    if (!isDevelopmentStage) {
      return clear;
    }
    return;
  }, [clear]);

  if (!investedProject) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <LayoutDashboardView sidebar={false} headerProps={{logo: true}}>
      {/* {isWalletModalRevealed && (
        <WalletNotificationDialog
          handleClose={() => {
            setWalletModalRevealed(false);
            setErrorMessage(null);
          }}
        />
      )} */}
      {isSubmitted ? <SuccessfulAllocation /> : <ProjectInvestBody />}
    </LayoutDashboardView>
  );
}
