import {create, useStore} from 'zustand';

export interface Participant {
  position: string;
  points: number;
  reward: number | null;
}

type LeaderBoardStore = {
  leadersList: Array<Participant>;
  userResults: Participant | null;
};

export const leaderBoardStore = create<LeaderBoardStore>(() => ({
  leadersList: [],
  userResults: null,
}));

export const useLeaderBoardStore = () => useStore(leaderBoardStore);
