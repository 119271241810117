import calendarIcon from '../assets/calendarIcon.svg';
import dashBoardIcon from '../assets/dashboardIcon.svg';
import faqIcon from '../assets/faqIcon.svg';
import starIcon from '../assets/starIcon.svg';
import userIcon from '../assets/userIcon.svg';
import pieChartIcon from '../assets/PieChartIcon.svg';

export const rawLaunchpadLinks = [
  {
    id: 0,
    icon: dashBoardIcon,
    navIcon: dashBoardIcon,
    linkName: 'Dashboard',
    url: '/dashboard',
  },
  {
    id: 1,
    icon: userIcon,
    navIcon: userIcon,
    linkName: 'Tasks',
    url: '/account',
    headerIcon: starIcon,
    headerName: 'Account level',
  },
  {
    id: 1,
    icon: pieChartIcon,
    navIcon: pieChartIcon,
    linkName: 'Claiming Portal',
    url: '/FCO',
    headerIcon: pieChartIcon,
    headerName: 'Claiming Portal',
  },
  {
    id: 2,
    icon: calendarIcon,
    navIcon: calendarIcon,
    linkName: 'Campaigns',
    url: '/campaigns',
  },
  {
    id: 3,
    icon: faqIcon,
    navIcon: faqIcon,
    linkName: 'FAQ & SUPPORT',
    url: '/faq-and-support', // this link will be replaced with tg chat link
  },
  {
    id: 4,
    icon: userIcon,
    navIcon: starIcon,
    linkName: 'Account',
    url: '/account',
    isAdaptive: true,
  },
];

export const launchpadLinks = rawLaunchpadLinks;
