export const WoodleInfoField = ({
  value,
  label,
}: {
  label: string;
  value: string | JSX.Element;
}) => {
  return (
    <div className="flex-1 flex justify-between md:justify-normal items-center md:flex-col gap-[6px] font-mono">
      <div className="text-l-400">{label}</div>
      <div className="text-green-200 font-bold text-lg leading-6 text-end md:text-center flex-1 md:flex md:items-center">
        {value}
      </div>
    </div>
  );
};
