import {useState} from 'react';
import * as Popover from '@radix-ui/react-popover';
import walletIcon from '../../assets/wallet.svg';
import {useAccount, useSignMessage} from 'wagmi';
import {shortenAddress} from '../../utilities/idex';
import {WalletNotificationDialog} from '../WalletNotificationDialog/WalletNotificationDialog';
import {signUpService} from '../../services/SignUp';
import {TooltipInfoIcon} from '../Tooltip/TooltipInfoIcon';
import Metamask from '../../assets/metamask_icon.png';
import {CopyIcon} from '../../assets/svgComponents/CopyIcon';
import {NextArrow} from '../../assets/svgComponents/NextArrow';
import {MobileOverlay} from '../MobileOverlay';
import {bindWalletModalService} from '../../modules/BindWalletModal/service';
import {Tooltip} from '../Tooltip';

export const UserWallet = ({bindedUserWallet}: {bindedUserWallet: string}) => {
  const {address} = useAccount();
  const [isRebindModalShown, setIsRebindModalShown] = useState(false);
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [bindErrorMessage, setBindErrorMessage] = useState('');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    void navigator.clipboard.writeText(bindedUserWallet);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const {signMessage} = useSignMessage({
    onSuccess: async (data: string) => {
      try {
        await signUpService.sendWalletRequest(address, data, errorMessage => {
          setBindErrorMessage(errorMessage);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <Popover.Root open={isPopoverOpened} onOpenChange={setIsPopoverOpened}>
        <Popover.Trigger asChild>
          <div
            className="relative min-h-[44px] 2xl:min-h-[3rem] flex justify-center items-center gap-2 2xl:px-3 px-2 rounded-[100px] border-solid border-l-200 border-[1px]"
            style={{cursor: 'pointer'}}
          >
            {!!bindErrorMessage && (
              <Tooltip
                align="end"
                side="left"
                content={bindErrorMessage}
                contentClassName="z-[200]"
                triggerClassName="absolute w-5 2xl:w-6 h-5 2xl:h-6 -top-[6px] -right-[3px] 2xl:-top-[6px] 2xl:-right-1.5"
              >
                <span
                  className={`bg-[#040506] w-[inherit] h-[inherit] rounded-full after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:w-3 after:h-3 2xl:after:w-[14px] 2xl:after:h-[14px]
             ${'after:bg-[#FB4949]'}`}
                ></span>
              </Tooltip>
            )}
            {/* <img className={'w-6 h-6'} src={ethIcon} alt="coinIcon" /> */}

            <img className={'w-6 h-6'} src={walletIcon} alt="walletIcon" />
            <span className="hidden md:block text-sm uppercase">
              {shortenAddress(bindedUserWallet)}
            </span>
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            side="bottom"
            sideOffset={10}
            align="end"
            avoidCollisions={true}
            className="z-50 w-screen outline-none"
            style={{
              minWidth: 'var(--radix-popover-trigger-width)',
            }}
          >
            <>
              <div className="relative z-20 mx-auto border p-5 md:mr-10 font-sans w-full sm:w-[413px] max-w-[336px] sm:max-w-[327px] max-h-[488px] invisible-scrollbar !border-l-100 !border-opacity-10 rounded-[1.25rem] bg-[#0E1012] text-white overflow-auto">
                <div className="flex gap-2 items-center mb-5">
                  <div>Wallet</div>
                  <TooltipInfoIcon
                    content={
                      'This address is binded to your account and affects NFTs and Gitscore related tasks'
                    }
                    hidden={false}
                  />
                </div>
                <div className="mb-5 flex items-center gap-[10px]">
                  <div className="p-2 bg-l-100 rounded-md">
                    <img className="w-6 h-6" src={Metamask as string} alt="" />
                  </div>
                  <div className="flex-1">
                    <div className="text-l-600 text-xs leading-5">Ethereum</div>
                    <div className="font-mono font-bold text-sm leading-6">
                      {shortenAddress(bindedUserWallet, 4, 5)}
                    </div>
                  </div>
                  <CopyIcon
                    className="cursor-pointer [&>*]:transition-all"
                    rectanglesColor={copied ? '#E5FD55' : 'white'}
                    onClick={handleCopy}
                  />
                </div>
                <div className="flex justify-end relative">
                  <div
                    className={
                      'flex items-center gap-[10px] text-xs font-bold uppercase leading-3 text-green-200 hover:underline cursor-pointer'
                    }
                    onClick={() => {
                      setBindErrorMessage('');
                      if (address) {
                        signMessage({
                          message:
                            'Binding the wallet to an account on the Raiser platform.',
                        });
                      } else {
                        bindWalletModalService.handleModalOpen({
                          signMessageErrorCallback: setBindErrorMessage,
                        });
                      }
                    }}
                  >
                    connect new wallet
                    <NextArrow width={24} height={4} fill="#E5FD55" />
                  </div>
                </div>
              </div>

              <MobileOverlay />
            </>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {isRebindModalShown && (
        <WalletNotificationDialog
          handleClose={() => setIsRebindModalShown(false)}
        />
      )}
    </>
  );
};
