import {Synaps} from '@synaps-io/verify-sdk';
import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {BackButton} from '../../assets/svgComponents/BackButton';
import verify from '../../assets/verify.png';
import verifySuccess from '../../assets/verify_success.png';
import {Button} from '../../components/Button';
import {SkipButton} from '../../components/SkipButton';
import {
  stepsContentHeadingFlexboxClasses,
  stepsMainHeadingClasses,
} from '../../constants/stepsLayoutVariables';
import {signUpService} from '../../services/SignUp';
import {handleBackStep, handleSkipStep} from '../Login/helpers/handleSteps';
import {useSignUpStore} from '../Login/store/useSignUpStore';
import {useStepsStore} from '../Login/store/useStepsStore';
import {useUserStore} from '../AccountProgress/store/useUserStore';

export const KYCView = () => {
  const {user} = useUserStore();
  const {setIsVerified, isVerified, setIsActiveButton, loading} =
    useSignUpStore();
  const {setActiveStep, stepsArray} = useStepsStore();
  const navigate = useNavigate();

  const handleBackStepClick = () => {
    handleBackStep();
  };

  const handleVerification = useRef(async () => {
    const kyc = await signUpService.getKYC();
    let sessionId = null;
    let status = null;

    if (!kyc || !kyc.sessionId || kyc.status === 'REJECTED') {
      const res = await signUpService.sendKYC();

      if (!res || !res.sessionId) {
        return;
      }

      sessionId = res.sessionId;
      status = res.status;
    } else {
      sessionId = kyc.sessionId;
      status = kyc.status;
    }

    if (status === 'APPROVED') {
      setIsVerified(true);
      return;
    }

    Synaps.init({
      sessionId: sessionId,
      onFinish: async () => {
        const kyc = await signUpService.getKYC();

        if (kyc && kyc.status === 'APPROVED') {
          setIsVerified(true);
          return;
        }
      },
      mode: 'modal',
    });
    Synaps.show();
  });

  const handleRedirectToDashboardPage = (): void => {
    handleSkipStep();
    navigate('/dashboard');
  };

  const handleCheck = () => {
    const filteredSteps = stepsArray.filter(step => step.id > 0);
    const uncompletedUnskippedSteps = filteredSteps.filter(
      step => !step.isCompleted && step.isSkipped
    );
    if (user?.kyc?.status !== 'APPROVED' || isVerified) {
      handleVerification.current().catch(err => console.warn(err));
    } else if (uncompletedUnskippedSteps.length > 0) {
      const firstUnfinishedUnskippedStep = uncompletedUnskippedSteps[0];
      setActiveStep(firstUnfinishedUnskippedStep.id);
    } else if (filteredSteps.some(step => !step.isCompleted)) {
      const firstUnfinishedStep = filteredSteps.find(step => !step.isCompleted);
      if (firstUnfinishedStep) {
        setActiveStep(firstUnfinishedStep.id);
      }
    } else {
      handleRedirectToDashboardPage();
    }
  };

  const getButtonText = () => {
    const filteredSteps = stepsArray.filter(step => step.id > 0);
    const uncompletedUnskippedSteps = filteredSteps.filter(
      step => !step.isCompleted && step.isSkipped
    );
    if (user?.kyc?.status !== 'APPROVED' || isVerified) {
      return 'Start Verification';
    } else if (uncompletedUnskippedSteps.length > 0) {
      return `${uncompletedUnskippedSteps[0].name}`;
    } else {
      return 'Finish';
    }
  };

  useEffect(() => {
    const uncompletedUnskippedSteps = stepsArray.filter(
      step => !step.isCompleted && step.isSkipped
    );

    setIsActiveButton(
      user?.kyc?.status !== 'APPROVED' ||
        (isVerified && stepsArray.some(step => !step.isCompleted)) ||
        user?.kyc?.status !== 'APPROVED' ||
        (isVerified && uncompletedUnskippedSteps.length > 0)
    );
  }, [isVerified, stepsArray, user]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className={stepsContentHeadingFlexboxClasses}>
        <span className={stepsMainHeadingClasses}>
          {user?.kyc?.status !== 'APPROVED' || isVerified
            ? 'Verify Your Account'
            : 'Account Verified'}
        </span>
      </div>

      <div className="flex pt-5 w-full lg:w-fit lg:pt-[28px]">
        <div className="flex w-full lg:w-[372px] h-[124px] rounded-[12px] border border-[#39393A]">
          <div className="flex mx-auto cursor-pointer" onClick={handleCheck}>
            {user?.kyc?.status !== 'APPROVED' || isVerified ? (
              <img src={verify as string} alt="" />
            ) : (
              <img src={verifySuccess as string} alt="" />
            )}
          </div>
        </div>
      </div>
      <div className="flex pt-[23px] lg:pt-[25px] max-w-[372px]">
        <span className="text-white lg:text-center text-opacity-60 font-mono font-normal text-xs lg:text-[14px] leading-[18px]">
          {isVerified || user?.kyc?.status !== 'APPROVED'
            ? 'To enable transactions, we need to verify your identity. This process should take less than 5 minutes to complete.'
            : user.profileSetupProgress === 100
            ? 'All stages of registration have been completed'
            : 'Now you can complete the remaining steps'}
        </span>
      </div>
      <div
        className={`flex relative w-full lg:w-fit justify-center items-center text-center ${
          user?.kyc?.status !== 'APPROVED' || isVerified
            ? 'pt-[68px] lg:pt-[96px]'
            : 'pt-[75px] lg:pt-[127px]'
        }`}
      >
        <div className="pr-[10px] cursor-pointer" onClick={handleBackStepClick}>
          <BackButton />
        </div>
        <Button
          loading={loading}
          className="flex-1 h-12 lg:w-[356px] "
          text={getButtonText()}
          onClick={handleCheck}
          withArrow
        />
      </div>
      {user?.kyc?.status === 'APPROVED' ? (
        <div className="h-[72px] relative lg:hidden"></div>
      ) : null}
      {user?.kyc?.status !== 'APPROVED' ? (
        <SkipButton onClick={handleRedirectToDashboardPage} text="skip step" />
      ) : null}
    </div>
  );
};
