import {ButtonHTMLAttributes, FC, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useTimeDifference} from '../../hooks/useTimeDifferenceExtended';
import './styles.css';
import {Button} from '../Button';
// import {useAccount} from 'wagmi';
import {useUserStore} from '../../modules/AccountProgress/store/useUserStore';
import {handleKYCVerification} from '../../utilities/handleKYCVerification';
import {useIsInvestAvailable} from '../../hooks/useIsInvestAvailable';
import {isTimePassed} from '../../utilities/isTimePassed';

interface TimerProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  targetDate: Date;
  type: 'startsIn' | 'endsIn';
  endDate?: Date;
  transparentTimer?: boolean;
  hasNotificationIcon?: boolean;
  signatureUrl?: string;
  onClick?: () => void;
}

export const ToBeAnouncedTimer: FC<Omit<TimerProps, 'targetDate'>> = ({
  className,
  transparentTimer = false,
  ...props
}) => {
  return (
    <button
      className={classNames('timer bg-[rgba(221,221,221,0.20)]', className, {
        'bg-transparent': transparentTimer,
      })}
      {...props}
      type="button"
    >
      <span className="flex justify-center items-center gap-1.5">
        <span className="timer-content">TO BE ANNOUNCED</span>
      </span>
    </button>
  );
};

const SoldOutButton: FC<{className?: string}> = ({className = ''}) => {
  return (
    <Button
      text="Sold out"
      disabled
      className={`timer bg-white text-black w-full ${
        className || ''
      } !bg-[rgba(221,221,221,0.20)]`}
    />
  );
};

const TimerButton: FC<TimerProps> = ({
  targetDate,
  type,
  className,
  hasNotificationIcon = true,
  onClick,
  transparentTimer = false,
  signatureUrl,
  endDate,
  ...props
}) => {
  // year, month - 1, day, hour, minute, second, second;
  // const {address} = useAccount();
  const {user} = useUserStore();
  const {isInvestAvailable} = useIsInvestAvailable(signatureUrl);

  const {
    d: daysLeft,
    h: hoursLeft,
    m: minutesLeft,
    s: secondsLeft,
  } = useTimeDifference(targetDate.getTime());

  const [time, setTime] = useState({
    hour: hoursLeft,
    day: daysLeft,
    minute: minutesLeft,
    seconds: secondsLeft,
  });

  const [isTimerFinished, setIsTimerFinished] = useState<boolean>(
    isTimePassed(targetDate)
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!isTimePassed(targetDate)) {
      timer = setTimeout(() => {
        setTime({
          minute: minutesLeft,
          hour: hoursLeft,
          day: daysLeft,
          seconds: secondsLeft,
        });
      }, 1000);
    } else {
      setIsTimerFinished(true);
    }

    return () => clearTimeout(timer);
  }, [daysLeft, hoursLeft, minutesLeft, secondsLeft, time, targetDate]);

  if (endDate && isTimePassed(endDate)) {
    return (
      <Button
        text="Finished"
        disabled
        className={`timer bg-white text-black w-full ${
          className || ''
        } !bg-[rgba(221,221,221,0.20)]`}
      />
    );
  }

  if (!isTimerFinished) {
    return (
      <button
        type="button"
        className={classNames('timer bg-[rgba(221,221,221,0.20)]', className, {
          'bg-transparent': transparentTimer,
        })}
        {...props}
      >
        <span className="flex justify-center items-center gap-1.5">
          <span className="timer-type">
            {type === 'startsIn' ? <>Starts in:</> : 'End in:'}
          </span>
          <span className="timer-content">
            <span>{time.day}d</span>
            <span>{time.hour}h</span>
            <span>{time.minute}m</span>
            <span>{time.seconds.toString().padStart(2, '0')}s</span>
          </span>
          {hasNotificationIcon && <span>{/*  <NotifyIcon /> */}</span>}
        </span>
      </button>
    );
  }

  if (!isTimerFinished && user?.kyc?.status !== 'APPROVED') {
    return (
      <Button
        text="Pass the KYC"
        className={`w-full ${className || ''}`}
        loading={isLoading}
        onClick={() => {
          setIsLoading(true);
          void handleKYCVerification(() => setIsLoading(false));
        }}
      />
    );
  }

  return (
    <Button
      text="invest"
      disabled={!isInvestAvailable}
      className={`timer bg-white text-black w-full ${className || ''} ${
        isInvestAvailable ? '' : '!bg-[rgba(221,221,221,0.20)]'
      }`}
      onClick={onClick}
    />
  );
};

export const Timer: FC<
  Omit<TimerProps, 'endDate' | 'targetDate' | 'onClick'> & {
    endDate: TimerProps['endDate'] | null;
    targetDate: TimerProps['targetDate'] | null;
    onClick: TimerProps['onClick'] | null;
    soldOut: boolean;
  }
> = ({endDate, targetDate, soldOut, onClick, ...props}) => {
  const isTimerButtonActive = !!(endDate && targetDate);
  const isRoundFinished = !endDate || isTimePassed(endDate);
  // it's written in so filthy way because ts incorrectly determines type of this function with default value
  if (!onClick) {
    onClick = () => {};
  }

  if (soldOut && !isRoundFinished) {
    return <SoldOutButton className={props.className} />;
  }

  return isTimerButtonActive ? (
    <TimerButton
      {...props}
      endDate={endDate}
      targetDate={targetDate}
      onClick={onClick}
    />
  ) : (
    <ToBeAnouncedTimer {...props} />
  );
};
