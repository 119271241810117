import {useNavigate} from 'react-router-dom';

import {useProjectInvestStore} from '../store/useProjectInvestStore';

import {Button} from '../../../../components/Button';
import {BlocksIcon} from '../../../../assets/svgComponents/BlocksIcon';
import {successNotification} from '../../../../utilities/notifications';
import {roundNumber} from '../helpers/roundNumber';

export const SuccessfulAllocation = () => {
  const {investedProject, investedSum} = useProjectInvestStore();
  const navigate = useNavigate();

  const allocatedTokensAmount = roundNumber(
    (investedSum || 0) / (investedProject?.tokenPrice || 0)
  );

  return (
    <div className="flex justify-center items-center h-full font-sans">
      <div className="px-5 md:px-10 pt-6 pb-9 bg-[#0B0D0E] md:rounded-[20px] rounded-t-20 md:border border-[#232526] border-l-1 w-full md:w-[560px] flex flex-col justify-center">
        <div className="bg-[url('./assets/gridBg.svg')] md:min-h-[192px] flex flex-col items-end">
          <img
            className="m-auto"
            width={126}
            height={126}
            src={investedProject?.token?.iconUrl}
          ></img>
        </div>
        <div className="w-full mb-4 text-center leading-9">
          <span className="text-white text-2xl font-bold capitalize ">
            {allocatedTokensAmount}{' '}
          </span>
          <span className="text-l-600 text-opacity-60 text-base font-medium capitalize">
            {investedProject?.token?.symbol}
          </span>
        </div>
        <h3 className="mb-6 text-white text-center text-2xl leading-9 font-bold">
          You have invested successfully
        </h3>
        <p className="mb-6 md:mb-12 text-center text-xs md:text-sm leading-9 text-l-600">
          You will be able to claim your tokens later in Dashboard.
        </p>
        <Button
          text={
            <div className="flex items-center gap-2">
              GO TO DASHBOARD <BlocksIcon />
            </div>
          }
          className="w-[280px] font-mono"
          onClick={() => {
            navigate('/');
            successNotification('Allocation successful');
          }}
        />
      </div>
    </div>
  );
};
