import {
  SocialName,
  userStore,
} from '../modules/AccountProgress/store/useUserStore';

export const getSocialId = (socialName: SocialName) => {
  return userStore
    .getState()
    .user?.connectedSocials?.find(({social}) => social.name === socialName)
    ?.socialId;
};
