import {create, useStore} from 'zustand';

interface Profile {
  id: string;
  user_id: string;
  name: string;
  surname: string;
}

interface Level {
  id: string;
  value: number;
  pointsToReach: number;
  platformFee: number;
  maxAllocation: number;
}

export enum SocialName {
  Telegram = 'Telegram',
  Twitter = 'Twitter',
  Discord = 'Discord',
}

interface Social {
  id: string;
  name: SocialName | string;
  iconUrl: string;
}

interface SocialData {
  id: number;
  firstName: string;
  username: string;
}

interface LevelData {
  id: string;
  progress: number;
  userId: string;
  currentLevelId: string;
  currentLevel?: Level;
  nextLevelPoints?: Level;
}

interface Kyc {
  id: string;
  status: string;
  sessionId: string;
}

export interface ConnectedWallet {
  id: string;
  address: string;
  profile_id: string;
  created_at: string;
}

export interface ConnectedSocials {
  id: string;
  socialId: string;
  social: Social;
  socialData: SocialData;
  createdAt: string;
}

export interface UserData {
  id: string;
  email: string;
  isKol: boolean;
  profile: Profile;
  levelData: LevelData;
  connectedWallets: ConnectedWallet[];
  referralCode: string;
  kyc?: Kyc;
  profileSetupProgress: number;
  connectedSocials: ConnectedSocials[];
  totalTicketsAmount: number;
}

export interface UserStore {
  user: UserData | null;
  loading: boolean;
  error: boolean;
}

export const userStore = create<UserStore>(() => ({
  user: null,
  loading: false,
  error: false,
}));

export const useUserStore = () => useStore(userStore);
