import {TasksListProps} from '../../types/types';

import {Modal} from '../../components/Modal';
import {QuizModalBody} from '../Quiz/QuizModalBody';
import {Task} from './components/Task';

import {useTasksModalStore} from './store/useTasksModalStore';
import {tasksService} from './service/TasksService';
import {useDashboardStore} from '../Dashboard';
import {userService} from '../../services/userApi';
import {useEffect} from 'react';

export const TasksView = ({
  taskRowDirection,
  numberOfVisibleTasks,
  tasks,
  refetchTasks,
}: TasksListProps) => {
  const {selectedTaskInfo} = useTasksModalStore();
  const {invitedCount} = useDashboardStore();

  const handleModalClose = () => {
    tasksService.setSelectedTaskInfo(null);
    refetchTasks();
  };

  useEffect(() => {
    if (invitedCount <= 0) {
      userService.getInvitedCountData().catch(err => console.error(err));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ul className="flex flex-col gap-5">
        {numberOfVisibleTasks ? (
          tasks.slice(0, numberOfVisibleTasks).map(task => {
            return (
              <Task
                task={task}
                taskRowDirection={taskRowDirection}
                key={task.id}
              />
            );
          })
        ) : tasks.length ? (
          tasks.slice(0, tasks.length).map(task => {
            return (
              <Task
                task={task}
                taskRowDirection={taskRowDirection}
                key={task.id}
              />
            );
          })
        ) : (
          <div className=" text-lg font-bold">
            You don't have available tasks now
          </div>
        )}
      </ul>
      {selectedTaskInfo && (
        <Modal handleClose={handleModalClose}>
          <QuizModalBody
            handleClose={handleModalClose}
            selectedQuizInfo={selectedTaskInfo}
          />
        </Modal>
      )}
    </>
  );
};
