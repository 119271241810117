import {IAnswerResult} from '../../types';

export const AnswerResult = ({text, Icon, title}: IAnswerResult) => {
  return (
    <div className="border-solid border-l-100 bg-l-50 w-full h-fit font-sans pl-3 py-3 border rounded-lg">
      <div className="flex flex-row gap-2 items-start">
        {Icon}
        <div className="text-sm font-bold leading-[24px] capitalize text-white">
          {title}
        </div>
      </div>
      <div className="text-xs leading-[20px] text-l-600 ml-8 break-all pr-2">
        {text}
      </div>
    </div>
  );
};
