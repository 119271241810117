import {useState} from 'react';
import {MobileDialog} from '../../components/MobileDialog';
import {useWindowResize} from '../../hooks/useWindowResize';
import {LayoutDashboardView} from '../../layouts/LayoutDashboardView';
import {AccountProgressView} from '../AccountProgress/AccountProgressView';
import {AccountRoadmapView} from '../AccountRoadmap/AccountRoadmapView';
import {useLevelsStore} from './store/useLevelsStore';
import {DailyReward} from '../../components/DailyReward';
import {AccountTasksView} from './components/AccountTasksView';

export const AccountLevelView = () => {
  const [isLevelsVisible, setLevelsVisible] = useState<boolean>(false);

  const {isAdaptive} = useWindowResize(1280);
  const {levels} = useLevelsStore();

  const boxStyle =
    'border-l-100 bg-[#0E1012] border-solid border-[1px] rounded-20 w-full';

  const handleLevelsDisplay = () => {
    isAdaptive ? setLevelsVisible(state => !state) : '';
  };

  return (
    <LayoutDashboardView>
      <div className="flex flex-col-reverse items-start xl:grid xl:grid-cols-[1fr_346px] full-hd-bp:grid-cols-[1fr_440px] px-4 xl:px-10 py-8 gap-5 flex-1">
        <AccountTasksView />
        <div className={`${boxStyle}`}>
          <div className="relative px-6 pt-5 xl:pt-6 pb-5 xl:border-b-l-100 xl:border-solid xl:border-b-[1px]">
            <div className="mb-8">
              <AccountProgressView
                isViewMoreButtonDisplayed
                handleLevelsDisplay={handleLevelsDisplay}
              />
            </div>
            <DailyReward sm />
          </div>
          {!isAdaptive && levels && levels?.length > 0 && (
            <AccountRoadmapView isLevelsVisible={isLevelsVisible} />
          )}
          {isAdaptive && (
            <MobileDialog
              onOpenChange={(isVisible: boolean) => setLevelsVisible(isVisible)}
              isOpen={isLevelsVisible}
            >
              <AccountRoadmapView isLevelsVisible={isLevelsVisible} />
            </MobileDialog>
          )}
        </div>
      </div>
    </LayoutDashboardView>
  );
};
