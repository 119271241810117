import {useEffect} from 'react';
import {LayoutDashboardView} from '../../layouts/LayoutDashboardView';
import {ProjectFCO} from './components/ProjectFCO';
import {allocationsService} from './service/service';
import {useAllocationsStore} from './store/useAllocationsStore';
import {ClaimModal} from './components/ClaimModal';

export const FCOView = () => {
  const {allocations, isLoading} = useAllocationsStore();

  useEffect(() => {
    void allocationsService.getMyAllocations();
  }, []);

  return (
    <LayoutDashboardView>
      <div className="p-5 xl:p-10 flex flex-col gap-5 flex-1 h-full">
        {!isLoading &&
          (allocations.length ? (
            allocations.map(project => (
              <ProjectFCO project={project} key={project.id} />
            ))
          ) : (
            <div className="w-full h-full text-3xl font-semibold flex justify-center items-center">
              You have not invested yet
            </div>
          ))}
      </div>
      <ClaimModal />
    </LayoutDashboardView>
  );
};
