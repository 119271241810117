import {useEffect, useState} from 'react';
import {useScrollSpy} from '../../../hooks/useScrollSpy';
import {useWindowResize} from '../../../hooks/useWindowResize';
import {formatTempId} from '../../../utilities/string';
import {ProjectSideBarItem} from './ProjectSideBarItem';
import {MobileDialog} from '../../../components/MobileDialog';

export interface ArticleSideBarProps {
  items: {
    label: string;
    href: string;
  }[];
}

export const ProjectSideBar = () => {
  const [items, setItems] = useState<ArticleSideBarProps['items']>([]);

  const {isAdaptive: isMd} = useWindowResize(768);

  const activeId = useScrollSpy(
    items.map(({href}) => href.replace('#', '')),
    {
      rootMargin: isMd ? '-30% 0px -70% 0px' : '-20% 0px -80% 0px',
      threshold: [0, 0.5, 1],
    }
  );
  const {isAdaptive} = useWindowResize(768);
  const [isOpen, setIsOpen] = useState(false);

  const [targetId, setTargetId] = useState<string | null>(null);
  const [shouldIgnoreScroll, setShouldIgnoreScroll] = useState(false);

  useEffect(() => {
    if (!isAdaptive) {
      setIsOpen(false);
    }
  }, [isAdaptive]);

  useEffect(() => {
    const handleWindowScroll = () => {
      if (shouldIgnoreScroll && targetId) {
        const target = document.getElementById(targetId) as HTMLElement | null;

        if (target) {
          const box = target.getBoundingClientRect();

          const inTopView =
            box.top >= 0 &&
            box.bottom <= window.innerHeight &&
            box.top < window.innerHeight / 2;

          if (!inTopView) {
            setShouldIgnoreScroll(false);
          }
        }
      }
    };

    window.addEventListener('scrollend', handleWindowScroll);

    return () => {
      window.removeEventListener('scrollend', handleWindowScroll);
    };
  }, [shouldIgnoreScroll, targetId, activeId]);

  useEffect(() => {
    const sections = document
      .getElementById('prose')
      ?.querySelectorAll('section');

    if (!sections) return;

    const items: ArticleSideBarProps['items'] = [];

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const label = section.querySelector('h2')?.textContent ?? '';

      if (!section.id) {
        sections[i].setAttribute('id', formatTempId(label, i + 1));
      }

      if (label) {
        items.push({
          label: label || '',
          href: `#${section.id}`,
        });
      }
    }

    setItems(items);
  }, []);

  const handleOpen = () => {
    if (!isAdaptive) return;
    setIsOpen(true);
  };

  return (
    <>
      <div
        className="sticky top-[4.25rem] -translate-y-px z-10 w-[calc(100%+1.5rem)] h-min max-h-screen -mx-4 bg-[#040506] border-y border-white border-opacity-10 cursor-pointer md:overflow-auto md:cursor-default md:-mx-6 md:w-[calc(100%+3rem)] md:top-0 lg:mx-0 lg:top-0 lg:overflow-visible lg:bg-Background-Page lg:border-0 lg:max-w-[160px]"
        id="project-side-bar"
        onClick={handleOpen}
      >
        <div className="flex flex-row w-max py-4 px-5 items-start gap-4 lg:flex-col lg:min-w-40 lg:p-0 lg:pt-2 overflow-hidden">
          {items.map(({label, href}, i) => (
            <ProjectSideBarItem
              key={i}
              label={label}
              href={href}
              isActive={
                href ===
                `#${shouldIgnoreScroll && targetId ? targetId : activeId}`
              }
              isAdaptive={isAdaptive}
              onClick={() => {
                setTargetId(href.replace('#', ''));
                setShouldIgnoreScroll(true);
              }}
            />
          ))}
        </div>
      </div>
      <MobileDialog isOpen={isOpen} onOpenChange={val => setIsOpen(val)}>
        <div className="relative flex flex-col gap-3.5 px-5 z-[100]">
          {items.map(({label, href}, i) => (
            <ProjectSideBarItem
              key={i}
              label={label}
              href={href}
              isActive={href === `#${activeId}`}
              isAdaptive={false}
              className="mb-3.5 text-lg leading-6 font-normal font-sans"
              onClick={() => {
                setTargetId(href.replace('#', ''));
                setShouldIgnoreScroll(true);
                setIsOpen(false);
              }}
            />
          ))}
        </div>
      </MobileDialog>
    </>
  );
};
